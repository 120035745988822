
import { useNavigate } from "react-router-dom";
import DatePicker from "react-datepicker";
import moment from "moment";
import { HiOutlineFolderDownload } from "react-icons/hi";
import React, { useEffect, useState } from "react";
import MenuField from "components/dashboard/MenuField";
import { Button } from "components/Button";
import {
  useGetBumpaOrdersMutation,
  useImportBumpaMutation,
} from "services/slices/bumpa";
import { TableLoader } from "components/TableLoader";
import { toast } from "react-toastify";
import ModalContainer from "components/Modal";
import { Loader } from "components/Loader";
import isValidOrder from "helpers/validateBumpa";
import { useGetStatesQuery } from "services/slices/dashboard";
import { filterTerms } from "constant/data";
import { getDateRange } from "helpers/getDateRange";
import { handleErrorToast, handleSuccessToast } from "helpers/toastMessage";
import { getFromStorage } from "helpers";

function ImportOrder() {
  const [filterDate, setFilterDate] = useState<boolean>(false);
  const [selectedPeriod, setSelectedPeriod] = useState("lastyear");
  const [selectedPeriodName, setSelectedPeriodName] =
    useState<string>("Last 7 days");
  const [startDate, setStartDate] = useState<Date | null>(null);
  const [endDate, setEndDate] = useState<Date | null>(null);
  const [formattedDate, setFormattedDate] = useState<string>("");
  const [formattedEndDate, setFormattedEndDate] = useState<string>("");
  const [debouncedSearch, setDebouncedSearch] = useState<string>("");
  const [showDateModal, setShowDateModal] = useState<boolean>(false);
  const [selectOne, setSelectOne] = useState<boolean>(false);
  const [selectedOrders, setSelectedOrders] = useState<
    { [key: string]: any }[]
  >([]);
  const [statistics, setStatistics] = useState<{ [key: string]: any }[]>([]);
  const [viewData, setViewData] = useState<{ [key: string]: any }>([]);
  const [viewOrder, setViewOrder] = useState<boolean>(false);
  const [loadingAll, setLoadingAll] = useState<boolean>(false);
  const [selectedOrderNumber, setSelectedOrderNumber] = useState<string>("");

  const [orderEndDate, setOrderEndDate] = useState<string>(
    moment().add(1, "days").format("YYYY-MM-DD")
  );
  const [orderStartDate, setOrderStartDate] = useState<string>(
    moment().subtract(7, "days").format("YYYY-MM-DD")
  );
  const user: any = getFromStorage("fez-user");
  const org: any = getFromStorage("fez-org-details");
  const [getAllOrders, { data: statsData, isLoading }] =
    useGetBumpaOrdersMutation();
  const [importOrders, { isLoading: isImportLoading }] =
    useImportBumpaMutation();
  const { data: states } = useGetStatesQuery();

  const navigate = useNavigate();
  const data = JSON.parse(user);
  const orgData = JSON.parse(org);

  const getOptions = (status: any) => {
    switch (status) {
      case "UNFULFILLED":
        return [
          {
            label: "View",
            value: "VIEW_ORDER",
            icon: "/images/lock.svg",
          },
          {
            label: "Import",
            value: "IMPORT_ORDER",
            icon: "/images/import-order.svg",
          },
        ];
      default:
        return [];
    }
  };

  useEffect(() => {
    if (selectedPeriod && selectedPeriod.toLowerCase() !== "custom") {
      const { startDate, endDate } = getDateRange(selectedPeriod);
      setOrderStartDate(startDate);
      setOrderEndDate(endDate);
    }
  }, [selectedPeriod]);

  const handleDebouncedChange = (e: any) => {
    const query = e.target.value;
    setDebouncedSearch(query);
  };

  useEffect(() => {
    if (debouncedSearch) {
      const filtered = statsData?.orders.filter(
        (item: { [key: string]: any }) =>
          item?.order_number
            .toLowerCase()
            .includes(debouncedSearch.toLowerCase())
      );

      setStatistics(filtered);
    } else {
      setStatistics(statsData?.orders);
    }
  }, [debouncedSearch, statsData]);

  useEffect(() => {
    if (selectedPeriod === "custom") {
      setShowDateModal(true);
    }
  }, [selectedPeriod]);

  const onClearDate = () => {
    setSelectedPeriodName("Last 7 days");
    setSelectedPeriod("last7days");
    setOrderStartDate(moment().subtract(7, "days").format("YYYY-MM-DD"));
    setOrderEndDate(moment().add(1, "days").format("YYYY-MM-DD"));
    setShowDateModal(false);
  };

  const handlePeriodChange = (value: string) => {
    setSelectedPeriod(value);
  };

  const handleDateChange = (date: Date | null) => {
    if (date) {
      const dateStart = moment(date).format("DD-MM-YYYY");
      setStartDate(date);
      setFormattedDate(dateStart);
    }
  };

  const handleEndDateChange = (date: Date | null) => {
    if (date) {
      const dateStart = moment(date).format("DD-MM-YYYY");
      setEndDate(date);
      setFormattedEndDate(dateStart);
    }
  };

  useEffect(() => {
    if (Boolean(selectedPeriod.toLowerCase() === "custom")) {
      setShowDateModal(true);
    } else {
      setShowDateModal(false);
    }
  }, [selectedPeriod]);

  const onApplyDate = () => {
    const getOrders = async () => {
      await getAllOrders({
        startDate: formattedDate,
        endDate: formattedEndDate,
        platform: "bumpa",
      })
        .unwrap()
        .then((data) => {
          setStartDate(null);
          setEndDate(null);
          setFormattedDate("");
          setFormattedEndDate("");
        })
        .catch((e: { data: { description: string } }) => {
          handleErrorToast(e);
        });
    };
    if (formattedDate !== "" && formattedEndDate !== "") {
      setSelectedPeriodName(`${formattedDate} - ${formattedEndDate}`);
      getOrders();
      setOrderStartDate(formattedDate);
      setOrderEndDate(formattedEndDate);
    }
    setShowDateModal(false);
  };

  useEffect(() => {
    const getOrders = async () => {
      await getAllOrders({
        startDate: orderStartDate,
        endDate: orderEndDate,
        platform: "bumpa",
      });
    };
    if (selectedPeriod.toLowerCase() !== "custom") {
      getOrders();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [orderStartDate, orderEndDate, selectedPeriod]);

  const isAllSelected =
    statistics?.length > 0 && selectedOrders.length === statistics.length;

  const handleSelectAll = () => {
    if (isAllSelected) {
      setSelectedOrders([]);
    } else {
      const allOrders = statistics?.map((user: any) => user) || [];
      setSelectedOrders(allOrders);
    }
  };
  const handleSelectOne = (order: any) => {
    const isAlreadySelected = selectedOrders.some(
      (selectedOrder) => selectedOrder.id === order.id
    );

    if (isAlreadySelected) {
      setSelectedOrders(
        selectedOrders.filter((selectedOrder) => selectedOrder.id !== order.id)
      );
    } else {
      setSelectedOrders([...selectedOrders, order]);
    }
  };

  const onImportOne = async (order: any) => {
    setSelectedOrderNumber(order?.order_number);
    const validationResult = await isValidOrder(
      {
        id: order.id,
        recipientAddress: order?.shipping_details?.street,
        recipientState: order?.shipping_details?.state,
        recipientName: order?.customer?.name,
        recipientPhone: order?.customer?.phone,
      },
      states.states
    );
    if (validationResult === true) {
      const body = {
        platform: "bumpa",
        userID: data?.userID,
        orgID: orgData?.orgCode,
        clientAddress: orgData?.orgAddress,
        clientPhone: orgData?.orgPhone,
        clientName: data?.["Full Name"],
        userfullname: data?.Username,
        data: [
          {
            recipientName: order?.customer?.name,
            recipientPhone: order?.customer?.phone,
            recipientAddress: order?.shipping_details?.street,
            recipientState: order?.shipping_details?.state || null,
            additionalDetails: order?.note,
            uniqueID: order?.id,
            itemDescription: `${order?.items?.length} Item(s)`,
            valueOfItem: Number(order?.total),
            senderAddress: order?.customer_details?.street,
            senderPhone: order?.customer?.phone,
            senderName: order?.customer?.name,
            BatchID: `bumpa-${order?.id}`,
          },
        ],
      };
      await importOrders(body)
        .unwrap()
        .then((data: any) => {
          handleSuccessToast("Import successful!");
          setSelectOne(false);
          setViewOrder(false);
          navigate("/dashboard/import-orders");
        })
        .catch((e: any) => {
          setSelectOne(false);
          handleErrorToast(e);
        });
    } else {
      toast.error(validationResult, {
        className: "toast-error",
        progressClassName: "Toastify__progress-bar--error",
      });
    }
  };
  const onImportAll = async () => {
    setLoadingAll(true);
    const validationResults = await selectedOrders.map((order) => {
      const formattedOrder = {
        id: order.id,
        recipientAddress: order?.shipping_details?.street,
        recipientState: order?.shipping_details?.state,
        recipientName: order?.customer?.name,
        recipientPhone: order?.customer?.phone,
      };
      return isValidOrder(formattedOrder, states.states);
    });

    const results = await Promise.all(validationResults);

    const validOrders = selectedOrders.filter(
      (_, index) => results[index] === true
    );

    if (validOrders.length === 0) {
      setLoadingAll(false);
      results.forEach((result, index) => {
        if (result !== true) {
          toast.error(
            `Error with Order ${selectedOrders[index].id}: ${result}`,
            {
              className: "toast-error",
              progressClassName: "Toastify__progress-bar--error",
            }
          );
        }
      });
      return;
    }

    const body = {
      platform: "bumpa",
      userID: data?.userID,
      orgID: orgData?.orgCode,
      clientAddress: orgData?.orgAddress,
      clientPhone: orgData?.orgPhone,
      clientName: data?.["Full Name"],
      userfullname: data?.Username,
      data: validOrders.map((order) => ({
        recipientName: order?.customer?.name,
        recipientPhone: order?.customer?.phone,
        recipientAddress: order?.shipping_details?.street,
        recipientState: order?.shipping_details?.state || null,
        additionalDetails: order?.note,
        uniqueID: order?.id,
        itemDescription: `${order?.items?.length} Item(s)`,
        valueOfItem: Number(order?.total),
        senderAddress: order?.customer_details?.street,
        senderPhone: order?.customer?.phone,
        senderName: order?.customer?.name,
        BatchID: `bumpa-${order?.id}`,
      })),
    };

    await importOrders(body)
      .unwrap()
      .then((data: any) => {
        setLoadingAll(false);
        toast.success("Import successful!", {
          className: "toast-success",
          progressClassName: "Toastify__progress-bar--success",
        });
      })
      .catch((e: { data: { description: string } }) => {
        setLoadingAll(false);
        handleErrorToast(e);
      });

    // Handle invalid orders
    results.forEach((result, index) => {
      if (result !== true) {
        toast.error(`Error with Order ${selectedOrders[index].id}: ${result}`, {
          className: "toast-error",
          progressClassName: "Toastify__progress-bar--error",
        });
      }
    });
  };

  return (
    <>
      <div className="px-[20px] md:px-[72px] mb-[150px]">
        {!viewOrder && (
          <div className="flex flex-row justify-between items-center mt-10">
            <h1 className="text-xl font-onestBold font-[800] text-[#0A5001] tracking-wide">
              Import Order
            </h1>
            <div>
              <Button
                iconLeft
                icon={<HiOutlineFolderDownload />}
                title="Import all"
                onClick={onImportAll}
                loading={isImportLoading||loadingAll}
                disabled={selectedOrders.length < 2}
                className={` ${
                  selectedOrders?.length > 1 ? "bg-[#289D17]" : "bg-[#BEC0BE]"
                } cursor-pointer py-4 flex justify-center bmd:mt-0 text-[#E6E8E6] gap-3 items-center px-4 bmd:px-8 rounded-lg`}
              />
            </div>
          </div>
        )}

        <div className="hidden md:block">
          <div className="bg-[#F9F9FB] p-4 mt-10 rounded-lg">
            <div className="flex gap-3 items-center justify-between ">
              <div className="flex gap-3">
                <div className="w-full md:w-[279px] bg-white flex gap-2 items-center rounded-[9px] pl-4 pr-4 border-[1px] border-[#E4EBF5] ">
                  <img
                    className="w-[14px] h-[14px]"
                    src="/images/search-icon.svg"
                    alt="search-icon"
                  />
                  <input
                    className="text-[13px] py-[10px] w-[250px] outline-none"
                    type="text"
                    placeholder="Search by order number"
                    onChange={(e) => handleDebouncedChange(e)}
                    value={debouncedSearch}
                  />
                </div>
                <div
                  className="min-w-[180px] bg-white relative"
                  onClick={() => setFilterDate(!filterDate)}
                >
                  <div className="flex gap-2 items-center cursor-pointer bg-[#Fff] rounded-[9px] pl-4 pr-4 border-[1px] border-[#E4EBF5] ">
                    <img
                      className="w-[14px] h-[14px]"
                      src="/images/filter.svg"
                      alt="search-icon"
                    />
                    <p className="cursor-pointer text-[#929DAE] text-[13px] py-[10px] outline-none">
                      Filter: {selectedPeriodName}
                    </p>
                  </div>
                  {filterDate && (
                    <div className="p-4 border-[1px] w-[190px] left-0 top-11 bg-white z-10 absolute rounded-lg border-[#E4EBF5]">
                      <h1 className="text-[#929DAE] pb-3 font-[900] text-xs font-onestBold">
                        PERIOD
                      </h1>
                      <form className="">
                        {filterTerms.map((terms) => (
                          <div
                            className="group flex my-3 gap-2.5 hover:bg-[#D4F3D5] text-[#289D17] hover:py-2 rounded-lg pl-2"
                            onClick={() => {
                              setSelectedPeriodName(terms?.name);
                              handlePeriodChange(terms?.value);
                            }}
                          >
                            <p className="flex items-center mb-0 cursor-pointer ">
                              <span
                                className={`${
                                  selectedPeriod === terms.value &&
                                  "bg-[#289D17]"
                                } w-4 h-4 border border-gray-300 rounded-full flex items-center justify-center group-hover:bg-[#289D17]`}
                              >
                                <span className="w-2 h-2 bg-white rounded-full"></span>
                              </span>
                              <span
                                className={`${
                                  selectedPeriod === terms.value
                                    ? "text-[#289D17]"
                                    : "text-[#464748] group-hover:text-[#289D17]"
                                } ml-3 text-sm  `}
                              >
                                {terms.name}
                              </span>
                            </p>
                          </div>
                        ))}
                      </form>
                    </div>
                  )}
                </div>
              </div>
              <div className="text-xs text-[#4A564D] font-[500] flex gap-1.5 mr-[50px]">
                <span className="text-[#929DAE]"> Scroll right </span>
                <div className="mt-.05">
                  {" "}
                  <img src="/images/forward-arrow.svg" alt="" />
                </div>
              </div>
            </div>
            <div className="w-full whitespace-nowrap text-left border-spacing-2 overflow-auto">
              {isLoading ? (
                <TableLoader rowLength={15} />
              ) : (
                <>
                  {statistics?.length > 0 ? (
                    <table className="import-order whitespace-nowrap mt-3 w-full">
                      <thead className="bg-white !mx-12">
                        <tr className="border-b-[1px] py-5 border-[#E4EBF5]">
                          <th className="pl-2 pr-4">
                            <input
                              type="checkbox"
                              className="accent-[#289D17]"
                              onClick={handleSelectAll}
                              checked={
                                selectedOrders.length === statistics.length
                              }
                            />
                          </th>
                          <th className="py-3 text-left pl-2 pr-3 text-xs text-[#929DAE] font-onestRegular">
                            Order Number
                          </th>
                          <th className="py-3  pl-3 pr-4 text-left text-xs text-[#929DAE] font-onestRegular">
                            Customer Name
                          </th>
                          <th className="py-3 text-xs pl-6 pr-8 text-[#929DAE] font-onestRegular">
                            Payment Status
                          </th>
                          <th className="py-3 text-xs pl-4 pr-4 text-[#929DAE] font-onestRegular">
                            Shipping Status
                          </th>
                          <th className="py-3 text-xs pl-3 pr-10 text-[#929DAE] font-onestRegular">
                            Pickup Address
                          </th>
                          <th className="py-3 text-xs pl-3 pr-6 text-[#929DAE] font-onestRegular">
                            Shipping Destination
                          </th>
                          <th className="py-3 text-xs  pl-3 pr-6 text-[#929DAE] font-onestRegular">
                            Date Created
                          </th>
                          <th className="py-3 sticky right-0 text-xs border-l-[2px] border-[#E4EBF5]  pl-3 pr-0 text-[#929DAE] font-onestRegular bg-white">
                            Action
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        {statistics.map((item: any) => (
                          <tr
                            key={item?.id}
                            className="bg-[#fff] py-5 border-b-[1px] border-[#E4EBF5]"
                          >
                            <td className="pl-2">
                              <input
                                type="checkbox"
                                className="accent-[#289D17]"
                                onClick={() => handleSelectOne(item)}
                                checked={selectedOrders.some(
                                  (order) => order.id === item.id
                                )}
                              />
                            </td>
                            <td className="py-6 pl-2 pr-4 text-sm w-[100px] text-[#161718] font-onestRegular">
                              {item?.order_number}
                            </td>
                            <td className="py-4 pl-3 pr-4  text-sm font-onestRegular">
                              {`${item?.customer_details?.first_name} ${" "}
                        ${item?.customer_details?.first_name}`}
                            </td>
                            <td className="pl-8 pr-2">
                              <span
                                className={`inline px-2 text-xs py-1 rounded-md font-onestMedium font-[600] ${
                                  item.payment_status.toLowerCase() === "unpaid"
                                    ? "bg-[#F8D1D1] text-[#E64500]"
                                    : item.payment_status.toLowerCase() ===
                                      "paid"
                                    ? "bg-[#17730a]/25 text-[#206118]"
                                    : "bg-[#F8E4D1] text-[#65513F]"
                                }`}
                              >
                                {item?.payment_status}
                              </span>
                            </td>
                            <td className="pl-3 pr-2">
                              <span className="py-1 bg-[#FAEFE5] font-[600] rounded-[4px] w-[10%] px-2 text-[#65513F] text-xs">
                                {item?.shipping_status}
                              </span>
                            </td>
                            <td className="py-3 pl-2 pr-6 text-sm font-onestRegular">
                              <span className="w-[100px]">
                                {item?.pickupAddress}
                              </span>
                            </td>
                            <td className="py-3 pl-3 pr-8 text-sm font-onestRegular">
                              <span className="w-[100px]">
                                {item?.shipping_details?.street}
                              </span>
                            </td>
                            <td className="py-3 pl-3 pr-6 text-sm font-onestRegular">
                              <span className="w-[50px]">
                                {moment(item?.created_at).format(
                                  "MMM DD, YYYY"
                                )}
                              </span>
                            </td>
                            <td className="pl-4 sticky right-0 pr-6 bg-white border-l-[1px] border-[#E4EBF5] cursor-pointer ">
                              <Button
                                title="Import"
                                className="!py-1 !rounded-[4px]"
                                loading={
                                  selectOne &&
                                  item?.order_number === selectedOrderNumber &&
                                  isImportLoading
                                }
                                onClick={() => {
                                  setSelectOne(true);
                                  onImportOne(item);
                                }}
                              />
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  ) : (
                    <div className="bg-[#F9F9FB] p-0 mt-3">
                      <div className="w-full mt-0 whitespace-nowrap text-left border-spacing-2 bg-white overflow-x-clip bmd:overflow-auto">
                        <table className=" mt-0 whitespace-nowrap text-left border-spacing-2 bg-white w-full">
                          <thead className="bg-white !mx-12">
                            <tr className="border-b-[1px] border-[#E4EBF5]">
                              <th className="px-2">
                                <input type="checkbox" />
                              </th>
                              <th className="py-3 text-left  pl-4 pr-4 text-xs text-[#929DAE] font-onestRegular">
                                Order Number
                              </th>
                              <th className="py-3 text-left  pl-4 pr-4 text-xs text-[#929DAE] font-onestRegular">
                                Customer Name
                              </th>
                              <th className="py-3 text-xs  pl-4 pr-4 text-[#929DAE] font-onestRegular">
                                Payment Status
                              </th>
                              <th className="py-3 text-xs  pl-4 pr-4 text-[#929DAE] font-onestRegular">
                                Shipping Status
                              </th>
                              <th className="py-3 text-xs  pl-4 pr-4 text-[#929DAE] font-onestRegular">
                                Pickup Address
                              </th>
                              <th className="py-3 text-xs  pl-4 pr-4 text-[#929DAE] font-onestRegular">
                                Shipping Destination
                              </th>
                              <th className="py-3 text-xs  pl-4 pr-4 text-[#929DAE] font-onestRegular">
                                Date Created
                              </th>
                              <th className="py-3 text-xs  pl-4 pr-4 text-[#929DAE] font-onestRegular">
                                Action
                              </th>
                            </tr>
                          </thead>
                        </table>
                        <div className="flex justify-center py-[72px] w-full">
                          <div className="">
                            <div className="flex justify-center pb-5 w-full">
                              {" "}
                              <img src="/images/bumpa-icon.svg" alt="" />
                            </div>
                            <p className="font-onestRegular mb-2 bmd:w-full text-sm text-[#4A564D]">
                              You do not have any shipments from Bumpa
                            </p>
                            <p className="font-onestRegular w-[70%] bmd:w-full text-xs text-center text-[#A0ABA3]">
                              When you import a shipment, it will show here
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                  )}
                </>
              )}
            </div>
          </div>
        </div>

        <div className="md:hidden">
          {!viewOrder ? (
            <div>
              <div className="mt-8 sm:mt-[72px] rounded-lg max-w-[38rem] block px-3 py-6 md:hidden mx-0 sm:mx-auto border-[1px] border-[#F9F9FB] bg-[#F9F9FB]">
                <div className="flex items-center gap-3 mt-3 mb-3">
                  <input
                    type="checkbox"
                    className="accent-[#289D17]"
                    onChange={handleSelectAll}
                    checked={selectedOrders?.length === statistics?.length}
                  />
                  <div className="w-[75%] bg-[#fff] flex gap-2 items-center rounded-[9px] pl-4 pr-4 border-[1px] border-[#E4EBF5]">
                    <img
                      className="w-[14px] h-[14px]"
                      src="/images/search-icon.svg"
                      alt="search-icon"
                    />
                    <input
                      className="text-[13px] py-[10px] w-[279px] outline-none"
                      type="text"
                      placeholder="Keyword by Order"
                      onChange={(e) => handleDebouncedChange(e)}
                      value={debouncedSearch}
                    />
                  </div>
                  <div
                    className="relative"
                    onClick={() => setFilterDate(!filterDate)}
                  >
                    <div className="flex items-center gap-3 bg-white border-[1px] rounded-[9px] px-4 py-3 cursor-pointer border-[#E4EBF5]">
                      <img
                        className="w-full"
                        src="/images/filter-mobile.svg"
                        alt=""
                      />
                    </div>
                    {filterDate && (
                      <div className="p-4 border-[1px] w-[190px] right-3 top-11 bg-white z-10 absolute rounded-lg border-[#E4EBF5]">
                        <h1 className="text-[#929DAE] pb-3 font-[900] text-xs font-onestBold">
                          PERIOD
                        </h1>
                        <form className="">
                          {filterTerms.map((terms) => (
                            <div
                              className="group flex my-3 gap-2.5 hover:bg-[#D4F3D5] text-[#289D17] hover:py-2 rounded-lg pl-2"
                              onClick={() => {
                                setSelectedPeriodName(terms?.name);
                                handlePeriodChange(terms?.value);
                              }}
                            >
                              <p className="flex items-center mb-0 cursor-pointer ">
                                <span
                                  className={`${
                                    selectedPeriod === terms.value &&
                                    "bg-[#289D17]"
                                  } w-4 h-4 border border-gray-300 rounded-full flex items-center justify-center group-hover:bg-[#289D17]`}
                                >
                                  <span className="w-2 h-2 bg-white rounded-full"></span>
                                </span>
                                <span
                                  className={`${
                                    selectedPeriod === terms.value
                                      ? "text-[#289D17]"
                                      : "text-[#464748] group-hover:text-[#289D17]"
                                  } ml-3 text-sm  `}
                                >
                                  {terms.name}
                                </span>
                              </p>
                            </div>
                          ))}
                        </form>
                      </div>
                    )}
                  </div>
                </div>
                <div className="">
                  {isLoading ? (
                    <div className="my-10">
                      <Loader />
                    </div>
                  ) : (
                    <>
                      {statistics?.map((item: any) => {
                        return (
                          <div className="bg-[#fff] my-3 b" key={item.id}>
                            <div className=" border-[1px] flex rounded-lg p-3 justify-between pb-5 border-[#E4EBF5]">
                              <div className="flex gap-3">
                                <div>
                                  <input
                                    type="checkbox"
                                    className="accent-[#289D17]"
                                    onChange={() => handleSelectOne(item)}
                                    checked={selectedOrders.some(
                                      (order) => order.id === item.id
                                    )}
                                  />
                                </div>
                                <div>
                                  <p className="text-sm text-[#071227] font-[700]">
                                    Order {item.order_number}
                                  </p>
                                  <p className="pt-2 text-xs text-[#9899A1] mb-2">
                                    {moment(item?.created_at).format(
                                      "MMM DD, YYYY"
                                    )}
                                  </p>
                                  <div className="py-1 bg-[#FAEFE5] font-[600] rounded-[4px] px-2 text-[#65513F] text-xs">
                                    {item?.shipping_status}
                                  </div>
                                </div>
                              </div>
                              <div className="">
                                <MenuField
                                  onClick={(x: string) => {
                                    if (x === "VIEW_ORDER") {
                                      setViewData(item);
                                      setViewOrder(!viewOrder);
                                      return;
                                    }
                                    if (x === "IMPORT_ORDER") {
                                      navigate(
                                        `/dashboard/shipments/edit-shipment/${item.order_number}`
                                      );
                                      return;
                                    }
                                  }}
                                  options={getOptions(item?.shipping_status)}
                                >
                                  <div className="align-bottom mt-1">
                                    <svg
                                      width="24"
                                      height="24"
                                      viewBox="0 0 24 24"
                                      fill="none"
                                      xmlns="http://www.w3.org/2000/svg"
                                    >
                                      <path
                                        d="M12 18L12 18.01M12 12L12 12.01M12 6L12 6.01M12 13C11.4477 13 11 12.5523 11 12C11 11.4477 11.4477 11 12 11C12.5523 11 13 11.4477 13 12C13 12.5523 12.5523 13 12 13ZM12 19C11.4477 19 11 18.5523 11 18C11 17.4477 11.4477 17 12 17C12.5523 17 13 17.4477 13 18C13 18.5523 12.5523 19 12 19ZM12 7C11.4477 7 11 6.55228 11 6C11 5.44772 11.4477 5 12 5C12.5523 5 13 5.44772 13 6C13 6.55228 12.5523 7 12 7Z"
                                        stroke="#5C6881"
                                        strokeWidth="2"
                                        strokeLinecap="round"
                                        strokeLinejoin="round"
                                      />
                                    </svg>
                                  </div>
                                </MenuField>
                              </div>
                            </div>
                          </div>
                        );
                      })}
                    </>
                  )}
                </div>
              </div>
            </div>
          ) : (
            <div>
              <div
                onClick={() => setViewOrder(!viewOrder)}
                className="flex gap-1 mt-14 md:mt-0 ml-4 cursor-pointer w-[100px] md:hidden"
              >
                <div>
                  {" "}
                  <img className="w-3 h-3" src="/images/backArrow.svg" alt="" />
                </div>
                <p className="text-[#4a564d] font-[500] text-[13px] -mt-1 font-onestMedium">
                  Go back
                </p>
              </div>
              <div className="mt-4 rounded-lg max-w-[38rem] block px-5 py-6 mb-[150px] md:hidden sm:mx-auto border-[1px] border-[#E1F0DF] bg-dashboard-gradient">
                <h1 className="text-[#0A5001] font-[900] text-xl md:text-2xl mb-2 font-onestBold">
                  Order - {viewData?.order_number}
                </h1>
                <span className="text-xs text-[#65513F] bg-[#F8E4D1] px-2 py-1 rounded-md font-[500] font-onestMedium">
                  {viewData?.shipping_status}
                </span>
                <div className="border-[1px] rounded-lg mt-8 bg-white border-[#E4EBF5]">
                  <div className="font-onestRegular mt-[30px] py-4 px-3">
                    <h1 className="font-onestRegular ">Customer Name</h1>
                    <p className="font-onestMedium ">
                      {viewData?.customer?.name}
                    </p>
                  </div>
                  <div className="px-3 py-4 ">
                    <h1 className="font-onestRegular">Payment Status</h1>
                    <p className="font-onestMedium ">
                      {" "}
                      {viewData?.payment_status}
                    </p>
                  </div>
                  <div className=" py-4 px-3">
                    <h1 className="font-onestRegular">Pickup Address</h1>
                    <p className="font-onestMedium">
                      {" "}
                      {viewData?.customer_details?.street}
                    </p>
                  </div>
                  <div className="py-4  px-3">
                    <h1 className="font-onestRegular ">Shipping Destination</h1>
                    <p className="font-onestMedium ">
                      {viewData?.shipping_details?.street}
                    </p>
                  </div>
                  <div className="py-4 px-3">
                    <h1 className="font-onestRegular">Date Created</h1>
                    <p className="font-onestMedium ">
                      {" "}
                      {moment(viewData?.created_at).format("DD MMM,YYYY")}
                    </p>
                  </div>
                </div>
                <div className="mt-8">
                  <Button
                    title="Import"
                    className="!py-2.5"
                    onClick={() => onImportOne(viewData)}
                    loading={isImportLoading}
                  />
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
      <ModalContainer
        open={showDateModal}
        showCloseIcon={false}
        tailwindClassName="max-w-[466px]"
      >
        <div className="w-full p-8 bg-white flex flex-col  rounded-[12px]">
          <p className="text-lg text-center pb-4 font-onestBold text-[#0A5001] ">
            Custom date range
          </p>
          <div className="flex gap-3 justify-between">
            <div className="">
              <DatePicker
                placeholderText={"Start date"}
                selected={startDate}
                onChange={handleDateChange}
                className="border-[1px] border-[#E4EBF5] w-[150px] p-2.5 rounded-lg outline-none"
              />
            </div>
            <div className="">
              <DatePicker
                placeholderText={"End date"}
                selected={endDate}
                onChange={handleEndDateChange}
                className="border-[1px] border-[#E4EBF5] w-[150px] p-2.5 rounded-lg outline-none"
              />
            </div>
          </div>
          <div className="flex justify-between gap-3 items-center pt-[30px]">
            <Button
              title="Clear"
              onClick={onClearDate}
              className="!py-[10px] !border-[#BEC0BE] !px-12 !w-fit !rounded-lg !bg-transparent !border !text-[#289D17]"
            />
            <Button
              title="Apply"
              className="!py-[10px] !w-fit !rounded-lg !px-12 !bg-[#127E04] !text-[#fff]"
              onClick={onApplyDate}
            />
          </div>
        </div>
      </ModalContainer>
    </>
  );
}

export default ImportOrder;
