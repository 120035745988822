export const saveToStorage = (
  name: string,
  data: any ,
  shouldRemember?: boolean
) => {
  if (shouldRemember) {
    removeFromStorage(name);
    localStorage.setItem(name, data);
  } else {
    sessionStorage.setItem(name, data);
  }
};

export const getFromStorage = (name: string) => {
  if (!name) return null; 

  const item =
  sessionStorage.getItem(name)|| localStorage.getItem(name)


  return item; 
};


export const removeFromStorage = (name: string) => {
  localStorage.removeItem(name);
  sessionStorage.removeItem(name);
};
