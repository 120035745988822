import { useEffect, useState } from "react";
import { TextInput } from "components/common/form/Input";
import { Button } from "components/Button";
import ModalContainer from "components/Modal";
import { DropDownSelect } from "components/common/form/Dropdown";
import Card from "components/dashboard/Card";
import Dropdown from "components/common/form/SelectDropdown";
import { useGetStatesQuery } from "services/slices/dashboard";
import getSymbolFromCurrency from "currency-symbol-map";
import {
  useGetExportCostPriceMutation,
  useGetImportCostPriceMutation,
  useGetOrderPriceMutation,
} from "services/slices/pricing";
import { formatMoney } from "helpers/formatMoney";
import { useNavigate } from "react-router-dom";
import { deliveryModes, deliveryType } from "constant/data";
import { handleErrorToast } from "helpers/toastMessage";
import {
  useGetCountryQuery,
  useGetExportCountryQuery,
} from "services/slices/shipNow";
import { useGetOrderWeightQuery } from "services/slices/shipNow";

type Ilist = {
  id: number;
  name: string;
};
interface LocalCostProps {
  state: string;
  pickupState: string;
  weight: number;
}
interface ImportCostProps {
  destinationState?: string;
  importLocationId?: number;
  weight: number;
  referralCode?: string;
}
interface State {
  id: number;
  state: string;
  country: {
    name: string;
  };
}
interface ExportState {
  id: number;
  name: string;
}
const intialValues = {
  dropOffState: "",
  importDropOffState: "",
  pickUpState: "",
  weight: "",
  importWeight: "",
  exportWeight: "",
  quantity: "",
  exportQuantity: "",
  exportPickUpState: "",
  country: "",
  exportCountry: "",
};

const Pricing = () => {
  const navigate = useNavigate();
  const [deliveryDetails, setDeliveryDetails] = useState(intialValues);
  const [query, setQuery] = useState("");
  const [isOpen, setIsOpen] = useState(false);
  const [weightIsOpen, setWeightIsOpen] = useState(false);
  const [selectedOption, setSelectedOption] = useState<Ilist>();
  const [selectedWeightOption, setSelectedWeightOption] = useState<Ilist>();
  const [dropOffstateName, setDropOffStateName] = useState<string>("");
  const [currencyCode, setCurrencyCode] = useState<string>("");
  const [importDropOffStateName, setImportDropOffStateName] =
    useState<string>("");
  const [countryStateName, setCountryStateName] = useState<string>("");
  const [exportCountryStateName, setExportCountryStateName] =
    useState<string>("");
  const [pickUpstateName, setPickUpStateName] = useState<string>("");
  const [exportPickUpStateName, setExportPickUpStateName] =
    useState<string>("");
  const [formErrors, setFormErrors] = useState<any>({});
  const [deliveryMode, setDeliveryMode] = useState("");
  const [isOpenDeliveryMode, setIsOpenDeliveryMode] = useState(false);
  const [searchDropOffState, setSearchDropOffState] = useState<string>("");
  const [searchPickUpState, setSearchPickUpState] = useState<string>("");
  const [searchCountryState, setSearchCountryState] = useState<string>("");
  const { data } = useGetStatesQuery();
  const { data: countryData } = useGetCountryQuery();
  const { data: exportCountryData } = useGetExportCountryQuery();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [getOrderCost, { isLoading }] = useGetOrderPriceMutation();

  const {
    data: weightData,
    isLoading: isWeightLoading,
    refetch: refetchWeight,
  } = useGetOrderWeightQuery({ id: deliveryDetails?.exportCountry });

  const [getImportCostPrice, { isLoading: importLoading }] =
    useGetImportCostPriceMutation();
  const [getExportCostPrice, { isLoading: exportLoading }] =
    useGetExportCostPriceMutation();
  const [amount, setAmount] = useState("");

  const validateStepOne = (values: any) => {
    let errors: any = {};
    if (!values.pickUpState || values.pickUpState === "") {
      errors.pickUpState = "Pick up state is required";
    } else if (!values.dropOffState || values.dropOffState === "") {
      errors.dropOffState = "Drop off state is required";
    } else if (!values.deliveryMode || values.deliveryMode === "") {
      errors.deliveryMode = "DeliveryMode is required";
    } else if (!values.weight || values.weight === "") {
      errors.weight = "Weight is required";
    }
    return errors;
  };

  const validateStepTwo = (values: any) => {
    let errors: any = {};
    if (!values.importDropOffState || values.importDropOffState === "") {
      errors.importDropOffState = "Drop off state is required";
    } else if (!values.importWeight || values.importWeight === "") {
      errors.importWeight = "Weight is required";
    } else if (!values.country || values.country === "") {
      errors.country = "Select  country";
    }
    return errors;
  };

  const validateStepThree = (values: any) => {
    let errors: any = {};
    if (!values.exportPickUpState || values.exportPickUpState === "") {
      errors.exportPickUpState = "Pick-up state is required";
    } else if (!values.exportWeight || values.exportWeight === "") {
      errors.exportWeight = "Weight is required";
    } else if (!values.exportCountry || values.exportCountry === "") {
      errors.exportCountry = "Select  country";
    }
    return errors;
  };

  const handleOptionClick = (option: any) => {
    setSelectedOption(option);
    setQuery(option.name);
    setIsOpen(false);
    setFormErrors((prev: any) => ({
      ...prev,
      deliveryType: "",
    }));
  };

  const handleDeliveryModeClick = (option: any) => {
    setDeliveryMode(option.name);
    setDeliveryDetails((prev) => ({
      ...prev,
      deliveryMode: option.name,
    }));
    setFormErrors((prev: any) => ({
      ...prev,
      deliveryMode: "",
    }));
    setIsOpenDeliveryMode(false);
  };

  const toggleDropdown = () => {
    setIsOpen((prev) => !prev);
  };

  const toggleDeliveryMode = () => {
    setIsOpenDeliveryMode((prev) => !prev);
  };

  useEffect(() => {
    if (deliveryDetails?.exportCountry) {
      refetchWeight();
    }
  }, [deliveryDetails?.exportCountry, refetchWeight]);

  const filteredDropOffSearch = data?.states
    ?.filter((item: State) =>
      item.state?.toLowerCase()?.includes(searchDropOffState.toLowerCase())
    )
    ?.sort((a: State, b: State) => a.state.localeCompare(b.state));
  const filteredCountrySearch = countryData?.data?.importLocations
    ?.filter((item: State) =>
      item.country.name
        ?.toLowerCase()
        ?.includes(searchCountryState.toLowerCase())
    )
    ?.sort((a: State, b: State) =>
      (a?.country?.name || "").localeCompare(b?.country?.name || "")
    );
  const filteredExportCountrySearch = exportCountryData?.data?.exportLocations
    ?.filter((item: ExportState) =>
      item?.name?.toLowerCase()?.includes(searchCountryState?.toLowerCase())
    )
    ?.sort((a: ExportState, b: ExportState) => a.name.localeCompare(b.name));

  const filteredPickUpSearch = data?.states
    ?.filter((item: State) =>
      item.state?.toLowerCase()?.includes(searchPickUpState.toLowerCase())
    )
    ?.sort((a: State, b: State) => a.state.localeCompare(b.state));

  const onSubmitLocalDelivery = async (e: any) => {
    e.preventDefault();
    setFormErrors(validateStepOne(deliveryDetails));
    const body: LocalCostProps = {
      state: deliveryDetails.dropOffState,
      pickupState: deliveryDetails.pickUpState,
      weight: Number(deliveryDetails.weight),
    };
    if (
      deliveryDetails.dropOffState &&
      deliveryDetails.weight &&
      deliveryDetails.pickUpState
    ) {
      await getOrderCost(body)
        .unwrap()
        .then((data) => {
          setIsModalOpen(true);
          setAmount(data?.Cost?.cost);
        })
        .catch((e: { data: { description: string } }) => {
          handleErrorToast(e);
        });
    }
  };

  const onSubmitImportDelivery = async (e: any) => {
    e.preventDefault();
    setFormErrors(validateStepTwo(deliveryDetails));
    const body: ImportCostProps = {
      destinationState: deliveryDetails.importDropOffState,
      importLocationId: Number(deliveryDetails.country),
      weight: Number(deliveryDetails.importWeight),
      referralCode: "",
    };
    if (
      deliveryDetails.importDropOffState &&
      deliveryDetails.importWeight &&
      deliveryDetails.country
    ) {
      await getImportCostPrice(body)
        .unwrap()
        .then((data) => {
          setIsModalOpen(true);
          setAmount(data?.data?.price);
        })
        .catch((e: { data: { description: string } }) => {
          handleErrorToast(e);
        });
    }
  };

  const onSubmitExportDelivery = async (e: any) => {
    e.preventDefault();
    setFormErrors(validateStepThree(deliveryDetails));
    const body: any = {
      destinationState: deliveryDetails.exportPickUpState,
      exportLocationId: Number(deliveryDetails.exportCountry),
      weightId: Number(deliveryDetails.exportWeight),
      referralCode: "",
    };
    if (
      deliveryDetails.exportPickUpState &&
      deliveryDetails.exportWeight &&
      deliveryDetails.exportCountry
    ) {
      await getExportCostPrice(body)
        .unwrap()
        .then((data) => {
          setIsModalOpen(true);
          setAmount(data?.data?.discountedRate);
        })
        .catch((e: { data: { description: string } }) => {
          handleErrorToast(e);
        });
    }
  };

  return (
    <>
      <div className="mb-10">
        <Card>
          <h1 className="text-2xl  font-onestBold font-[800] text-[#0a5001] tracking-wide">
            Delivery estimate
          </h1>
          <p className="text-[#a0aba3] text-base font-[500] font-onestMedium">
            Get a free delivery quote for import and exports
          </p>
          <form
            action=""
            onSubmit={
              selectedOption?.id === 1
                ? onSubmitLocalDelivery
                : selectedOption?.id === 2
                ? onSubmitImportDelivery
                : onSubmitExportDelivery
            }
          >
            <div className="mt-10 border-b-[1px] pb-6 border-[#E4EBF5]">
              <DropDownSelect
                title="Select delivery type"
                lists={deliveryType}
                value={query}
                setQuery={setQuery}
                isOpen={isOpen}
                toggleDropdown={toggleDropdown}
                handleOptionClick={handleOptionClick}
                placeholder="Select type"
                name="example-dropdown"
              />
              {formErrors.deliveryType && (
                <span className="text-xs text-[#BA3838] font-onestMedium">
                  {formErrors.deliveryType}
                </span>
              )}
            </div>

            {selectedOption?.id === 1 && (
              <>
                <div className="mt-6 pb-6">
                  <DropDownSelect
                    title="Delivery mode"
                    lists={deliveryModes}
                    value={deliveryMode}
                    isRequired
                    setQuery={setDeliveryMode}
                    isOpen={isOpenDeliveryMode}
                    toggleDropdown={toggleDeliveryMode}
                    handleOptionClick={handleDeliveryModeClick}
                    placeholder="Select delivery mode"
                    name="example-dropdown"
                  />
                  {formErrors.deliveryMode && (
                    <span className="text-xs text-[#BA3838] font-onestMedium">
                      {formErrors.deliveryMode}
                    </span>
                  )}
                </div>
                <div className="">
                  <TextInput
                    type="number"
                    label="Item weight"
                    isWeight
                    isRequired
                    value={deliveryDetails.weight}
                    onChange={(e) => {
                      setDeliveryDetails((prev) => ({
                        ...prev,
                        weight: e.target.value,
                      }));
                      setFormErrors((prev: any) => ({
                        ...prev,
                        weight: "",
                      }));
                    }}
                    placeholder={"Item weight"}
                  />
                  {formErrors.weight && (
                    <span className="text-xs text-[#BA3838] font-onestMedium">
                      {formErrors.weight}
                    </span>
                  )}
                </div>
                <div className="mt-6">
                  <div className="flex ">
                    <p className="text-[#4A564D] mb-2 text-sm leading-[20px] font-onestMedium">
                      Pick up State
                    </p>
                    <span className="text-red-500 text-sm">*</span>
                  </div>
                  <Dropdown
                     selectPlaceholderName="pick-up state"
                     dropdownStateName={pickUpstateName}
                     dropdownValue={deliveryDetails?.pickUpState}
                     searchSubject={searchPickUpState}
                     setSearchSubject={setSearchPickUpState}
                    dropdownContainerClasses={`shadow-cardShadow-4 !w-full !right-0  border border-slate-100  rounded `}
                    dropdownOptions={[
                      {
                        customChild: (
                          <div>
                            <div>
                              {filteredPickUpSearch?.map(
                                (item: any, idx: number) => {
                                  return (
                                    <div
                                      key={idx}
                                      onClick={() => {
                                        setDeliveryDetails((prev) => ({
                                          ...prev,
                                          pickUpState: item.state,
                                        }));
                                        setSearchPickUpState("");
                                        setPickUpStateName(item?.state);
                                        setFormErrors((prev: any) => ({
                                          ...prev,
                                          pickUpState: "",
                                        }));
                                      }}
                                    >
                                      <div className="px-3 py-2  hover:bg-[#edecec] cursor-pointer">
                                        <p>{item?.state}</p>
                                      </div>
                                    </div>
                                  );
                                }
                              )}
                            </div>
                          </div>
                        ),
                      },
                    ]}
                  />
                  {formErrors.pickUpState && (
                    <span className="text-xs text-[#BA3838] font-onestMedium">
                      {formErrors.pickUpState}
                    </span>
                  )}
                </div>
                <div className="mt-6">
                  <div className="flex">
                    <p className="text-[#4A564D] mb-2 text-sm leading-[20px] font-onestMedium">
                      Drop-off State
                    </p>
                    <span className="text-red-500 text-sm">*</span>
                  </div>
                  <Dropdown
                    selectPlaceholderName="drop-off state"
                    dropdownStateName={dropOffstateName}
                    dropdownValue={deliveryDetails?.dropOffState}
                    searchSubject={searchDropOffState}
                    setSearchSubject={setSearchDropOffState}
                    
                    dropdownContainerClasses={`shadow-cardShadow-4 !w-full !right-0  border border-slate-100  rounded `}
                    dropdownOptions={[
                      {
                        customChild: (
                          <div>
                            <div>
                              {filteredDropOffSearch?.map(
                                (item: any, idx: number) => {
                                  return (
                                    <div
                                      key={idx}
                                      onClick={() => {
                                        setDeliveryDetails((prev) => ({
                                          ...prev,
                                          dropOffState: item.state,
                                        }));
                                        setSearchDropOffState("");
                                        setDropOffStateName(item?.state);
                                        setFormErrors((prev: any) => ({
                                          ...prev,
                                          dropOffState: "",
                                        }));
                                      }}
                                    >
                                      <div className="px-3 py-2  hover:bg-[#edecec] cursor-pointer">
                                        <p>{item?.state}</p>
                                      </div>
                                    </div>
                                  );
                                }
                              )}
                            </div>
                          </div>
                        ),
                      },
                    ]}
                  />
                  {formErrors.dropOffState && (
                    <span className="text-xs text-[#BA3838] font-onestMedium">
                      {formErrors.dropOffState}
                    </span>
                  )}
                </div>
              </>
            )}

            {selectedOption?.id === 2 && (
              <>
                <div className="grid grid-cols-2 gap-x-4 mt-4">
                  <div className="">
                    <TextInput
                      type="number"
                      label="Item weight"
                      isWeight
                      isRequired
                      name="importWeight"
                      value={deliveryDetails.importWeight}
                      onChange={(e) => {
                        setDeliveryDetails((prev) => ({
                          ...prev,
                          importWeight: e.target.value,
                        }));
                        setFormErrors((prev: any) => ({
                          ...prev,
                          importWeight: "",
                        }));
                      }}
                      placeholder={"Item weight"}
                    />
                    {formErrors.importWeight && (
                      <span className="text-xs text-[#BA3838] font-onestMedium">
                        {formErrors.importWeight}
                      </span>
                    )}
                  </div>
                  <div>
                    <TextInput
                      type="number"
                      label="Quantity"
                      name="quantity"
                      value={deliveryDetails.quantity}
                      onChange={(e) => {
                        setDeliveryDetails((prev) => ({
                          ...prev,
                          quantity: e.target.value,
                        }));
                        setFormErrors((prev: any) => ({
                          ...prev,
                          quantity: "",
                        }));
                      }}
                      placeholder={"Enter quantity"}
                    />
                    {formErrors.quantity && (
                      <span className="text-xs text-[#BA3838] font-onestMedium">
                        {formErrors.quantity}
                      </span>
                    )}
                  </div>
                </div>
                <div className="mt-6">
                  <div className="flex">
                    <p className="text-[#4A564D] mb-2 text-sm leading-[20px] font-onestMedium">
                      Select country
                    </p>
                    <span className="text-red-500 text-sm">*</span>
                  </div>
                  <Dropdown
                    selectPlaceholderName="country"
                    dropdownStateName={countryStateName}
                    dropdownValue={deliveryDetails?.country}
                    searchSubject={searchCountryState}
                    setSearchSubject={setSearchCountryState}
                    dropdownContainerClasses={`shadow-cardShadow-4 !w-full !right-0  border border-slate-100  rounded `}
                    dropdownOptions={[
                      {
                        customChild: (
                          <div>
                            <div>
                              {filteredCountrySearch?.map(
                                (item: any, idx: number) => {
                                  return (
                                    <div
                                      key={idx}
                                      onClick={() => {
                                        setSearchCountryState("");
                                        setDeliveryDetails((prev) => ({
                                          ...prev,
                                          country: item.id,
                                        }));
                                        setCurrencyCode(item?.currency?.code);
                                        setCountryStateName(
                                          item?.country?.name
                                        );
                                        setFormErrors((prev: any) => ({
                                          ...prev,
                                          country: "",
                                        }));
                                      }}
                                    >
                                      <div className="px-3 py-2  hover:bg-[#edecec] cursor-pointer">
                                        <p>{item?.country?.name}</p>
                                      </div>
                                    </div>
                                  );
                                }
                              )}
                            </div>
                          </div>
                        ),
                      },
                    ]}
                  />
                  {formErrors.country && (
                    <span className="text-xs text-[#BA3838] font-onestMedium">
                      {formErrors.country}
                    </span>
                  )}
                </div>
                <div className="mt-6">
                  <div className="flex">
                    <p className="text-[#4A564D] mb-2 text-sm leading-[20px] font-onestMedium">
                      Drop-off State
                    </p>
                    <span className="text-red-500 text-sm">*</span>
                  </div>
                  <Dropdown
                    selectPlaceholderName="drop-off state"
                    dropdownStateName={importDropOffStateName}
                    dropdownValue={deliveryDetails?.importDropOffState}
                    searchSubject={searchDropOffState}
                    setSearchSubject={setSearchDropOffState}
                    dropdownContainerClasses={`shadow-cardShadow-4 !w-full !right-0  border border-slate-100  rounded `}
                    dropdownOptions={[
                      {
                        customChild: (
                          <div>
                            <div>
                              {filteredDropOffSearch?.map(
                                (item: any, idx: number) => {
                                  return (
                                    <div
                                      key={idx}
                                      onClick={() => {
                                        setDeliveryDetails((prev) => ({
                                          ...prev,
                                          importDropOffState: item.state,
                                        }));
                                        setImportDropOffStateName(item?.state);
                                        setFormErrors((prev: any) => ({
                                          ...prev,
                                          importDropOffState: "",
                                        }));
                                      }}
                                    >
                                      <div className="px-3 py-2  hover:bg-[#edecec] cursor-pointer">
                                        <p>{item?.state}</p>
                                      </div>
                                    </div>
                                  );
                                }
                              )}
                            </div>
                          </div>
                        ),
                      },
                    ]}
                  />
                  {formErrors.importDropOffState && (
                    <span className="text-xs text-[#BA3838] font-onestMedium">
                      {formErrors.importDropOffState}
                    </span>
                  )}
                </div>
              </>
            )}

            {selectedOption?.id === 3 && (
              <>
                <div className="mt-6">
                  <div className="flex">
                    <p className="text-[#4A564D] mb-2 text-sm leading-[20px] font-onestMedium">
                      Select Drop-off country
                    </p>
                    <span className="text-red-500 text-sm">*</span>
                  </div>
                  <Dropdown
                    selectPlaceholderName="country"
                    dropdownStateName={exportCountryStateName}
                    dropdownValue={deliveryDetails?.exportCountry}
                    searchSubject={searchCountryState}
                    setSearchSubject={setSearchCountryState}
                    dropdownContainerClasses={`shadow-cardShadow-4 !w-full !right-0  border border-slate-100  rounded `}
                    dropdownOptions={[
                      {
                        customChild: (
                          <div>
                            <div>
                              {filteredExportCountrySearch?.map(
                                (item: any, idx: number) => {
                                  return (
                                    <div
                                      key={idx}
                                      onClick={() => {
                                        setSearchCountryState("");
                                        setDeliveryDetails((prev) => ({
                                          ...prev,
                                          exportCountry: item.id,
                                        }));
                                        setExportCountryStateName(item?.name);
                                        setFormErrors((prev: any) => ({
                                          ...prev,
                                          exportCountry: "",
                                        }));
                                      }}
                                    >
                                      <div className="px-3 py-2  hover:bg-[#edecec] cursor-pointer">
                                        <p>{item?.name}</p>
                                      </div>
                                    </div>
                                  );
                                }
                              )}
                            </div>
                          </div>
                        ),
                      },
                    ]}
                  />
                  {formErrors.exportCountry && (
                    <span className="text-xs text-[#BA3838] font-onestMedium">
                      {formErrors.exportCountry}
                    </span>
                  )}
                </div>
                <div className="grid grid-cols-2 gap-x-4 mt-4">
                  <div className="">
                    <DropDownSelect
                      isRequired
                      showcheckCountrySelected
                      checkCountrySelected={deliveryDetails.exportCountry}
                      title="Select weight"
                      lists={weightData?.weight}
                      value={selectedWeightOption?.name}
                      loading={isWeightLoading}
                      isOpen={weightIsOpen}
                      toggleDropdown={() => setWeightIsOpen((prev) => !prev)}
                      handleOptionClick={(e: any) => {
                        setSelectedWeightOption(e);
                        setDeliveryDetails((prev) => ({
                          ...prev,
                          exportWeight: e.id,
                        }));
                        setWeightIsOpen(false);
                        setFormErrors((prev: any) => ({
                          ...prev,
                          exportWeight: "",
                        }));
                      }}
                      placeholder="Select weight"
                    />
                    {formErrors.exportWeight && (
                      <span className="text-xs text-[#BA3838] font-onestMedium">
                        {formErrors.exportWeight}
                      </span>
                    )}
                  </div>
                  <div>
                    <TextInput
                      type="number"
                      label="Quantity"
                      name="exportQuantity"
                      value={deliveryDetails.exportQuantity}
                      onChange={(e) => {
                        setDeliveryDetails((prev) => ({
                          ...prev,
                          exportQuantity: e.target.value,
                        }));
                        setFormErrors((prev: any) => ({
                          ...prev,
                          exportQuantity: "",
                        }));
                      }}
                      placeholder={"Enter quantity"}
                    />
                    {formErrors.exportQuantity && (
                      <span className="text-xs text-[#BA3838] font-onestMedium">
                        {formErrors.exportQuantity}
                      </span>
                    )}
                  </div>
                </div>
                <div className="mt-6">
                  <div className="flex">
                    <p className="text-[#4A564D] mb-2 text-sm leading-[20px] font-onestMedium">
                      Pick-up State
                    </p>
                    <span className="text-red-500 text-sm">*</span>
                  </div>
                  <Dropdown
                    selectPlaceholderName="state"
                    dropdownStateName={exportPickUpStateName}
                    dropdownValue={deliveryDetails?.exportPickUpState}
                    searchSubject={searchPickUpState}
                    setSearchSubject={setSearchPickUpState}
                    dropdownContainerClasses={`shadow-cardShadow-4 !w-full !right-0  border border-slate-100  rounded `}
                    dropdownOptions={[
                      {
                        customChild: (
                          <div>
                            <div>
                              {filteredPickUpSearch?.map(
                                (item: any, idx: number) => {
                                  return (
                                    <div
                                      key={idx}
                                      onClick={() => {
                                        setSearchPickUpState("");
                                        setDeliveryDetails((prev) => ({
                                          ...prev,
                                          exportPickUpState: item.state,
                                        }));
                                        setExportPickUpStateName(item?.state);
                                        setFormErrors((prev: any) => ({
                                          ...prev,
                                          exportPickUpState: "",
                                        }));
                                      }}
                                    >
                                      <div className="px-3 py-2  hover:bg-[#edecec] cursor-pointer">
                                        <p>{item?.state}</p>
                                      </div>
                                    </div>
                                  );
                                }
                              )}
                            </div>
                          </div>
                        ),
                      },
                    ]}
                  />
                  {formErrors.exportPickUpState && (
                    <span className="text-xs text-[#BA3838] font-onestMedium">
                      {formErrors.exportPickUpState}
                    </span>
                  )}
                </div>
              </>
            )}

            <div className="w-full sm:w-[120px]">
              <Button
                className={`mt-10 py-3 text-base text-[#0A5001] font-[600] ${
                  selectedOption?.id === 1 &&
                  (!deliveryDetails.dropOffState ||
                    !deliveryDetails.pickUpState ||
                    !deliveryDetails.weight ||
                    !deliveryMode) &&
                  "bg-[#8C9D8A]"
                } ${
                  selectedOption?.id === 2 &&
                  (!deliveryDetails.importWeight ||
                    !deliveryDetails.importDropOffState ||
                    !deliveryDetails.country) &&
                  "bg-[#8C9D8A]"
                } 
                ${
                  selectedOption?.id === 3 &&
                  (!deliveryDetails.exportWeight ||
                    !deliveryDetails.exportPickUpState ||
                    !deliveryDetails.exportCountry) &&
                  "bg-[#8C9D8A]"
                } 
                ${!selectedOption?.id && "bg-[#8C9D8A]"}`}
                title="Submit"
                type="submit"
                disabled={isLoading || importLoading || exportLoading}
                loading={isLoading || importLoading || exportLoading}
              />
            </div>
          </form>
        </Card>
      </div>
      <ModalContainer
        open={isModalOpen}
        showCloseIcon={false}
        tailwindClassName="w-[350px] sm:w-[420px]"
        closeModal={() => setIsModalOpen(false)}
      >
        <div className="w-full px-8 pb-8 bg-white flex flex-col  rounded-[12px]">
          <div
            onClick={() => setIsModalOpen(false)}
            className="flex justify-end mt-6 cursor-pointer"
          >
            <img src="/images/close.svg" alt="" />
          </div>
          <p className="text-lg pb-4 text-center border-b border-[#E4EBF5] font-onestBold text-[#0A5001] ">
            Delivery Estimate
          </p>
          <div className="flex mt-7 justify-center">
            <img src="/images/fezbox.svg" alt="" />
          </div>
          <p className="pt-8 text-sm text-center text-[#0E1728] font-onestRegular">
            Your delivery estimate is
          </p>
          {selectedOption?.id === 2 ? (
            <h1 className="text-[28px] font-[600] font-onestMedium text-center">
              {getSymbolFromCurrency(currencyCode)}
              {formatMoney().format(amount)}
            </h1>
          ) : (
            <h1 className="text-[28px] font-[600] font-onestMedium text-center">
              ₦{formatMoney().format(amount)}
            </h1>
          )}

          <div className="flex justify-center pt-[30px]">
            <button
              className="!py-[10px] !w-fit !rounded-[100px] !px-16 flex gap-2 items-center !bg-[#18af04] !text-[#fff]"
              onClick={() => navigate("/dashboard/ship-now")}
            >
              Ship Now
              <img src="/images/arrowLeft.svg" alt="arrow-icon" />
            </button>
          </div>
        </div>
      </ModalContainer>
    </>
  );
};

export default Pricing;
