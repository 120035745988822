import moment from "moment";

export const onDateChange = (
  date: Date | null,
  setDate: React.Dispatch<React.SetStateAction<Date | null>>,
  setFormattedDate: React.Dispatch<React.SetStateAction<string>>,
  format: string = "DD-MM-YYYY"
) => {
  if (date) {
    const formattedDate = moment(date).format(format);
    setDate(date);
    setFormattedDate(formattedDate);
  }
};

export const resetDateSelection = (
    setPeriodName: React.Dispatch<React.SetStateAction<string>>,
    setPeriod: React.Dispatch<React.SetStateAction<string>>,
    setShowModal: React.Dispatch<React.SetStateAction<boolean>>,
    setStartDate: React.Dispatch<React.SetStateAction<string>>,
    setEndDate: React.Dispatch<React.SetStateAction<string>>,
    periodName: string = "Last 7 days",
    period: string = "last7days",
    startOffset: number = 7, 
    endOffset: number = 1 
  ) => {
    setPeriodName(periodName);
    setPeriod(period);
    setShowModal(false);
    setStartDate(moment().subtract(startOffset, "days").format("YYYY-MM-DD"));
    setEndDate(moment().add(endOffset, "days").format("YYYY-MM-DD"));
  };
    
