import { FaCircle } from "react-icons/fa6";
import { useEffect, useState } from "react";
import { usePaystackPayment } from "react-paystack";
import { useNavigate } from "react-router-dom";
import { Button } from "components/Button";
import { TextInput } from "components/common/form/Input";
import ModalContainer from "components/Modal";
import {
  useGetCountryQuery,
  useGetExportCountryQuery,
  useGetLockerAvailabilityQuery,
  useGetOrderWeightQuery,
  useGetSafeLockersQuery,
  useOrderMutation,
  useRemovePendingOrderMutation,
  useUpdateReferralMutation,
} from "services/slices/shipNow";
import { useGetStatesQuery } from "services/slices/dashboard";
import {
  useGetExportCostPriceMutation,
  useGetImportCostPriceMutation,
  useGetLocalCostPriceMutation,
} from "services/slices/pricing";
import { formatMoney } from "helpers/formatMoney";
import {
  useGetWalletBalanceQuery,
  useWalletCheckoutMutation,
} from "services/slices/wallet";
import { generateRandomDataWithDelay } from "helpers/generateRandomNumber";
import { useGetUnReadNotificationsQuery } from "services/slices/notification";
import { handleErrorToast } from "helpers/toastMessage";
import { useGetItemCategoryQuery } from "services/slices/shipNow";
import getSymbolFromCurrency from "currency-symbol-map";
import { copyToClipboard } from "helpers/handleCopy";
import { getFromStorage, removeFromStorage, saveToStorage } from "helpers";
import { deliveryType, initialValues } from "constant/data";
import {
  CostProps,
  ExportState,
  Ilist,
  Item,
  Location,
  Locker,
  Props,
  State,
} from "type";
import {
  validateAmount,
  validateStepOne,
  validateStepThree,
  validateStepTwo,
} from "helpers/validation";
import AddOrderForm from "components/dashboard/shipment/single/AddOrder";
import MobileAddOrderForm from "components/dashboard/shipment/single/MobileAddOrder";
import { handleErrorChange } from "helpers/handleSingleFormErrorChange";
import SelectShipmentType from "components/dashboard/shipment/single/SelectShipmentType";
import PaymentPage from "components/dashboard/shipment/single/PaymentPage";

function SingleShipment() {
  const navigate = useNavigate();
  const orgEmail = getFromStorage("fez-org-email");
  const fezOrg: any = getFromStorage("fez-org-details");
  const userData: any = getFromStorage("fez-user");
  const orgCode: any = getFromStorage("fez-org-code");
  const clientId = JSON.parse(userData);
  const regex = /^[^\s@]+@[^\s@]+\.[^\s@]{2,}$/i;
  const [query, setQuery] = useState<string>("");
  const [dropOffLockerName, setDropOffLockerName] = useState<string>("");
  const [payType, setPayType] = useState<string>("");
  const [useSmartLocker, setUseSmartLocker] = useState(false);
  const [isDeliveryType, setIsDeliveryType] = useState<boolean>(false);
  const [selectedOption, setSelectedOption] = useState<Ilist>({
    id: null,
    name: "",
  });
  const [selectedWeightOption, setSelectedWeightOption] = useState<Ilist>();
  const [totalWeight, setTotalWeight] = useState(0);
  const [weightIsOpen, setWeightIsOpen] = useState<boolean>(false);
  const [isPayment, setIsPayment] = useState<boolean>(false);
  const [isCreateShipment, setIsCreateShipment] = useState<boolean>(false);
  const [totalCost, setTotalCost] = useState<number>(0);
  const [addOrder, setAddOrder] = useState<boolean>(false);
  const [differentDeliveryType, setDifferentDeliveryType] =
    useState<boolean>(false);
  const [editedDetails, setEditedDetails] = useState<any>();
  const [referenceId, setReferenceId] = useState<string>("");
  const [proof, setProof] = useState<number>(0);
  const [currencyValue, setCurrencyValue] = useState<string>("");
  const [referenceIdTwo, setReferenceIdTwo] = useState<string>("");
  const [moneyReference, setMoneyReference] = useState<string>("");
  const [importDropOffStateName, setImportDropOffStateName] =
    useState<string>("");
  const [showAddresses, setShowAddresses] = useState<Location>();
  const [showClipboard, setShowClipboard] = useState<boolean>(false);
  const [copiedAdd, setCopiedAdd] = useState("");
  const [isModalPayment, setModalPayment] = useState<boolean>(false);
  const [orderLockModal, setOrderLockModal] = useState<boolean>(false);
  const [searchState, setSearchState] = useState<string>("");
  const [searchCountryState, setSearchCountryState] = useState<string>("");
  const [searchItem, setSearchItem] = useState<string>("");
  const [importCountryStateName, setImportCountryStateName] =
    useState<string>("");
  const [exportCountryStateName, setExportCountryStateName] =
    useState<string>("");
  const [searchPickUpState, setSearchPickUpState] = useState<string>("");
  const [searchLocker, setSearchLocker] = useState<string>("");
  const [stateName, setStateName] = useState<string>("");
  const [itemName, setItemName] = useState<string>("");
  const [pickUpStateName, setPickUpStateName] = useState<string>("");
  const [exportPickUpStateName, setExportPickUpStateName] =
    useState<string>("");
  const [deliveryDetails, setDeliveryDetails] = useState(initialValues);
  const [editOrder, setEditOrder] = useState<boolean>(false);
  const [addMoney, setAddMoney] = useState<boolean>(false);
  const [amountAdded, setAmountAdded] = useState<any>();
  const [addMoneySuccess, setAddMoneySuccess] = useState<boolean>(false);
  const [clickPayment, setClickPayment] = useState<boolean>(false);
  const [weightArray, setWeightArray] = useState<Item[]>([]);
  const [fetchNotifications, setFetchNotifications] = useState<boolean>(false);
  const [formErrors, setFormErrors] = useState<any>({});
  const { data, isLoading: isLoadingState } = useGetStatesQuery();
  const [getCost, { isLoading }] = useGetLocalCostPriceMutation();
  const [updateReferral] = useUpdateReferralMutation();
  const [getImportCostPrice, { isLoading: importLoading }] =
    useGetImportCostPriceMutation();
  const [getExportCostPrice, { isLoading: exportLoading }] =
    useGetExportCostPriceMutation();
  const [getOrder, { isLoading: orderLoading }] = useOrderMutation();
  const { data: itemData, isLoading: isItemLoading } =
    useGetItemCategoryQuery();
  const {
    data: lockerAvailabilityData,
    isLoading: isLockerAvailabilityLoading,
    isError: isLockerAvailabilityError,
    refetch: refetchLockerAvailability,
  } = useGetLockerAvailabilityQuery(
    { lockerID: deliveryDetails.dropOffLockerAddress },
    { skip: !deliveryDetails.dropOffLockerAddress }
  );

  const {
    data: weightData,
    isLoading: isWeightLoading,
    refetch: refetchWeight,
  } = useGetOrderWeightQuery(
    { id: deliveryDetails?.exportCountry },
    { skip: !deliveryDetails.exportCountry }
  );

  const { data: lockerData, isLoading: isLockerLoading } =
    useGetSafeLockersQuery();
  const { data: walletData, isLoading: isWalletLoading } =
    useGetWalletBalanceQuery(undefined, {
      refetchOnMountOrArgChange: true,
      refetchOnFocus: false,
      refetchOnReconnect: true,
    });
  const [walletCheckOut, { isLoading: isWalletCheckoutLoading }] =
    useWalletCheckoutMutation();
  const [removeOrder, { isLoading: isRemovingOrder }] =
    useRemovePendingOrderMutation();
  const { data: countryData, isLoading: isCountryLoading } =
    useGetCountryQuery();
  const { data: exportCountryData, isLoading: isExportCountryLoading } =
    useGetExportCountryQuery();

  useEffect(() => {
    if (Array.isArray(weightData?.weight)) {
      const sortedWeights = [...weightData.weight].sort(
        (a: Item, b: Item) => parseFloat(a.name) - parseFloat(b.name)
      );
      setWeightArray(sortedWeights);
    }
  }, [weightData]);

  const { refetch: refetchNotification } = useGetUnReadNotificationsQuery(
    {
      id: clientId?.userID,
    },
    { skip: !fetchNotifications }
  );

  const filteredLockerSearch: Locker[] | [] = lockerData?.Lockers?.slice()
    ?.sort((a: Locker, b: Locker) =>
      a.lockerAddress.localeCompare(b.lockerAddress)
    )
    ?.filter((item: Locker) =>
      item?.lockerAddress?.toLowerCase()?.includes(searchLocker.toLowerCase())
    );
  const filteredStateSearch: State[] | [] = data?.states
    ?.slice()
    ?.sort((a: State, b: State) => a.state.localeCompare(b.state))
    ?.filter((item: State) =>
      item?.state?.toLowerCase()?.includes(searchState.toLowerCase())
    );
  const filteredPickUpStateSearch: State[] | [] = data?.states
    ?.slice()
    ?.sort((a: State, b: State) => a.state.localeCompare(b.state))
    ?.filter((item: State) =>
      item.state.toLowerCase().includes(searchPickUpState.toLowerCase())
    );
  const filteredItemSearch: Item[] | [] = itemData?.data?.categories
    ?.slice()
    ?.sort((a: Item, b: Item) => a.name.localeCompare(b.name))
    ?.filter((item: Item) =>
      item?.name?.toLowerCase()?.includes(searchItem.toLowerCase())
    );
  const filteredCountrySearch = countryData?.data?.importLocations
    ?.filter((item: State) =>
      item?.country?.name
        ?.toLowerCase()
        ?.includes(searchCountryState.toLowerCase())
    )
    ?.sort((a: State, b: State) =>
      (a?.country?.name || "").localeCompare(b?.country?.name || "")
    );

  const filteredExportCountrySearch = exportCountryData?.data?.exportLocations
    ?.filter((item: ExportState) =>
      item?.name?.toLowerCase()?.includes(searchCountryState?.toLowerCase())
    )
    ?.sort((a: ExportState, b: ExportState) => a.name.localeCompare(b.name));

  const fezOrgDetails = JSON.parse(fezOrg);

  useEffect(() => {
    setDeliveryDetails((prev) => ({
      ...prev,
      pickUpAddress: fezOrgDetails.orgAddress,
      pickUpPhoneNumber: fezOrgDetails.orgPhone,
      pickUpState: fezOrgDetails.orgState,
    }));
    setPickUpStateName(fezOrgDetails.orgState);
  }, [
    fezOrgDetails.orgAddress,
    fezOrgDetails.orgPhone,
    fezOrgDetails.orgState,
  ]);

  const [items, setItems] = useState<any[]>(() => {
    const savedItems = getFromStorage("items");
    return savedItems ? JSON.parse(savedItems) : [];
  });

  const handleCopy = (address: string) => {
    setCopiedAdd(address);
    copyToClipboard(address, setShowClipboard);
  };

  useEffect(() => {
    if (deliveryDetails?.exportCountry) {
      refetchWeight();
    }
  }, [deliveryDetails?.exportCountry, refetchWeight]);

  useEffect(() => {
    if (fetchNotifications) {
      refetchNotification();
      setFetchNotifications(false);
    }
  }, [fetchNotifications, refetchNotification]);

  const toggleDeliveryType = () => {
    setIsDeliveryType((prev) => !prev);
  };

  const handleDeliveryType = (option: any) => {
    if (items.length > 0) {
      let hasMatchingDeliveryType = false;

      items.forEach((item) => {
        if (item.deliveryType === option.name) {
          hasMatchingDeliveryType = true;
        }
      });

      if (hasMatchingDeliveryType) {
        setSelectedOption(option);
        setQuery(option.name);
        setDeliveryDetails((prev) => ({
          ...prev,
          deliveryType: option.name,
        }));
        setFormErrors((prev: any) => ({
          ...prev,
          deliveryType: "",
        }));
        setIsDeliveryType(false);
        setDifferentDeliveryType(false);
      } else {
        setDifferentDeliveryType(true);
      }
    } else {
      setSelectedOption(option);
      setQuery(option.name);
      setDeliveryDetails((prev) => ({
        ...prev,
        deliveryType: option.name,
      }));
      setFormErrors((prev: any) => ({
        ...prev,
        deliveryType: "",
      }));
      setIsDeliveryType(false);
      setDifferentDeliveryType(false);
    }
  };

  useEffect(() => {
    const newTotalWeight = items.reduce(
      (total, item) => total + item.itemWeight * item.quantity,
      0
    );
    setTotalWeight(newTotalWeight);
  }, [items]);

  useEffect(() => {
    if (deliveryDetails?.dropOffLockerAddress === "" && useSmartLocker) {
      setFormErrors((prev: any) => ({
        ...prev,
        dropOffLockerAddress: "Select a drop off locker",
      }));
    } else {
      setFormErrors((prev: any) => ({
        ...prev,
        dropOffLockerAddress: "",
      }));
    }
  }, [useSmartLocker, deliveryDetails?.dropOffLockerAddress]);

  useEffect(() => {
    if (deliveryDetails?.recipientEmail === "" && useSmartLocker) {
      setFormErrors((prev: any) => ({
        ...prev,
        recipientEmail: "Recipient email is required",
      }));
    } else if (deliveryDetails?.recipientEmail !== "" && useSmartLocker) {
      setFormErrors((prev: any) => ({
        ...prev,
        recipientEmail: "",
      }));
    }
  }, [useSmartLocker, deliveryDetails?.recipientEmail]);

  useEffect(() => {
    if (deliveryDetails?.dropOffLockerAddress !== "" && useSmartLocker) {
      const getLockerAvailability = async () => {
        try {
          refetchLockerAvailability();
        } catch (error) {
          console.log(error);
        }
      };
      getLockerAvailability();
    }
  }, [
    deliveryDetails?.dropOffLockerAddress,
    useSmartLocker,
    refetchLockerAvailability,
  ]);

  useEffect(() => {
    if (
      lockerData &&
      deliveryDetails?.dropOffLockerAddress !== "" &&
      deliveryDetails?.weight !== ""
    ) {
      if (lockerData?.maxWeight < Number(deliveryDetails?.weight)) {
        setFormErrors((prev: any) => ({
          ...prev,
          weight: "Weight is more than locker limit",
        }));
      } else {
        setFormErrors((prev: any) => ({
          ...prev,
          weight: "",
        }));
      }
    }
  }, [
    deliveryDetails?.dropOffLockerAddress,
    deliveryDetails?.weight,
    lockerData,
  ]);

  const handleSubmit = async (
    e: React.FormEvent<HTMLFormElement>,
    isPayment?: boolean
  ) => {
    e.preventDefault();

    setFormErrors(validateStepOne(deliveryDetails));

    if (!query || query === "") {
      setFormErrors((prev: any) => ({
        ...prev,
        deliveryType: "Delivery type is required",
      }));
    } else if (query !== "") {
      setFormErrors((prev: any) => ({
        ...prev,
        deliveryType: "",
      }));
    }
    if (deliveryDetails?.dropOffAddress !== "" && !useSmartLocker) {
      setFormErrors((prev: any) => ({
        ...prev,
        dropOffAddress: "",
      }));
    } else if (deliveryDetails?.dropOffAddress === "" && !useSmartLocker) {
      setFormErrors((prev: any) => ({
        ...prev,
        dropOffAddress: "Drop off address is required",
      }));
    } else if (useSmartLocker) {
      setFormErrors((prev: any) => ({
        ...prev,
        dropOffAddress: "",
      }));
    }
    if (deliveryDetails?.recipientEmail === "" && useSmartLocker) {
      setFormErrors((prev: any) => ({
        ...prev,
        recipientEmail: "Recipient email is required",
      }));
    } else if (
      deliveryDetails.recipientEmail !== "" &&
      !regex.test(deliveryDetails.recipientEmail)
    ) {
      setFormErrors((prev: any) => ({
        ...prev,
        recipientEmail: "Invalid email format",
      }));
    } else {
      setFormErrors((prev: any) => ({
        ...prev,
        recipientEmail: "",
      }));
    }
    if (deliveryDetails?.dropOffLockerAddress !== "" && useSmartLocker) {
      setFormErrors((prev: any) => ({
        ...prev,
        dropOffLocker: "",
      }));
    } else if (deliveryDetails?.dropOffLockerAddress === "" && useSmartLocker) {
      setFormErrors((prev: any) => ({
        ...prev,
        dropOffLocker: "Select a drop off address is required",
      }));
    }
    if (
      !formErrors.weight &&
      ((deliveryDetails.dropOffAddress !== undefined &&
        deliveryDetails.dropOffAddress !== "") ||
        (deliveryDetails.dropOffLockerAddress !== undefined &&
          deliveryDetails.dropOffLockerAddress !== "")) &&
      (((!isLockerAvailabilityError || lockerAvailabilityData?.Available) &&
        !formErrors.dropOffLockerAddress &&
        useSmartLocker) ||
        (!useSmartLocker && formErrors.dropOffAddress === "")) &&
      deliveryDetails.recipientName !== "" &&
      deliveryDetails.recipientName !== undefined &&
      deliveryDetails.pickUpAddress !== undefined &&
      deliveryDetails.recipientPhoneNumber !== undefined &&
      deliveryDetails.recipientPhoneNumber !== "" &&
      deliveryDetails.recipientPhoneNumber.length === 11 &&
      deliveryDetails.pickUpState !== undefined &&
      deliveryDetails.state !== undefined &&
      deliveryDetails.weight !== undefined &&
      deliveryDetails.itemDescription !== "" &&
      deliveryDetails.itemDescription !== undefined &&
      deliveryDetails.state !== "" &&
      deliveryDetails.weight !== "" &&
      query !== "" &&
      query !== undefined &&
      (!deliveryDetails.pickUpPhoneNumber ||
        deliveryDetails.pickUpPhoneNumber === "" ||
        (deliveryDetails.pickUpPhoneNumber !== undefined &&
          deliveryDetails.pickUpPhoneNumber !== "" &&
          deliveryDetails.pickUpPhoneNumber.length === 11)) &&
      deliveryDetails.valueOfItem !== "" &&
      deliveryDetails.valueOfItem !== undefined &&
      deliveryDetails.pickUpAddress !== "" &&
      deliveryDetails.pickUpState !== "" &&
      !formErrors.recipientEmail
    ) {
      const body: CostProps = {
        recipientState: deliveryDetails.state,
        pickUpState: deliveryDetails.pickUpState,
        mode: "",
        weight: Number(deliveryDetails.weight),
        referralCode: deliveryDetails.discountCode,
        // dropOffId: deliveryDetails.dropOffId || null,
      };
      if (lockerAvailabilityData?.Available && useSmartLocker) {
        body.locker = true;
      }
      await getCost(body)
        .unwrap()
        .then((data: any) => {
          if (
            useSmartLocker &&
            lockerData?.maxValueOfItem < Number(data?.data?.cost)
          ) {
            handleErrorToast("Value of item is more than locker value of item");
          } else {
            setClickPayment(false);
            if (editOrder) {
              const itemIndex = items.findIndex(
                (item: any) => item.uniqueID === editedDetails?.uniqueID
              );
              if (itemIndex !== -1) {
                const updatedItem = {
                  ...items[itemIndex],
                  ...deliveryDetails,
                  cost:
                    data?.data?.dropOffDiscount?.discount || data?.data?.cost,
                  discountedCost: data?.data?.discount?.discountedPrice,
                };
                const updatedItems = [...items];
                updatedItems[itemIndex] = updatedItem;
                setItems(updatedItems);
                setAddOrder(true);
              }
              setEditOrder(false);
            } else {
              const body = {
                ...deliveryDetails,
                uniqueID: crypto.randomUUID(),
                cost: data?.data?.dropOffDiscount?.discount || data?.data?.cost,
                discountedCost: data?.data?.discount?.discountedPrice,
              };
              setItems((prevItems: any) => [...prevItems, body]);
              if (isPayment) {
                onGenerate();
                setIsPayment(true);
              } else {
                setAddOrder(true);
              }
            }
            setDeliveryDetails(initialValues);
            setQuery("");
            setUseSmartLocker(false);
            saveToStorage("items", JSON.stringify(items));
          }
        })
        .catch((error: { data: { description: string } }) => {
          handleErrorToast(error);
        });
    }
  };

  const handleImportSubmit = async (
    e: React.FormEvent<HTMLFormElement>,
    isPayment?: boolean
  ) => {
    e.preventDefault();
    setFormErrors(validateStepTwo(deliveryDetails));

    if (!query || query === "") {
      setFormErrors((prev: any) => ({
        ...prev,
        deliveryType: "Delivery type is required",
      }));
    } else if (query !== "") {
      setFormErrors((prev: any) => ({
        ...prev,
        deliveryType: "",
      }));
    }
    if (!deliveryDetails.file || deliveryDetails.file === "") {
      setFormErrors((prev: any) => ({
        ...prev,
        file: "Invoice is required",
      }));
    } else if (deliveryDetails.file !== "") {
      setFormErrors((prev: any) => ({
        ...prev,
        file: "",
      }));
    }
    if (
      deliveryDetails.importRecipientName !== "" &&
      deliveryDetails.importRecipientName !== undefined &&
      deliveryDetails.importDropOffAdd !== "" &&
      deliveryDetails.importDropOffAdd !== undefined &&
      deliveryDetails.importRecipientPhone !== "" &&
      deliveryDetails.importRecipientPhone !== undefined &&
      deliveryDetails.importOrderNumber !== "" &&
      deliveryDetails.importOrderNumber !== undefined &&
      deliveryDetails.importItem !== "" &&
      deliveryDetails.importItem !== undefined &&
      deliveryDetails.importDropOffState !== "" &&
      deliveryDetails.importDropOffState !== undefined &&
      deliveryDetails.importRecipientPhone.length === 11 &&
      deliveryDetails.importCountry !== "" &&
      deliveryDetails.importCountry !== undefined &&
      deliveryDetails.importWeight !== "" &&
      deliveryDetails.importWeight !== undefined &&
      ((deliveryDetails.importValueofItem !== "" &&
        Number(deliveryDetails.importValueofItem) >= Number(proof) &&
        deliveryDetails.file !== "") ||
        (deliveryDetails.importValueofItem !== "" &&
          Number(deliveryDetails.importValueofItem) !== Number(proof) &&
          deliveryDetails.file === "")) &&
      query !== "" &&
      query !== undefined &&
      deliveryDetails.importValueofItem !== "" &&
      deliveryDetails.importValueofItem !== undefined &&
      deliveryDetails.importDescription !== "" &&
      deliveryDetails.importDescription !== undefined &&
      deliveryDetails.importVendorName !== "" &&
      deliveryDetails.importVendorName !== undefined &&
      (deliveryDetails.importRecipientEmail === "" ||
        deliveryDetails.importRecipientEmail === undefined ||
        !deliveryDetails.importRecipientEmail ||
        (deliveryDetails.importRecipientEmail !== "" &&
          regex.test(deliveryDetails.importRecipientEmail)))
    ) {
      await getImportCostPrice({
        destinationState: deliveryDetails.importDropOffState,
        importLocationId: Number(deliveryDetails.importCountry),
        weight: Number(deliveryDetails.importWeight),
        referralCode: deliveryDetails.importReferral,
      })
        .unwrap()
        .then((data: any) => {
          const { pickUpState, recipientName, ...restOfDeliveryDetails } =
            deliveryDetails;
          setClickPayment(false);
          if (editOrder) {
            const itemIndex = items.findIndex(
              (item: any) => item.uniqueID === editedDetails?.uniqueID
            );
            if (itemIndex !== -1) {
              const updatedItem = {
                ...items[itemIndex],
                ...restOfDeliveryDetails,
                cost: data?.data?.price,
                discountedCost: data?.data?.discountedPrice,
              };
              const updatedItems = [...items];
              updatedItems[itemIndex] = updatedItem;
              setItems(updatedItems);
              setAddOrder(true);
            }
            setEditOrder(false);
          } else {
            const body = {
              ...restOfDeliveryDetails,
              uniqueID: crypto.randomUUID(),
              cost: data?.data?.price,
              discountedCost: data?.data?.discountedPrice,
            };
            setItems((prevItems: any) => [...prevItems, body]);
            if (isPayment) {
              onGenerate();
              setIsPayment(true);
            } else {
              setAddOrder(true);
            }
          }

          setDeliveryDetails(initialValues);
          setShowAddresses({
            state: "",
            city: "",
            postCode: "",
            addresses: [],
          });
          setQuery("");
          saveToStorage("items", JSON.stringify(items));
        })
        .catch((error: { data: { description: string } }) => {
          handleErrorToast(error);
        });
    }
  };
  const handleExportSubmit = async (
    e: React.FormEvent<HTMLFormElement>,
    isPayment?: boolean
  ) => {
    e.preventDefault();
    setFormErrors(validateStepThree(deliveryDetails));

    if (!query || query === "") {
      setFormErrors((prev: any) => ({
        ...prev,
        deliveryType: "Delivery type is required",
      }));
    } else if (query !== "") {
      setFormErrors((prev: any) => ({
        ...prev,
        deliveryType: "",
      }));
    }
    if (
      deliveryDetails.exportRecipientName !== "" &&
      deliveryDetails.exportRecipientName !== undefined &&
      deliveryDetails.exportDropOffAddress !== "" &&
      deliveryDetails.exportDropOffAddress !== undefined &&
      deliveryDetails.exportPhoneNumber !== "" &&
      deliveryDetails.exportPhoneNumber !== undefined &&
      (deliveryDetails.exportPickUpPhoneNumber.length >= 9 ||
        deliveryDetails.exportPickUpPhoneNumber.length <= 13) &&
      deliveryDetails.exportZipCode !== "" &&
      deliveryDetails.exportZipCode !== undefined &&
      deliveryDetails.exportDropOffCity !== "" &&
      deliveryDetails.exportDropOffCity !== undefined &&
      deliveryDetails.exportPickUpPhoneNumber !== "" &&
      deliveryDetails.exportPickUpPhoneNumber !== undefined &&
      deliveryDetails.exportPickUpPhoneNumber.length === 11 &&
      deliveryDetails.exportCountry !== "" &&
      deliveryDetails.exportCountry !== undefined &&
      deliveryDetails.exportPickUpState !== "" &&
      deliveryDetails.exportPickUpState !== undefined &&
      deliveryDetails.exportWeight !== "" &&
      deliveryDetails.exportWeight !== undefined &&
      query !== "" &&
      query !== undefined &&
      deliveryDetails.exportValueofItem !== "" &&
      deliveryDetails.exportValueofItem !== undefined &&
      deliveryDetails.exportItemDescription !== "" &&
      deliveryDetails.exportItemDescription !== undefined &&
      (deliveryDetails.exportRecipientEmail === "" ||
        deliveryDetails.exportRecipientEmail === undefined ||
        !deliveryDetails.exportRecipientEmail ||
        (deliveryDetails.exportRecipientEmail !== "" &&
          regex.test(deliveryDetails.exportRecipientEmail)))
    ) {
      await getExportCostPrice({
        destinationState: deliveryDetails.exportPickUpState,
        exportLocationId: Number(deliveryDetails.exportCountry),
        weightId: Number(deliveryDetails.exportWeight),
        referralCode: deliveryDetails.exportReferral,
      })
        .unwrap()
        .then((data: any) => {
          const { pickUpState, recipientName, ...restOfDeliveryDetails } =
            deliveryDetails;

          setClickPayment(false);
          if (editOrder) {
            const itemIndex = items.findIndex(
              (item: any) => item.uniqueID === editedDetails?.uniqueID
            );
            if (itemIndex !== -1) {
              const updatedItem = {
                ...items[itemIndex],
                ...restOfDeliveryDetails,
                cost: data?.data?.price,
                discountedCost: data?.data?.discountedRate,
              };
              const updatedItems = [...items];
              updatedItems[itemIndex] = updatedItem;
              setItems(updatedItems);
              setAddOrder(true);
            }
            setEditOrder(false);
          } else {
            const body = {
              ...restOfDeliveryDetails,
              uniqueID: crypto.randomUUID(),
              cost: data?.data?.price,
              discountedCost: data?.data?.discountedRate,
            };
            setItems((prevItems: any) => [...prevItems, body]);
            if (isPayment) {
              onGenerate();
              setIsPayment(true);
            } else {
              setAddOrder(true);
            }
          }
          setDeliveryDetails(initialValues);
          setSelectedWeightOption({
            id: null,
            name: "",
          });
          setQuery("");
          saveToStorage("items", JSON.stringify(items));
        })
        .catch((error: { data: { description: string } }) => {
          handleErrorToast(error);
        });
    }
  };
  const handleDeliveryChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value, checked } = e.target;

    if (name === "recipientPhoneNumber") {
      if (!/^\d*$/.test(value)) {
        handleErrorChange(
          name,
          "Please enter a valid phone number (numbers only).",
          setFormErrors
        );
        return;
      } else {
        handleErrorChange(name, value, setFormErrors);
      }
    } else {
      handleErrorChange(name, value, setFormErrors);
    }

    setDeliveryDetails((prev) => ({
      ...prev,
      [name]: name === "fragile" ? checked : value,
    }));
  };

  useEffect(() => {
    const orderLock = process.env.REACT_APP_LOCK_ORDER;
    if (orderLock === "true") {
      setOrderLockModal(true);
    }
  }, []);

  useEffect(() => {
    if (editedDetails) {
      const selectedType = deliveryType.find(
        (type: { name: string }) => type.name === editedDetails?.deliveryType
      );
      setSelectedOption({
        id: selectedType.id,
        name: "",
      });
      setDeliveryDetails({
        pickUpAddress: editedDetails?.pickUpAddress,
        state: editedDetails?.state,
        pickUpState: editedDetails?.pickUpState,
        weight: editedDetails?.weight,
        recipientName: editedDetails?.recipientName,
        recipientPhoneNumber: editedDetails?.recipientPhoneNumber,
        recipientEmail: editedDetails?.recipientEmail,
        quantity: editedDetails?.quantity,
        itemDescription: editedDetails?.itemDescription,
        discountCode: editedDetails?.discountCode,
        dropOffAddress: editedDetails?.dropOffAddress,
        valueOfItem: editedDetails?.valueOfItem,
        pickUpPhoneNumber: editedDetails?.pickUpPhoneNumber,
        importItem: editedDetails?.importItem,
        importCountry: editedDetails?.importCountry,
        importReferral: editedDetails?.importReferral,
        importDescription: editedDetails?.importDescription,
        importQuantity: editedDetails?.importQuantity,
        importWeight: editedDetails?.importWeight,
        importRecipientEmail: editedDetails?.importRecipientEmail,
        importRecipientPhone: editedDetails?.importRecipientPhone,
        importRecipientName: editedDetails?.importRecipientName,
        importDropOffAdd: editedDetails?.importDropOffAdd,
        importVendorName: editedDetails?.importVendorName,
        importOrderNumber: editedDetails?.importOrderNumber,
        importShippingAdd: editedDetails?.importShippingAdd,
        importDropOffState: editedDetails?.importDropOffState,
        importValueofItem: editedDetails?.importValueofItem,
        file: editedDetails.file,
        fileNames: editedDetails.fileNames,
        exportCountry: editedDetails.exportCountry,
        exportRecipientName: editedDetails.exportRecipientName,
        exportWeight: editedDetails.exportWeight,
        exportReferral: editedDetails.exportReferral,
        exportItemDescription: editedDetails.exportItemDescription,
        exportQuantity: editedDetails.exportQuantity,
        exportRecipientEmail: editedDetails.exportRecipientEmail,
        exportPhoneNumber: editedDetails.exportPhoneNumber,
        exportZipCode: editedDetails.exportZipCode,
        exportDropOffAddress: editedDetails.exportDropOffAddress,
        exportDropOffCity: editedDetails.exportDropOffCity,
        exportPickUpState: editedDetails.exportPickUpState,
        exportValueofItem: editedDetails.exportValueofItem,
        exportPickUpPhoneNumber: editedDetails.exportPickUpPhoneNumber,
        exportPickUpAddress: editedDetails.exportPickUpAddress,
        safeLockerDropOffState: editedDetails.safeLockerDropOffState,
        dropOffLockerAddress: editedDetails.dropOffLockerAddress,
        deliveryType: editedDetails.deliveryType,
        cost: editedDetails.cost,
        fragile: editedDetails?.fragile,
        // dropOffId: editedDetails?.dropOffId,
      });
      const deliveryData: any = deliveryType?.find(
        (item: any) => item?.name === editedDetails?.deliveryType
      );
      setStateName(editedDetails?.state);
      setPickUpStateName(editedDetails?.pickUpState);
      const country = filteredCountrySearch?.find(
        (country: State) => country.id === editedDetails?.importCountry
      );
      const dropOff = filteredPickUpStateSearch?.filter(
        (country: State) => country.state === editedDetails?.importDropOffState
      );

      const item = filteredItemSearch?.filter(
        (item: Item) => item.id === editedDetails?.importItem
      );
      setImportDropOffStateName(dropOff?.[0]?.state);
      setImportCountryStateName(country?.country?.name);
      setProof(country?.proof);
      setItemName(item[0]?.name);
      setQuery(deliveryData?.name);
    }
    // eslint-disable-next-line
  }, [editedDetails]);

  const handleEdit = (uniqueID: string) => {
    setEditOrder(true);
    const updatedItems = items.find((item) => item.uniqueID === uniqueID);
    setEditedDetails(updatedItems);
    const pickUpCountry = filteredCountrySearch?.find(
      (item: State) => item.id === updatedItems.importCountry
    );
    const weightSelected = weightData?.weight?.find(
      (item: ExportState) => item.id === updatedItems.exportWeight
    );
    const exportCountry = filteredExportCountrySearch?.find(
      (item: ExportState) => item.id === updatedItems.exportCountry
    );
    const lockerData = filteredLockerSearch?.find(
      (data: Locker) => data.lockerID === updatedItems.dropOffLockerAddress
    );
    if (updatedItems.dropOffLockerAddress !== "") {
      setUseSmartLocker(true);
    } else {
      setUseSmartLocker(false);
    }
    if (lockerData) {
      setDropOffLockerName(lockerData?.lockerAddress);
    }
    setSelectedWeightOption(weightSelected);
    setShowAddresses(pickUpCountry);
    setExportCountryStateName(exportCountry?.name);
    setExportPickUpStateName(updatedItems.exportPickUpState);
  };

  const handleDelete = (uniqueID: string) => {
    const updatedItems = items.filter((item) => item.uniqueID !== uniqueID);
    setItems(updatedItems);
    saveToStorage("items", JSON.stringify(updatedItems));
  };

  const onGenerate = () => {
    const response = generateRandomDataWithDelay();

    if (orgCode) {
      setReferenceId(`c_${response}_${orgCode}_OP`);
      setReferenceIdTwo(`${response}_${orgCode}_OP`);
      setMoneyReference(`c_${orgCode}_${response}${new Date().valueOf()}_BFW`);
    }
  };

  const config = {
    reference: referenceIdTwo,
    email:
      orgEmail === "null" || !orgEmail ? `${orgCode}@fezltd.com` : orgEmail,
    amount: Math.round(Number(totalCost)) * 100,
    publicKey: `${process.env.REACT_APP_PAYSTACK_KEY}`,
  };

  const addMoneyConfig = {
    reference: moneyReference,
    email:
      orgEmail === "null" || !orgEmail ? `${orgCode}@fezltd.com` : orgEmail,
    amount: Number(amountAdded) * 100,
    publicKey: `${process.env.REACT_APP_PAYSTACK_KEY}`,
  };
  const initializePayment = usePaystackPayment(config);
  const initializeAddMoney = usePaystackPayment(addMoneyConfig);

  const onUpdateReferralCode = async () => {
    const orderNos = JSON.parse(getFromStorage("orderNos") || "[]");

    const resultData = Object.values(orderNos).map((orderNo, index) => ({
      orderNo,
      discountCode:
        selectedOption?.id === 1
          ? items[index]?.discountCode
          : selectedOption?.id === 2
          ? items[index]?.importReferral
          : items[index]?.exportReferral,
    }));

    const orders = {
      orders: resultData?.map((data: any) => ({
        referralCode: data.discountCode,
        orderNo: data.orderNo,
      })),
    };

    if (resultData.length > 0 && resultData[0]?.discountCode !== "") {
      await updateReferral(orders);
    }
  };

  const onSuccess = () => {
    onUpdateReferralCode();
    setDeliveryDetails((prev) => ({
      ...initialValues,
      pickUpAddress: fezOrgDetails.orgAddress,
      pickUpPhoneNumber: fezOrgDetails.orgPhone,
      pickUpState: fezOrgDetails.orgState,
    }));
    setPickUpStateName(fezOrgDetails.orgState);
    setQuery("");
    setModalPayment(true);
    setFetchNotifications(true);
    removeFromStorage("items");

    setTimeout(() => {
      setFetchNotifications(false);
    }, 60000);
  };

  const onClose = () => {
    onDeleteOrder();
    onGenerate();
  };
  const onAddMoneySuccess = () => {
    saveToStorage("amountAdded", amountAdded);
    setAddMoney(false);
    setAddMoneySuccess(true);
    setFetchNotifications(true);

    setTimeout(() => {
      setFetchNotifications(false);
    }, 60000);
  };

  const onAddMoneyClose = () => {
    onGenerate();
  };

  useEffect(() => {
    removeFromStorage("amountAdded");
  }, []);

  const onDeleteOrder = async () => {
    const orderNos: any = getFromStorage("orderNos");

    const parsedOrderNos = JSON.parse(orderNos);
    const valuesString = Object.values(parsedOrderNos).join(", ");

    await removeOrder({ orderNo: valuesString })
      .unwrap()
      .then((data) => {
        // onHandlePayment(payType);
      })
      .catch((error: { data: { description: string } }) => {
        handleErrorToast(error);
      });
  };

  const processPayment = async () => {
    const body = {
      totalCost,
      paymentReference: "w" + referenceId.slice(1),
    };
    await walletCheckOut(body)
      .unwrap()
      .then((data) => {
        if (data) {
          onUpdateReferralCode();
          setDeliveryDetails((prev) => ({
            ...initialValues,
            pickUpAddress: fezOrgDetails.orgAddress,
            pickUpPhoneNumber: fezOrgDetails.orgPhone,
            pickUpState: fezOrgDetails.orgState,
          }));
          setPickUpStateName(fezOrgDetails.orgState);
          setQuery("");
          setModalPayment(true);
          setFetchNotifications(true);
          setTimeout(() => {
            setFetchNotifications(false);
          }, 60000);
        }
      })
      .catch((error: { data: { description: string } }) => {
        handleErrorToast(error);
        onDeleteOrder();
        onGenerate();
      });
  };

  const onHandlePayment = async (payType: string) => {
    const newFirstLetter = payType.toLowerCase() === "wallet" ? "w" : "c";
    const requestPayload = items.reduce((acc, item, idx) => {
      const uniqueID = `fez-${referenceIdTwo}-${idx}`;
      const lockerAddress = filteredLockerSearch?.find(
        (data: Locker) => data.lockerID === item.dropOffLockerAddress
      );

      acc[uniqueID] = {
        recipientAddress: item.dropOffLockerAddress
          ? lockerAddress?.lockerAddress
          : item.dropOffAddress,
        recipientState: item.state,
        recipientName: item.recipientName,
        recipientPhone: item.recipientPhoneNumber,
        uniqueID: uniqueID,
        BatchID: `${orgCode}-${referenceId}`,
        orderRequestSource: "B2B Dashboard",
        paymentReference: newFirstLetter + referenceId.slice(1),
        recipientEmail: item.recipientEmail,
        weight: item.weight,
        pickUpAddress: item.pickUpAddress,
        valueOfItem: Number(item.valueOfItem),
        clientPhone: item.pickUpPhoneNumber,
        pickUpState: item.pickUpState,
        referralCode: item.discountCode,
        additionalDetails: item.additional,
        cost: Number(item?.dropOffDiscount?.discount || item.cost),
        discountedCost: Number(item.discountedCost),
        itemDescription: item.itemDescription,
        fragile: item.fragile,
        offline: payType.toLowerCase() === "pay-later" ? "PL" : "",
        ...(item.dropOffLockerAddress && {
          lockerID: Number(item.dropOffLockerAddress),
        }),
      };
      return acc;
    }, {} as { [key: string | number]: Props });
    await getOrder({ body: requestPayload })
      .unwrap()
      .then((data) => {
        saveToStorage("orderNos", JSON.stringify(data?.orderNos));
        if (payType.toLowerCase() === "paystack") {
          initializePayment(onSuccess, onClose);
        } else if (payType.toLowerCase() === "wallet") {
          processPayment();
        } else {
          onUpdateReferralCode();
          setItems([]);
          setDeliveryDetails((prev) => ({
            ...initialValues,
            pickUpAddress: fezOrgDetails.orgAddress,
            pickUpPhoneNumber: fezOrgDetails.orgPhone,
            pickUpState: fezOrgDetails.orgState,
          }));
          setPickUpStateName(fezOrgDetails.orgState);
          removeFromStorage("items");
          setModalPayment(true);
        }
      })
      .catch((error: { data: { description: string } }) => {
        handleErrorToast(error);
      });
  };
  const onHandleImportPayment = async () => {
    const requestPayload = items.reduce((acc, item, idx) => {
      const uniqueID = `fez-${referenceIdTwo}-${idx}`;

      acc[uniqueID] = {
        recipientName: item.importRecipientName,
        recipientPhone: item.importRecipientPhone,
        recipientAddress: item.importDropOffAdd,
        recipientState: item.importDropOffState,
        recipientEmail: item.importRecipientEmail,
        weight: item.importWeight,
        referralCode: item.importReferral,
        additionalDetails: null,
        uniqueID: uniqueID,
        cost: Number(item.cost),
        discountedCost: Number(item.discountedCost),
        BatchID: `${orgCode}-${referenceId}`,
        orderRequestSource: "B2B Dashboard",
        paymentReference: "c" + referenceId.slice(1),
        itemDescription: item.importDescription,
        recipientCity: "",
        importLocationId: item.importCountry,
        valueOfItem: Number(item.importValueofItem),
        trackingId: item.importOrderNumber,
        itemCategory: item.importItem,
        businessName: item.importVendorName,
        uploadedProof: item.file,
        uploadedProofExtension: item.fileName,
        fragile: item.fragile,
      };
      return acc;
    }, {} as { [key: string | number]: any });
    await getOrder({ body: requestPayload, requestType: "import" })
      .unwrap()
      .then((data) => {
        onUpdateReferralCode();
        saveToStorage("orderNos", JSON.stringify(data?.orderNos));
        setItems([]);
        removeFromStorage("items");
        setModalPayment(true);
      })
      .catch((error: { data: { description: string } }) => {
        handleErrorToast(error);
      });
  };
  const onHandleExportPayment = async (payType: string) => {
    const newFirstLetter = payType.toLowerCase() === "wallet" ? "w" : "c";

    const requestPayload = items.reduce((acc, item, idx) => {
      const uniqueID = `fez-${referenceIdTwo}-${idx}`;
      const weightValue = weightArray.find(
        (weight: Item) => Number(weight.id) === Number(item.exportWeight)
      )?.name;
      acc[uniqueID] = {
        recipientName: item.exportRecipientName,
        recipientPhone: item.exportPhoneNumber,
        recipientAddress: item.exportDropOffAddress,
        recipientEmail: item.exportRecipientEmail,
        weight: Number(weightValue),
        pickUpAddress: item.exportPickUpAddress,
        pickUpState: item.exportPickUpState,
        referralCode: item.exportReferral,
        pickUpPhone: item.exportPickUpPhoneNumber,
        additionalDetails: null,
        uniqueID,
        cost: Number(item?.dropOffDiscount?.discount || item.cost),
        discountedCost: Number(item.discountedCost),
        BatchID: `${orgCode}-${referenceId}`,
        orderRequestSource: "B2B Dashboard",
        paymentReference: newFirstLetter + referenceId.slice(1),
        itemDescription: item.exportItemDescription,
        recipientCity: item.exportDropOffCity,
        zipCode: item.exportZipCode,
        exportLocationId: item.exportCountry,
        valueOfItem: Number(item.exportValueofItem),
        offline: payType.toLowerCase() === "pay-later" ? "PL" : "",
        fragile: item.fragile,
      };
      return acc;
    }, {} as { [key: string | number]: any });
    await getOrder({ body: requestPayload, requestType: "export" })
      .unwrap()
      .then((data) => {
        saveToStorage("orderNos", JSON.stringify(data?.orderNos));
        if (payType.toLowerCase() === "paystack") {
          initializePayment(onSuccess, onClose);
        } else if (payType.toLowerCase() === "wallet") {
          processPayment();
        } else {
          onUpdateReferralCode();
          setItems([]);
          removeFromStorage("items");

          setModalPayment(true);
        }
      })
      .catch((error: { data: { description: string } }) => {
        handleErrorToast(error);
      });
  };

  useEffect(() => {
    const totalCost = items.reduce((accumulator: number, currentItem: any) => {
      return (
        accumulator +
        (Number(currentItem.discountedCost) || Number(currentItem.cost))
      );
    }, 0);
    setTotalCost(totalCost);
  }, [items]);

  const onAddMoney = () => {
    setFormErrors(validateAmount(amountAdded));
    if (amountAdded !== "") {
      initializeAddMoney(onAddMoneySuccess, onAddMoneyClose);
    }
  };

  return (
    <>
      {isCreateShipment ? (
        !isPayment ? (
          <div className="mt-14 md:mt-0 overflow-x-hidden ">
            <div
              onClick={() => setIsCreateShipment(false)}
              className="mx-5 flex mb-2 md:hidden"
            >
              <div>
                {" "}
                <img className="w-3 h-3" src="/images/backArrow.svg" alt="" />
              </div>
              <p className=" text-[#4a564d] font-[500] text-[13px] -mt-1 font-onestMedium">
                Go back
              </p>
            </div>
            <div className="grid lg:grid-cols-[62%_38%] lg:gap-4 xl:gap-[22px] md:mt-[72px] mx-5 bmd:mx-[50px] xl:mx-[80px]">
              <div className="border-[1px] border-[#E1F0DF] px-5 xl:px-8 bg-dashboard-gradient rounded-lg">
                <div className="hidden md:flex items-center justify-between pt-8  w-full">
                  <div
                    onClick={() => setIsCreateShipment(false)}
                    className="hidden md:flex gap-1 cursor-pointer w-[100px]"
                  >
                    <div>
                      {" "}
                      <img
                        className="w-3 h-3"
                        src="/images/backArrow.svg"
                        alt=""
                      />
                    </div>
                    <p className="text-[#4a564d] font-[500] text-[13px] -mt-1 font-onestMedium">
                      Go back
                    </p>
                  </div>
                  <div className="flex justify-center md:justify-end w-full gap-5 md:gap-2.5">
                    <div className="block md:hidden">
                      <div
                        className={`w-[82px] h-[6px] bg-add-gradient rounded-xl`}
                      ></div>
                      <p className="text-[11px] flex justify-center md:justify-end font-[500] text-[#68726B] font-onestMedium">
                        Pick-up
                      </p>
                    </div>
                    <div className="">
                      <div
                        className={`w-[82px] h-[6px] bg-add-gradient rounded-xl`}
                      ></div>
                      <p className="text-[11px] flex md:justify-end font-[500] text-[#68726B] font-onestMedium">
                        Delivery details
                      </p>
                    </div>
                    <div>
                      <div
                        className={`w-[82px] h-[6px] bg-[#e0f4dc] rounded-xl`}
                      ></div>
                      <p className="text-[11px] flex justify-center md:justify-end font-[500] text-[#68726B] font-onestMedium">
                        Payment
                      </p>
                    </div>
                  </div>
                </div>
                <div className="flex md:hidden items-center justify-between mt-6 mx-4">
                  <div className="relative">
                    <FaCircle color="#18AF04" />
                    <p className="absolute mt-1 text-[#68726B] font-[500] -left-2 text-[12px] font-onestMedium w-[100px]">
                      Pick-up
                    </p>
                  </div>
                  <div className="w-full h-0.5 bg-[#D4F3D5]"></div>
                  <div className="relative">
                    <FaCircle color="#18AF04" />
                    <p className="absolute mt-1 text-[#68726B] font-[500] -left-3 text-[12px] font-onestMedium w-[100px]">
                      Delivery
                    </p>
                  </div>
                  <div className="w-full h-0.5 bg-[#D4F3D5]"></div>
                  <div className="relative">
                    <FaCircle color="#D4F3D5" />
                    <p className="absolute mt-1 text-[#68726B] font-[500] -left-4 text-[12px] font-onestMedium w-[100px]">
                      Payment
                    </p>
                  </div>
                </div>
                <h1 className="text-xl md:text-2xl mt-14 md:mt-10 font-onestBold font-[800] text-[#0a5001] tracking-wide">
                  You are creating single shipping orders
                </h1>
                <p className="text-[#0e1728] hidden md:block text-sm font-[500] font-onestMedium w-[90%] mt-3 md:mt-12">
                  Enter your pick-up and delivery details
                </p>
                {addOrder && (
                  <div className="block lg:hidden">
                    {items.length > 0 && (
                      <div className="h-full xl:h-[596px]">
                        <div className="flex gap-[14px] items-center pt-8 pb-2 bg-white">
                          <h1 className="text-[#0a5001] text-sm font-[700] font-onestBold">
                            MY ORDERS ({items.length})
                          </h1>
                        </div>
                        <div className="bg-white h-[70%]">
                          {items.map((item) => {
                            const pickUpCountry = filteredCountrySearch.filter(
                              (state: any) => {
                                return state.id === item.importCountry;
                              }
                            );
                            return (
                              <div
                                key={item.id}
                                className="flex gap-3 border-[1px] px-4 rounded-lg my-6 border-[#e4ebf5] py-4"
                              >
                                <div className="bg-[#F4F5F6] w-[28px] h-[28px] flex items-center justify-center rounded-[30px]">
                                  <img
                                    className="w-[14px] h-[14px]"
                                    src="/images/location.svg"
                                    alt=""
                                  />
                                </div>
                                <div className="flex justify-between w-full">
                                  <div>
                                    <h1 className="text-[#071227] text-sm font-onestMedium font-[500] mb-1.5">
                                      {item.recipientName ||
                                        item.importRecipientName ||
                                        item.exportRecipientName}
                                    </h1>
                                    <p className="text-[#686868] flex cursor-pointer items-center font-onestRegular text-[13px] mb-0.5">
                                      {item.deliveryType}{" "}
                                      <FaCircle
                                        color="#d1d1d2"
                                        size={6}
                                        className="bg-[#d1d1d2] mx-2 rounded-lg"
                                      />{" "}
                                      {item.pickUpState ||
                                        pickUpCountry?.[0]?.country?.name ||
                                        item.exportPickUpState}
                                    </p>
                                    <div className="flex space-x-2">
                                      <p
                                        className="text-[#18AF04] cursor-pointer text-xs font-onestRegular"
                                        onClick={() => {
                                          setAddOrder(false);
                                          handleEdit(item.uniqueID);
                                        }}
                                      >
                                        Edit order
                                      </p>

                                      <p
                                        onClick={() => {
                                          handleDelete(item.uniqueID);
                                          if (items.length === 1) {
                                            setAddOrder(false);
                                            setIsPayment(false);
                                          }
                                        }}
                                        className="ml-3 text-[#F64108] cursor-pointer text-xs font-onestRegular"
                                      >
                                        Delete
                                      </p>
                                    </div>
                                  </div>
                                  {selectedOption?.id === 2 ? (
                                    <div className="flex space-x-1 ">
                                      {item.discountedCost &&
                                      item.discountedCost !== item.cost ? (
                                        <div className="flex space-x-1 ">
                                          <p className="text-red-400 font-onestMedium line-through text-[14px] font-[500]">
                                            {getSymbolFromCurrency(
                                              currencyValue
                                            )}
                                            {formatMoney().format(
                                              item?.dropOffDiscount?.discount ||
                                                item.cost
                                            )}
                                          </p>
                                          <p className="text-[#071527] font-onestMedium text-[14px] font-[500]">
                                            {getSymbolFromCurrency(
                                              currencyValue
                                            )}
                                            {formatMoney().format(
                                              item?.dropOffDiscount?.discount ||
                                                item.discountedCost
                                            )}
                                          </p>
                                        </div>
                                      ) : (
                                        <p className="text-[#071527] font-onestMedium text-[14px] font-[500]">
                                          {getSymbolFromCurrency(currencyValue)}
                                          {formatMoney().format(
                                            item?.dropOffDiscount?.discount ||
                                              item.cost
                                          )}
                                        </p>
                                      )}
                                    </div>
                                  ) : (
                                    <div className="flex space-x-1 ">
                                      {item.discountedCost &&
                                      item.discountedCost !== item.cost ? (
                                        <div className="flex space-x-1">
                                          <p className="text-red-400 font-onestMedium line-through text-[14px] font-[500]">
                                            ₦
                                            {formatMoney().format(
                                              item?.dropOffDiscount?.discount ||
                                                item.cost
                                            )}
                                          </p>
                                          <p className="text-[#071527] font-onestMedium text-[14px] font-[500]">
                                            ₦
                                            {formatMoney().format(
                                              item?.dropOffDiscount?.discount ||
                                                item.discountedCost
                                            )}
                                          </p>
                                        </div>
                                      ) : (
                                        <p className="text-[#071527] font-onestMedium text-[14px] font-[500]">
                                          ₦
                                          {formatMoney().format(
                                            item?.dropOffDiscount?.discount ||
                                              item.cost
                                          )}
                                        </p>
                                      )}
                                    </div>
                                  )}
                                </div>
                              </div>
                            );
                          })}
                        </div>
                        <div className="xl:px-8 flex-col xl:flex xl:flex-row items-center justify-between mt-6">
                          {selectedOption?.id !== 2 && (
                            <h1 className="font-[700] font-onestBold">
                              Total: ₦{formatMoney().format(totalCost)}
                            </h1>
                          )}

                          <div className="space-y-3 pb-5">
                            <Button
                              title={
                                selectedOption?.id === 2
                                  ? "Continue with your shipment"
                                  : "Make Payment"
                              }
                              onClick={() => {
                                onGenerate();
                                setIsPayment(true);
                              }}
                              className="!w-full mt-5 py-4 bg-[#18AF04] !font-[700] !text-xs xl:!text-[13px] font-onestBold"
                            />
                            <Button
                              title="Add New Order"
                              onClick={() => setAddOrder(false)}
                              className="!w-full mt-5 py-4 !text-[#18AF04] !bg-transparent border border-[#18AF04] !font-[700] !text-xs xl:!text-[13px] font-onestBold"
                            />
                          </div>
                        </div>
                      </div>
                    )}
                  </div>
                )}

                <AddOrderForm
                  formErrors={formErrors}
                  selectedOption={selectedOption}
                  handleSubmit={(e: any) => handleSubmit(e)}
                  handleImportSubmit={(e: any) => handleImportSubmit(e)}
                  handleExportSubmit={(e: any) => handleExportSubmit(e)}
                  handleSubmitPayment={(e: any) => handleSubmit(e, true)}
                  handleImportSubmitPayment={(e: any) =>
                    handleImportSubmit(e, true)
                  }
                  handleExportSubmitPayment={(e: any) =>
                    handleExportSubmit(e, true)
                  }
                  query={query}
                  setQuery={setQuery}
                  isDeliveryType={isDeliveryType}
                  toggleDeliveryType={toggleDeliveryType}
                  handleDeliveryType={handleDeliveryType}
                  pickUpStateName={pickUpStateName}
                  isLoadingState={isLoadingState}
                  deliveryDetails={deliveryDetails}
                  searchPickUpState={searchPickUpState}
                  setSearchPickUpState={setSearchPickUpState}
                  filteredPickUpStateSearch={filteredPickUpStateSearch}
                  setDeliveryDetails={setDeliveryDetails}
                  setPickUpStateName={setPickUpStateName}
                  setFormErrors={setFormErrors}
                  handleDeliveryChange={handleDeliveryChange}
                  stateName={stateName}
                  searchState={searchState}
                  setSearchState={setSearchState}
                  filteredStateSearch={filteredStateSearch}
                  setStateName={setStateName}
                  isLockerLoading={isLockerLoading}
                  useSmartLocker={useSmartLocker}
                  lockerData={lockerData}
                  setUseSmartLocker={setUseSmartLocker}
                  dropOffLockerName={dropOffLockerName}
                  searchLocker={searchLocker}
                  setSearchLocker={setSearchLocker}
                  setDropOffLockerName={setDropOffLockerName}
                  filteredLockerSearch={filteredLockerSearch}
                  lockerAvailabilityData={lockerAvailabilityData}
                  isLockerAvailabilityLoading={isLockerAvailabilityLoading}
                  isCountryLoading={isCountryLoading}
                  importCountryStateName={importCountryStateName}
                  searchCountryState={searchCountryState}
                  setSearchCountryState={setSearchCountryState}
                  filteredCountrySearch={filteredCountrySearch}
                  setProof={setProof}
                  setImportCountryStateName={setImportCountryStateName}
                  setShowAddresses={setShowAddresses}
                  showAddresses={showAddresses}
                  setCurrencyValue={setCurrencyValue}
                  showClipboard={showClipboard}
                  copiedAdd={copiedAdd}
                  handleCopy={handleCopy}
                  isItemLoading={isItemLoading}
                  searchItem={searchItem}
                  itemName={itemName}
                  setSearchItem={setSearchItem}
                  setItemName={setItemName}
                  filteredItemSearch={filteredItemSearch}
                  importDropOffStateName={importDropOffStateName}
                  setImportDropOffStateName={setImportDropOffStateName}
                  currencyValue={currencyValue}
                  proof={proof}
                  setClickPayment={setClickPayment}
                  clickPayment={clickPayment}
                  totalWeight={totalWeight}
                  exportCountryStateName={exportCountryStateName}
                  isExportCountryLoading={isExportCountryLoading}
                  filteredExportCountrySearch={filteredExportCountrySearch}
                  setExportCountryStateName={setExportCountryStateName}
                  setExportPickUpStateName={setExportPickUpStateName}
                  exportPickUpStateName={exportPickUpStateName}
                  isWeightLoading={isWeightLoading}
                  weightArray={weightArray}
                  setWeightIsOpen={setWeightIsOpen}
                  weightIsOpen={weightIsOpen}
                  setSelectedWeightOption={setSelectedWeightOption}
                  selectedWeightOption={selectedWeightOption}
                  setIsPayment={setIsPayment}
                  items={items}
                  onGenerate={onGenerate}
                  isLoading={isLoading}
                  importLoading={importLoading}
                  exportLoading={exportLoading}
                />

                {!addOrder && (
                  <MobileAddOrderForm
                    formErrors={formErrors}
                    selectedOption={selectedOption}
                    handleSubmit={(e: any) => handleSubmit(e)}
                    handleImportSubmit={(e: any) => handleImportSubmit(e)}
                    handleExportSubmit={(e: any) => handleExportSubmit(e)}
                    query={query}
                    setQuery={setQuery}
                    isDeliveryType={isDeliveryType}
                    toggleDeliveryType={toggleDeliveryType}
                    handleDeliveryType={handleDeliveryType}
                    pickUpStateName={pickUpStateName}
                    isLoadingState={isLoadingState}
                    deliveryDetails={deliveryDetails}
                    searchPickUpState={searchPickUpState}
                    setSearchPickUpState={setSearchPickUpState}
                    filteredPickUpStateSearch={filteredPickUpStateSearch}
                    setDeliveryDetails={setDeliveryDetails}
                    setPickUpStateName={setPickUpStateName}
                    setFormErrors={setFormErrors}
                    handleDeliveryChange={handleDeliveryChange}
                    stateName={stateName}
                    searchState={searchState}
                    setSearchState={setSearchState}
                    filteredStateSearch={filteredStateSearch}
                    setStateName={setStateName}
                    isLockerLoading={isLockerLoading}
                    useSmartLocker={useSmartLocker}
                    lockerData={lockerData}
                    setUseSmartLocker={setUseSmartLocker}
                    dropOffLockerName={dropOffLockerName}
                    searchLocker={searchLocker}
                    setSearchLocker={setSearchLocker}
                    setDropOffLockerName={setDropOffLockerName}
                    filteredLockerSearch={filteredLockerSearch}
                    lockerAvailabilityData={lockerAvailabilityData}
                    isLockerAvailabilityLoading={isLockerAvailabilityLoading}
                    isCountryLoading={isCountryLoading}
                    importCountryStateName={importCountryStateName}
                    searchCountryState={searchCountryState}
                    setSearchCountryState={setSearchCountryState}
                    filteredCountrySearch={filteredCountrySearch}
                    setProof={setProof}
                    setImportCountryStateName={setImportCountryStateName}
                    setShowAddresses={setShowAddresses}
                    showAddresses={showAddresses}
                    setCurrencyValue={setCurrencyValue}
                    showClipboard={showClipboard}
                    copiedAdd={copiedAdd}
                    handleCopy={handleCopy}
                    isItemLoading={isItemLoading}
                    searchItem={searchItem}
                    itemName={itemName}
                    setSearchItem={setSearchItem}
                    setItemName={setItemName}
                    filteredItemSearch={filteredItemSearch}
                    importDropOffStateName={importDropOffStateName}
                    setImportDropOffStateName={setImportDropOffStateName}
                    currencyValue={currencyValue}
                    proof={proof}
                    setClickPayment={setClickPayment}
                    clickPayment={clickPayment}
                    totalWeight={totalWeight}
                    exportCountryStateName={exportCountryStateName}
                    isExportCountryLoading={isExportCountryLoading}
                    filteredExportCountrySearch={filteredExportCountrySearch}
                    setExportCountryStateName={setExportCountryStateName}
                    setExportPickUpStateName={setExportPickUpStateName}
                    exportPickUpStateName={exportPickUpStateName}
                    isWeightLoading={isWeightLoading}
                    weightArray={weightArray}
                    setWeightIsOpen={setWeightIsOpen}
                    weightIsOpen={weightIsOpen}
                    setSelectedWeightOption={setSelectedWeightOption}
                    selectedWeightOption={selectedWeightOption}
                    setIsPayment={setIsPayment}
                    items={items}
                    onGenerate={onGenerate}
                    isLoading={isLoading}
                    importLoading={importLoading}
                    exportLoading={exportLoading}
                    setAddOrder={setAddOrder}
                  />
                )}
              </div>
              <div className={`hidden lg:block`}>
                {items.length === 0 && (
                  <div className="hidden bmd:block border-[1px] border-[#E1F0DF] h-[596px] px-8 py-8 rounded-lg">
                    <div className="flex gap-[14px] items-center">
                      <h1 className="text-[#A0ABA3] text-sm font-[700] font-onestBold">
                        MY ORDERS
                      </h1>
                      <img src="/images/order-box.svg" alt="" />
                    </div>
                    <p className="text-[#A0ABA3] text-center mt-[151px] mx-auto w-[60%] text-[13px]">
                      When you add a shipment, it will show here
                    </p>
                  </div>
                )}
                {items.length > 0 && (
                  <div className="hidden md:block border-[1px] border-[#E1F0DF] h-full max-h-[567px] bg-[#F4F5F6]  rounded-lg ">
                    <div className="flex gap-[14px] items-center px-8 pt-8 pb-6 bg-white">
                      <h1 className="text-[#0a5001] text-sm font-[700] font-onestBold">
                        MY ORDERS ({items.length})
                      </h1>
                    </div>
                    <div className="px-8 bg-white overflow-y-auto h-[400px]">
                      {items.map((item, index) => {
                        const pickUpCountry = filteredCountrySearch.filter(
                          (state: any) => {
                            return state.id === item.importCountry;
                          }
                        );

                        return (
                          <div
                            key={index}
                            className="flex gap-3 border-b-[1px] border-[#e4ebf5] py-4"
                          >
                            <div className="bg-[#F4F5F6] w-[28px] h-[28px] flex items-center justify-center rounded-[30px]">
                              <img
                                className="w-[14px] h-[14px]"
                                src="/images/location.svg"
                                alt=""
                              />
                            </div>
                            <div className="flex justify-between w-full">
                              <div>
                                <h1 className="text-[#071227] text-sm font-onestMedium font-[500] mb-1.5">
                                  {item.recipientName ||
                                    item.importRecipientName ||
                                    item.exportRecipientName}
                                </h1>
                                <p className="text-[#686868] flex items-center font-onestRegular text-[13px] mb-0.5">
                                  <span>{item.deliveryType} </span>
                                  <FaCircle
                                    color="#d1d1d2"
                                    size={6}
                                    className="bg-[#d1d1d2] mx-2 rounded-lg"
                                  />{" "}
                                  <span>
                                    {item.pickUpState ||
                                      pickUpCountry?.[0]?.country?.name ||
                                      item.exportPickUpState}
                                  </span>
                                </p>
                                <div className="flex space-x-3">
                                  <p
                                    className="text-[#18AF04] cursor-pointer text-xs font-onestRegular"
                                    onClick={() => {
                                      handleEdit(item.uniqueID);
                                    }}
                                  >
                                    Edit order
                                  </p>
                                  <p
                                    onClick={() => {
                                      handleDelete(item.uniqueID);
                                      if (items.length === 1) {
                                        setAddOrder(false);
                                        setIsPayment(false);
                                      }
                                    }}
                                    className=" text-[#F64108] cursor-pointer text-xs font-onestRegular"
                                  >
                                    Delete
                                  </p>
                                </div>
                              </div>

                              {selectedOption?.id === 2 ? (
                                <div className="flex space-x-1 ">
                                  {item.discountedCost &&
                                  item.discountedCost !== item.cost ? (
                                    <div className="flex space-x-1 ">
                                      <p className="text-red-400 font-onestMedium line-through text-[14px] font-[500]">
                                        {getSymbolFromCurrency(currencyValue)}
                                        {formatMoney().format(item.cost)}
                                      </p>
                                      <p className="text-[#071527] font-onestMedium text-[14px] font-[500]">
                                        {getSymbolFromCurrency(currencyValue)}
                                        {formatMoney().format(
                                          item.discountedCost
                                        )}
                                      </p>
                                    </div>
                                  ) : (
                                    <p className="text-[#071527] font-onestMedium text-[14px] font-[500]">
                                      {getSymbolFromCurrency(currencyValue)}
                                      {formatMoney().format(item.cost)}
                                    </p>
                                  )}
                                </div>
                              ) : (
                                <div className="flex space-x-1 ">
                                  {item.discountedCost &&
                                  item.discountedCost !== item.cost ? (
                                    <div className="flex space-x-1">
                                      <p className="text-red-400 font-onestMedium line-through text-[14px] font-[500]">
                                        ₦{formatMoney().format(item.cost)}
                                      </p>
                                      <p className="text-[#071527] font-onestMedium text-[14px] font-[500]">
                                        ₦
                                        {formatMoney().format(
                                          item.discountedCost
                                        )}
                                      </p>
                                    </div>
                                  ) : (
                                    <p className="text-[#071527] font-onestMedium text-[14px] font-[500]">
                                      ₦{formatMoney().format(item.cost)}
                                    </p>
                                  )}
                                </div>
                              )}
                            </div>
                          </div>
                        );
                      })}
                    </div>
                    <div className="px-4 xl:px-8 pt-5 bmd:flex-col lg:flex xl:flex xl:flex-row items-center justify-between ">
                      {selectedOption?.id !== 2 && (
                        <h1 className="font-[700] font-onestBold">
                          ₦{formatMoney().format(totalCost)}
                        </h1>
                      )}
                      <Button
                        title={
                          selectedOption?.id === 2
                            ? "Continue with your shipment"
                            : "Make Payment"
                        }
                        onClick={() => {
                          onGenerate();
                          setIsPayment(true);
                        }}
                        className="!w-fit py-2.5 bg-[#18AF04] !font-[700] !text-xs xl:!text-[13px] font-onestBold"
                      />
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>
        ) : (
          <PaymentPage
            setIsPayment={setIsPayment}
            isWalletLoading={isWalletLoading}
            totalCost={totalCost}
            walletData={walletData}
            items={items}
            selectedOption={selectedOption}
            onGenerate={onGenerate}
            setAddMoney={setAddMoney}
            orderLoading={orderLoading}
            onHandleImportPayment={onHandleImportPayment}
            payType={payType}
            setPayType={setPayType}
            onHandleExportPayment={onHandleExportPayment}
            onHandlePayment={onHandlePayment}
            isWalletCheckoutLoading={isWalletCheckoutLoading}
            fezOrgDetails={fezOrgDetails}
            filteredCountrySearch={filteredCountrySearch}
            setAddOrder={setAddOrder}
            currencyValue={currencyValue}
            setItems={setItems}
            setEditedDetails={setEditedDetails}
            weightData={weightData}
            filteredExportCountrySearch={filteredExportCountrySearch}
            filteredLockerSearch={filteredLockerSearch}
            setUseSmartLocker={setUseSmartLocker}
            setDropOffLockerName={setDropOffLockerName}
            setExportCountryStateName={setExportCountryStateName}
            setExportPickUpStateName={setExportPickUpStateName}
            setSelectedWeightOption={setSelectedWeightOption}
            setShowAddresses={setShowAddresses}
            setEditOrder={setEditOrder}
            isRemovingOrder={isRemovingOrder}
          />
        )
      ) : (
        <SelectShipmentType setIsCreateShipment={setIsCreateShipment} />
      )}
      <ModalContainer
        open={isModalPayment}
        showCloseIcon={false}
        tailwindClassName="w-[350px] sm:w-[466px]"
        closeModal={() => setModalPayment(false)}
      >
        <div className="w-full px-8 pb-8 bg-white flex flex-col  rounded-[12px] relative">
          <p className="text-lg pb-3 mt-6 border-b border-[#E4EBF5] text-center font-onestBold text-[#0A5001] ">
            Yippee!! 💃🏾
          </p>
          <div className="flex justify-center mt-8">
            {" "}
            <img
              src="/images/fezbox.svg"
              alt=""
              className="w-[123px] h-[98px]"
            />
          </div>
          <p className="text-center text-[#4A564D] text-sm font-onestRegular mt-8">
            Your order has been successfully uploaded and is ready to be
            shipped.{" "}
          </p>
          <div className="flex justify-center mt-14">
            <Button
              title="View orders"
              onClick={() => {
                removeFromStorage("items");
                navigate("/dashboard/shipments");
              }}
              className="!w-[210px] py-4 bg-[#18AF04] rounded-lg !font-[500] !text-base"
            />
          </div>
        </div>
      </ModalContainer>
      <ModalContainer
        open={addMoney}
        showCloseIcon={false}
        tailwindClassName="w-[350px] sm:w-[520px]"
      >
        <div className="w-full px-8 pb-8 bg-white flex flex-col  rounded-[12px]">
          <p className="text-lg pb-3 mt-6 border-b border-[#E4EBF5] font-onestBold text-[#0A5001] ">
            Add money
          </p>
          <div className="mt-6">
            <TextInput
              type="number"
              label="Amount"
              value={amountAdded}
              onChange={(e: any) => {
                if (e.target.value !== "") {
                  setFormErrors((prev: any) => ({
                    ...prev,
                    amount: "",
                  }));
                }
                if (!isNaN(e.target.value)) {
                  setAmountAdded(e.target.value);
                }
              }}
              placeholder="e.g 100"
            />
            {formErrors.amount && (
              <span className="text-xs text-[#BA3838] font-onestMedium">
                {formErrors.amount}
              </span>
            )}
          </div>
          <div className="flex justify-between mt-14">
            <Button
              onClick={() => setAddMoney(false)}
              title="Cancel"
              className="!w-[100px] py-4 !bg-[#fff] border-[1px] border-[#E4EBF5] !text-base rounded-full !text-[#18af04] !font-[500]"
            />
            <Button
              title="Add Money"
              onClick={onAddMoney}
              className="!w-[170px] py-4 bg-[#18AF04] rounded-full !font-[500] !text-base"
            />
          </div>
        </div>
      </ModalContainer>
      <ModalContainer
        open={addMoneySuccess}
        showCloseIcon={false}
        tailwindClassName="w-[350px] sm:w-[466px]"
        closeModal={() => setAddMoneySuccess(false)}
      >
        <div className="w-full px-8 pb-8 bg-white flex flex-col  rounded-[12px] relative">
          <p className="text-lg pb-3 mt-6 border-b border-[#E4EBF5] text-center font-onestBold text-[#0A5001] ">
            Yippee!! 💃🏾
          </p>
          <img
            className="w-6 h-6 absolute right-6 top-6 cursor-pointer"
            onClick={() => {
              setAmountAdded(null);
              setAddMoneySuccess(false);
            }}
            src="/images/close.svg"
            alt=""
          />
          <div className="flex justify-center mt-8">
            {" "}
            <img
              src="/images/fezbox.svg"
              alt=""
              className="w-[123px] h-[98px]"
            />
          </div>
          <p className="text-center text-[#4A564D] text-sm font-onestRegular mt-8">
            Money has been added to your wallet
          </p>
          <div className="flex justify-center mt-14">
            <Button
              title="Close"
              onClick={() => {
                setAmountAdded(null);
                setAddMoneySuccess(false);
              }}
              className="!w-[210px] py-3 bg-[#18AF04] rounded-lg !font-[500] !text-base"
            />
          </div>
        </div>
      </ModalContainer>
      <ModalContainer
        open={differentDeliveryType}
        showCloseIcon={false}
        tailwindClassName="w-[350px] sm:w-[466px]"
      >
        <div className="w-full px-8 pb-8 bg-white flex flex-col  rounded-[12px] relative">
          <p className="text-lg pb-3 mt-6 border-b border-[#E4EBF5] text-center font-onestBold text-[#0A5001] ">
            Only Same Delivery Type!!
          </p>
          <div className="flex justify-center mt-8">
            {" "}
            <img
              src="/images/warning.svg"
              alt=""
              className="w-[123px] h-[98px]"
            />
          </div>
          <p className="text-center text-[#4A564D] text-sm font-onestRegular mt-8">
            Please note: You can only select one delivery type at a time. To
            proceed, choose a single delivery type for all orders in this batch.
          </p>
          <div className="flex justify-center mt-14">
            <Button
              title="OK"
              onClick={() => {
                setDifferentDeliveryType(false);
              }}
              className=" !px-9 py-2.5 bg-[#18AF04] rounded-lg !font-[500] !text-base"
            />
          </div>
        </div>
      </ModalContainer>
      <ModalContainer
        open={orderLockModal}
        showCloseIcon={false}
        tailwindClassName="w-[350px] sm:w-[466px]"
      >
        <div className="w-full px-8 pb-8 bg-white flex flex-col  rounded-[12px] relative">
          <p className="text-center text-[#207435] text-xl font-onestMedium mt-8">
            We will be right back!!
          </p>
          <div className="flex justify-center mt-4">
            <Button
              title="OK"
              onClick={() => navigate("/dashboard")}
              className=" !px-9 py-2.5 bg-[#18AF04] rounded-lg !font-[500] !text-base"
            />
          </div>
        </div>
      </ModalContainer>
    </>
  );
}

export default SingleShipment;
