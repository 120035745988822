import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { getFromStorage } from "helpers";


export const notifications = createApi({
  reducerPath: "notifications",
  baseQuery: fetchBaseQuery({
    baseUrl: `${process.env.REACT_APP_URL}`,
    prepareHeaders: (headers) => {
      headers.set("Content-Type", "application/json");
      const token = getFromStorage("token");
      const secretKey = getFromStorage("secret-key");
      if (token) {
        headers.set("Authorization", `Bearer ${token}`);
        headers.set("secret-key", `${secretKey}`);
      }
      return headers;
    },
  }),
  endpoints: (builder) => ({
    getNotifications: builder.query<any, any>({
      query: ({ id, startDate, endDate, page }) => ({
        url: `notifications/${id}?startDate=${startDate}&endDate=${endDate}&page=${page}`,
        method: "GET",
      }),
    }),
    getUnReadNotifications: builder.query<any, any>({
      query: ({ id }) => ({
        url: `notifications/${id}/count`,
        method: "GET",
      }),
    }),
    notificationViewed: builder.mutation<any, any>({
      query: ({ id }) => ({
        url: `notifications/${id}/viewed`,
        method: "POST",
      }),
    }),
  }),
});

export const {
  useGetNotificationsQuery,
  useGetUnReadNotificationsQuery,
  useNotificationViewedMutation,
} = notifications;

