import { DropDownSelect } from "components/common/form/Dropdown";
import { deliveryType } from "constant/data";
import React from "react";
import { Button } from "components/Button";
import MobileLocalDeliveryForm from "../deliveryType/MobileLocal";
import MobileImportDeliveryForm from "../deliveryType/MobileImport";
import MobileExportDeliveryForm from "../deliveryType/MobileExport";

interface SingleFormProps {
  formErrors: any;
  selectedOption: any;
  isDeliveryType: boolean;
  isLoadingState: boolean;
  pickUpStateName: string;
  searchPickUpState: string;
  handleSubmit: (e?: React.FormEvent) => Promise<void>;
  handleImportSubmit: (e?: React.FormEvent) => Promise<void>;
  handleExportSubmit: (e?: React.FormEvent) => Promise<void>;
  toggleDeliveryType: () => void;
  handleDeliveryType: (option: any) => void;
  onGenerate: () => void;
  query: string;
  deliveryDetails: any;
  filteredPickUpStateSearch: any;
  lockerAvailabilityData: any;
  filteredStateSearch: any;
  filteredLockerSearch: any;
  setFormErrors: React.Dispatch<React.SetStateAction<any>>;
  setDeliveryDetails: React.Dispatch<React.SetStateAction<any>>;
  setQuery: React.Dispatch<React.SetStateAction<string>>;
  setSearchPickUpState: React.Dispatch<React.SetStateAction<string>>;
  setPickUpStateName: React.Dispatch<React.SetStateAction<string>>;
  handleDeliveryChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  stateName: string;
  searchState: string;
  setSearchState: React.Dispatch<React.SetStateAction<string>>;
  setStateName: React.Dispatch<React.SetStateAction<string>>;
  setUseSmartLocker: React.Dispatch<React.SetStateAction<boolean>>;
  isLockerLoading: boolean;
  useSmartLocker: boolean;
  isLockerAvailabilityLoading: boolean;
  lockerData: any;
  dropOffLockerName: string;
  searchLocker: string;
  setSearchLocker: React.Dispatch<React.SetStateAction<string>>;
  setDropOffLockerName: React.Dispatch<React.SetStateAction<string>>;
  isCountryLoading: boolean;
  importCountryStateName: string;
  searchCountryState: string;
  setSearchCountryState: React.Dispatch<React.SetStateAction<string>>;
  filteredCountrySearch: any;
  setProof: React.Dispatch<React.SetStateAction<number>>;
  setImportCountryStateName: React.Dispatch<React.SetStateAction<string>>;
  setShowAddresses: React.Dispatch<React.SetStateAction<any>>;
  showAddresses: any;
  setCurrencyValue: React.Dispatch<React.SetStateAction<string>>;
  setIsPayment: React.Dispatch<React.SetStateAction<boolean>>;
  showClipboard: boolean;
  copiedAdd: string;
  handleCopy: (address: string) => void;
  isItemLoading: boolean;
  itemName: string;
  totalWeight: number;
  searchItem: string;
  setSearchItem: React.Dispatch<React.SetStateAction<string>>;
  setItemName: React.Dispatch<React.SetStateAction<string>>;
  filteredItemSearch: any;
  importDropOffStateName: string;
  setImportDropOffStateName: React.Dispatch<React.SetStateAction<string>>;
  setClickPayment: React.Dispatch<React.SetStateAction<boolean>>;
  clickPayment: boolean;
  currencyValue: string;
  proof: number;
  items: any;
  exportCountryStateName: string;
  exportPickUpStateName: string;
  isExportCountryLoading: boolean;
  filteredExportCountrySearch: any;
  setExportCountryStateName: React.Dispatch<React.SetStateAction<string>>;
  setExportPickUpStateName: React.Dispatch<React.SetStateAction<string>>;
  isWeightLoading: boolean;
  weightArray: any;
  setWeightIsOpen: React.Dispatch<React.SetStateAction<boolean>>;
  weightIsOpen: boolean;
  setSelectedWeightOption: React.Dispatch<React.SetStateAction<any>>;
  setAddOrder: React.Dispatch<React.SetStateAction<boolean>>;
  selectedWeightOption: any;
  isLoading: boolean;
  importLoading: boolean;
  exportLoading: boolean;
}

const MobileAddOrderForm = ({
  formErrors,
  selectedOption,
  handleSubmit,
  handleImportSubmit,
  handleExportSubmit,
  query,
  setQuery,
  isDeliveryType,
  toggleDeliveryType,
  handleDeliveryType,
  pickUpStateName,
  isLoadingState,
  deliveryDetails,
  searchPickUpState,
  setSearchPickUpState,
  filteredPickUpStateSearch,
  setDeliveryDetails,
  setPickUpStateName,
  setFormErrors,
  handleDeliveryChange,
  stateName,
  searchState,
  setSearchState,
  filteredStateSearch,
  setStateName,
  isLockerLoading,
  useSmartLocker,
  lockerData,
  setUseSmartLocker,
  dropOffLockerName,
  searchLocker,
  setSearchLocker,
  setDropOffLockerName,
  filteredLockerSearch,
  lockerAvailabilityData,
  isLockerAvailabilityLoading,
  isCountryLoading,
  importCountryStateName,
  searchCountryState,
  setSearchCountryState,
  filteredCountrySearch,
  setProof,
  setImportCountryStateName,
  setShowAddresses,
  showAddresses,
  setCurrencyValue,
  showClipboard,
  copiedAdd,
  handleCopy,
  isItemLoading,
  searchItem,
  itemName,
  setSearchItem,
  setItemName,
  filteredItemSearch,
  importDropOffStateName,
  setImportDropOffStateName,
  currencyValue,
  proof,
  exportCountryStateName,
  filteredExportCountrySearch,
  setExportCountryStateName,
  setExportPickUpStateName,
  exportPickUpStateName,
  isWeightLoading,
  setWeightIsOpen,
  weightIsOpen,
  setSelectedWeightOption,
  selectedWeightOption,
  items,
  isLoading,
  importLoading,
  exportLoading,
  setAddOrder,
  isExportCountryLoading,
  weightArray,
}: SingleFormProps) => {
  return (
    <form
      className={`lg:hidden block`}
      onSubmit={
        selectedOption?.id === 1
          ? handleSubmit
          : selectedOption?.id === 2
          ? handleImportSubmit
          : handleExportSubmit
      }
    >
      <div className="mt-6 border-b-2 pb-6 border-[#e4ebf5]">
        <DropDownSelect
          title="Select delivery type"
          lists={deliveryType}
          value={query}
          setQuery={setQuery}
          isOpen={isDeliveryType}
          isRequired
          toggleDropdown={toggleDeliveryType}
          handleOptionClick={handleDeliveryType}
          placeholder="Select type"
          name="deliveryType"
        />
        {formErrors.deliveryType && (
          <span className="text-xs text-[#BA3838] font-onestMedium">
            {formErrors.deliveryType}
          </span>
        )}
      </div>
      {selectedOption?.id === 1 && (
        <MobileLocalDeliveryForm
          formErrors={formErrors}
          pickUpStateName={pickUpStateName}
          isLoadingState={isLoadingState}
          deliveryDetails={deliveryDetails}
          searchPickUpState={searchPickUpState}
          setSearchPickUpState={setSearchPickUpState}
          filteredPickUpStateSearch={filteredPickUpStateSearch}
          setDeliveryDetails={setDeliveryDetails}
          setPickUpStateName={setPickUpStateName}
          setFormErrors={setFormErrors}
          handleDeliveryChange={(e: React.ChangeEvent<HTMLInputElement>) =>
            handleDeliveryChange(e)
          }
          stateName={stateName}
          searchState={searchState}
          setSearchState={setSearchState}
          filteredStateSearch={filteredStateSearch}
          setStateName={setStateName}
          isLockerLoading={isLockerLoading}
          useSmartLocker={useSmartLocker}
          lockerData={lockerData}
          setUseSmartLocker={setUseSmartLocker}
          dropOffLockerName={dropOffLockerName}
          searchLocker={searchLocker}
          setSearchLocker={setSearchLocker}
          setDropOffLockerName={setDropOffLockerName}
          filteredLockerSearch={filteredLockerSearch}
          lockerAvailabilityData={lockerAvailabilityData}
          isLockerAvailabilityLoading={isLockerAvailabilityLoading}
        />
      )}

      {selectedOption?.id === 2 && (
        <MobileImportDeliveryForm
          isCountryLoading={isCountryLoading}
          importCountryStateName={importCountryStateName}
          searchCountryState={searchCountryState}
          setSearchCountryState={setSearchCountryState}
          filteredCountrySearch={filteredCountrySearch}
          setProof={setProof}
          setImportCountryStateName={setImportCountryStateName}
          setShowAddresses={setShowAddresses}
          showAddresses={showAddresses}
          setCurrencyValue={setCurrencyValue}
          showClipboard={showClipboard}
          copiedAdd={copiedAdd}
          handleCopy={handleCopy}
          isItemLoading={isItemLoading}
          searchItem={searchItem}
          itemName={itemName}
          setSearchItem={setSearchItem}
          setItemName={setItemName}
          filteredItemSearch={filteredItemSearch}
          importDropOffStateName={importDropOffStateName}
          setImportDropOffStateName={setImportDropOffStateName}
          currencyValue={currencyValue}
          proof={proof}
          formErrors={formErrors}
          setFormErrors={setFormErrors}
          deliveryDetails={deliveryDetails}
          setDeliveryDetails={setDeliveryDetails}
          handleDeliveryChange={(e: React.ChangeEvent<HTMLInputElement>) =>
            handleDeliveryChange(e)
          }
          searchPickUpState={searchPickUpState}
          setSearchPickUpState={setSearchPickUpState}
          filteredPickUpStateSearch={filteredPickUpStateSearch}
        />
      )}
      {selectedOption?.id === 3 && (
        <MobileExportDeliveryForm
          setFormErrors={setFormErrors}
          formErrors={formErrors}
          deliveryDetails={deliveryDetails}
          setDeliveryDetails={setDeliveryDetails}
          handleDeliveryChange={(e: React.ChangeEvent<HTMLInputElement>) =>
            handleDeliveryChange(e)
          }
          searchCountryState={searchCountryState}
          setSearchCountryState={setSearchCountryState}
          exportCountryStateName={exportCountryStateName}
          isExportCountryLoading={isExportCountryLoading}
          filteredExportCountrySearch={filteredExportCountrySearch}
          exportPickUpStateName={exportPickUpStateName}
          filteredStateSearch={filteredStateSearch}
          setExportCountryStateName={setExportCountryStateName}
          setExportPickUpStateName={setExportPickUpStateName}
          isWeightLoading={isWeightLoading}
          weightArray={weightArray}
          setWeightIsOpen={setWeightIsOpen}
          weightIsOpen={weightIsOpen}
          setSelectedWeightOption={setSelectedWeightOption}
          selectedWeightOption={selectedWeightOption}
          isLoadingState={isLoadingState}
          searchState={searchState}
          setSearchState={setSearchState}
        />
      )}

      <div className="mt-3 space-y-5 md:mt-[64px] mb-[47px]">
        <Button
          title="Add to Orders"
          type="submit"
          disabled={
            selectedOption?.id === 1
              ? isLoading
              : selectedOption?.id === 2
              ? importLoading
              : exportLoading
          }
          loading={
            selectedOption?.id === 1
              ? isLoading
              : selectedOption?.id === 2
              ? importLoading
              : exportLoading
          }
          className={`flex justify-center py-2.5 !text-white border-[1px] border-[#18af04]  !font-[700] !text-base font-onestBold `}
        />
        <Button
          title="View Orders"
          type="submit"
          disabled={
            selectedOption?.id === 1
              ? isLoading
              : selectedOption?.id === 2
              ? importLoading
              : exportLoading
          }
          onClick={() => {
            items.length > 0 && setAddOrder(true);
          }}
          className={`flex justify-center py-2.5 !bg-white border-[1px] !text-[#18af04]  border-[#18af04]  !font-[700] !text-base font-onestBold `}
        />
      </div>
    </form>
  );
};
export default MobileAddOrderForm;
