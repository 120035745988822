import { Button } from "components/Button";
import ModalContainer from "components/Modal";
import { TableLoader } from "components/TableLoader";
import { filterTerms } from "constant/data";
import { formatMoney } from "helpers/formatMoney";
import { getDateRange } from "helpers/getDateRange";
import { handleErrorToast } from "helpers/toastMessage";
import moment from "moment";
import React, { useEffect, useState } from "react";
import DatePicker from "react-datepicker";
import { useGetReportMutation } from "services/slices/report";

interface OrderStat {
  year: number;
  month: number;
  day: number;
  status: string;
  "pending pick-up": number;
  "picked-up": number;
  "Prepared For Delivery": number;
  "Delivery In Progress": number;
  delivered: number;
  "In Transit Back to you": number;
  returned: number;
  grandTotal: number;
  percentage: {
    pendingPickUp: number;
    pickedUp: number;
    preparedForDelivery: number;
    deliveryInProgress: number;
    delivered: number;
    inTransitBackToYou: number;
    returned: number;
    grandTotal: number;
  };
}

interface ReportData {
  orderStats: OrderStat[];
}

const periodTerms = [
  {
    id: 1,
    name: "Day",
    value: "Day",
  },
  {
    id: 2,
    name: "Month",
    value: "Month",
  },
  {
    id: 3,
    name: "Year",
    value: "Year",
  },
];

function Reports() {
  const [filterDate, setFilterDate] = useState<boolean>(false);
  const [periodDate, setPeriodDate] = useState<boolean>(false);
  const [showDateModal, setShowDateModal] = useState<boolean>(false);
  const [downloading, setDownloading] = useState<boolean>(false);
  const [startDate, setStartDate] = useState<Date | null>(null);
  const [formattedDate, setFormattedDate] = useState<string>("");
  const [endDate, setEndDate] = useState<Date | null>(null);
  const [formattedEndDate, setFormattedEndDate] = useState<string>("");
  const [reportEndDate, setReportEndDate] = useState<string>(
    moment().add(1, "days").format("YYYY-MM-DD")
  );
  const [reportStartDate, setReportStartDate] = useState<string>(
    moment().subtract(7, "days").format("YYYY-MM-DD")
  );
  const [selectedPeriod, setSelectedPeriod] = useState("last7days");
  const [selectedPeriodName, setSelectedPeriodName] = useState("Last 7 days");
  const [selectedPeriodDateName, setSelectedPeriodDateName] = useState("Day");

  const [getReport, { isLoading, data: reportData }] =
    useGetReportMutation();

  const handlePeriodChange = (value: string) => {
    setSelectedPeriod(value);
  };

  const onClearDate = () => {
    setStartDate(null);
    setEndDate(null);
    setFormattedEndDate("");
    setFormattedDate("");
    setReportStartDate(moment().subtract(7, "days").format("YYYY-MM-DD"));
    setReportEndDate(moment().add(1, "days").format("YYYY-MM-DD"));
    setSelectedPeriodName("Last 7 days");
    setSelectedPeriod("last7days");
  };

  const handleDateChange = (date: Date | null) => {
    if (date) {
      const dateStart = moment(date).format("DD-MM-YYYY");
      setStartDate(date);
      setFormattedDate(dateStart);
    }
  };

  const handleEndDateChange = (date: Date | null) => {
    if (date) {
      const dateStart = moment(date).format("DD-MM-YYYY");
      setEndDate(date);
      setFormattedEndDate(dateStart);
    }
  };

  useEffect(() => {
    if (selectedPeriod && selectedPeriod.toLowerCase() !== "custom") {
      const { startDate, endDate } = getDateRange(selectedPeriod);
      setReportStartDate(startDate);
      setReportEndDate(endDate);
    }
  }, [selectedPeriod, startDate, endDate]);

  useEffect(() => {
    if (Boolean(selectedPeriod.toLowerCase() === "custom")) {
      setShowDateModal(true);
    } else {
      setShowDateModal(false);
    }
  }, [selectedPeriod]);

  const downloadCSV = (reportData: ReportData) => {
    const headers = [
      "Year",
      "Month",
      "Day",
      "Status",
      "Pending Pick-up",
      "Picked up",
      "Prepared For delivery",
      "Delivery In Progress",
      "Delivered",
      "In Transit back to you",
      "Returned",
      "Grand Total",
    ];
    if (reportData?.orderStats) {
      setDownloading(true);
      let csvContent = "data:text/csv;charset=utf-8,";
      csvContent += headers.join(",") + "\n";

      reportData.orderStats.forEach((data: OrderStat) => {
        const grandTotal =
          Number(data?.["pending pick-up"]) +
          Number(data?.["Prepared For Delivery"]) +
          Number(data?.["picked-up"]) +
          Number(data?.["In Transit Back to you"]) +
          Number(data?.["Delivery In Progress"]) +
          Number(data?.delivered) +
          Number(data?.returned);
        const pendingPickUpPercent =
          (Number(data?.["pending pick-up"]) / grandTotal) * 100;
        const preparedDeliveryPercent =
          (Number(data?.["Prepared For Delivery"]) / grandTotal) * 100;
        const pickedUpPercent =
          (Number(data?.["picked-up"]) / grandTotal) * 100;
        const inTransitPercent =
          (Number(data?.["In Transit Back to you"]) / grandTotal) * 100;
        const deliveryInProgressPercent =
          (Number(data?.["Delivery In Progress"]) / grandTotal) * 100;
        const deliveredPercent = (Number(data?.delivered) / grandTotal) * 100;
        const returnedPercent = (Number(data?.returned) / grandTotal) * 100;
        const grandPercent =
          pendingPickUpPercent +
          preparedDeliveryPercent +
          pickedUpPercent +
          inTransitPercent +
          deliveryInProgressPercent +
          deliveredPercent +
          returnedPercent;
        const mainRow = [
          data.year,
          moment()
            .month(data.month - 1)
            .format("MMM"),
          data.day,
          data.status,
          data["pending pick-up"],
          data["picked-up"],
          data["Prepared For Delivery"],
          data["Delivery In Progress"],
          data.delivered,
          data["In Transit Back to you"],
          data.returned,
          grandTotal,
        ];
        csvContent += mainRow.join(",") + "\n";
        const percentageRow = [
          "",
          "",
          "",
          "(%)",
          formatMoney().format(pendingPickUpPercent || 0),
          formatMoney().format(pickedUpPercent || 0),
          formatMoney().format(preparedDeliveryPercent || 0),
          formatMoney().format(deliveryInProgressPercent || 0),
          formatMoney().format(deliveredPercent || 0),
          formatMoney().format(inTransitPercent || 0),
          formatMoney().format(returnedPercent || 0),
          formatMoney().format(grandPercent || 0),
        ];
        csvContent += percentageRow.join(",") + "\n";
      });

      const encodedUri = encodeURI(csvContent);
      const link = document.createElement("a");
      link.setAttribute("href", encodedUri);
      link.setAttribute("download", "report_data.csv");
      document.body.appendChild(link);
      link.click();
      setDownloading(false);
    }
  };

  useEffect(() => {
    const getAllReport = async () => {
      await getReport({
        startDate: reportStartDate,
        endDate: reportEndDate,
        filterBy: selectedPeriodDateName.toLowerCase(),
      })
        .unwrap()
        .then((data) => {})
        .catch((e: { data: { description: string } }) => {
          handleErrorToast(e);
        });
    };
    if (selectedPeriod.toLowerCase() !== "custom") {
      getAllReport();
    }
  }, [getReport, reportStartDate, reportEndDate, selectedPeriod, selectedPeriodDateName]);

  const onApplyDate = () => {
    const getAllReport = async () => {
      await getReport({
        startDate: formattedDate,
        endDate: formattedEndDate,
        filterBy: selectedPeriodDateName.toLowerCase(),
      })
        .unwrap()
        .then((data) => {
          setFormattedDate("")
          setFormattedEndDate("")
          setEndDate(null)
          setStartDate(null)
        })
        .catch((e: { data: { description: string } }) => {
          handleErrorToast(e);
        });
    };
    if (formattedDate !== "" && formattedEndDate !== "") {
      setSelectedPeriodName(`${formattedDate} - ${formattedEndDate}`);
      getAllReport();
    }
    setShowDateModal(false);
  };
  return (
    <div className="px-3 md:px-[46px] mt-[24px]">
      <div className="flex justify-between items-center">
        <h1 className="text-lg font-onestBold font-[800] text-[#0A5001] tracking-wide">
          Reports
        </h1>
        <Button
          onClick={() => downloadCSV(reportData)}
          className={`md:!w-[180px] py-4 ${"!bg-[#289D17]"}`}
          loading={downloading}
          disabled={!reportData}
          title="Download CSV"
        />
      </div>
      <div className=" md:flex md:flex-row justify-between mt-10">
        <p className="text-[#071227]">Order summary</p>
        <div className="flex justify-between gap-3 mt-10 md:mt-0 ">
          <div
            onClick={() => setPeriodDate(!periodDate)}
            className=" w-fit cursor-pointer relative flex gap-2 items-center rounded-[9px] pl-4 pr-4 border-[1px] border-[#E4EBF5] "
          >
            <img
              className="w-[14px] h-[14px]"
              src="/images/calendar.svg"
              alt="calendar icon"
            />
            <p className="text-[13px]">{selectedPeriodDateName}</p>
            {periodDate && (
              <div className="py-2 px-2 border-[1px] w-fit right-0.5 top-11 bg-white z-10 absolute rounded-lg border-[#E4EBF5]">
                <div className="">
                  {periodTerms.map((terms) => (
                    <div
                      className={`group  flex my-3 gap-2.5 hover:bg-[#D4F3D5] text-[#289D17] hover:py-2 hover:px-2 rounded-lg pl-2`}
                      onClick={() => {
                        setSelectedPeriodDateName(terms.name);
                      }}
                    >
                      <p className="flex items-center mb-0 cursor-pointer ">
                        <span
                          className={`w-4 h-4 border border-gray-300 rounded-full flex items-center justify-center ${
                            selectedPeriodDateName === terms.name &&
                            "bg-[#289D17]"
                          } group-hover:bg-[#289D17]`}
                        >
                          <span className="w-2 h-2 bg-white rounded-full"></span>
                        </span>
                        <span
                          className={`ml-3 text-sm text-[#464748]  ${
                            selectedPeriodDateName === terms.name &&
                            "text-[#289D17]"
                          } hover:text-[#289D17]`}
                        >
                          {terms.name}
                        </span>
                      </p>
                    </div>
                  ))}
                </div>
              </div>
            )}
          </div>
          <div
            onClick={() => setFilterDate(!filterDate)}
            className="min-w-[180px] relative flex gap-2 cursor-pointer items-center rounded-[9px] pl-4 pr-4 border-[1px] border-[#E4EBF5] "
          >
            <img
              className="w-[14px] h-[14px]"
              src="/images/filter.svg"
              alt="search-icon"
            />
            <p className="text-[13px] text-[#929DAE]  py-[10px]  outline-none">
              Filter:{" "}
              <span className="text-[#161718]">{selectedPeriodName}</span>
            </p>
            <img
              className="w-[14px] rounded-xl cursor-pointer h-[14px]"
              src="/images/circle-close.svg"
              alt="close icon"
              onClick={(e) => {
                e.stopPropagation();
                onClearDate();
              }}
            />
            {filterDate && (
              <div className="p-4 border-[1px] w-[200px] right-0.5 top-11 bg-white z-10 absolute rounded-lg border-[#E4EBF5]">
                <h1 className="text-[#929DAE] pb-3 font-[900] text-xs font-onestBold">
                  PERIOD
                </h1>
                <form className="">
                  {filterTerms
                    .filter(
                      (term) =>
                        term.value !== "thisyear" && term.value !== "lastyear"
                    )
                    .map((terms) => (
                      <div
                        className="group flex my-3 gap-2.5 hover:bg-[#D4F3D5] text-[#289D17] hover:py-2 rounded-lg pl-2"
                        onClick={() => {
                          setSelectedPeriodName(terms.name);
                          handlePeriodChange(terms.value);
                        }}
                      >
                        <p className="flex items-center mb-0 cursor-pointer ">
                          <span
                            className={`${
                              selectedPeriod === terms.value && "bg-[#289D17]"
                            } w-4 h-4 border border-gray-300 rounded-full flex items-center justify-center group-hover:bg-[#289D17]`}
                          >
                            <span className="w-2 h-2 bg-white rounded-full"></span>
                          </span>
                          <span
                            className={`${
                              selectedPeriod === terms.value
                                ? "text-[#289D17]"
                                : "text-[#464748] group-hover:text-[#289D17]"
                            } ml-3 text-sm  `}
                          >
                            {terms.name}
                          </span>
                        </p>
                      </div>
                    ))}
                </form>
              </div>
            )}
          </div>
        </div>
      </div>
      <div className="reports-table w-full overflow-x-auto pb-4 border-[2px] border-[#E4EBF5] rounded-lg mt-3">
        <table className="whitespace-nowrap mt-3 w-full border-spacing-2 xl:border-spacing-0 ">
          <thead className="bg-white">
            <tr className="border-b-[1px] py-5 border-[#E4EBF5]">
              <th className="py-3 text-xs pl-4 pr-6 text-[#929DAE] font-onestRegular">
                Year
              </th>
              {selectedPeriodDateName !== "Year" && (
                <>
                  <th className="py-3 text-xs pl-1 pr-4 text-[#929DAE] font-onestRegular">
                    Month
                  </th>
                  {selectedPeriodDateName !== "Month" && (
                    <th className="py-3 text-xs pl-1 pr-4 text-[#929DAE] font-onestRegular">
                      Day
                    </th>
                  )}
                </>
              )}
              <th className="py-3 text-xs pl-4 pr-6 text-[#929DAE] font-onestRegular">
                Status
              </th>
              <th className="py-3 text-xs pl-6 pr-6  text-[#929DAE] font-onestRegular">
                Pending Pick-up
              </th>
              <th className="py-3 text-xs pl-4 pr-6  text-[#929DAE] font-onestRegular">
                Picked up
              </th>
              <th className="py-3 text-xs pl-4 pr-6  text-[#929DAE] font-onestRegular">
                <span className="font-[400]"> Prepared For delivery</span>
              </th>
              <th className="py-3 text-xs pl-4 pr-6  text-[#929DAE] font-onestRegular">
                Delivery In Progress
              </th>
              <th className="py-3 text-xs pl-4 pr-6  text-[#929DAE] font-onestRegular">
                Delivered
              </th>
              <th className="py-3 text-xs pl-4 pr-6  text-[#929DAE] font-onestRegular">
                In Transit back to you
              </th>
              <th className="py-3 text-xs pl-4 pr-6  text-[#929DAE] font-onestRegular">
                Returned
              </th>
              <th className="py-3 text-xs pl-4 pr-6  text-[#929DAE] font-onestRegular">
                Grand Total
              </th>
            </tr>
          </thead>

          {isLoading ? (
            <TableLoader rowLength={19} />
          ) : (
            <>
              {reportData?.orderStats?.length > 0 ? (
                <tbody>
                  {reportData?.orderStats.map((data: any, index: number) => {
                    const grandTotal =
                      Number(data?.["pending pick-up"]) +
                      Number(data?.["Prepared For Delivery"]) +
                      Number(data?.["picked-up"]) +
                      Number(data?.["In Transit Back to you"]) +
                      Number(data?.["Delivery In Progress"]) +
                      Number(data?.delivered) +
                      Number(data?.returned);
                    const pendingPickUpPercent =
                      (Number(data?.["pending pick-up"]) / grandTotal) * 100;
                    const preparedDeliveryPercent =
                      (Number(data?.["Prepared For Delivery"]) / grandTotal) *
                      100;
                    const pickedUpPercent =
                      (Number(data?.["picked-up"]) / grandTotal) * 100;
                    const inTransitPercent =
                      (Number(data?.["In Transit Back to you"]) / grandTotal) *
                      100;
                    const deliveryInProgressPercent =
                      (Number(data?.["Delivery In Progress"]) / grandTotal) *
                      100;
                    const deliveredPercent =
                      (Number(data?.delivered) / grandTotal) * 100;
                    const returnedPercent =
                      (Number(data?.returned) / grandTotal) * 100;
                    const grandPercent =
                      pendingPickUpPercent +
                      preparedDeliveryPercent +
                      pickedUpPercent +
                      inTransitPercent +
                      deliveryInProgressPercent +
                      deliveredPercent +
                      returnedPercent;
                    return (
                      <React.Fragment key={index}>
                        <tr className="py-6 mx-2">
                          <td className="bg-[#EFFAEE] pl-4 text-center  pr-4 text-[#0E1728] font-onestRegular">
                            <span className="bg-[#EFFAEE]"> {data?.year}</span>
                          </td>
                          {selectedPeriodDateName !== "Year" && (
                            <>
                              <td className="bg-[#EFFAEE] text-center  py-3 pl-4 pr-6 text-[#0E1728] font-onestRegular">
                                {" "}
                                {moment()
                                  .month(data?.month - 1)
                                  .format("MMM")}
                              </td>
                              {selectedPeriodDateName !== "Month" && (
                                <td className="bg-[#EFFAEE] text-center  py-3 pl-4 pr-6 text-[#0E1728] font-onestRegular">
                                  {data?.day}
                                </td>
                              )}
                            </>
                          )}

                          <td className="py-3 pr-6 text-center  pl-4 text-[#0E1728] font-onestRegular">
                            {data?.status}
                          </td>
                          <td className="py-3 pl-4 text-center  pr-6 text-[#0E1728] font-onestRegular">
                            {" "}
                            {data?.["pending pick-up"]}
                          </td>
                          <td className="py-3 pl-4 text-center  pr-6 text-[#0E1728] font-onestRegular">
                            {data?.["picked-up"]}
                          </td>
                          <td className="py-3 pl-4 text-center  pr-6 text-[#0E1728] font-onestRegular">
                            {data?.["Prepared For Delivery"]}
                          </td>
                          <td className="py-3 pl-4 text-center  pr-6 text-sm text-[#0E1728] font-onestRegular">
                            {data?.["Delivery In Progress"]}
                          </td>
                          <td className="py-3 text-center  pl-4 pr-6 text-[#0E1728] font-onestRegular">
                            {data?.delivered}
                          </td>
                          <td className="py-3 text-center   pl-4 pr-6 text-[#0E1728] font-onestRegular">
                            {data?.["In Transit Back to you"]}
                          </td>
                          <td className="py-3 pl-4 text-center  pr-6 text-[#0E1728] font-onestRegular">
                            {data?.returned}
                          </td>
                          <td className="py-3 pl-4 text-center pr-6 text-[#0E1728] font-onestRegular">
                            {grandTotal}
                          </td>
                        </tr>
                        <tr className="border-b-[1px] py-4 border-[#E4EBF5]">
                          <td></td>
                          {selectedPeriodDateName !== "Year" && (
                            <>
                              <td></td>
                              {selectedPeriodDateName !== "Month" && <td></td>}
                            </>
                          )}
                          <td className="pr-6 text-center  pl-4">(%)</td>
                          <td className="bg-[#F9F9FB] text-center  py-2 pl-4 pr-6">
                            {formatMoney().format(
                              Number(pendingPickUpPercent || 0)
                            )}
                          </td>
                          <td className="bg-[#F9F9FB] text-center  pl-4 pr-6">
                            {formatMoney().format(Number(pickedUpPercent || 0))}
                          </td>
                          <td className="bg-[#F9F9FB] text-center  pl-4 pr-6">
                            {formatMoney().format(
                              Number(preparedDeliveryPercent || 0)
                            )}
                          </td>
                          <td className="bg-[#F9F9FB] text-center  pl-4 pr-6">
                            {formatMoney().format(
                              Number(deliveryInProgressPercent || 0)
                            )}
                          </td>
                          <td className="bg-[#F9F9FB] text-center  pl-4 pr-6">
                            {formatMoney().format(
                              Number(deliveredPercent || 0)
                            )}
                          </td>
                          <td className="bg-[#F9F9FB] text-center  pl-4 pr-6">
                            {formatMoney().format(
                              Number(inTransitPercent || 0)
                            )}
                          </td>
                          <td className="bg-[#F9F9FB] text-center  pl-4 pr-6">
                            {formatMoney().format(Number(returnedPercent || 0))}
                          </td>
                          <td className="bg-[#F9F9FB] text-center pl-4 pr-6">
                            {formatMoney().format(Number(grandPercent || 0))}
                          </td>
                        </tr>
                      </React.Fragment>
                    );
                  })}
                </tbody>
              ) : (
                <div className="flex justify-center w-full">
                  <p className=" pl-6 mt-3">No report !!</p>
                </div>
              )}
            </>
          )}
        </table>
      </div>
      <ModalContainer
        open={showDateModal}
        showCloseIcon={false}
        tailwindClassName="max-w-[466px]"
      >
        <div className="w-full p-8 bg-white flex flex-col  rounded-[12px]">
          <p className="text-lg text-center pb-4 font-onestBold text-[#0A5001] ">
            Custom date range
          </p>
          <div className="flex gap-3 justify-between">
            <div className="">
              <DatePicker
                placeholderText={"Start date"}
                selected={startDate}
                onChange={handleDateChange}
                className="border-[1px] border-[#E4EBF5] w-[150px] p-2.5 rounded-lg outline-none"
              />
            </div>
            <div className="">
              <DatePicker
                placeholderText={"End date"}
                selected={endDate}
                onChange={handleEndDateChange}
                className="border-[1px] border-[#E4EBF5] w-[150px] p-2.5 rounded-lg outline-none"
              />
            </div>
          </div>
          <div className="flex justify-between gap-3 items-center pt-[30px]">
            <Button
              title="Clear"
              onClick={onClearDate}
              className="!py-[10px] !border-[#BEC0BE] !px-12 !w-fit !rounded-lg !bg-transparent !border !text-[#289D17]"
            />
            <Button
              title="Apply"
              className="!py-[10px] !w-fit !rounded-lg !px-12 !bg-[#127E04] !text-[#fff]"
              onClick={onApplyDate}
            />
          </div>
        </div>
      </ModalContainer>
    </div>
  );
}

export default Reports;
