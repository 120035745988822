import React from "react";
import { Link } from "react-router-dom";

interface Props{
    setIsCreateShipment:React.Dispatch<React.SetStateAction<boolean>>;
}
const SelectShipmentType =({setIsCreateShipment}:Props)=>{

    return (
        <div className="mt-7 sm:mt-[72px] rounded-lg max-w-[38rem] mx-4 md:mx-auto border-[1px] border-[#E1F0DF] flex bg-dashboard-gradient justify-center">
        <div className="w-full px-4 sm:px-10 py-6 sm:py-8 onestBold">
          <h1 className="text-2xl font-onestBold font-[800] text-[#0a5001] tracking-wide">
            Create a shipment
          </h1>
          <p className="text-[#a0aba3] font-[500] font-onestRegular tracking-normal mb-12">
            Create a single shipments or bulk shipment
          </p>
          <p className="text-[#071227] font-[700] font-onestMedium">
            What type of shipment do you want to create?
          </p>
          <div className="grid bmd:grid-cols-2 gap-6 mt-6">
            <div
              onClick={() => setIsCreateShipment(true)}
              className="rounded-[12px] cursor-pointer px-5 border-[1px] pb-5 border-[#E4EBF5]"
            >
              <img
                className="mt-6 md:mt-[60px]"
                src="/images/singleShipment.svg"
                alt="Single shipment"
              />
              <h1 className="text-[#071227] pt-5 pb-1.5 text-sm font-[600] font-onestMedium">
                Single shipment
              </h1>
              <p className="text-[#a0aba3] text-xs font-[500] font-onestRegular tracking-normal">
                Create one shipment or many one at a time
              </p>
            </div>
            <Link
              to="/dashboard/ship-now/bulk-upload"
              className="rounded-[12px] px-5 border-[1px] pb-5 border-[#E4EBF5]"
            >
              <img
                className="mt-6 md:mt-[60px]"
                src="/images/bulkShipment.svg"
                alt="Bulk shipment"
              />
              <h1 className="text-[#071227] pt-5 pb-1.5 text-sm font-[600] font-onestMedium">
                Bulk shipment
              </h1>
              <p className="text-[#a0aba3] text-xs font-[500] font-onestRegular tracking-normal">
                Add all your shipment at once with our bulk upload feature
              </p>
            </Link>
          </div>
        </div>
      </div>
    )
}

export default SelectShipmentType