import { Button } from "components/Button";
import { useNavigate, useParams } from "react-router-dom";
import moment from "moment";
import {
  useGetInvoiceByDocNumberQuery,
  useGetInvoicePDFQuery,
} from "services/slices/invoice";
import { Loader } from "components/Loader";
import { formatMoney } from "helpers/formatMoney";
import { useEffect, useState } from "react";
import OrderSummary from "components/dashboard/Invoice/OrderSummary";
import { truncate } from "lodash";

const InvoiceDetails = () => {
  const navigate = useNavigate();
  const { id } = useParams();
  const [isDownload, setIsDownload] = useState<boolean>(false);

  const { data: invoiceData, isLoading: isInvoiceLoading } =
    useGetInvoiceByDocNumberQuery(
      {
        id: id || "",
      },
      {
        refetchOnMountOrArgChange: true,
        refetchOnFocus: true,
        refetchOnReconnect: true,
      }
    );
  const { data: invoiceDownloadData, isLoading: isDownloadingInvoice } =
    useGetInvoicePDFQuery(
      {
        id: id || "",
      },
      {
        skip: !isDownload,
        refetchOnMountOrArgChange: true,
        refetchOnFocus: true,
        refetchOnReconnect: true,
      }
    );

  const triggerDownload = () => {
    if (!isDownloadingInvoice) {
      setIsDownload(true);
      setTimeout(() => {
        setIsDownload(false);
      }, 2000);
    }
  };

  useEffect(() => {
    if (invoiceDownloadData?.data?.pdf) {
      const blob = new Blob(
        [
          Uint8Array.from(atob(invoiceDownloadData.data.pdf), (c) =>
            c.charCodeAt(0)
          ),
        ],
        { type: "application/pdf" }
      );
      const link = document.createElement("a");
      link.href = URL.createObjectURL(blob);
      link.download = "invoice.pdf";
      link.click();
      URL.revokeObjectURL(link.href);
    }
  }, [invoiceDownloadData]);

  return (
    <div className="mt-8 mx-8 ">
      <div
        onClick={() => navigate(-1)}
        className="flex gap-1 mt-14 md:mt-0 ml-4 cursor-pointer w-fit"
      >
        <div>
          {" "}
          <img className="w-3 h-3" src="/images/backArrow.svg" alt="" />
        </div>
        <p className="text-[#4a564d] font-[500] text-[13px] -mt-1 font-onestMedium">
          Go back
        </p>
      </div>
      <div className="md:mt-12 mt-7">
        {isInvoiceLoading ? (
          <div>
            <Loader />
          </div>
        ) : (
          <>
            <div className=" mb-5 md:hidden   justify-between">
              <div className="flex items-center space-x-1">
                <p className="text-[#0A5001] xs:hidden block font-onestBold text-lg">
                  Invoice:{" "}
                  {truncate(invoiceData?.data?.invoiceNumber, { length: 14 })}
                </p>
                <p className="text-[#0A5001] xs:block hidden font-onestBold text-lg">
                  Invoice: {invoiceData?.data?.invoiceNumber}
                </p>
                <div>
                  <span
                    className={`text-sm text-[#65513F] ${
                      invoiceData?.data.status === "paid"
                        ? "bg-[#D4F3D5] text-[#289D17]"
                        : invoiceData?.data.status === "partly_paid"
                        ? "bg-[#F8E4D1] text-[#65513F]"
                        : "bg-[#F8D1D1] text-[#E64500]"
                    } px-2 py-1 rounded-md font-[500] font-onestMedium`}
                  >
                    {invoiceData?.data.status === "partly_paid"
                      ? "Partially Paid"
                      : invoiceData?.data.status === "paid"
                      ? "Paid"
                      : "Unpaid"}
                  </span>
                </div>
              </div>
            </div>
            <div className="border-[1.5px] border-[#E1F0DF] p-5 md:p-[32px] rounded-[16px]">
              <div className=" mb-5 md:flex hidden  justify-between">
                <div className="flex items-center space-x-1">
                  <p className="text-[#0A5001] font-onestBold text-lg">
                    Invoice: {invoiceData?.data?.invoiceNumber}
                  </p>
                  <div>
                    <span
                      className={`text-sm text-[#65513F] ${
                        invoiceData?.data.status === "paid"
                          ? "bg-[#D4F3D5] text-[#289D17]"
                          : invoiceData?.data.status === "partly_paid"
                          ? "bg-[#F8E4D1] text-[#65513F]"
                          : "bg-[#F8D1D1] text-[#E64500]"
                      } px-2 py-1 rounded-md font-[500] font-onestMedium`}
                    >
                      {invoiceData?.data.status === "partly_paid"
                        ? "Partially Paid"
                        : invoiceData?.data.status === "paid"
                        ? "Paid"
                        : "Unpaid"}
                    </span>
                  </div>
                </div>
                <div className="flex items-center space-x-4">
                  <Button
                    title={"Download Invoice"}
                    iconLeft={true}
                    loading={isDownloadingInvoice}
                    disabled={isDownloadingInvoice}
                    onClick={triggerDownload}
                    className="!px-6 !bg-transparent !border-[#18AF04] !border  !text-[#45C734] !py-2 !rounded-[8px]"
                  />
                  {invoiceData?.data.status !== "paid" && (
                    <Button
                      title={"Pay Now"}
                      iconLeft={true}
                      onClick={() =>
                        navigate(
                          `/dashboard/invoice/${invoiceData?.data?.invoiceNumber}/pay`
                        )
                      }
                      className="!px-6 !py-2 !rounded-[8px]"
                    />
                  )}
                </div>
              </div>
              <hr className="md:block hidden" />
              <div className="md:mt-5 grid grid-cols-1 gap-4 md:grid-cols-4 items-center md:w-[70%]">
                <div className="font-onestMedium  md:flex-col  flex md:items-start items-center md:justify-start justify-between flex-row text-sm  md:space-y-1.5">
                  <p className="text-[#8E8A92] ">Date Issued</p>
                  <p className="text-[#3A3341]  text-left">
                    {moment(invoiceData?.data?.createdAt).format(
                      "DD MMM, YYYY"
                    )}
                  </p>
                </div>
                <div className="font-onestMedium  md:flex-col  flex items-center md:justify-start justify-between flex-row text-sm  md:space-y-1.5">
                  <p className="text-[#8E8A92] ">No of shipments</p>
                  <p className="text-[#3A3341]  text-left">
                    {formatMoney().format(invoiceData?.data?.orderCount) || 0}
                  </p>
                </div>

                <div className="font-onestMedium  md:flex-col  flex items-center md:justify-start justify-between flex-row text-sm  md:space-y-1.5">
                  <p className="text-[#8E8A92] ">Amount paid</p>
                  <p className="text-[#3A3341]  text-left">
                    ₦ {formatMoney().format(invoiceData?.data?.paidAmount) || 0}
                  </p>
                </div>
                <div className="font-onestMedium  md:flex-col  flex items-center md:justify-start justify-between flex-row text-sm  md:space-y-1.5">
                  <p className="text-[#8E8A92] ">Amount due</p>
                  <p className="text-[#3A3341]  text-left">
                    ₦
                    {formatMoney().format(
                      invoiceData?.data?.outstandingAmount
                    ) || 0}
                  </p>
                </div>
              </div>
            </div>
            <div className="flex md:hidden flex-col w-full mt-4 md:space-y-0 space-y-4 md:space-x-4">
              <Button
                title={"Download Invoice"}
                iconLeft={true}
                loading={isDownloadingInvoice}
                onClick={triggerDownload}
                className="!px-6 !bg-transparent  !border-[#18AF04] !border  !text-[#45C734] !py-2 !rounded-[8px]"
              />
              {invoiceData?.data.status !== "paid" && (
                <Button
                  title={"Pay Now"}
                  iconLeft={true}
                  onClick={() =>
                    navigate(
                      `/dashboard/invoice/${invoiceData?.data?.invoiceNumber}/pay`
                    )
                  }
                  className="!px-6 !py-2 !rounded-[8px]"
                />
              )}
            </div>
          </>
        )}
        <OrderSummary />
      </div>
    </div>
  );
};

export default InvoiceDetails;
