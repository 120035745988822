import { Button } from "components/Button";
import { Loader } from "components/Loader";
import { TableLoader } from "components/TableLoader";
import { formatMoney } from "helpers/formatMoney";
import moment from "moment";
import { useEffect, useState } from "react";
import { IoAddOutline } from "react-icons/io5";
import { RiDeleteBin6Line } from "react-icons/ri";
import { useNavigate, useParams } from "react-router-dom";
import {
  useRequestbyIdQuery,
  useUpdateOrderMutation,
} from "services/slices/inventory";
import { CreateRetrieveItem } from "./CreateRetrieveItem";
import { updateRetrievalItemDetails } from "constant/data";
import { validateItem } from "helpers/validation";
import { OrderUpdateSubmission } from "type/inventory";
import { handleErrorToast } from "helpers/toastMessage";
import ModalContainer from "components/Modal";

interface Item {
  id: any;
  sub_category: string;
  category: string;
  categoryName: string;
  quantity: number;
  weight: string;
  size: string;
  price_per_unit: string;
  description: string;
  image: string;
  subCategoryId: string;
}

const EditRetrieveItem = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const [isRetrievalItem, setIsRetrievalItem] = useState<boolean>(false);
  const [isChangesSaved, setIsChangesSaved] = useState<boolean>(false);
  const [items, setItems] = useState<Item[]>([]);
  const [deleteId, setDeleteId] = useState<number[]>([]);
  const [itemDetails, setItemDetails] = useState(updateRetrievalItemDetails);
  const [warehouseDeliveryMode, setWarehouseDeliveryMode] =
    useState<string>("");

  const [itemName, setItemName] = useState<string>("");
  const [formErrors, setFormErrors] = useState<any>({});
  const { data, isLoading } = useRequestbyIdQuery(Number(id), {
    skip: !id,
    refetchOnMountOrArgChange: true,
    refetchOnReconnect: true,
    refetchOnFocus: false,
  });
  const [updateOrder, { isLoading: isUpdating }] = useUpdateOrderMutation();

  useEffect(() => {
    if (data?.data?.order) {
      setItems(
        data.data.order.items.map((item: any) => ({
          id: item.id || `${Date.now() + "rt"}`,
          name: item.product.name,
          sub_category: item.product.name,
          categoryName: item.product?.category?.name || "N/A",
          subCategoryId: item.product.id,
          category: item.product?.category?.id,
          quantity: item.quantity,
          retrieval_date: item.date,
          weight: item.product.weight,
          size: item.product?.size,
          price_per_unit: item.product.price_per_unit,
          description: item.product.description,
          expiry_date: item.expiry_date,
          image: item.product.image,
        }))
      );
      setItemDetails({
        category: "",
        categoryName: "",
        retrievalDate: data.data.order.date.split(" ")[0],
        item: "",
        quantity: "",
        deliveryAddress: data.data.order.delivery_address || "",
        subCategoryId: "",
        weight: "",
        size: "",
        price_per_unit: "",
        description: "",
        expiry_date: "",
        image: "",
        sub_category: "",
      });
      setWarehouseDeliveryMode(data.data.order.delivery_mode);
    }
  }, [data]);

  const onSubmit = async () => {
    setFormErrors(validateItem(itemDetails));
    if (
      warehouseDeliveryMode !== "drop-off" &&
      itemDetails.deliveryAddress !== ""
    ) {
      setFormErrors((prev: any) => ({
        ...prev,
        deliveryAddress: "",
      }));
    } else if (
      warehouseDeliveryMode !== "drop-off" &&
      itemDetails.deliveryAddress === ""
    ) {
      setFormErrors((prev: any) => ({
        ...prev,
        deliveryAddress: "",
      }));
    } else if (
      warehouseDeliveryMode === "drop-off" &&
      itemDetails.deliveryAddress === ""
    ) {
      setFormErrors((prev: any) => ({
        ...prev,
        deliveryAddress: "Delivery address is required",
      }));
    }
    if (warehouseDeliveryMode === "") {
      setFormErrors((prev: any) => ({
        ...prev,
        deliveryMode: "Delivery mode is required",
      }));
    }
    if (
      itemDetails.category !== "" &&
      !formErrors.deliveryAddress &&
      itemDetails.item !== "" &&
      itemDetails.retrievalDate !== "" &&
      warehouseDeliveryMode !== "" &&
      itemDetails.quantity !== ""
    ) {
      setItems((prevItems) => {
        const updatedItems = prevItems.some(
          (item) => item.sub_category === itemDetails.sub_category
        )
          ? prevItems.map((item) =>
              item.sub_category === itemDetails.sub_category
                ? {
                    ...item,
                    quantity: Number(itemDetails.quantity),
                    retrieval_date: itemDetails.retrievalDate,
                    sub_category: itemDetails.sub_category,
                    categoryName: itemDetails.categoryName,
                    weight: itemDetails.weight,
                    size: itemDetails.size,
                    price_per_unit: itemDetails.price_per_unit,
                    description: itemDetails.description,
                    expiry_date: itemDetails.expiry_date,
                    image: itemDetails.image,
                    subCategoryId: itemDetails.subCategoryId,
                    category: itemDetails.category,
                  }
                : item
            )
          : [
              ...prevItems,
              {
                id: `${Date.now() + "rt"}`,
                name: itemDetails.item,
                sub_category: itemDetails.sub_category,
                quantity: Number(itemDetails.quantity),
                retrieval_date: itemDetails.retrievalDate,
                categoryName: itemDetails.categoryName,
                weight: itemDetails.weight,
                size: itemDetails.size,
                price_per_unit: itemDetails.price_per_unit,
                description: itemDetails.description,
                expiry_date: itemDetails.expiry_date,
                image: itemDetails.image,
                subCategoryId: itemDetails.subCategoryId,
                category: itemDetails.category,
              },
            ];

        return updatedItems;
      });
      setItemDetails({
        retrievalDate: itemDetails.retrievalDate,
        deliveryAddress: itemDetails.deliveryAddress,
        category: "",
        categoryName: "",
        item: "",
        quantity: "",
        subCategoryId: "",
        weight: "",
        size: "",
        price_per_unit: "",
        description: "",
        expiry_date: "",
        image: "",
        sub_category: "",
      });
      setIsRetrievalItem(false);
    }
  };

  const handleDelete = (id: any) => {
    if (!id.toString().includes("rt")) {
      setDeleteId((prev) => [...prev, Number(id)]);
    }
    setItems((prevItems) =>
      prevItems.filter((item) => String(item.id) !== String(id))
    );
  };

  const onUpdate = () => {
    const payload: OrderUpdateSubmission = {
      body: {
        deleted: deleteId,
        items: items
          .filter((item) => item.id.toString().includes("rt"))
          .map((item) => ({
            sub_category: Number(item.subCategoryId),
            quantity: Number(item.quantity),
            category: Number(item.category),
            weight: Number(item.weight),
            size: Number(item.size),
            price_per_unit: Number(item.price_per_unit),
            description: item.description,
            image: item.image,
          })),
        edited: [],
      },
      id: id || "",
    };
    updateOrder(payload)
      .unwrap()
      .then((data) => {
        setIsChangesSaved(true);
      })
      .catch((e) => handleErrorToast(e));
  };

  return (
    <div className="mt-14 md:mt-0 overflow-x-hidden">
      {isRetrievalItem ? (
        <CreateRetrieveItem
          itemDetails={itemDetails}
          setItemDetails={setItemDetails}
          warehouseDeliveryMode={warehouseDeliveryMode}
          setWarehouseDeliveryMode={setWarehouseDeliveryMode}
          onSubmit={onSubmit}
          itemName={itemName}
          setItemName={setItemName}
          setFormErrors={setFormErrors}
          formErrors={formErrors}
          isEdit={true}
         
        />
      ) : (
        <div className="mt-7 min-w-[657px] pt-10 sm:mt-[72px] rounded-lg max-w-[38rem] mx-4 md:mx-auto border-[1px] border-[#E1F0DF] flex flex-col bg-dashboard-gradient justify-center">
          <div className="w-full px-4 sm:px-10 font-onestMedium mb-10">
            <div className="mb-5">
              <div className="flex items-center justify-between">
                <div className="flex space-x-4 items-center">
                  <p className="text-textBlackGreytext-xl md:text-lg font-onestBold">
                    Edit Retrieval Request
                  </p>
                </div>
              </div>
              <div className="flex items-center space-x-1 mt-3">
                <p className="text-textLightGrey text-sm">Request ID:</p>
                {isLoading ? (
                  <Loader />
                ) : (
                  <p className="text-[#464748] text-sm">
                    {data?.data.order.order_number}
                  </p>
                )}
              </div>
              <div className="flex items-center space-x-1 mt-3">
                <p className="text-textLightGrey text-sm">Date requested:</p>
                {isLoading ? (
                  <Loader />
                ) : (
                  <p className="text-[#464748] text-sm">
                    {moment(data?.data.order.created_at).format(
                      "DD MMM YYYY hh:mma"
                    )}
                  </p>
                )}
              </div>
              <p className="mt-8 font-onestRegular text-[#4A564D]">
                Edit the details and save changes to update storage request
              </p>
            </div>
            {isLoading ? (
              <div className="">
                <TableLoader rowLength={8} />
              </div>
            ) : (
              <div className="overflow-auto max-h-[600px] h-fit  relative">
                <div className="bg-[#F9F9FB] overflow-auto max-h-[600px] h-fit rounded-2xl w-full border border-borderGrey  pb-4 mt-10">
                  <table className="whitespace-nowrap text-left border-spacing-2 rounded-2xl w-full shadow-[0px 4px 8px -2px rgba(16, 24, 40, 0.1), 0px 2px 4px -2px rgba(16, 24, 40, 0.06)]">
                    <thead className="bg-white sticky top-0 z-20 border-b-[1px] border-[#E4EBF5]">
                      <tr className="">
                        <th className="py-4 text-xs pl-4  text-[#929DAE] font-onestRegular">
                          Item name
                        </th>

                        <th className="py-4 text-xs pl-4 pr-4 text-[#929DAE] font-onestRegular">
                          Sub-cateogry
                        </th>
                        <th className="py-4 text-xs pl-4 pr-4 text-[#929DAE] font-onestRegular">
                          Quantity
                        </th>
                        <th className="py-4 text-xs pl-4 pr-4 text-[#929DAE] font-onestRegular">
                          Action
                        </th>
                      </tr>
                    </thead>

                    <tbody className=" bg-white">
                      {items?.map((item) => (
                        <tr
                          key={item.id}
                          className="text-sm  text-[#161718]  w-full font-[400] border-b-[1px] border-[#E4EBF5]"
                        >
                          <td
                            className={` whitespace-normal py-4 pl-4 flex gap-x-1.5 pr-4 `}
                          >
                            {item.categoryName}
                          </td>
                          <td className={`  pl-4 text-sx  px-4`}>
                            {item.sub_category || "N/A"}
                          </td>
                          <td className={` pl-4 text-xs `}>
                            {formatMoney().format(item.quantity)} Qty
                          </td>
                          <td className="pl-5  bg-white  text-xs ">
                            <Button
                              className=" !w-fit !px-0 !bg-transparent !text-[#BA3838]"
                              title=""
                              onClick={() => handleDelete(item.id)}
                              iconLeft
                              icon={
                                <RiDeleteBin6Line className="text-[#BA3838]" />
                              }
                            />
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
              </div>
            )}
            {!isLoading && (
              <div className="mt-5 flex justify-between  space-x-3">
                <div>
                  <Button
                    className=" !w-fit !py-2.5 !bg-transparent !border !text-textGreen"
                    title="Cancel"
                    disabled={isUpdating}
                    onClick={() => navigate(`/dashboard/inventory/requests`)}
                  />
                </div>
                <div className="flex items-center space-x-5">
                  <Button
                    className=" !w-fit !py-2.5 !bg-transparent !border !text-textGreen"
                    title="Add more Item"
                    onClick={() => setIsRetrievalItem(true)}
                    iconLeft
                    disabled={isUpdating}
                    icon={<IoAddOutline className="text-textGreen" />}
                  />
                  <Button
                    className=" !w-fit !py-2.5"
                    title="Save Changes"
                    onClick={onUpdate}
                    loading={isUpdating}
                  />
                </div>
              </div>
            )}
          </div>
        </div>
      )}
      <ModalContainer
        open={isChangesSaved}
        showCloseIcon={false}
        tailwindClassName="w-[350px] sm:w-[466px]"
        closeModal={() => setIsChangesSaved(false)}
      >
        <div className="w-full px-8 pb-8 bg-white flex flex-col  rounded-[12px] relative">
          <p className="text-lg pb-3 mt-6 border-b border-[#E4EBF5] text-center font-onestBold text-[#0A5001] ">
            Changes Saved!
          </p>
          <img
            className="w-6 h-6 absolute right-6 top-6 cursor-pointer"
            onClick={() => setIsChangesSaved(false)}
            src="/images/close.svg"
            alt=""
          />
          <div className="flex justify-center mt-8">
            {" "}
            <img
              src="/images/storeCreated.svg"
              alt=""
              className="w-[123px] h-[98px]"
            />
          </div>
          <p className="text-center text-[#4A564D] text-sm font-onestRegular mt-8">
            Your retrieve request has been updated successfully
          </p>
          <div className="flex justify-center mt-14">
            <Button
              title="Okay"
              onClick={() => navigate("/dashboard/inventory/requests")}
              className="!w-fit !px-10 py-3 bg-[#18AF04] rounded-lg !font-[500] !text-base"
            />
          </div>
        </div>
      </ModalContainer>
    </div>
  );
};

export default EditRetrieveItem;
