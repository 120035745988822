import Toggle from "react-toggle";
import "react-toggle/style.css";

export default function ToggleButton({
  data,
  handleFn,
}: {
  data: any;
  handleFn: (e: React.ChangeEvent<HTMLInputElement>) => void;
}): JSX.Element {
  return (
    <div className="mt-6">
      <div className="flex items-center gap-3">
        <label className="text-[#4A564D] text-sm leading-[20px] font-onestMedium">
          Fragile
        </label>
        <Toggle name="fragile" checked={data} onChange={handleFn} />
      </div>
    </div>
  );
}
