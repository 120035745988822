import { Button } from "components/Button";
import { TextInput } from "components/common/form/Input";
import Dropdown from "components/common/form/SelectDropdown";
import { retievalMode } from "constant/data";
import React, { useEffect, useState } from "react";
import { useGetProductsQuery } from "services/slices/inventory";

interface Props {
  itemDetails: any;
  formErrors: any;
  setFormErrors: React.Dispatch<React.SetStateAction<any>>;
  setItemDetails: React.Dispatch<React.SetStateAction<any>>;
  warehouseDeliveryMode: string;
  setWarehouseDeliveryMode: React.Dispatch<React.SetStateAction<string>>;
  setItemName: React.Dispatch<React.SetStateAction<string>>;
  itemName: string;
  onSubmit: () => void;
  isEdit: boolean;
}
interface Item {
  id: number;
  name: string;
  product_category_id: number;
  weight: string;
  size: string;
  price_per_unit: string;
  description: string;
  expiry_date: string;
  image: string;
  subCategoryId: string;
  stock_quantity: number;
}
export const CreateRetrieveItem = ({
  itemDetails,
  setItemDetails,
  warehouseDeliveryMode,
  setWarehouseDeliveryMode,
  onSubmit,
  setItemName,
  itemName,
  formErrors,
  setFormErrors,
  isEdit,
}: Props) => {
  const [searchCategory, setSearchCategory] = useState<string>("");
  const [searchItem, setSearchItem] = useState<string>("");
  const [items, setItems] = useState<Item[]>([]);
  const { data, isLoading } = useGetProductsQuery("retrieval");
  const [stockQuantity, setStockQuantity] = useState<number>(0);

  const isValid =
    warehouseDeliveryMode !== "" &&
    itemDetails.category !== "" &&
    itemDetails.item !== "" &&
    itemDetails.retrievalDate !== "" &&
    itemDetails.quantity &&
    Number(itemDetails.quantity) <= stockQuantity &&
    ((warehouseDeliveryMode === "pickup" &&
      itemDetails.deliveryAddress === "") ||
      (warehouseDeliveryMode !== "pickup" &&
        itemDetails.deliveryAddress !== ""));

  useEffect(() => {
    const selectedCategory = data?.data.categories?.find(
      (category) => category.name === itemDetails.categoryName
    );

    setItems(selectedCategory?.products || []);

    if (itemDetails.sub_category) {
      const selectedSubCategory = selectedCategory?.products?.find(
        (product) => product.name === itemDetails.sub_category
      );

      setStockQuantity(selectedSubCategory?.stock_quantity || 0);
    }
  }, [
    data?.data.categories,
    itemDetails.categoryName,
    itemDetails.sub_category,
  ]);

  const filteredCategories = data?.data.categories
    ?.filter(
      (category) =>
        category.name.toLowerCase().includes(searchCategory.toLowerCase()) ||
        category.products.some((product) =>
          product.name.toLowerCase().includes(searchCategory.toLowerCase())
        )
    )
    ?.sort((a, b) => a.name.localeCompare(b.name));

  const filteredProducts = items
    ?.filter((product) =>
      product.name.toLowerCase().includes(searchItem.toLowerCase())
    )
    ?.sort((a, b) => a.name.localeCompare(b.name));

  return (
    <div className="mt-7 pt-10 sm:mt-[72px] rounded-lg max-w-[38rem] mx-4 md:mx-auto border-[1px] border-[#E1F0DF] flex flex-col bg-dashboard-gradient justify-center">
      <div className="w-full px-4 sm:px-10 font-onestMedium">
        <h1 className="text-2xl font-onestBold font-[800] text-[#0a5001] tracking-wide">
          Retrieve Item
        </h1>
        <p className="text-[#a0aba3] font-[500] font-onestRegular tracking-normal mb-12">
          Fill in the details retrieve items from the warehouse
        </p>

        <form
          className={``}
          onSubmit={(e) => {
            e.preventDefault();
            onSubmit();
          }}
        >
          <div>
            <div className="flex ">
              <p className="text-[#4A564D]  text-sm leading-[20px] font-onestMedium">
                Retrieval mode
              </p>
              <span className="text-red-500 text-sm">*</span>
            </div>
            <div className="flex sm:flex-row flex-col mt-2 items-center sm:space-y-0 space-y-3 sm:space-x-2 w-full">
              {retievalMode.map((mode) => (
                <div
                  key={mode.id}
                  className={`flex border ${
                    warehouseDeliveryMode === mode.value
                      ? "border-[#289D17]"
                      : "border-borderGrey"
                  } w-full py-2 px-3 rounded-[8px] items-center space-x-3`}
                >
                  <div
                    onClick={() => {
                      if (!isEdit) setWarehouseDeliveryMode(mode.value);
                      setFormErrors((prev: any) => ({
                        ...prev,
                        deliveryMode: "",
                      }));
                    }}
                    className={`w-[18px] flex justify-center items-center ${
                      isEdit ? "" : "cursor-pointer "
                    } h-[18px] border ${
                      warehouseDeliveryMode === mode.value
                        ? "border-[#289D17]"
                        : "border-borderGrey"
                    }  rounded-full`}
                  >
                    {warehouseDeliveryMode === mode.value && (
                      <div className="w-[10px] h-[10px] bg-[#289D17] rounded-full"></div>
                    )}
                  </div>
                  <p className="text-[#9899A1] text-sm">{mode.name}</p>
                </div>
              ))}
            </div>
            {formErrors.deliveryMode && (
              <span className="text-xs text-[#BA3838] font-onestMexdium">
                {formErrors.deliveryMode}
              </span>
            )}
          </div>
          {warehouseDeliveryMode === "drop-off" && (
            <div className="mt-6">
              <TextInput
                type="text"
                label="Enter delivery address"
                disabled={isEdit}
                placeholder="Enter delivery address"
                name="deliveryAddress"
                isRequired
                onChange={(e) => {
                  setItemDetails((prev: any) => ({
                    ...prev,
                    deliveryAddress: e.target.value,
                  }));
                  setFormErrors((prev: any) => ({
                    ...prev,
                    deliveryAddress: "",
                  }));
                }}
                value={itemDetails.deliveryAddress}
              />

              {formErrors.deliveryAddress && (
                <span className="text-xs text-[#BA3838] font-onestMedium">
                  {formErrors.deliveryAddress}
                </span>
              )}
            </div>
          )}
          <div className="mt-6">
            <TextInput
              type="date"
              label="Retrieval date"
              placeholder="Enter date"
              disabled={isEdit}
              min={new Date().toISOString().split("T")[0]}
              name="retrievalDate"
              isRequired
              onChange={(e) => {
                setItemDetails((prev: any) => ({
                  ...prev,
                  retrievalDate: e.target.value,
                }));
                setFormErrors((prev: any) => ({
                  ...prev,
                  retrievalDate: "",
                }));
              }}
              value={itemDetails.retrievalDate}
            />

            {formErrors.retrievalDate && (
              <span className="text-xs text-[#BA3838] font-onestMedium">
                {formErrors.retrievalDate}
              </span>
            )}
          </div>
          <hr className="my-5 border border-borderGrey" />

          <div className="mt-6">
            <div className="flex">
              <p className="text-[#4A564D] mb-2 text-sm leading-[20px] font-onestMedium">
                Category
              </p>
              <span className="text-red-500 text-sm">*</span>
            </div>
            <Dropdown
              loader={isLoading}
              selectPlaceholderName="item to retrieve"
              dropdownStateName={itemDetails.categoryName}
              dropdownValue={itemDetails?.category}
              searchSubject={searchCategory}
              search
              setSearchSubject={setSearchCategory}
              dropdownContainerClasses={`shadow-cardShadow-4 !w-full !right-0  border border-slate-100  rounded `}
              dropdownOptions={[
                {
                  customChild: (
                    <div>
                      {filteredCategories && filteredCategories.length > 0 ? (
                        <div>
                          {filteredCategories?.map((item) => {
                            return (
                              <div
                                key={item.id}
                                onClick={() => {
                                  setItemDetails((prev: any) => ({
                                    ...prev,
                                    category: item.id,
                                    categoryName: item.name,
                                    item: "",
                                    subCategoryId: "",
                                    sub_category: "",
                                    weight: "",
                                    size: "",
                                    price_per_unit: "",
                                    description: "",
                                    expiry_date: "",
                                    image: "",
                                  }));
                                  setItems(item.products);
                                  setSearchCategory("");
                                  setFormErrors((prev: any) => ({
                                    ...prev,
                                    category: "",
                                  }));
                                }}
                              >
                                <div className="px-3 py-2  text-sm font-onestMedium text-[#4a564d] hover:bg-[#edecec] cursor-pointer">
                                  <p>{item?.name}</p>
                                </div>
                              </div>
                            );
                          })}
                        </div>
                      ) : (
                        <p className="text-sm">No data</p>
                      )}
                    </div>
                  ),
                },
              ]}
            />
            {formErrors.category && (
              <span className="text-xs text-[#BA3838] font-onestMedium">
                {formErrors.category}
              </span>
            )}
          </div>
          <div className="mt-6">
            <div className="flex">
              <p className="text-[#4A564D] mb-2 text-sm leading-[20px] font-onestMedium">
                Item Name
              </p>
              <span className="text-red-500 text-sm">*</span>
            </div>
            <Dropdown
              loader={isLoading}
              selectPlaceholderName="Item name"
              dropdownStateName={itemName}
              dropdownValue={itemDetails?.item}
              searchSubject={searchItem}
              setSearchSubject={setSearchItem}
              search
              dropdownContainerClasses={`shadow-cardShadow-4 !w-full !right-0  border border-slate-100  rounded `}
              dropdownOptions={[
                {
                  customChild: (
                    <div>
                      {filteredProducts && filteredProducts.length > 0 ? (
                        <div>
                          {filteredProducts?.map((item) => {
                            return (
                              <div
                                key={item.id}
                                onClick={() => {
                                  setItemDetails((prev: Item) => ({
                                    ...prev,
                                    item: item.name,
                                    subCategoryId: item.id,
                                    sub_category: item.name,
                                    weight: item.weight,
                                    size: item.size,
                                    price_per_unit: item.price_per_unit,
                                    description: item.description,
                                    expiry_date: item.expiry_date,
                                    image: item.image,
                                  }));
                                  setStockQuantity(item.stock_quantity);
                                  setSearchItem("");
                                  setItemName(item?.name);
                                  setFormErrors((prev: any) => ({
                                    ...prev,
                                    item: "",
                                  }));
                                }}
                              >
                                <div className="px-3 py-2  text-sm font-onestMedium text-[#4a564d] hover:bg-[#edecec] cursor-pointer">
                                  <p>{item?.name}</p>
                                </div>
                              </div>
                            );
                          })}
                        </div>
                      ) : (
                        <>
                          {items.length === 0 ? (
                            <p className="text-xs text-red-500 ml-2">
                              Select categotory
                            </p>
                          ) : (
                            <p className="text-sm">No data</p>
                          )}
                        </>
                      )}
                    </div>
                  ),
                },
              ]}
            />
            {formErrors.item && (
              <span className="text-xs text-[#BA3838] font-onestMedium">
                {formErrors.item}
              </span>
            )}
          </div>
          <div className="mt-6  w-full">
            <TextInput
              type="number"
              label="Item quantity"
              isRequired
              placeholder="Enter quantity to retrieve"
              value={itemDetails.quantity}
              name="quantity"
              onChange={(e) => {
                const value = Math.max(0, Number(e.target.value));
                setItemDetails((prev: any) => ({
                  ...prev,
                  quantity: value,
                }));
                if (Number(e.target.value) > stockQuantity) {
                  setFormErrors((prev: any) => ({
                    ...prev,
                    quantity:
                      "Quantity inputed is more than the stock quantity",
                  }));
                } else {
                  setFormErrors((prev: any) => ({
                    ...prev,
                    quantity: "",
                  }));
                }
              }}
            />

            {formErrors.quantity && (
              <span className="text-xs text-[#BA3838] font-onestMedium">
                {formErrors.quantity}
              </span>
            )}
          </div>

          <div className="mt-6 mb-8">
            <Button
              disabled={isValid ? false : true}
              title="Request Retrieval"
              className={`!py-[10px] !rounded-lg !px-12 ${
                !isValid ? "!bg-[#8C9D8A]" : "!bg-[#18AF04]"
              }  !text-[#fff]`}
              type="submit"
            />
          </div>
        </form>
      </div>
    </div>
  );
};
