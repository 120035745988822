import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { getFromStorage } from "helpers";


export const businessType = createApi({
  reducerPath: "businessType",
  baseQuery: fetchBaseQuery({
    baseUrl: `${process.env.REACT_APP_URL}`,
    prepareHeaders: (headers) => {
      headers.set("Content-Type", "application/json");
      const token = getFromStorage("token");
      const secretKey = getFromStorage("secret-key");
      if (token) {
        headers.set("Authorization", `Bearer ${token}`);
        headers.set("secret-key", `${secretKey}`);
      }
      return headers;
    },
  }),
  endpoints: (builder) => ({
    businessType: builder.query<any, void>({
        query: () => ({
          url: `businessTypes`,
          method: "GET",
        }),
      }),
  }),
});

export const {
  useBusinessTypeQuery,
} = businessType;
