import { Button } from "components/Button";
import { Loader } from "components/Loader";
import PagePagination from "components/Pagination";
import { TableLoader } from "components/TableLoader";
import { debouncedFunction } from "helpers/debouncedSearch";
import { handleErrorToast } from "helpers/toastMessage";
import moment from "moment";
import EmptyShipmentTable from "pages/dashboard/shipment/EmptyShipmentTable";
import ShipmentTable from "pages/dashboard/shipment/ShipmentTable";
import React, { useCallback, useEffect, useState } from "react";
import { IoEyeOutline } from "react-icons/io5";
import { Link } from "react-router-dom";
import { useParams } from "react-router-dom";
import { useGetInvoiceOrdersMutation } from "services/slices/invoice";
import {
  useDownloadCreateMutation,
  useDownloadStatusMutation,
} from "services/slices/shipNow";

const OrderSummary = () => {
  const { id } = useParams();
  const [page, setPage] = useState<number>(0);
  const [debounceSearch, setDebounceSearch] = useState<string>("");
  const [search, setSearch] = useState<string>("");
  const [requestId, setRequestId] = useState<string>("");
  const [getInvoiceOrders, { data, isLoading }] = useGetInvoiceOrdersMutation();
  const [
    downloadCreate,
    { isLoading: isDownloadCreating, data: downloadCreateData },
  ] = useDownloadCreateMutation();

  const [downloadStatus, { isLoading: isDownloadStatus }] =
    useDownloadStatusMutation();
  const debouncedSetSearch = useCallback((value: string) => {
    debouncedFunction(value, setSearch);
  }, []);

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    debouncedSetSearch(e.target.value);
    setDebounceSearch(e.target.value);
    setPage(0);
  };

  useEffect(() => {
    const getInvoiceOrder = async () => {
      await getInvoiceOrders({
        startDate: moment().subtract(7, "days").format("YYYY-MM-DD"),
        endDate: moment().add(1, "days").format("YYYY-MM-DD"),
        disable: "sDate",
        invoiceNumber: id || "",
        page: page + 1,
        orderNo: search,
      });
    };

    getInvoiceOrder();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id, page, search]);

  const fetchDownloadStatus = async () => {
    const maxTime = 15 * 60 * 1000;
    const startTime = Date.now();
    const intervalTime = 5000;

    const checkDownloadStatus = async () => {
      try {
        const data = await downloadStatus({ requestid: requestId }).unwrap();

        if (data?.["Details"]?.url) {
          window.location.href = data["Details"].url;
          return;
        }

        if (Date.now() - startTime >= maxTime) {
          handleErrorToast("Download error. please try again!!");
          return;
        }

        setTimeout(checkDownloadStatus, intervalTime);
      } catch (error) {
        if (Date.now() - startTime < maxTime) {
          setTimeout(checkDownloadStatus, intervalTime);
        } else {
          handleErrorToast("Download error. please try again!!");
        }
      }
    };

    checkDownloadStatus();
  };

  const generateUniqueRequestId = () => {
    return crypto.randomUUID();
  };
  const onDownloadAll = async () => {
    const uniqueRequestId = generateUniqueRequestId();
    setRequestId(uniqueRequestId);

    const filters: any = {
      startDate: moment().subtract(7, "days").format("YYYY-MM-DD"),
      endDate: moment().add(1, "days").format("YYYY-MM-DD"),
      requestid: uniqueRequestId,
      invoiceNumber: id || "",
    };

    await downloadCreate(filters)
      .unwrap()
      .then((data) => {})
      .catch((error: { data: { description: string } }) => {
        handleErrorToast(error);
      });
  };

  useEffect(() => {
    if (requestId && downloadCreateData) {
      fetchDownloadStatus();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [requestId, downloadCreateData]);

  return (
    <div className="mt-8">
      <div>
        <div className="flex md:flex-row flex-col md:space-y-0 space-y-2 justify-between">
          <p className="text-lg text-[#071227] font-onestMedium">
            Order summary
          </p>
          {data?.orders?.data?.length > 0 && (
            <Button
              title={"Download Summary"}
              iconLeft={true}
              loading={isDownloadCreating || isDownloadStatus}
              disabled={isDownloadCreating || isDownloadStatus}
              onClick={onDownloadAll}
              className="!px-6 !bg-transparent !border-[#18AF04] !border  !text-[#45C734] !py-2 !rounded-[8px]"
            />
          )}
        </div>
        <div className="md:block hidden bg-[#F9F9FB] pb-[0px] h-fit p-4 mt-6">
          <div className="flex gap-6 mb-3 items-center justify-between">
            <div className="flex gap-2 items-center">
              <div className="w-[60%] md:w-[289px] bg-[#fff] flex gap-2 items-center rounded-[9px] pl-4 pr-4 border-[1px] border-[#E4EBF5]">
                <img
                  className="w-[14px] h-[14px]"
                  src="/images/search-icon.svg"
                  alt="search-icon"
                />
                <input
                  className="text-[13px] py-[10px] w-[279px] outline-none"
                  type="text"
                  placeholder="Keyword Search by Order ID"
                  onChange={(e) => {
                    handleInputChange(e);
                  }}
                  value={debounceSearch}
                />
              </div>
            </div>
            <div className="text-xs text-[#4A564D] font-[500] flex gap-1.5 mr-[50px]">
              <span className="text-[#929DAE]"> Scroll right </span>
              <div className="mt-.05">
                {" "}
                <img src="/images/forward-arrow.svg" alt="forward-icon" />
              </div>
            </div>
          </div>

          {isLoading ? (
            <TableLoader rowLength={17} />
          ) : (
            <div className="overflow-x-auto ">
              {data?.orders?.data?.length > 0 ? (
                <>
                  <ShipmentTable
                    data={data?.orders}
                    showInvoice
                    hideOrderStatus
                    
                  />
                </>
              ) : (
                <>
                  <EmptyShipmentTable
                    title="You do not have any order summary yet"
                    content="When there's an order summary, it will show here"
                  />
                </>
              )}
            </div>
          )}
        </div>
        <div className="mt-7 sm:mt-[72px] rounded-lg max-w-[38rem] block px-5 py-6 mb-8 md:hidden  sm:mx-auto border-[1px] border-[#E1F0DF] bg-dashboard-gradient">
          <div className="flex gap-3 mt-3 mb-3">
            <div className="w-fit bg-[#fff] flex gap-2 items-center rounded-[9px] pl-4 pr-4 border-[1px] border-[#E4EBF5]">
              <img
                className="w-[14px] h-[14px]"
                src="/images/search-icon.svg"
                alt="search-icon"
              />
              <input
                className="text-[13px] py-[10px] max-w-[279px] outline-none"
                type="text"
                placeholder="Search by Order ID"
                onChange={(e) => {
                  handleInputChange(e);
                }}
                value={debounceSearch}
              />
            </div>
          </div>

          <div className="">
            {isLoading ? (
              <div>
                <Loader />
              </div>
            ) : (
              <div>
                {data?.orders?.data?.length > 0 ? (
                  <>
                    {data?.orders?.data.map((item: any) => {
                      return (
                        <div className="bg-[#fff] my-3" key={item.id}>
                          <div className=" border-[1px] flex rounded-lg p-3 justify-between pb-5 border-[#E4EBF5]">
                            <div>
                              <p className="text-sm text-[#071227] mt-2 font-[700]">
                                Order: {item.orderNo}
                              </p>
                              <p className="pt-1 text-[13px] text-[#071227] mb-2">
                                {item.recipientName}
                              </p>
                              <p className="text-xs text-[#9899A1] mb-2">
                                {item.orderDate}
                              </p>

                              <div
                                className={`inline px-2.5 w-fit py-1.5 text-xs mb-3 rounded-md font-onestMedium font-[600] ${
                                  item.orderStatus === "Pending Payment"
                                    ? "bg-[#D4F3D5] text-[#289D17]"
                                    : item.orderStatus === "Pending Pick-Up"
                                    ? "bg-[#F8E4D1] text-[#65513F]"
                                    : "bg-[#FAEFE5] text-[#F59A49]"
                                }`}
                              >
                                {item.orderStatus}
                              </div>
                            </div>
                            <Link
                              to={`/dashboard/shipments/${item.orderNo}`}
                              className="text-sm text-[#45C734] flex items-center gap-1 px-2 py-1 rounded-md font-[400] font-onestRegular"
                            >
                              <IoEyeOutline />
                              View
                            </Link>
                          </div>
                        </div>
                      );
                    })}
                    {data?.orders?.data?.length > 0 &&
                      data?.orders?.meta?.total >
                        data?.orders?.meta?.per_page && (
                        <div className="mt-3">
                          <PagePagination
                            totalPages={data?.orders?.last_page}
                            page={page}
                            handlePageClick={(event) => {
                              setPage(event.selected);
                            }}
                          />
                        </div>
                      )}
                  </>
                ) : (
                  <div className="flex justify-center py-[32px] w-full">
                    <div className="">
                      <div className="flex justify-center pb-5 w-full">
                        {" "}
                        <img src="/images/new-request.svg" alt="" />
                      </div>
                      <p className="font-onestRegular mb-2 bmd:w-full text-sm text-[#4A564D]">
                        You do not have any order summary yet
                      </p>
                      <p className="font-onestRegular w-[100%] bmd:w-full text-xs text-center text-[#A0ABA3]">
                        When there's an order summary, it will show here
                      </p>
                    </div>
                  </div>
                )}
              </div>
            )}
          </div>
        </div>
        {data?.orders?.data?.length > 0 &&
          data?.orders?.meta?.total > data?.orders?.meta?.per_page && (
            <div className="mt-4 pb-3">
              <PagePagination
                totalPages={data?.orders?.meta?.last_page}
                page={page}
                handlePageClick={(event) => {
                  setPage(event.selected);
                }}
              />
            </div>
          )}
      </div>
    </div>
  );
};

export default OrderSummary;
