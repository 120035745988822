import { Loader } from "components/Loader";
import React, { useEffect, useRef, useState } from "react";
import { TextInput } from "./Input";
import { truncate } from "lodash";

type DropdownProps = {
  id?: string;
  label?: string;
  handleChange?: (name: string, value: string) => void;
  name?: string;
  value?: any;
  disabled?: boolean;
  placeholder?: string;
  dropdownOptions?: {
    customChild?: JSX.Element;
    value?: string;
    action?: () => void;
    disabled?: boolean;
    label?: string | JSX.Element;
    subText?: string | JSX.Element;
    icon?: JSX.Element;
    rightIcon?: JSX.Element;
    labelClassName?: string;
  }[];
  dropdown?: React.ReactNode;
  dropdownTriggerClassName?: string;
  dropdownContainerClasses?: string;
  dropdownItemsClasses?: string;
  className?: string;
  dropdownClassName?: string;
  wholeContainerClass?: string;
  search?: React.ReactNode;
  clearSearchString?: () => void;
  leaveOpen?: boolean;
  loader?: boolean;
  endOfList?: boolean;
  dropdownValueValid?: boolean;
  dropdownValueValidShow?: boolean;
  searchSubject?: string;
  dropdownValue: string;
  dropdownStateName: string;
  selectPlaceholderName: string;
  setSearchSubject?: React.Dispatch<React.SetStateAction<string>>;
};

const Dropdown = ({
  handleChange,
  name,
  value,
  disabled,
  selectPlaceholderName,
  dropdownOptions,
  dropdown,
  dropdownTriggerClassName,
  dropdownContainerClasses,
  dropdownItemsClasses,
  dropdownValue,
  dropdownStateName,
  dropdownClassName,
  search,
  leaveOpen,
  wholeContainerClass,
  loader,
  endOfList,
  setSearchSubject,
  searchSubject,
  dropdownValueValid,
  dropdownValueValidShow,
}: DropdownProps) => {
  const [open, setOpen] = useState(false);
  const selectedOption = dropdownOptions?.find((item) => item.value === value);
  const ref = useRef<HTMLDivElement>(null);

  const handleSelect = (value: string) => {
    if (!leaveOpen) {
      handleChange?.(name as string, value);
      setOpen(false);
    }
  };
  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (ref.current && !ref.current.contains(event.target as Node) && open) {
        setOpen(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [ref, open]);

  return (
    <div className={`relative  ${wholeContainerClass}`}>
      <div
        onClick={() => (disabled ? null : setOpen(!open))}
        className={`w-full  ${dropdownTriggerClassName} ${
          disabled ? "cursor-default" : "cursor-pointer"
        }`}
      >
        <div
          className={`flex border ${
            dropdownValueValidShow && !dropdownValueValid
              ? "border-[#BA3838]"
              : "border-[#E4EBF5]"
          } rounded-[8px] items-center space-x-2  whitespace-nowrap h-[40px] p-2 justify-between `}
        >
          <div className="flex items-center space-x-1">
            <i className="ri-map-pin-line text-sm text-g-75 "></i>
            <span
              className={` ${
                dropdownValue === "" ? "text-[#9899A1]" : "text-[#000]"
              }  font-onestMedium text-sm `}
            >
              {dropdownValue === "" || dropdownValue === undefined
                ? `Select ${selectPlaceholderName}`
                : truncate(dropdownStateName, {length:40})}
            </span>
          </div>

          <img
            src={"/images/chevronDown.svg"}
            alt="drop-down"
            className="mt-0.5 pr-2 cursor-pointer"
          />
        </div>
      </div>

      {open &&
        (dropdownOptions && dropdownOptions?.length > 0 ? (
          <div
            id="dropdownContent"
            ref={ref}
            className={`${
              dropdown ? "min-w-max" : ""
            } py-2 bg-white absolute top-[101%] text-[#4a564d] w-[100px] h-auto max-h-64 z-[10] overflow-auto border ${dropdownContainerClasses} `}
          >
            <div className="relative">
              {search && setSearchSubject && (
                <div className="sticky top-[px] bg-white">
                  <div className="p-[5px]">
                    <div>
                      <TextInput
                        value={searchSubject}
                        name={"search"}
                        type={"text"}
                        placeholder="Search"
                        onChange={(e) => setSearchSubject(e.target.value)}
                      />
                    </div>
                  </div>
                </div>
              )}
              {dropdownOptions.map((option, idx) => {
                return option.customChild ? (
                  <div key={idx} onClick={() => setOpen(false)}>
                    {option.customChild}
                  </div>
                ) : (
                  <div
                    key={option.value}
                    onClick={() => {
                      if (option?.action && !option?.disabled) {
                        option.action?.();
                      }
                      if (!option?.disabled) {
                        handleSelect(option?.value as string);
                      }
                    }}
                    className={`flex items-center  px-4 justify-between ${
                      selectedOption?.value === option?.value && ""
                    } ${
                      option?.disabled && "opacity-40"
                    } cursor-pointer py-3 ${dropdownItemsClasses}`}
                  >
                    <p
                      className={`text-[13px] flex gap-3  items-center font-onestRegular text-[#4a564d]`}
                    >
                      {option?.icon && <span>{option?.icon}</span>}{" "}
                      <div>
                        <span
                          className={`text-[13px] font-onestRegular text-[#4a564d] ${`${option?.labelClassName}`}`}
                        >
                          {option?.label}
                        </span>
                        {option?.subText && (
                          <span className="text-[13px] font-onestRegular">
                            {option?.subText}
                          </span>
                        )}
                      </div>
                      {option?.rightIcon && <span>{option?.rightIcon}</span>}{" "}
                    </p>
                    {selectedOption?.value === option?.value && (
                      <span className="material-icons  text-base">done</span>
                    )}
                  </div>
                );
              })}
              {(loader || endOfList) && (
                <div className="">
                  {loader && !endOfList && (
                    <div className="flex justify-center my-4">
                      <Loader />
                    </div>
                  )}
                  {endOfList && (
                    <div className="flex my-4 justify-center text-slate-500 text-sm font-onestRegular">
                      End of list
                    </div>
                  )}
                </div>
              )}
            </div>
          </div>
        ) : (
          <div
            ref={ref}
            className={`${
              search ? "mt-3" : "w-full"
            } py-2 bg-white absolute top-[101%] h-auto max-h-64 z-20 overflow-auto border ${dropdownClassName}`}
            id="custom-dropdown"
          >
            {search && <div className="px-[5px] pb-[5px]">{search}</div>}

            <div className="flex px-4 justify-between cursor-pointer">
              <p className="text-sm flex gap-3 py-3 items-center font-onestRegular">
                <span>No Data</span>
              </p>
            </div>
          </div>
        ))}
    </div>
  );
};

export default Dropdown;
