import { useNavigate } from "react-router-dom";

export const useNavigateToInventory = () => {
  const navigate = useNavigate();

  return (approvalStatus: string, type: string, id: number) => {
    const status = approvalStatus.trim().toLowerCase();
    const itemType = type.trim().toLowerCase();
    if (itemType === "retrieval") {
      navigate(`/dashboard/inventory/retrieve-item/${id}`);
    }
    else if (status === "pending inbound" && itemType === "addition") {
      navigate(`/dashboard/inventory/storage-request/${id}`);
    } else if (
      status === "awaiting client approval" &&
      itemType === "addition"
    ) {
      navigate(`/dashboard/inventory/storage-request/${id}/approval`);
    } else if (status === "approved" && itemType === "addition") {
      navigate(`/dashboard/inventory/storage-request/${id}/approval`);
    } else if (status === "rejected" && itemType === "addition") {
      navigate(`/dashboard/inventory/storage-request/${id}/approval`);
    }
  };
};
export const useNavigateToEditInventory = () => {
  const navigate = useNavigate();

  return (approvalStatus: string, type: string, id: number) => {
    const status = approvalStatus.trim().toLowerCase();
    const itemType = type.trim().toLowerCase();

    if (status === "pending inbound" && itemType === "retrieval") {
      navigate(`/dashboard/inventory/edit-retrieve-item/${id}`);
    } else if (status === "pending outbound" && itemType === "retrieval") {
      navigate(`/dashboard/inventory/edit-retrieve-item/${id}`);
    } else if (status === "pending inbound" && itemType === "addition") {
      navigate(`/dashboard/inventory/edit-storage-request/${id}`);
    } else if (status === "awaiting client" && itemType === "addition") {
      navigate(`/dashboard/inventory/storage-request/${id}/approve`);
    } else if (status === "approved" && itemType === "addition") {
      navigate(`/dashboard/inventory/storage-request/${id}/approve`);
    }
  };
};
