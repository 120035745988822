import { useEffect, useState } from "react";
import { MdLogout } from "react-icons/md";
import { useLocation, useNavigate } from "react-router-dom";
import { NavLink } from "react-router-dom";
import { isEqual } from "lodash";
import { GoChevronDown, GoChevronUp } from "react-icons/go";
import {
  productLinks as staticLinks,
  serviceLinks,
  toolsLinks,
} from "variables/sidebarLinks";
import { useGetAuthDataQuery } from "services/slices/getAuthSlice";
import { getFromStorage, removeFromStorage } from "helpers";

const Sidebar = ({ hamburger, onClose }: any) => {
  const location = useLocation();
  const navigate = useNavigate();
  const [activeSideLink, setActiveSideLink] = useState<string | string[]>("");
  const [isHoveredId, setIsHoveredId] = useState<string | number>(-1);
  const [showInfo, setShowInfo] = useState<boolean>(false);
  const [productLinks, setProductLinks] = useState(staticLinks);
  const { data } = useGetAuthDataQuery();

  const userData = data?.userDetails;
  const userOrg = data?.orgDetails["Org Full Name"];

  const abbreviatedName = (fullName: string): string =>
    fullName
      .split(" ")
      .map((part, index, arr) =>
        index === arr.length - 1 ? `${part.charAt(0)}.` : part
      )
      .join(" ");

  const getInitials = (fullName: string): string =>
    fullName
      .split(" ")
      .map((part) => part.charAt(0).toUpperCase())
      .join("");

  const invoicePermission = getFromStorage("invoice");
  
  useEffect(() => {
    const updatedLinks = [
      ...staticLinks,
      ...(invoicePermission==="true"
        ? [
            {
              title: "Invoice",
              icons: "/images/invoice.svg",
              link: "/dashboard/invoice",
            },
          ]
        : []),
    ];
  
    setProductLinks(updatedLinks);
  
  }, [invoicePermission]);
  const LinkItem = ({
    item,
    idx,
  }: {
    item: {
      title: string;
      icons?: string;
      link: string | string[];
      children?: {
        title: string;
        link: string;
      }[];
    };

    idx: string | number;
  }) => {
    const openChildren =
      typeof item?.link === "string"
        ? item?.link.includes(activeSideLink as string) ||
          isEqual(item?.link, activeSideLink)
        : item?.link
            ?.map((path) => (activeSideLink as string)?.startsWith?.(path))
            ?.find((value) => Boolean(value));

    return !item?.children ? (
      <NavLink
        to={typeof item.link === "object" ? item.link?.[0] : item.link}
        onClick={() => {
          setActiveSideLink(item.link);
          onClose();
        }}
        end
        onMouseEnter={() => setIsHoveredId(idx)}
        onMouseLeave={() => setIsHoveredId(-1)}
        className={({ isActive }) =>
          `flex pl-3 py-2.5 mt-1 hover:rounded-lg font-onestBold hover:bg-gradient-to-l from-[#F3FBF2] from-10% to-[#FFFFFF] to-90% hover:border-[2px] border-[#B9F0B2] ${
            isActive &&
            "rounded-lg font-onestBold bg-gradient-to-l from-[#F3FBF2] from-10% to-[#FFFFFF] to-90% border-[2px] border-[#B9F0B2] "
          } cursor-pointer`
        }
      >
        {({ isActive }) => (
          <div className="flex gap-2 items-center">
            {item?.icons && <img alt={item.title} src={item.icons} />}

            <p
              className={`text-sm opacity-1 ${
                isHoveredId === idx ? "text-[#161718]" : "text-slate-500"
              } ${isActive && "!text-[#161718]"} font-onestMedium`}
            >
              {item.title}
            </p>
          </div>
        )}
      </NavLink>
    ) : (
      <div>
        <NavLink
          to={"#"}
          onClick={() => {
            setActiveSideLink(item.link?.[0]);
          }}
          onMouseEnter={() => setIsHoveredId(idx)}
          onMouseLeave={() => setIsHoveredId(-1)}
          className={`flex justify-between px-3 py-2.5 mt-1 hover:rounded-lg font-onestBold hover:bg-gradient-to-l from-[#F3FBF2] from-10% to-[#FFFFFF] to-90% hover:border-[2px] border-[#B9F0B2] ${
            (item?.link.includes(activeSideLink as string) || openChildren) &&
            "rounded-lg font-bold bg-gradient-to-l from-[#F3FBF2] from-10% to-[#FFFFFF] to-90% border-[2px] border-[#B9F0B2] text-[#161718]"
          } cursor-pointer`}
        >
          <div className="flex items-center space-x-3">
            {item?.icons && <img alt={item.title} src={item.icons} />}

            <p
              className={`text-sm opacity-1 ${
                isHoveredId === idx ? "text-[#161718]" : "text-[#4A564D]"
              } ${
                (item?.link.includes(activeSideLink as string) ||
                  openChildren) &&
                "!text-[#161718]"
              } font-onestMedium`}
            >
              {item.title}
            </p>
          </div>
          <img src="/images/arrowDown.svg" alt="right-arrow" />
        </NavLink>
        {(item?.link.includes(activeSideLink as string) ||
          isEqual(item?.link, activeSideLink) ||
          openChildren) && (
          <div className="pl-4 mt-1">
            {item?.children?.map((child, index) => {
              return (
                <NavLink
                  key={child?.title + index}
                  to={child.link}
                  onClick={() => {
                    setActiveSideLink(child.link);
                    onClose();
                  }}
                  className={`flex relative items-center gap-2 px-6  py-2`}
                >
                  {({ isActive }) => (
                    <>
                      <div
                        className={` mt-1 absolute left-0 h-full ${
                          isActive && child.link === location.pathname
                            ? "border-[#289D17] border-l-4 "
                            : "border-white border-l-2 "
                        } rounded-[8px]`}
                      ></div>
                      <p
                        className={`text-sm font-onestMedium ${
                          isActive && child.link === location.pathname
                            ? "text-[#289D17]"
                            : "text-[#929DAE]"
                        }`}
                      >
                        {child.title}
                      </p>
                    </>
                  )}
                </NavLink>
              );
            })}
          </div>
        )}
      </div>
    );
  };

  const onLogOut = () => {
    removeFromStorage("webhook");
    removeFromStorage("integration-key");
    removeFromStorage("token");
    removeFromStorage("secret-key");
    removeFromStorage("fez-org-code");
    removeFromStorage("fez-org-id");
    removeFromStorage("fez-org-email");
    removeFromStorage("expire-token-date");
    removeFromStorage("fez-user");
    removeFromStorage("fez-ref");
    removeFromStorage("fez-org-details");
    removeFromStorage("invoice");
    removeFromStorage("path");
    navigate("/");
  };
  return (
    <>
      <div className="hidden bmd:block w-[310px] bg-[#F4F5F6] container auto h-[100vh] overflow-y-scroll mt-[2px]">
        <h1 className="text-[10px] text-[#929dae] font-[600] mt-7  pl-8">
          PRODUCTS
        </h1>

        <div className="mt-3 pl-5 pr-4 ">
          {productLinks?.map((item, idx) => {
            return <LinkItem key={item.title + idx} item={item} idx={idx} />;
          })}
        </div>

        <h1 className="text-[10px] text-[#929dae] font-[600] mt-7  pl-8">
          TOOLS
        </h1>
        <div className="mt-3 pl-6 pr-4 ">
          {toolsLinks?.map((item, idx) => {
            return <LinkItem key={item.title + idx} item={item} idx={idx} />;
          })}
        </div>

        <h1 className="text-[10px] text-[#929dae] font-[600] mt-7  pl-8">
          SERVICES
        </h1>

        <div className="mt-3 pl-6 pr-4 mb-[115px]">
          {serviceLinks?.map((item, idx) => {
            return <LinkItem key={item.title + idx} item={item} idx={idx} />;
          })}
        </div>
        <div className="bg-[#fff] rounded-lg mb-[180px] mx-4 p-4">
          <section id="faq">
            <h1 className="text-[#929dae] font-[600] mb-3 text-[10px]">
              MY ACCOUNT
            </h1>
            <div>
              <div>
                <header className="flex justify-between">
                  <div
                    onClick={() => navigate("/dashboard/setting")}
                    className="flex cursor-pointer items-center gap-2"
                  >
                    <p className="text-[#D0823C] shrink-0 w-[32px] h-[32px] flex justify-center items-center border-[1px] border-[#FFE1C7] p-2.5 rounded-full bg-[#fff4ea] font-[500] text-[11px]">
                    {userData?.["Full Name"] &&
                        getInitials(userData?.["Full Name"])}
                    </p>
                    <h4 className="w-[90%] md:w-full text-sm text-[#3B3A3A] font-onestRegular font-bold mb-0">
                      {userData?.["Full Name"] &&
                        abbreviatedName(userData?.["Full Name"])}
                    </h4>
                  </div>
                  <button onClick={() => setShowInfo(!showInfo)}>
                    {showInfo ? <GoChevronUp /> : <GoChevronDown />}
                  </button>
                </header>
                <div
                  className={`transition-all duration-500 ${
                    showInfo
                      ? "ease-in max-h-[200px] opacity-100"
                      : "ease-in-out max-h-0 opacity-0"
                  } overflow-hidden`}
                >
                  <div className="text-sm md:text-base leading-[22px] ml-[50px] mr-8">
                    <p className="text-[#929DAE] text-[10px] font-onestMedium">
                      {userData?.userEmail}
                    </p>
                    <p className="text-[10px] text-[#4A564D] font-onestMedium border-b-[1px] border-[#E4EBF5]">
                      {userOrg}
                    </p>
                    <div
                      onClick={onLogOut}
                      className="mt-4 cursor-pointer flex items-center gap-3"
                    >
                      <MdLogout color={"#BA3838"} />
                      <p className="text-[#4A564D] text-sm text-onestMedium">
                        Log out
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </div>
      </div>
      {hamburger && (
        <div className="relative md:hidden h-[100vh]">
          <div className="w-[380px] absolute bg-[#F4F5F6] -top-16 z-50  h-[100vh] overflow-y-scroll">
            <div
              onClick={() => onClose()}
              className="flex justify-end cursor-pointer mt-6 pr-6"
            >
              {" "}
              <img src="/images/close.svg" alt="" />
            </div>
            <h1 className="text-[10px] text-[#929dae] font-[600] mt-8  pl-8">
              PRODUCTS
            </h1>

            <div className="mt-3 pl-6 pr-4 ">
              {productLinks?.map((item, idx) => {
                return (
                  <LinkItem key={item.title + idx} item={item} idx={idx} />
                );
              })}
            </div>

            <h1 className="text-[10px] text-[#929dae] font-[600] mt-7  pl-8">
              TOOLS
            </h1>
            <div className="mt-3 pl-6 pr-4 ">
              {toolsLinks?.map((item, idx) => {
                return (
                  <LinkItem key={item.title + idx} item={item} idx={idx} />
                );
              })}
            </div>

            <h1 className="text-[10px] text-[#929dae] font-[600] mt-7  pl-8">
              SERVICES
            </h1>

            <div className="mt-3 pl-6 pr-4 mb-[115px]">
              {serviceLinks?.map((item, idx) => {
                return (
                  <LinkItem key={item.title + idx} item={item} idx={idx} />
                );
              })}
            </div>

            <div className="bg-[#fff] rounded-lg mb-[100px] mx-4 p-4">
              <section id="faq">
                <h1 className="text-[#929dae] font-[600] mb-3 text-[10px]">
                  MY ACCOUNT
                </h1>
                <div>
                  <div>
                    <header className="flex justify-between">
                      <div
                        onClick={() => navigate("/dashboard/setting")}
                        className="flex items-center gap-2"
                      >
                        <p className="text-[#D0823C] border-[1px] border-[#FFE1C7] p-2.5 rounded-full bg-[#fff4ea] font-[500] text-[11px]">
                          OA
                        </p>
                        <h4 className="w-[90%] md:w-full text-sm text-[#3B3A3A] font-onestRegular font-bold mb-0">
                          {userData?.["Full Name"] &&
                            abbreviatedName(userData?.["Full Name"])}
                        </h4>
                      </div>
                      <button onClick={() => setShowInfo(!showInfo)}>
                        {showInfo ? <GoChevronUp /> : <GoChevronDown />}
                      </button>
                    </header>
                    <div
                      className={`transition-all duration-500 ${
                        showInfo
                          ? "ease-in max-h-[200px] opacity-100"
                          : "ease-in-out max-h-0 opacity-0"
                      } overflow-hidden`}
                    >
                      <div className="text-sm md:text-base leading-[22px] ml-[50px] mr-8">
                        <p className="text-[#929DAE] text-[10px] font-onestMedium">
                          {userData?.userEmail}
                        </p>
                        <p className="text-[10px] text-[#4A564D] font-onestMedium border-b-[1px] border-[#E4EBF5]">
                          {userOrg}
                        </p>
                        <div
                          onClick={onLogOut}
                          className="mt-4 cursor-pointer flex items-center gap-3"
                        >
                          <MdLogout color={"#BA3838"} />
                          <p className="text-[#4A564D] text-sm text-onestMedium">
                            Log out
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </section>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default Sidebar;
