export const getApprovalStatusStyles = (status?: string) => {
  if (!status) return "bg-[#F8F5D1] text-[#65603F]"; 

  const statusLower = status.trim().toLowerCase();

  const styles: Record<string, { bg: string; text: string }> = {
    "pending inbound": { bg: "bg-[#F8E4D1]", text: "text-[#65513F]" },
    "pending outbound": { bg: "bg-[#FFE5B3]", text: "text-[#584116]" },
    approved: { bg: "bg-[#D4F3D5]", text: "text-[#17730A]" },
    returned: { bg: "bg-[#D4F3D5]", text: "text-[#17730A]" },
    delivered: { bg: "bg-[#F5FFEE]", text: "text-[#32462B]" },
    rejected: { bg: "bg-[#F8D1D1]", text: "text-[#E64500]" },
  };

  return `${styles[statusLower]?.bg || "bg-[#F8F5D1]"} ${
    styles[statusLower]?.text || "text-[#65603F]"
  }`;
};
