import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { getFromStorage } from "helpers";


interface AllTicket {
  startDate?: string;
  endDate?: string;
  filterBy?: string;
}

export const report = createApi({
  reducerPath: "report",
  baseQuery: fetchBaseQuery({
    baseUrl: `${process.env.REACT_APP_URL}`,
    prepareHeaders: (headers) => {
      headers.set("Content-Type", "application/json");
      const token = getFromStorage("token");
      const secretKey = getFromStorage("secret-key");
      if (token) {
        headers.set("Authorization", `Bearer ${token}`);
        headers.set("secret-key", `${secretKey}`);
      }
      return headers;
    },
  }),
  endpoints: (builder) => ({
    getReport: builder.mutation<any, AllTicket>({
      query: (body) => ({
        url: `orders/orderStatsByDayMonthYear`,
        method: "POST",
        body,
      }),
    }),
  }),
});

export const { useGetReportMutation } = report;
