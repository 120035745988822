import React, { useCallback, useEffect, useState } from "react";
import moment from "moment";
import InvoiceHistory from "components/dashboard/Invoice/History";
import PaymentHistory from "components/dashboard/Invoice/PaymentHistory";
import { getDateRange } from "helpers/getDateRange";
import {
  useGetAllInvoicesQuery,
  useGetAllInvoiceTransactionQuery,
  useGetInvoicesStatsQuery,
  useGetInvoiceTransactionStatsQuery,
} from "services/slices/invoice";
import { debouncedFunction } from "helpers/debouncedSearch";
import MobileInvoice from "components/dashboard/Invoice/MobileInvoice";
import { filterTerms } from "constant/data";
import { DropDownSelect } from "components/common/form/Dropdown";
import ModalContainer from "components/Modal";
import { Button } from "components/Button";
interface Ilist {
  id: number | null;
  name: string;
  value: string;
}

const Invoice = () => {
  const [selectedInvoiceType, setSelectedInvoiceType] = useState<number>(1);
  const [debounceSearch, setDebounceSearch] = useState<string>("");
  const [query, setQuery] = useState<string>("");
  const [page, setPage] = useState<number>(0);
  const [showFilterModal, setShowFilterModal] = useState<boolean>(false);
  const [isInvoiceType, setIsInvoiceType] = useState<boolean>(false);
  const [selectedOption, setSelectedOption] = useState<Ilist>();
  const [filterQuery, setFilterQuery] = useState<Ilist>();
  const [paymentStatus, setPaymentStatus] = useState<string>("");
  const [selectedId, setSelectedId] = useState<number>(1);
  const [isFilterType, setIsFilterType] = useState(false);
  const [isDateRangeType, setIsDateRangeType] = useState(false);
  const [selectedPeriod, setSelectedPeriod] = useState("This year");
  const [startDate, setStartDate] = useState<string>(
    moment().startOf("year").format("YYYY-MM-DD")
  );
  const [endDate, setEndDate] = useState<string>(moment().format("YYYY-MM-DD"));
  const [formattedDate, setFormattedDate] = useState<Date | null>(null);
  const [formattedEndDate, setFormattedEndDate] = useState<Date | null>(null);
  const [search, setSearch] = useState<string>("");
  const [debounceTransactionSearch, setDebounceTransactionSearch] =
    useState<string>("");
  const [transactionPage, setTransactionPage] = useState<number>(0);
  const [transactionSearch, setTransactionSearch] = useState<string>("");
  const [showTransactionFilterModal, setShowTransactionFilterModal] =
    useState<boolean>(false);
  const [selectedTransactionOption, setSelectedTransactionOption] =
    useState<Ilist>();
  const [filterTransactionQuery, setFilterTransactionQuery] = useState<Ilist>();
  const [paymentTransactionStatus, setPaymentTransactionStatus] =
    useState<string>("");
  const [isFilterTransactionType, setIsFilterTransactionType] = useState(false);
  const [isTransactionDateRangeType, setIsTransactionDateRangeType] =
    useState(false);
  const [selectedTransactionPeriod, setSelectedTransactionPeriod] =
    useState("This year");
    useState("This year");
  const [transactionStartDate, setTransactionStartDate] = useState<string>(
    moment().startOf("year").format("YYYY-MM-DD")
  );
  const [transactionEndDate, setTransactionEndDate] = useState<string>(
    moment().format("YYYY-MM-DD")
  );
  const [transactionFormattedDate, setTransactionFormattedDate] =
    useState<Date | null>(null);
  const [transactionFormattedEndDate, setTransactionFormattedEndDate] =
    useState<Date | null>(null);
  const {
    data: invoiceData,
    isLoading: isInvoiceLoading,
    isFetching,
    refetch: refetchInvoice,
  } = useGetAllInvoicesQuery(
    {
      startDate,
      endDate,
      search,
      paymentStatus,
      page: page + 1,
    },
    {
      refetchOnMountOrArgChange: true,
      refetchOnFocus: false,
      refetchOnReconnect: true,
    }
  );
  const { data: invoiceStats, refetch: refetchInvoiceStats } =
    useGetInvoicesStatsQuery(
      {
        startDate,
        endDate,
        search,
        paymentStatus,
      },
      {
        refetchOnMountOrArgChange: true,
        refetchOnFocus: false,
        refetchOnReconnect: true,
      }
    );
  const {
    data: transactionData,
    isLoading: isTransactionLoading,
    isFetching: isTransactionFetching,
    refetch: refetchTransaction,
  } = useGetAllInvoiceTransactionQuery(
    {
      startDate: transactionStartDate,
      endDate: transactionEndDate,
      search: transactionSearch,
      paymentStatus: paymentTransactionStatus,
      page: transactionPage + 1,
    },
    {
      refetchOnMountOrArgChange: true,
      refetchOnFocus: false,
      refetchOnReconnect: true,
    }
  );
  const { data: transactionStats, refetch: refetchTransactionStats } =
    useGetInvoiceTransactionStatsQuery(
      {
        startDate: transactionStartDate,
        endDate: transactionEndDate,
        search: transactionSearch,
        paymentStatus: paymentTransactionStatus,
      },
      {
        refetchOnMountOrArgChange: true,
        refetchOnFocus: false,
        refetchOnReconnect: true,
      }
    );
  const filterType: any = [
    { id: 1, name: "Partially paid", value: "partly_paid" },
    { id: 2, name: "Paid", value: "paid" },
    { id: 3, name: "Unpaid", value: "pending" },
  ];

  const debouncedSetSearch = useCallback((value: string) => {
    debouncedFunction(value, setSearch);
  }, []);

  const handleDateChange = (date: Date | null) => {
    if (date) {
      setFormattedDate(date);
    }
  };

  const handleEndDateChange = (date: Date | null) => {
    if (date) {
      setFormattedEndDate(date);
    }
  };
  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    debouncedSetSearch(e.target.value);
    setDebounceSearch(e.target.value);
    setPage(0);
  };
  const toggleFilterType = () => {
    setIsFilterType((prev) => !prev);
    setIsDateRangeType(false);
  };

  const handleFilterType = (option: any) => {
    setFilterQuery(option);
    setIsFilterType(false);
  };
  const toggleDateRangeType = () => {
    setIsDateRangeType((prev) => !prev);
    setIsFilterType(false);
  };
  const handleDateRangeType = (option: any) => {
    setSelectedOption(option);
    setSelectedPeriod(option.name);
    setIsDateRangeType(false);
  };

  useEffect(() => {
    if (selectedPeriod && selectedPeriod !== "custom") {
      const { startDate, endDate } = getDateRange(selectedOption?.value || "");
      const validStartDate = startDate ? new Date(startDate) : null;
      const validEndDate = endDate ? new Date(endDate) : null;
      setFormattedDate(validStartDate);
      setFormattedEndDate(validEndDate);
    }
  }, [selectedPeriod, selectedOption?.value]);

  const onApplyDate = () => {
    setPaymentStatus(filterQuery?.value || "");
    setPage(0);
    if (formattedDate !== null && formattedEndDate !== null) {
      setStartDate(moment(formattedDate).format("YYYY-MM-DD"));
      setEndDate(moment(formattedEndDate).format("YYYY-MM-DD"));
    }
    setShowFilterModal(false);
  };

  const onClearFilterDate = () => {
    setSelectedPeriod("This year");
    setSelectedPeriod("This year");
    setFormattedDate(null);
    setFormattedEndDate(null);
    setStartDate(moment().startOf("year").format("YYYY-MM-DD"));
    setEndDate(moment().format("YYYY-MM-DD"));
    setShowFilterModal(false);
    setPaymentStatus("");
    setFilterQuery({
      id: 0,
      name: "",
      value: "",
    });
  };

  const debouncedTransactionSetSearch = useCallback((value: string) => {
    debouncedFunction(value, setTransactionSearch);
  }, []);

  const handleTransactionDateChange = (date: Date | null) => {
    if (date) {
      setTransactionFormattedDate(date);
    }
  };

  const handleTransactionEndDateChange = (date: Date | null) => {
    if (date) {
      setTransactionFormattedEndDate(date);
    }
  };
  const handleTransactionInputChange = (
    e: React.ChangeEvent<HTMLInputElement>
  ) => {
    debouncedTransactionSetSearch(e.target.value);
    setDebounceTransactionSearch(e.target.value);
    setTransactionPage(0);
  };
  const toggleTransactionFilterType = () => {
    setIsFilterTransactionType((prev) => !prev);
    setIsTransactionDateRangeType(false);
  };

  const handleTransactionFilterType = (option: any) => {
    setFilterTransactionQuery(option);
    setIsFilterTransactionType(false);
  };
  const toggleTransactionDateRangeType = () => {
    setIsTransactionDateRangeType((prev) => !prev);
    setIsFilterTransactionType(false);
  };
  const handleTransactionDateRangeType = (option: any) => {
    setSelectedTransactionOption(option);
    setSelectedTransactionPeriod(option.name);
    setIsTransactionDateRangeType(false);
  };

  useEffect(() => {
    if (selectedTransactionPeriod && selectedTransactionPeriod !== "custom") {
      const { startDate, endDate } = getDateRange(
        selectedTransactionOption?.value || ""
      );
      const validStartDate = startDate ? new Date(startDate) : null;
      const validEndDate = endDate ? new Date(endDate) : null;
      setTransactionFormattedDate(validStartDate);
      setTransactionFormattedEndDate(validEndDate);
    }
  }, [selectedTransactionPeriod, selectedTransactionOption?.value]);

  const onTransactionApplyDate = () => {
    setPaymentTransactionStatus(filterTransactionQuery?.value || "");
    if (
      transactionFormattedDate !== null &&
      transactionFormattedEndDate !== null
    ) {
      setTransactionStartDate(
        moment(transactionFormattedDate).format("YYYY-MM-DD")
      );
      setTransactionEndDate(
        moment(transactionFormattedEndDate).format("YYYY-MM-DD")
      );
    }
    setShowTransactionFilterModal(false);
    setTransactionPage(0);
  };

  const onClearTransactionFilterDate = () => {
    setSelectedTransactionPeriod("This year");
    setSelectedTransactionPeriod("This year");
    setTransactionFormattedDate(null);
    setTransactionFormattedEndDate(null);
    setTransactionStartDate(moment().startOf("year").format("YYYY-MM-DD"));
    setTransactionEndDate(moment().format("YYYY-MM-DD"));
    setShowTransactionFilterModal(false);
    setPaymentTransactionStatus("");
    setFilterTransactionQuery({
      id: 0,
      name: "",
      value: "",
    });
  };
  const invoiceTitles = [
    {
      name: `Invoice History - ${invoiceStats?.data?.meta?.total || 0}`,
      id: 1,
    },
    {
      name: `Invoice Payment History - ${
        transactionStats?.data?.meta?.total || 0
      }`,
      id: 2,
    },
  ];

  useEffect(() => {
    setQuery(`Invoice History - ${invoiceStats?.data?.meta?.total || 0}`);
  }, [invoiceStats?.data?.meta?.total]);

  const onToggleInvoiceType = () => {
    setIsInvoiceType((prev) => !prev);
  };
  const handleInvoiceType = (option: any) => {
    setSelectedId(Number(option.id));
    setQuery(option.name);
    setIsInvoiceType(false);
  };
  useEffect(() => {
    if (selectedId === 1) {
      refetchInvoice();
      refetchInvoiceStats();
    } else {
      refetchTransaction();
      refetchTransactionStats();
    }
  }, [
    selectedId,
    refetchInvoice,
    refetchInvoiceStats,
    refetchTransaction,
    refetchTransactionStats,
  ]);

  return (
    <div className="p-10">
      <h2 className="text-[#0A5001] md:block hidden text-2xl font-onestBold">
        Invoices
      </h2>
      <div className="md:flex hidden items-center mt-10 space-x-8">
        {invoiceTitles.map((data) => (
          <p
            key={data.id}
            onClick={() => setSelectedInvoiceType(data.id)}
            className={`text-[#4A564D] cursor-pointer text-sm font-onestMedium  border-[#45C734] ${
              data.id === selectedInvoiceType && "border-b-[2px]"
            } pb-1.5 `}
          >
            {data.name}
          </p>
        ))}
      </div>
      {selectedInvoiceType === 1 && (
        <InvoiceHistory
          handleDateChange={handleDateChange}
          toggleDateRangeType={toggleDateRangeType}
          handleDateRangeType={handleDateRangeType}
          formattedDate={formattedDate}
          onApplyDate={onApplyDate}
          selectedPeriod={selectedPeriod}
          onClearFilterDate={onClearFilterDate}
          formattedEndDate={formattedEndDate}
          filterType={filterType}
          handleEndDateChange={handleEndDateChange}
          isDateRangeType={isDateRangeType}
          setSelectedPeriod={setSelectedPeriod}
          showFilterModal={showFilterModal}
          filterQuery={filterQuery}
          isFilterType={isFilterType}
          toggleFilterType={toggleFilterType}
          handleFilterType={handleFilterType}
          setPage={setPage}
          page={page}
          setFilterQuery={setFilterQuery}
          setShowFilterModal={setShowFilterModal}
          handleInputChange={handleInputChange}
          debounceSearch={debounceSearch}
          invoiceData={invoiceData}
          isInvoiceLoading={isInvoiceLoading || isFetching}
        />
      )}
      {selectedInvoiceType === 2 && (
        <PaymentHistory
          handleTransactionDateChange={handleTransactionDateChange}
          toggleTransactionDateRangeType={toggleTransactionDateRangeType}
          handleTransactionDateRangeType={handleTransactionDateRangeType}
          transactionFormattedDate={transactionFormattedDate}
          onTransactionApplyDate={onTransactionApplyDate}
          selectedTransactionPeriod={selectedTransactionPeriod}
          onClearTransactionFilterDate={onClearTransactionFilterDate}
          transactionFormattedEndDate={transactionFormattedEndDate}
          filterType={filterType}
          handleTransactionEndDateChange={handleTransactionEndDateChange}
          isTransactionDateRangeType={isTransactionDateRangeType}
          setSelectedTransactionPeriod={setSelectedTransactionPeriod}
          showTransactionFilterModal={showTransactionFilterModal}
          filterTransactionQuery={filterTransactionQuery}
          isFilterTransactionType={isFilterTransactionType}
          toggleTransactionFilterType={toggleTransactionFilterType}
          handleTransactionFilterType={handleTransactionFilterType}
          setTransactionPage={setTransactionPage}
          transactionPage={transactionPage}
          setFilterTransactionQuery={setFilterTransactionQuery}
          setShowTransactionFilterModal={setShowTransactionFilterModal}
          handleTransactionInputChange={handleTransactionInputChange}
          debounceTransactionSearch={debounceTransactionSearch}
          transactionData={transactionData}
          isTransactionLoading={isTransactionLoading || isTransactionFetching}
        />
      )}
      <MobileInvoice
        isInvoiceLoading={
          Boolean(selectedId === 1)
            ? isInvoiceLoading || isFetching
            : isTransactionLoading || isTransactionFetching
        }
        handleInputChange={
          Boolean(selectedId === 1)
            ? handleInputChange
            : handleTransactionInputChange
        }
        debounceSearch={
          Boolean(selectedId === 1) ? debounceSearch : debounceTransactionSearch
        }
        invoiceData={Boolean(selectedId === 1) ? invoiceData : transactionData}
        showInvoice={Boolean(selectedId === 1) ? true : false}
        invoiceTitles={invoiceTitles}
        onToggleInvoiceType={onToggleInvoiceType}
        handleInvoiceType={handleInvoiceType}
        setQuery={setQuery}
        query={query}
        isInvoiceType={isInvoiceType}
        filter={
          Boolean(selectedId === 1) ? selectedPeriod : selectedTransactionPeriod
        }
        setShowFilterMobileModal={
          Boolean(selectedId === 1)
            ? setShowFilterModal
            : setShowTransactionFilterModal
        }
      />

      <div className="pt-10 md:hidden block">
        {Boolean(selectedId === 1) && (
          <ModalContainer
            open={showFilterModal}
            showCloseIcon={false}
            showBg
            tailwindClassName={`!mt-52 pt-10 w-[100vw] ml-[-15px] bg-white  rounded-tl-[24px]  md:hidden`}
          >
            <div className="pl-4 pr-6 w-full pb-4 h-full ">
              <p className="text-[#929DAE] text-[10px]">FILTERS</p>

              <DropDownSelect
                title="Payment status"
                lists={filterType}
                value={filterQuery?.name}
                setQuery={setFilterQuery}
                isOpen={isFilterType}
                toggleDropdown={toggleFilterType}
                handleOptionClick={handleFilterType}
                placeholder="Select payment status"
                name="paymentStatus"
              />

              <div className="mt-10">
                <DropDownSelect
                  title="Date range"
                  lists={filterTerms}
                  value={selectedPeriod}
                  setQuery={setSelectedPeriod}
                  isOpen={isDateRangeType}
                  toggleDropdown={toggleDateRangeType}
                  handleOptionClick={handleDateRangeType}
                  placeholder="-Select a date range-"
                  name="deliveryType"
                />
              </div>

              <div className={` pt-[110px] space-y-4 gap-3 items-center pb-8 `}>
                <Button
                  title="Clear"
                  onClick={onClearFilterDate}
                  className="!py-[10px] !border-[#BEC0BE] !px-12  !rounded-lg !bg-transparent !border !text-[#464748]"
                />
                <Button
                  title="Apply"
                  className="!py-[10px]  !rounded-lg !px-12  !text-[#fff]"
                  onClick={() => {
                    onApplyDate();
                  }}
                />
              </div>
            </div>
          </ModalContainer>
        )}
        {!Boolean(selectedId === 1) && (
          <ModalContainer
            open={showTransactionFilterModal}
            showCloseIcon={false}
            showBg
            tailwindClassName={`!mt-52 pt-10 w-[100vw] ml-[-15px] bg-white  rounded-tl-[24px]  md:hidden`}
          >
            <div className="pl-4 pr-6 w-full pb-4 h-full ">
              <p className="text-[#929DAE] text-[10px]">FILTERS</p>

              <DropDownSelect
                title="Payment status"
                lists={filterType}
                value={filterTransactionQuery?.name}
                setQuery={setFilterTransactionQuery}
                isOpen={isFilterTransactionType}
                toggleDropdown={toggleTransactionFilterType}
                handleOptionClick={handleTransactionFilterType}
                placeholder="Select payment status"
                name="paymentStatus"
              />

              <div className="mt-10">
                <DropDownSelect
                  title="Date range"
                  lists={filterTerms}
                  value={selectedTransactionPeriod}
                  setQuery={setSelectedTransactionPeriod}
                  isOpen={isTransactionDateRangeType}
                  toggleDropdown={toggleTransactionDateRangeType}
                  handleOptionClick={handleTransactionDateRangeType}
                  placeholder="-Select a date range-"
                  name="deliveryType"
                />
              </div>

              <div className={` pt-[110px] space-y-4 gap-3 items-center pb-8 `}>
                <Button
                  title="Clear"
                  onClick={onClearTransactionFilterDate}
                  className="!py-[10px] !border-[#BEC0BE] !px-12  !rounded-lg !bg-transparent !border !text-[#464748]"
                />
                <Button
                  title="Apply"
                  className="!py-[10px]  !rounded-lg !px-12  !text-[#fff]"
                  onClick={() => {
                    onTransactionApplyDate();
                  }}
                />
              </div>
            </div>
          </ModalContainer>
        )}
      </div>
    </div>
  );
};

export default Invoice;
