export const handleErrorChange = (name: string, value: any, setFormErrors:any) => {
const regex = /^[^\s@]+@[^\s@]+\.[^\s@]{2,}$/i;

    if (name === "recipientEmail") {
      if (value !== "" && !regex.test(value)) {
        setFormErrors((prev: any) => ({
          ...prev,
          recipientEmail: "Invalid email format",
        }));
      } else if (value !== "" && regex.test(value)) {
        setFormErrors((prev: any) => ({
          ...prev,
          recipientEmail: "",
        }));
      }
    }
    if (name === "importRecipientEmail") {
      if (value !== "" && !regex.test(value)) {
        setFormErrors((prev: any) => ({
          ...prev,
          importRecipientEmail: "Invalid email format",
        }));
      } else if (value !== "" && regex.test(value)) {
        setFormErrors((prev: any) => ({
          ...prev,
          importRecipientEmail: "",
        }));
      }
    }
    if (name === "exportRecipientEmail") {
      if (value !== "" && !regex.test(value)) {
        setFormErrors((prev: any) => ({
          ...prev,
          exportRecipientEmail: "Invalid email format",
        }));
      } else if (value !== "" && regex.test(value)) {
        setFormErrors((prev: any) => ({
          ...prev,
          exportRecipientEmail: "",
        }));
      }
    }
    if (name === "pickUpAddress" && value !== "") {
      setFormErrors((prev: any) => ({
        ...prev,
        pickUpAddress: "",
      }));
    } else if (name === "pickUpAddress" && value === "") {
      setFormErrors((prev: any) => ({
        ...prev,
        pickUpAddress: "Pick-up address is required",
      }));
    }
    if (name === "exportPickUpState" && value !== "") {
      setFormErrors((prev: any) => ({
        ...prev,
        exportPickUpState: "",
      }));
    } else if (name === "exportPickUpState" && value === "") {
      setFormErrors((prev: any) => ({
        ...prev,
        exportPickUpState: "Pick-up state is required",
      }));
    }
    if (name === "dropOffAddress" && value !== "") {
      setFormErrors((prev: any) => ({
        ...prev,
        dropOffAddress: "",
      }));
    } else if (name === "dropOffAddress" && value === "") {
      setFormErrors((prev: any) => ({
        ...prev,
        dropOffAddress: "Drop-off address is required",
      }));
    }

    if (name === "importDropOffAdd" && value !== "") {
      setFormErrors((prev: any) => ({
        ...prev,
        importDropOffAdd: "",
      }));
    } else if (name === "importDropOffAdd" && value === "") {
      setFormErrors((prev: any) => ({
        ...prev,
        importDropOffAdd: "Drop-off address is required",
      }));
    }
    if (name === "exportDropOffAddress" && value !== "") {
      setFormErrors((prev: any) => ({
        ...prev,
        exportDropOffAddress: "",
      }));
    } else if (name === "exportDropOffAddress" && value === "") {
      setFormErrors((prev: any) => ({
        ...prev,
        exportDropOffAddress: "Drop-off address is required",
      }));
    }
    if (name === "exportPickUpAddress" && value !== "") {
      setFormErrors((prev: any) => ({
        ...prev,
        exportPickUpAddress: "",
      }));
    } else if (name === "exportPickUpAddress" && value === "") {
      setFormErrors((prev: any) => ({
        ...prev,
        exportPickUpAddress: "Pick-up address is required",
      }));
    }
    if (name === "importOrderNumber" && value !== "") {
      setFormErrors((prev: any) => ({
        ...prev,
        importOrderNumber: "",
      }));
    } else if (name === "importOrderNumber" && value === "") {
      setFormErrors((prev: any) => ({
        ...prev,
        importOrderNumber: "Order number is required",
      }));
    }
    if (name === "exportDropOffCity" && value !== "") {
      setFormErrors((prev: any) => ({
        ...prev,
        exportDropOffCity: "",
      }));
    } else if (name === "exportDropOffCity" && value === "") {
      setFormErrors((prev: any) => ({
        ...prev,
        exportDropOffCity: "Drop-off city is required",
      }));
    }
    if (name === "exportZipCode" && value !== "") {
      setFormErrors((prev: any) => ({
        ...prev,
        exportZipCode: "",
      }));
    } else if (name === "exportZipCode" && value === "") {
      setFormErrors((prev: any) => ({
        ...prev,
        exportZipCode: "Drop-off city is required",
      }));
    }
    if (name === "importVendorName" && value !== "") {
      setFormErrors((prev: any) => ({
        ...prev,
        importVendorName: "",
      }));
    } else if (name === "importVendorName" && value === "") {
      setFormErrors((prev: any) => ({
        ...prev,
        importVendorName: "Vendor/Business name is required",
      }));
    }
    if (name === "recipientName" && value !== "") {
      setFormErrors((prev: any) => ({
        ...prev,
        recipientName: "",
      }));
    } else if (name === "recipientName" && value === "") {
      setFormErrors((prev: any) => ({
        ...prev,
        recipientName: "Recipient name is required",
      }));
    }
    if (name === "importRecipientName" && value !== "") {
      setFormErrors((prev: any) => ({
        ...prev,
        importRecipientName: "",
      }));
    } else if (name === "importRecipientName" && value === "") {
      setFormErrors((prev: any) => ({
        ...prev,
        importRecipientName: "Recipient name is required",
      }));
    }
    if (name === "exportRecipientName" && value !== "") {
      setFormErrors((prev: any) => ({
        ...prev,
        exportRecipientName: "",
      }));
    } else if (name === "exportRecipientName" && value === "") {
      setFormErrors((prev: any) => ({
        ...prev,
        exportRecipientName: "Recipient name is required",
      }));
    }
    if (name === "recipientPhoneNumber") {
      if (!value) {
        setFormErrors((prev: any) => ({
          ...prev,
          recipientPhoneNumber: "Recipient phone number is required",
        }));
      } else if (value.length !== 11) {
        setFormErrors((prev: any) => ({
          ...prev,
          recipientPhoneNumber: "Phone number must be 11 digits.",
        }));
      } else {
        setFormErrors((prev: any) => ({
          ...prev,
          recipientPhoneNumber: "",
        }));
      }
    }

    if (name === "exportPhoneNumber") {
      if (!value) {
        setFormErrors((prev: any) => ({
          ...prev,
          exportPhoneNumber: "Recipient phone number is required",
        }));
      } else if (value.length < 9 || value.length > 13) {
        setFormErrors((prev: any) => ({
          ...prev,
          exportPhoneNumber: "phone number must be between 9 - 13 digits.",
        }));
      } else {
        setFormErrors((prev: any) => ({
          ...prev,
          exportPhoneNumber: "",
        }));
      }
    }
    if (name === "exportPickUpPhoneNumber") {
      if (!value) {
        setFormErrors((prev: any) => ({
          ...prev,
          exportPickUpPhoneNumber: "Pick-up phone number is required",
        }));
      } else if (value.length !== 11) {
        setFormErrors((prev: any) => ({
          ...prev,
          exportPickUpPhoneNumber: "Pick-up phone number must be 11 digits.",
        }));
      } else {
        setFormErrors((prev: any) => ({
          ...prev,
          exportPickUpPhoneNumber: "",
        }));
      }
    }
    if (name === "importRecipientPhone") {
      if (!value) {
        setFormErrors((prev: any) => ({
          ...prev,
          importRecipientPhone: "Recipient phone number is required",
        }));
      } else if (value.length !== 11) {
        setFormErrors((prev: any) => ({
          ...prev,
          importRecipientPhone: "Phone number must be 11 digits.",
        }));
      } else {
        setFormErrors((prev: any) => ({
          ...prev,
          importRecipientPhone: "",
        }));
      }
    }
    if (name === "pickUpPhoneNumber") {
      if (!value) {
        setFormErrors((prev: any) => ({
          ...prev,
          pickUpPhoneNumber: "",
        }));
      } else if (value.length !== 11) {
        setFormErrors((prev: any) => ({
          ...prev,
          pickUpPhoneNumber: "Phone number must be 11 digits.",
        }));
      } else {
        setFormErrors((prev: any) => ({
          ...prev,
          pickUpPhoneNumber: "",
        }));
      }
    }
    if (name === "weight" && value !== "") {
      setFormErrors((prev: any) => ({
        ...prev,
        weight: "",
      }));
    } else if (name === "weight" && value === "") {
      setFormErrors((prev: any) => ({
        ...prev,
        weight: "Weight is required",
      }));
    }
    if (name === "importWeight" && value !== "") {
      setFormErrors((prev: any) => ({
        ...prev,
        importWeight: "",
      }));
    } else if (name === "importWeight" && value === "") {
      setFormErrors((prev: any) => ({
        ...prev,
        importWeight: "Weight is required",
      }));
    }
    if (name === "exportWeight" && value !== "") {
      setFormErrors((prev: any) => ({
        ...prev,
        exportWeight: "",
      }));
    } else if (name === "exportWeight" && value === "") {
      setFormErrors((prev: any) => ({
        ...prev,
        exportWeight: "Weight is required",
      }));
    }
    if (name === "valueOfItem" && value !== "") {
      setFormErrors((prev: any) => ({
        ...prev,
        valueOfItem: "",
      }));
    } else if (name === "valueOfItem" && value === "") {
      setFormErrors((prev: any) => ({
        ...prev,
        valueOfItem: "Value of item is required",
      }));
    }
    if (name === "itemDescription" && value !== "") {
      setFormErrors((prev: any) => ({
        ...prev,
        itemDescription: "",
      }));
    } else if (name === "itemDescription" && value === "") {
      setFormErrors((prev: any) => ({
        ...prev,
        itemDescription: "Item description is required",
      }));
    }

    if (name === "importDescription" && value !== "") {
      setFormErrors((prev: any) => ({
        ...prev,
        importDescription: "",
      }));
    } else if (name === "importDescription" && value === "") {
      setFormErrors((prev: any) => ({
        ...prev,
        importDescription: "Item description is required",
      }));
    }
    if (name === "exportItemDescription" && value !== "") {
      setFormErrors((prev: any) => ({
        ...prev,
        exportItemDescription: "",
      }));
    } else if (name === "exportItemDescription" && value === "") {
      setFormErrors((prev: any) => ({
        ...prev,
        exportItemDescription: "Item description is required",
      }));
    }
    if (name === "importValueofItem" && value !== "") {
      setFormErrors((prev: any) => ({
        ...prev,
        importValueofItem: "",
      }));
    } else if (name === "importValueofItem" && value === "") {
      setFormErrors((prev: any) => ({
        ...prev,
        importValueofItem: "Value of item is required",
      }));
    }
    if (name === "exportValueofItem" && value !== "") {
      setFormErrors((prev: any) => ({
        ...prev,
        exportValueofItem: "",
      }));
    } else if (name === "exportValueofItem" && value === "") {
      setFormErrors((prev: any) => ({
        ...prev,
        exportValueofItem: "Value of item is required",
      }));
    }
  };