import { Button } from "components/Button";
import ModalContainer from "components/Modal";
import { initialItemDetails } from "constant/data";
import { useState } from "react";
import { GoPencil } from "react-icons/go";
import { IoMdAdd } from "react-icons/io";
import { RiDeleteBin6Line } from "react-icons/ri";
import { Link, useNavigate } from "react-router-dom";
import CreateStorageItem from "./CreateStorageItem";
import { validateStoreItem } from "helpers/validation";
import { useCreateOrderMutation } from "services/slices/inventory";
import { handleErrorToast } from "helpers/toastMessage";

interface Item {
  id: number;
  subCategory: string;
  categoryName: string;
  quantity: number;
  category: string;
  weight: string;
  subCategoryId: string;
  size: string;
  price: string;
  description: string;
  expiryDate: string;
  image: string;
  pickUpAddress: string;
}

const SingleStoreItem = () => {
  const navigate = useNavigate();
  const [isStoreItem, setIsStoreItem] = useState<boolean>(false);
  const [isAddNew, setIsAddNew] = useState<boolean>(false);
  const [addItem, setAddItem] = useState<boolean>(false);
  const [isStorageCreated, setIsStorageCreated] = useState<boolean>(false);
  const [formErrors, setFormErrors] = useState<any>({});
  const [itemDetails, setItemDetails] = useState(initialItemDetails);
  const [categoryName, setCategoryName] = useState<string>("");
  const [items, setItems] = useState<Item[]>([]);
  const [searchCategory, setSearchCategory] = useState<string>("");
  const [warehouseDeliveryMode, setWarehouseDeliveryMode] =
    useState<string>("");
  const [createOrder, { isLoading }] = useCreateOrderMutation();

  const onSubmit = () => {
    setFormErrors(validateStoreItem(itemDetails));
    if (
      warehouseDeliveryMode !== "pickup" &&
      itemDetails.pickUpAddress === ""
    ) {
      setFormErrors((prev: any) => ({
        ...prev,
        pickUpAddress: "",
      }));
    } else if (
      warehouseDeliveryMode === "pickup" &&
      itemDetails.pickUpAddress === ""
    ) {
      setFormErrors((prev: any) => ({
        ...prev,
        pickUpAddress: "Delivery address is required",
      }));
    }
    if (warehouseDeliveryMode === "") {
      setFormErrors((prev: any) => ({
        ...prev,
        deliveryMode: "Delivery mode is required",
      }));
    }
    if (
      itemDetails.category !== "" &&
      !formErrors.pickUpAddress &&
      itemDetails.subCategory !== "" &&
      warehouseDeliveryMode !== "" &&
      itemDetails.quantity !== ""
    ) {
      setItems((prevItems) => {
        const updatedItems = prevItems.some(
          (item) => item.subCategory === itemDetails.subCategory
        )
          ? prevItems.map((item) =>
              item.subCategory === itemDetails.subCategory
                ? {
                    ...item,
                    quantity: Number(itemDetails.quantity),
                    expiryDate: itemDetails.expiryDate,
                    subCategory: itemDetails.subCategory,
                    category: itemDetails.category,
                    categoryName: itemDetails.categoryName,
                    weight: itemDetails.weight,
                    subCategoryId: itemDetails.subCategoryId,
                    size: itemDetails.size,
                    price: itemDetails.price,
                    description: itemDetails.description,
                    image: itemDetails.image,
                    pickUpAddress: itemDetails.pickUpAddress,
                  }
                : item
            )
          : [
              ...prevItems,
              {
                id: Date.now(),
                subCategory: itemDetails.subCategory,
                category: itemDetails.category,
                categoryName: itemDetails.categoryName,
                quantity: Number(itemDetails.quantity),
                expiryDate: itemDetails.expiryDate,
                weight: itemDetails.weight,
                subCategoryId: itemDetails.subCategoryId,
                size: itemDetails.size,
                price: itemDetails.price,
                description: itemDetails.description,
                image: itemDetails.image,
                pickUpAddress: itemDetails.pickUpAddress,
              },
            ];

        return updatedItems;
      });

      setAddItem(true);
    }
  };

  const handleDelete = (id: number) => {
    if (items.length === 1) {
      setItems((prevItems) => prevItems.filter((item) => item.id !== id));
      setAddItem(false);
    } else {
      setItems((prevItems) => prevItems.filter((item) => item.id !== id));
    }
  };

  const onRequestStorage = () => {
    createOrder({
      items: items.map((item) => ({
        name: item.subCategory,
        category: Number(item.category),
        sub_category: Number(item.subCategoryId),
        weight: Number(item.weight),
        size: Number(item.size),
        price_per_unit: Number(item.price),
        quantity: Number(item.quantity),
        description: item.description,
        expiry_date: item.expiryDate,
        image: item.image,
      })),
      address: itemDetails.pickUpAddress,
      delivery_mode: warehouseDeliveryMode,
    })
      .unwrap()
      .then((data) => {
        setIsStorageCreated(true);
      })
      .catch((e) => handleErrorToast(e));
  };

  return (
    <>
      {isStoreItem ? (
        !addItem ? (
          <CreateStorageItem
            setIsStoreItem={setIsStoreItem}
            warehouseDeliveryMode={warehouseDeliveryMode}
            setWarehouseDeliveryMode={setWarehouseDeliveryMode}
            formErrors={formErrors}
            categoryName={categoryName}
            itemDetails={itemDetails}
            searchCategory={searchCategory}
            setSearchCategory={setSearchCategory}
            setCategoryName={setCategoryName}
            setItemDetails={setItemDetails}
            setFormErrors={setFormErrors}
            onSubmit={onSubmit}
            isEdit={false}
            isAddNew={isAddNew}
            setIsAddNew={setIsAddNew}
          />
        ) : (
          <div className="overflow-x-hidden mx-9 mt-16">
            <div
              onClick={() => setAddItem(true)}
              className=" flex mb-2  cursor-pointer"
            >
              <div>
                {" "}
                <img className="w-3 h-3" src="/images/backArrow.svg" alt="" />
              </div>
              <p className=" text-[#4a564d] font-[500] text-[13px] -mt-1 font-onestMedium">
                Go back
              </p>
            </div>
            <div className="mt-6">
              <p className=" text-textBlackGreytext-xl md:text-2xl font-onestBold">
                Store Item in Warehouse
              </p>
              <p className="text-[#464748] text-base pt-2">
                Click ‘Request Storage’ to store the following items to
                warehouse
              </p>
              <div className="overflow-auto max-h-[500] h-fit  relative">
                <div className="bg-[#F9F9FB] overflow-auto max-h-[500] h-fit rounded-2xl w-full border border-borderGrey  pb-4 mt-10">
                  <table className="whitespace-nowrap bg-white text-left border-spacing-2 rounded-2xl w-full shadow-[0px 4px 8px -2px rgba(16, 24, 40, 0.1), 0px 2px 4px -2px rgba(16, 24, 40, 0.06)]">
                    <thead className="bg-white sticky top-0 z-20 border-b-[1px] border-[#E4EBF5]">
                      <tr className="">
                        <th className="py-3 pl-4 pr-4  text-xs text-[#929DAE] font-onestRegular">
                          Item ID/SKU
                        </th>
                        <th className="py-3 text-xs pl-4  text-[#929DAE] font-onestRegular">
                          Item name
                        </th>
                        <th className="py-3 text-xs pl-4 pr-4  text-[#929DAE] font-onestRegular">
                          Category
                        </th>
                        <th className="py-3 text-xs pl-4 pr-4 text-[#929DAE] font-onestRegular">
                          Quantity to store
                        </th>
                        <th className="py-3 text-xs pl-4 pr-20 text-[#929DAE] font-onestRegular">
                          Item weight
                        </th>
                        <th className="py-3 text-xs pl-4  text-[#929DAE] font-onestRegular">
                          Cost value per item
                        </th>
                        <th className="py-3 text-xs pl-4 pr-4  text-[#929DAE] font-onestRegular">
                          Expiry date
                        </th>
                        <th className="py-3 sticky right-0 text-xs bg-white pl-4 pr-10 text-[#929DAE] border-l-[1px] border-[#E4EBF5] font-onestRegular">
                          Actions
                        </th>
                      </tr>
                    </thead>

                    <tbody className="bg-white ">
                      {items.map((item) => {
                        return (
                          <tr
                            key={item.id}
                            className="text-sm text-[#161718]  w-full font-[400] border-b-[1px] border-[#E4EBF5]"
                          >
                            <td className={` py-4 text-xs pl-3 pr-6 `}>
                              {item.id}
                            </td>
                            <td
                              className={` w-[300px] whitespace-normal  pl-4  flex  gap-x-1.5 `}
                            >
                              {item.subCategory}
                            </td>
                            <td
                              className={` py-4 pl-4 text-sx    w-[300px] px-4`}
                            >
                              {item.categoryName}
                            </td>
                            <td className={`py-4 pl-4 text-xs  w-[300px]`}>
                              {item.quantity} Qty
                            </td>
                            <td
                              className={`py-4 pl-4 pr-6 !w-[150px]  text-xs `}
                            >
                              {item.weight}kg
                            </td>
                            <td
                              className={` !w-[400px] whitespace-normal  py-4 pl-4 flex gap-x-1.5 pr-4 `}
                            >
                              ₦{item.price}
                            </td>
                            <td
                              className={` py-4 pl-4 text-sx  w-[300px] px-4`}
                            >
                              {item.expiryDate}
                            </td>

                            <td className=" sticky right-0 pl-5 bg-white w-[100%] text-xs  border-[1px] border-l-[#E4EBF5]">
                              <div className="flex items-center">
                                <Button
                                  className=" !w-fit !px-0 !bg-transparent !text-[#1D4CC4]"
                                  title="Edit"
                                  disabled={isLoading}
                                  onClick={() => {
                                    setAddItem(false);
                                    setItemDetails((prev: any) => ({
                                      ...prev,
                                      category: item.category,
                                      categoryName: item.categoryName,
                                      quantity: item.quantity,
                                      weight: item.weight,
                                      subCategoryId: item.subCategoryId,
                                      subCategory: item.subCategory,
                                      size: item.size,
                                      price: item.price,
                                      description: item.description,
                                      expiryDate: item.expiryDate,
                                      image: item.image,
                                      pickUpAddress: item.pickUpAddress,
                                    }));
                                    setWarehouseDeliveryMode(
                                      warehouseDeliveryMode
                                    );
                                  }}
                                  iconLeft
                                  icon={<GoPencil className="text-[#1D4CC4]" />}
                                />
                                <Button
                                  className=" !w-fit  !bg-transparent !text-[#BA3838]"
                                  title="Delete"
                                  disabled={isLoading}
                                  onClick={() => handleDelete(item.id)}
                                  iconLeft
                                  icon={
                                    <RiDeleteBin6Line className="text-[#BA3838]" />
                                  }
                                />
                              </div>
                            </td>
                          </tr>
                        );
                      })}
                    </tbody>
                  </table>
                </div>
              </div>
              <div className="mt-10 md:flex-row flex-col flex md:space-y-0 space-y-3 w-full md:space-x-3">
                <div className="w-full md:hidden block">
                  <Button
                    className="  !bg-transparent py-2.5 !border !text-[#18AF04]"
                    title="Click to add more"
                    disabled={isLoading}
                    onClick={() => {
                      setIsAddNew(true);
                      setAddItem(false);
                      setItemDetails((prev: any) => ({
                        ...prev,
                        category: "",
                        categoryName: "",
                        quantity: "",
                        weight: "",
                        subCategoryId: "",
                        subCategory: "",
                        size: "",
                        price: "",
                        description: "",
                        expiryDate: "",
                        image: "",
                      }));
                    }}
                    iconLeft
                    icon={<IoMdAdd className="text-[#18AF04]" />}
                  />
                </div>
                <div className="md:block hidden">
                  <Button
                    className=" !w-fit  !bg-transparent py-2.5 !border !text-[#18AF04]"
                    title="Click to add more"
                    disabled={isLoading}
                    onClick={() => {
                      setIsAddNew(true);
                      setAddItem(false);
                      setItemDetails((prev: any) => ({
                        ...prev,
                        category: "",
                        categoryName: "",
                        quantity: "",
                        weight: "",
                        subCategoryId: "",
                        subCategory: "",
                        size: "",
                        price: "",
                        description: "",
                        expiryDate: "",
                        image: "",
                      }));
                    }}
                    iconLeft
                    icon={<IoMdAdd className="text-[#18AF04]" />}
                  />
                </div>
                <div className=" md:block hidden">
                  <Button
                    className="py-2.5 !w-fit "
                    title="Request Storage"
                    loading={isLoading}
                    disabled={isLoading}
                    onClick={onRequestStorage}
                  />
                </div>
                <div className="w-full md:hidden block">
                  <Button
                    className="py-2.5  "
                    title="Request Storage"
                    loading={isLoading}
                    disabled={isLoading}
                    onClick={onRequestStorage}
                  />
                </div>
              </div>
            </div>
          </div>
        )
      ) : (
        <div className="mt-7 sm:mt-[72px] rounded-lg max-w-[38rem] mx-4 md:mx-auto border-[1px] border-[#E1F0DF] flex bg-dashboard-gradient justify-center">
          <div className="w-full px-4 sm:px-10 py-6 sm:py-8 onestBold">
            <h1 className="text-2xl font-onestBold font-[800] text-[#0a5001] tracking-wide">
              Store Item in Warehouse
            </h1>
            <p className="text-[#a0aba3] font-[500] font-onestRegular tracking-normal mb-12">
              How would you like to upload your items?
            </p>

            <div className="grid bmd:grid-cols-2 gap-6 mt-6">
              <div
                onClick={() => setIsStoreItem(true)}
                className="rounded-[12px] cursor-pointer px-5 border-[1px] pb-5 border-[#E4EBF5]"
              >
                <img
                  className="mt-6 md:mt-[60px]"
                  src="/images/singleShipment.svg"
                  alt="Single shipment"
                />
                <h1 className="text-[#071227] pt-5 pb-1.5 text-sm font-[600] font-onestMedium">
                  Single Upload
                </h1>
                <p className="text-[#a0aba3] text-xs font-[500] font-onestRegular tracking-normal">
                  Add one item, or multiple items one at a time
                </p>
              </div>
              <Link
                to="/dashboard/inventory/store-item/bulk-upload"
                className="rounded-[12px] px-5 border-[1px] pb-5 border-[#E4EBF5]"
              >
                <img
                  className="mt-6 md:mt-[60px]"
                  src="/images/bulkShipment.svg"
                  alt="Bulk shipment"
                />
                <h1 className="text-[#071227] pt-5 pb-1.5 text-sm font-[600] font-onestMedium">
                  Bulk Upload
                </h1>
                <p className="text-[#a0aba3] text-xs font-[500] font-onestRegular tracking-normal">
                  Add all your items at once with the bulk upload feature
                </p>
              </Link>
            </div>
          </div>
        </div>
      )}
      <ModalContainer
        open={isStorageCreated}
        showCloseIcon={false}
        tailwindClassName="w-[300px] sm:w-[466px]"
        closeModal={() => setIsStorageCreated(false)}
      >
        <div className="w-full px-8 pb-8 bg-white flex flex-col  rounded-[12px] relative">
          <p className="text-lg pb-3 mt-6 border-b border-[#E4EBF5] text-center font-onestBold text-[#0A5001] ">
            New Storage Request Created
          </p>
          <img
            className="w-6 h-6 sm:block hidden absolute right-6 top-6 cursor-pointer"
            onClick={() => {
              navigate("/dashboard/inventory/requests");
            }}
            src="/images/close.svg"
            alt=""
          />
          <div className="flex justify-center mt-8">
            {" "}
            <img
              src="/images/storeCreated.svg"
              alt=""
              className="w-[123px] h-[98px]"
            />
          </div>
          <p className="text-center text-[#4A564D] text-sm font-onestRegular mt-8">
            Your storage request has been submitted
          </p>
          <div className="flex justify-center mt-14">
            <Button
              title="View Request"
              onClick={() => {
                navigate("/dashboard/inventory/requests");
              }}
              className="!w-[210px] py-4 bg-[#18AF04] rounded-lg !font-[500] !text-base"
            />
          </div>
        </div>
      </ModalContainer>
    </>
  );
};

export default SingleStoreItem;
