import { Button } from "components/Button";
import Dropdown from "components/common/form/SelectDropdown";
import React, { RefObject } from "react";
import { IoIosClose } from "react-icons/io";
import { BulkItem, EditableState } from "type/inventory";

interface Item {
  id: number;
  name: string;
  product_category_id: number;
  weight: string;
  size: string;
  price_per_unit: string;
  description: string;
  expiry_date: string;
  image: string;
  subCategoryId: string;
}
interface Props {
  result: any;
  tableContainerRef: RefObject<HTMLDivElement>;
  errorRefs: any;
  editable: EditableState;
  formErrors: any;
  setFormErrors: React.Dispatch<React.SetStateAction<any>>;
  searchCategory: string;
  setSearchCategory: React.Dispatch<React.SetStateAction<string>>;
  filteredCategorySearch: any;
  searchSubCategory: string;
  setSearchSubCategory: React.Dispatch<React.SetStateAction<string>>;
  setIsBulkStorage: React.Dispatch<React.SetStateAction<boolean>>;
  filteredSubCategorySearch: any;
  handleChange: any;
  handleEditClick: any;
  onAddStorageItem: any;
  handleDelete: any;
  isLoadingStorage: boolean;
  handleBlur: () => void;
  itemsBulk: BulkItem[];
  setItems: React.Dispatch<React.SetStateAction<Item[]>>;
  items: Item[];
}
const BulkStorageTable = ({
  result,
  tableContainerRef,
  errorRefs,
  editable,
  formErrors,
  handleChange,
  handleEditClick,
  handleBlur,
  itemsBulk,
  setFormErrors,
  searchCategory,
  setSearchCategory,
  filteredCategorySearch,
  searchSubCategory,
  setSearchSubCategory,
  setIsBulkStorage,
  handleDelete,
  setItems,
  isLoadingStorage,
  onAddStorageItem,
}: Props) => {
  return (
    <div className="hidden md:block mx-5 pb-[100px]">
      <div className="mt-7 relative pb-14 sm:mt-[72px] rounded-lg max-w-screen-lg mx-auto border-[1px] border-[#E1F0DF] flex bg-dashboard-gradient justify-center">
        <div className="w-full px-4 sm:px-10 py-6 sm:py-8 onestBold">
          <h1 className="text-2xl mt-10 font-onestBold font-[800] text-[#0a5001] tracking-wide">
            Bulk Storage
          </h1>
          <p className="text-[#4a564d] text-[13px] font-[500]">
            Preview and edit items to be stored. If there are no errors, submit
            storage request.
          </p>

          <div className="bg-[#F9F9FB] pb-4 mt-10 border-[1px] border-[#E4EBF5] rounded-lg">
            <div className="flex  bg-[#F9F9FB] justify-between  pt-4  ">
              <div className="flex text-xs text-[#4A564D] font-[600] gap-3 mb-3 ml-2">
                <p className="">{itemsBulk.length} items uploaded</p>
                <div className="flex items-center gap-1">
                  <img src="/images/check-mark.svg" alt="" />
                  <p className="text-[#18AF04]">
                    {result.rowValidCount} correct items
                  </p>
                </div>
                <div
                  className="flex items-center gap-0 cursor-pointer"
                  onClick={() => {}}
                >
                  <IoIosClose color={"#F64108"} size={18} />
                  <p className="text-[#F64108]">
                    {result.rowInvalidCount} incorrect items
                  </p>
                </div>
              </div>

              <div className="text-xs text-[#4A564D] font-[600] flex gap-1.5 mr-[50px] ">
                <span className="text-[#929DAE]"> Scroll right </span>
                <div className="mt-.05">
                  {" "}
                  <img src="/images/forward-arrow.svg" alt="" />
                </div>
              </div>
            </div>
            <div
              ref={tableContainerRef}
              className="overflow-auto  h-[500px]  relative "
            >
              <table className="whitespace-nowrap absolute  overflow-y-auto text-left  border-spacing-2 rounded-2xl w-full shadow-[0px 4px 8px -2px rgba(16, 24, 40, 0.1), 0px 2px 4px -2px rgba(16, 24, 40, 0.06)]">
                <thead className="bg-white !mx-12 sticky top-0 z-10 border-b-[1px] border-[#E4EBF5]">
                  <tr className="">
                    <th className="py-3 pl-4 pr-4 border-r-[1px] border-[#E4EBF5] text-xs text-[#929DAE] font-onestRegular">
                      Category
                    </th>
                    <th className="py-3 text-xs pl-4  text-[#929DAE] border-l-[1px] border-[#E4EBF5] font-onestRegular">
                      Sub-category
                    </th>
                    <th className="py-3 text-xs pl-4 pr-4 text-[#929DAE] border-l-[1px] border-[#E4EBF5] font-onestRegular">
                      Quantity to store
                    </th>
                    <th className="py-3 text-xs pl-4 pr-20 text-[#929DAE] border-l-[1px] border-[#E4EBF5] font-onestRegular">
                      Expiry Date
                    </th>
                    <th className="py-3 sticky right-0 text-xs bg-white pl-4 pr-10 text-[#929DAE] border-l-[1px] border-[#E4EBF5] font-onestRegular">
                      Action
                    </th>
                  </tr>
                </thead>

                <tbody className="overflow-y-auto max-h-[500px] h-fit">
                  {itemsBulk.map((item: any, idx: number) => {
                    const selectedCategory = filteredCategorySearch.find(
                      (cat: any) => cat.name === item.category
                    );
                    const subCategoryOptions = selectedCategory?.products || [];
                    const filteredSubCategories = subCategoryOptions
                      ?.filter((product: any) =>
                        product.name
                          .toLowerCase()
                          .includes(searchSubCategory.toLowerCase())
                      )
                      ?.sort((a: Item, b: Item) =>
                        a.name.localeCompare(b.name)
                      );
                    return (
                      <tr
                        key={item.id}
                        className="text-sm text-[#161718]  w-full font-[400] border-b-[1px] border-[#E4EBF5]"
                      >
                        <td
                          ref={(el) => {
                            if (!item.categoryValid)
                              errorRefs.current.category = el;
                          }}
                          className={` !w-[900px] ${
                            item.categoryValid
                              ? "bg-[#F9F9FB]"
                              : "bg-[#ffeded] text-[#f64108] font-[900]"
                          } py-4 pl-4 text-sx border-l-[#fff] border-[1px] border-[#E4EBF5] w-[300px] px-4`}
                        >
                          {editable.id === item.id &&
                          editable.field === "category" ? (
                            <div className="absolute -mt-5">
                              <Dropdown
                                selectPlaceholderName="drop-off state"
                                dropdownStateName={item.category}
                                dropdownValue={item.category}
                                searchSubject={searchCategory}
                                search
                                setSearchSubject={setSearchCategory}
                                dropdownContainerClasses={`shadow-cardShadow-4 !w-full !right-0  border border-slate-100  rounded `}
                                dropdownOptions={[
                                  {
                                    customChild: (
                                      <div>
                                        <div>
                                          {filteredCategorySearch?.map(
                                            (
                                              categoryItem: any,
                                              idx: number
                                            ) => {
                                              return (
                                                <div
                                                  key={idx}
                                                  onClick={() => {
                                                    handleChange(
                                                      item.id,
                                                      "category",
                                                      categoryItem.name
                                                    );
                                                    setItems(
                                                      categoryItem.products
                                                    );
                                                    setFormErrors(
                                                      (prev: any) => ({
                                                        ...prev,
                                                        category: "",
                                                      })
                                                    );
                                                  }}
                                                >
                                                  <div className="px-3 py-2 text-sm font-onestMedium text-[#4a564d] hover:bg-[#edecec] cursor-pointer">
                                                    <p>{categoryItem?.name}</p>
                                                  </div>
                                                </div>
                                              );
                                            }
                                          )}
                                        </div>
                                      </div>
                                    ),
                                  },
                                ]}
                              />
                            </div>
                          ) : (
                            <div className="flex w-[200px] items-center">
                              <div className="">{item.category} </div>
                              <img
                                className="w-2.5 cursor-pointer inline h-2.5 mb-2"
                                src="/images/edit.svg"
                                alt=""
                                onClick={() =>
                                  handleEditClick(item.id, "category")
                                }
                              />
                            </div>
                          )}
                        </td>
                        <td
                          ref={(el) => {
                            if (!item.subCategoryValid)
                              errorRefs.current.subCategory = el;
                          }}
                          className={` !w-[900px] ${
                            item.subCategoryValid
                              ? "bg-[#F9F9FB]"
                              : "bg-[#ffeded] text-[#f64108] font-[900]"
                          } py-4 pl-4 text-sx border-l-[#fff] border-[1px] border-[#E4EBF5] w-[300px] px-4`}
                        >
                          {editable.id === item.id &&
                          editable.field === "subCategory" ? (
                            <div className="absolute -mt-5">
                              <Dropdown
                                selectPlaceholderName="sub-categroy"
                                dropdownStateName={item.subCategory}
                                dropdownValue={item.subCategory}
                                search
                                searchSubject={searchSubCategory}
                                setSearchSubject={setSearchSubCategory}
                                dropdownContainerClasses={`shadow-cardShadow-4 !w-full !right-0  border border-slate-100  rounded `}
                                dropdownOptions={[
                                  {
                                    customChild: (
                                      <div>
                                        <div>
                                          {filteredSubCategories?.map(
                                            (
                                              subCategoryItem: any,
                                              idx: number
                                            ) => {
                                              return (
                                                <div
                                                  key={idx}
                                                  onClick={() => {
                                                    handleChange(
                                                      item.id,
                                                      "subCategory",
                                                      subCategoryItem.name
                                                    );

                                                    setFormErrors(
                                                      (prev: any) => ({
                                                        ...prev,
                                                        subCategory: "",
                                                      })
                                                    );
                                                  }}
                                                >
                                                  <div className="px-3 py-2 text-sm font-onestMedium text-[#4a564d] hover:bg-[#edecec] cursor-pointer">
                                                    <p>
                                                      {subCategoryItem.name}
                                                    </p>
                                                  </div>
                                                </div>
                                              );
                                            }
                                          )}
                                        </div>
                                      </div>
                                    ),
                                  },
                                ]}
                              />
                            </div>
                          ) : (
                            <div className="flex w-[200px] items-center">
                              <div className="">{item.subCategory} </div>
                              <img
                                className="w-2.5 cursor-pointer inline h-2.5 mb-2"
                                src="/images/edit.svg"
                                alt=""
                                onClick={() =>
                                  handleEditClick(item.id, "subCategory")
                                }
                              />
                            </div>
                          )}
                        </td>
                        <td
                          ref={(el) => {
                            if (!item.quantityValid)
                              errorRefs.current.quantity = el;
                          }}
                          className={` !w-[300px] whitespace-normal ${
                            item.quantityValid
                              ? "bg-[#F9F9FB]"
                              : "bg-[#ffeded] text-[#f64108]  h-full font-[600]"
                          } py-6 pl-4 flex gap-x-1.5 pr-4 border-r-[1px] border-[#E4EBF5]`}
                        >
                          {editable.id === item.id &&
                          editable.field === "quantity" ? (
                            <>
                              <input
                                name="quantity"
                                value={item.quantity}
                                onChange={(e) => {
                                  const value = Math.max(
                                    0,
                                    Number(e.target.value)
                                  );
                                  handleChange(item.id, "quantity", value);
                                }}
                                onBlur={handleBlur}
                                autoFocus
                                className="text-xs outline-none border-[1px] w-full border-[#E4EBF5] py-2 mr-1"
                              />
                              {formErrors.quantity && (
                                <span className="text-xs text-[#BA3838] font-onestMedium">
                                  {formErrors.quantity}
                                </span>
                              )}
                            </>
                          ) : (
                            <div className="flex gap-1">
                              <span className="text-xs break-all">
                                {item.quantity}
                              </span>
                              <img
                                className="w-2.5 h-2.5 cursor-pointer"
                                src="/images/edit.svg"
                                alt=""
                                onClick={() =>
                                  handleEditClick(item.id, "quantity")
                                }
                              />
                            </div>
                          )}
                        </td>
                        <td
                          ref={(el) => {
                            if (!item.expiryDateValid)
                              errorRefs.current.expiryDate = el;
                          }}
                          className={` !w-[900px] ${
                            item.expiryDateValid
                              ? "bg-[#F9F9FB]"
                              : "bg-[#ffeded] text-[#f64108] font-[900]"
                          } py-4 pl-4 text-sx border-l-[#fff] border-[1px] border-[#E4EBF5] w-[300px] px-4`}
                        >
                          {editable.id === item.id &&
                          editable.field === "expiryDate" ? (
                            <>
                              <input
                                type="date"
                                name="expiryDate"
                                value={item.expiryDate}
                                onChange={(e) =>
                                  handleChange(
                                    item.id,
                                    "expiryDate",
                                    e.target.value
                                  )
                                }
                                onBlur={handleBlur}
                                autoFocus
                                className="text-xs outline-none border-[1px] w-full border-[#E4EBF5] py-2 mr-1"
                              />
                              {formErrors.quantity && (
                                <span className="text-xs text-[#BA3838] font-onestMedium">
                                  {formErrors.quantity}
                                </span>
                              )}
                            </>
                          ) : (
                            <div className="flex w-[200px] items-center">
                              <div className="">{item.expiryDate} </div>
                              <img
                                className="w-2.5 cursor-pointer inline h-2.5 mb-2"
                                src="/images/edit.svg"
                                alt=""
                                onClick={() =>
                                  handleEditClick(item.id, "expiryDate")
                                }
                              />
                            </div>
                          )}
                        </td>

                        <td
                          className="py-4 sticky right-0 cursor-pointer pl-5 bg-white w-[100%] text-xs text-red-500 border-r-[#fff] border-l-[#fff] border-[1px] border-[#E4EBF5]"
                          onClick={() => {
                            handleDelete(item.id);
                            if (itemsBulk.length === 1 && idx === 0) {
                              setIsBulkStorage(false);
                            }
                          }}
                        >
                          {item.action}
                        </td>
                      </tr>
                    );
                  })}
                </tbody>
              </table>
            </div>
          </div>

          <div className="flex-col space-y-3  items-center left-[350px] right-0 px-5 shadow-faintShadow border-t justify-between  py-5 border-slate-100 bottom-[0px] w-full fixed z-[50]   bg-white">
            <div className="flex items-center space-x-6">
              <div className="w-fit">
                <Button
                  className="py-2.5 "
                  title="Add Items"
                  loading={isLoadingStorage}
                  disabled={isLoadingStorage || result.rowInvalidCount !== 0}
                  onClick={onAddStorageItem}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default BulkStorageTable;
