import React from "react";
import { useRoutes } from "react-router-dom";
import Routes from "./routes"; // Import the dynamic Routes component
import "./index.css";
import { ToastContainer } from "react-toastify";

function App() {
  const dynamicRoutes = Routes();
  const allRoutes = useRoutes(dynamicRoutes);

  return (
    <>
      <div className="w-full">{allRoutes}</div>
      <ToastContainer position="top-right" className="z-[9999]" />
    </>
  );
}

export default App;
