import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { getFromStorage } from "helpers";

interface Order {
  startDate: string;
  endDate: string;
  orderStatus?: string;
  page?: number;
  recipientPhone?: string;
  recipientEmail?: string;
  recipientName?: string;
  orderNo?: string;
  requestType?: string;
}
interface LockProps{
  lockerID: string,
}
interface DownloadProps {
  startDate:string,
  endDate:string,
  requestid:string,
  orderStatus:string
  requestType:string
}

type Props = Pick<DownloadProps, 'requestid'>;
export const shipNow = createApi({
  reducerPath: "shipNow",
  baseQuery: fetchBaseQuery({
    baseUrl: `${process.env.REACT_APP_URL}`,
    prepareHeaders: (headers) => {
      headers.set("Content-Type", "application/json");
      const token = getFromStorage("token");
      const secretKey = getFromStorage("secret-key");
      if (token) {
        headers.set("Authorization", `Bearer ${token}`);
        headers.set("secret-key", `${secretKey}`);
      }
      return headers;
      
    },
  }),
  tagTypes: ["Order"],
  endpoints: (builder) => ({
    extractOrder: builder.mutation({
      query: (body) => ({
        url: `orders/extract`,
        method: "POST",
        body,
      }),
      invalidatesTags: (result, error, arg) =>
        arg?.id ? [{ type: "Order", id: arg.id }] : [],
    }),
    updateReferral: builder.mutation({
      query: (body) => ({
        url: `orders/referrals-update`,
        method: "POST",
        body,
      }),
      invalidatesTags: (result, error, arg) =>
        arg?.id ? [{ type: "Order", id: arg.id }] : [],
    }),
    getAllOrders: builder.mutation<any, Order>({
      query: (body) => ({
        url: `/orders/clusterSearch`,
        method: "POST",
        body,
      }),
    }),
    getAllOrderStats: builder.mutation<any, Order>({
      query: (body) => ({
        url: `/orders/clusterSearch`,
        method: "POST",
        body,
      }),
    }),
    getAllLocalStats: builder.mutation<any, Order>({
      query: (body) => ({
        url: `/orders/clusterSearch`,
        method: "POST",
        body,
      }),
    }),
    getAllImportStats: builder.mutation<any, Order>({
      query: (body) => ({
        url: `/orders/clusterSearch`,
        method: "POST",
        body,
      }),
    }),
    getAllExportStats: builder.mutation<any, Order>({
      query: (body) => ({
        url: `/orders/clusterSearch`,
        method: "POST",
        body,
      }),
    }),
    getAllSafeLockerStats: builder.mutation<any, Order>({
      query: (body) => ({
        url: `/orders/clusterSearch`,
        method: "POST",
        body,
      }),
    }),
    editShipment: builder.mutation<any, any>({
      query: (body) => ({
        url: `order/multiple`,
        method: "PUT",
        body,
      }),
    }),
    getSingleOrder: builder.query<any, string>({
      query: (id) => ({
        url: `orders/${id}`,
        method: "GET",
      }),
    }),
    getCountry: builder.query<any, void>({
      query: () => ({
        url: `orders/all-import-locations`,
        method: 'GET',
      }),
    }),
   
    getExportCountry: builder.query<any, void>({
      query: () => ({
        url: `orders/export-locations`,
        method: 'GET',
      }),
    }),
    getItemCategory: builder.query<any, void>({
      query: () => ({
        url: `orders/item-categories`,
        method: 'GET',
      }),
    }),
    getOrderWeight: builder.query<any, any>({
      query: ({id}) => ({
        url: `orders/int/weights?intLocation=${id}`,
        method: 'GET',
      }),
    }),
    getSafeLockers: builder.query<any, void>({
      query: () => ({
        url: `Lockers/Lagos`,
        method: 'GET',
      }),
    }),
    order: builder.mutation({
      query: ({body, requestType}) => ({
        url: `order${requestType ? `?requestType=${requestType}` : ""}`,
        method: "POST",
        body,
      }),
      invalidatesTags: (result, error, arg) => [{ type: "Order", id: arg.id }],
    }),
    removePendingOrder: builder.mutation<any, any>({
      query: (body) => ({
        url: `orders/removePendingPayment`,
        method: "POST",
        body,
      }),
    }),
    getLockerAvailability: builder.query<any, LockProps>({
      query: ({lockerID}) => ({
        url: `LockerAvailability/${lockerID}`,
        method: 'GET',
      }
      ),
    }),
    downloadCreate: builder.mutation<any, DownloadProps>({
      query: (body) => ({
        url: `orders/download/create`,
        method: "POST",
        body,
      }),
    }),
    downloadStatus: builder.mutation<any, Props>({
      query: (body) => ({
        url: `orders/download/status`,
        method: "POST",
        body,
      }),
    }),
  }),
});

export const {
  useExtractOrderMutation,
  useOrderMutation,
  useRemovePendingOrderMutation,
  useUpdateReferralMutation,
  useGetAllOrdersMutation,
  useGetSingleOrderQuery,
  useEditShipmentMutation,
  useGetAllOrderStatsMutation,
  useGetAllExportStatsMutation,
  useGetAllImportStatsMutation,
  useGetAllLocalStatsMutation,
  useGetAllSafeLockerStatsMutation,
  useGetCountryQuery,
  useGetExportCountryQuery,
  useGetItemCategoryQuery,
  useGetOrderWeightQuery,
  useGetSafeLockersQuery,
  useGetLockerAvailabilityQuery,
  useDownloadCreateMutation,
  useDownloadStatusMutation,
} = shipNow;
