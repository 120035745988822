import { Button } from "components/Button";
import { TextInput } from "components/common/form/Input";
import { Loader } from "components/Loader";
import ModalContainer from "components/Modal";
import { formatMoney } from "helpers/formatMoney";
import { SlClose } from "react-icons/sl";
import { handleErrorToast } from "helpers/toastMessage";
import { useEffect, useState } from "react";
import { IoWarningOutline } from "react-icons/io5";
import { usePaystackPayment } from "react-paystack";
import { useNavigate, useParams } from "react-router-dom";
import {
  useGetInvoiceByDocNumberQuery,
  useGetInvoiceWalletCheckoutMutation,
} from "services/slices/invoice";
import { useGetNotificationsQuery } from "services/slices/notification";
import { useGetWalletBalanceQuery } from "services/slices/wallet";
import { generateRandomDataWithDelay } from "helpers/generateRandomNumber";
import { getFromStorage, saveToStorage } from "helpers";

const InvoicePayment = () => {
  const navigate = useNavigate();
  const orgEmail = getFromStorage("fez-org-email");
  const orgCode: any = getFromStorage("fez-org-code");
  const userData: any = getFromStorage("fez-user");
  const userId = JSON.parse(userData);
  const [formErrors, setFormErrors] = useState<any>({});
  const [addMoneySuccess, setAddMoneySuccess] = useState<boolean>(false);
  const [openPayModal, setOpenPayModal] = useState<boolean>(false);
  const [fetchNotifications, setFetchNotifications] = useState<boolean>(false);
  const { id } = useParams();
  const [amountAdded, setAmountAdded] = useState<any>();
  const [isModalPayment, setIsModalPayment] = useState<boolean>(false);
  const [amountToPay, setAmountToPay] = useState<number | string>("");
  const [reference, setReference] = useState<string>("");
  const [addMoney, setAddMoney] = useState<boolean>(false);
  const { data: walletData, isLoading: isWalletLoading } =
    useGetWalletBalanceQuery(undefined, {
      refetchOnMountOrArgChange: true,
      refetchOnFocus: true,
      refetchOnReconnect: true,
    });
  const { refetch: refetchNotification } = useGetNotificationsQuery(
    { id },
    { skip: !fetchNotifications }
  );
  const { data: invoiceData, isLoading: isInvoiceLoading } =
    useGetInvoiceByDocNumberQuery(
      {
        id: id || "",
      },
      {
        refetchOnMountOrArgChange: true,
        refetchOnFocus: true,
        refetchOnReconnect: true,
      }
    );

  const validateAmount = (value: string) => {
    let errors: any = {};
    if (!value || value === "") {
      errors.amount = "Amount is required";
    } else if (value !== "") {
      errors.amount = "";
    }
    return errors;
  };

  const [invoiceWalletCheckout, { isLoading: isInvoiceWalletLoading }] =
    useGetInvoiceWalletCheckoutMutation();

  const onSuccess = () => {
    setOpenPayModal(false);
    setIsModalPayment(true);
  };

  const onClose = () => {
    setIsModalPayment(false);
  };
  const onAddMoneyClose = () => {
    setAddMoney(false);
  };

  useEffect(() => {
    if (fetchNotifications) {
      refetchNotification();
    }
  }, [fetchNotifications, refetchNotification]);

  const Amount = parseFloat(Number(amountToPay).toFixed(2)) * 100;

  useEffect(() => {
    if (invoiceData?.data?.outstandingAmount !== undefined) {
      setAmountToPay(invoiceData.data.outstandingAmount);
    }
  }, [invoiceData]);
  const config = {
    reference: `${userId?.userID}_${reference}_INV_${orgCode}_${id}`,
    email:
      orgEmail === "null" || !orgEmail ? `${orgCode}@fezltd.com` : orgEmail,
    amount: Number(Math.round(Amount)),
    publicKey: `${process.env.REACT_APP_PAYSTACK_KEY}`,
  };
  const addMoneyConfig = {
    reference: `c_${orgCode}_${reference}${new Date().valueOf()}_BFW`,
    email:
      orgEmail === "null" || !orgEmail ? `${orgCode}@fezltd.com` : orgEmail,
    amount: Number(amountAdded) * 100,
    publicKey: `${process.env.REACT_APP_PAYSTACK_KEY}`,
  };
  const initializePayment = usePaystackPayment(config);
  const initializeAddMoney = usePaystackPayment(addMoneyConfig);
  
  const onPayCard = () => {
    initializePayment(onSuccess, onClose);
  };
  const onPayWithWallet = async () => {
    await invoiceWalletCheckout({
      paymentReference: `${userId?.userID}_${reference}_INVW_${orgCode}_${id}`,
      totalCost: Number(amountToPay),
      invoiceNumber: id || "",
    })
      .unwrap()
      .then((data) => {
        setOpenPayModal(false);
        setIsModalPayment(true);
      })
      .catch((error: { data: { description: string } }) => {
        handleErrorToast(error);
      });
  };

  const onAddMoneySuccess = () => {
    saveToStorage("amountAdded", amountAdded);
    setAddMoney(false);
    setAddMoneySuccess(true);
    setFetchNotifications(true);

    setTimeout(() => {
      setFetchNotifications(false);
    }, 60000);
  };

  const onAddMoney = () => {
    setFormErrors(validateAmount(amountAdded));
    if (amountAdded !== "") {
      initializeAddMoney(onAddMoneySuccess, onAddMoneyClose);
    }
  };

  return (
    <div className="flex justify-center md:px-0 px-5 w-full mt-20">
      <div className="border-[1px] w-[598px] py-10 border-[#E1F0DF] md:pt-8 px-5 md:px-8 bg-dashboard-gradient rounded-lg">
        <div
          onClick={() => navigate(-1)}
          className="flex gap-1 cursor-pointer w-[100px]"
        >
          <div>
            {" "}
            <img className="w-3 h-3" src="/images/backArrow.svg" alt="" />
          </div>
          <p className="text-[#4a564d] font-[500] text-[13px] -mt-1 font-onestMedium">
            Go back
          </p>
        </div>

        <h1 className="text-2xl mt-10 font-onestBold font-[800] text-[#0a5001] tracking-wide">
          Make Payment
        </h1>
        <p className=" text-[#4a564d] mt-0.5 font-onestMedium text-sm font-[500]">
          Invoice ID: {id}
        </p>
        {isInvoiceLoading ? (
          <div className="mt-10">
            <Loader />
          </div>
        ) : (
          <>
            <div className="bg-[#f3fbf2] mt-12  rounded-lg px-4 py-6 border-[1px] border-[#e1f0df]">
              <div className="flex justify-between items-center">
                <h4 className="text-[#0e1728] font-[500] w-full md:w-[20%] font-onestMedium text-sm">
                  Wallet Balance
                </h4>
                <div className="hidden md:block w-[45%] border-b-[1px] border-dashed border-[#b8c2cf]"></div>
                <div>
                  {isWalletLoading ? (
                    <Loader />
                  ) : (
                    <h1
                      className={`font-[800] text-lg font-onestBold text-[#0a5001]`}
                    >
                      {formatMoney().format(
                        walletData?.balance?.business_wallet +
                          Number(getFromStorage("amountAdded"))
                      )}
                    </h1>
                  )}
                </div>
              </div>
              <div className="flex justify-between items-center mt-5">
                <h4 className="text-[#0e1728] font-[500] w-full md:w-[20%] font-onestMedium text-sm">
                  No of orders
                </h4>
                <div className="hidden md:block w-[45%] border-b-[1px] -ml-[15%] border-dashed border-[#b8c2cf]"></div>
                <h1 className="font-[800] text-lg font-onestBold text-[#0a5001]">
                  {formatMoney().format(invoiceData?.data?.orderCount) || 0}
                </h1>
              </div>
              <div className="flex justify-between items-center mt-5">
                <h4 className="text-[#0e1728] font-[500] w-full md:w-[20%] font-onestMedium text-sm">
                  Amount due
                </h4>
                <div className="hidden md:block w-[50%] border-b-[1px] -ml-[10%] border-dashed border-[#b8c2cf]"></div>
                <h1 className="font-[800] text-lg font-onestBold text-[#0a5001]">
                  ₦
                  {formatMoney().format(invoiceData?.data?.outstandingAmount) ||
                    0}
                </h1>
              </div>
            </div>
            {invoiceData?.data?.outstandingAmount >=
              walletData?.balance?.business_wallet && (
              <div className="pb-3">
                <div className="flex gap-1 border-b-[1px] mt-3 p-2 border-[#F59A49] bg-[#FAEFE5] rounded-[10px]">
                  <div className="">
                    {" "}
                    <IoWarningOutline color="#F59A49" />
                  </div>
                  <h1 className="text-xs font-onestRegular font-[400] text-[#0E1728]">
                    Your wallet balance is insufficient, please add money
                  </h1>
                </div>
                <Button
                  onClick={() => {
                    setAddMoney(true);
                  }}
                  title="Add Money"
                  className="!w-[150px] py-4 mt-5 !bg-[#F3FBF2] border-[1px] border-[#18af04] !text-[#18af04]"
                />
              </div>
            )}
            <div className="flex-col mt-6 space-y-3 md:space-y-0 md:flex md:flex-row justify-between md:mt-[40px] ">
              <Button
                title="Pay"
                loading={false}
                onClick={() => {
                  const response = generateRandomDataWithDelay();
                  if (response) {
                    setReference(response);
                    setOpenPayModal(true);
                  }
                }}
                className="!w-[100px]  py-2.5 "
              />
            </div>
          </>
        )}
      </div>
      <ModalContainer
        open={isModalPayment}
        showCloseIcon={false}
        tailwindClassName="w-[350px] sm:w-[466px]"
        closeModal={() => setIsModalPayment(false)}
      >
        <div className="w-full px-8 pb-8 bg-white flex flex-col  rounded-[12px] relative">
          <p className="text-lg pb-3 mt-6 border-b border-[#E4EBF5] text-center font-onestBold text-[#0A5001] ">
            Yippee!! 💃🏾
          </p>
          <img
            className="w-6 h-6 absolute right-6 top-6 cursor-pointer"
            onClick={() => {
              navigate("/dashboard/invoice");
            }}
            src="/images/close.svg"
            alt=""
          />
          <div className="flex justify-center mt-8">
            {" "}
            <img
              src="/images/fezbox.svg"
              alt=""
              className="w-[123px] h-[98px]"
            />
          </div>
          <p className="text-center text-[#4A564D] text-sm font-onestRegular mt-8">
            We have received your payment for this invoice
          </p>
          <div className="flex justify-center mt-14">
            <Button
              title="View invoice"
              onClick={() => {
                navigate("/dashboard/invoice");
              }}
              className="!w-[210px] py-4 bg-[#18AF04] rounded-lg !font-[500] !text-base"
            />
          </div>
        </div>
      </ModalContainer>
      <ModalContainer
        open={addMoney}
        showCloseIcon={false}
        tailwindClassName="w-[350px] sm:w-[520px]"
      >
        <div className="w-full px-8 pb-8 bg-white flex flex-col  rounded-[12px]">
          <p className="text-lg pb-3 mt-6 border-b border-[#E4EBF5] font-onestBold text-[#0A5001] ">
            Add money
          </p>
          <div className="mt-6">
            <TextInput
              type="number"
              label="Amount"
              value={amountAdded}
              onChange={(e: any) => {
                if (e.target.value !== "") {
                  setFormErrors((prev: any) => ({
                    ...prev,
                    amount: "",
                  }));
                }
                if (!isNaN(e.target.value)) {
                  setAmountAdded(e.target.value);
                }
              }}
              placeholder="e.g 100"
            />
            {formErrors.amount && (
              <span className="text-xs text-[#BA3838] font-onestMedium">
                {formErrors.amount}
              </span>
            )}
          </div>
          <div className="flex justify-between mt-14">
            <Button
              onClick={() => {
                setAddMoney(false);
                setAmountAdded(null);
              }}
              title="Cancel"
              className="!w-[100px] py-4 !bg-[#fff] border-[1px] border-[#E4EBF5] !text-base rounded-full !text-[#18af04] !font-[500]"
            />
            <Button
              title="Add Money"
              onClick={onAddMoney}
              className="!w-[170px] py-4 bg-[#18AF04] rounded-full !font-[500] !text-base"
            />
          </div>
        </div>
      </ModalContainer>
      <ModalContainer
        open={addMoneySuccess}
        showCloseIcon={false}
        tailwindClassName="w-[350px] sm:w-[466px]"
        closeModal={() => setAddMoneySuccess(false)}
      >
        <div className="w-full px-8 pb-8 bg-white flex flex-col  rounded-[12px] relative">
          <p className="text-lg pb-3 mt-6 border-b border-[#E4EBF5] text-center font-onestBold text-[#0A5001] ">
            Yippee!! 💃🏾
          </p>
          <img
            className="w-6 h-6 absolute right-6 top-6 cursor-pointer"
            onClick={() => {
              setAmountAdded(null);
              setAddMoneySuccess(false);
            }}
            src="/images/close.svg"
            alt=""
          />
          <div className="flex justify-center mt-8">
            {" "}
            <img
              src="/images/fezbox.svg"
              alt=""
              className="w-[123px] h-[98px]"
            />
          </div>
          <p className="text-center text-[#4A564D] text-sm font-onestRegular mt-8">
            Money has been added to your wallet
          </p>
          <div className="flex justify-center mt-14">
            <Button
              title="Close"
              onClick={() => {
                setAmountAdded(null);
                setAddMoneySuccess(false);
              }}
              className="!w-[210px] py-3 bg-[#18AF04] rounded-lg !font-[500] !text-base"
            />
          </div>
        </div>
      </ModalContainer>
      <ModalContainer
        open={openPayModal}
        showCloseIcon={false}
        tailwindClassName="w-[350px] sm:w-[520px]"
      >
        <div className="w-full px-8 pb-8 bg-white flex flex-col  rounded-[12px]">
          <div className="flex justify-between pb-3 mt-6 border-b border-[#E4EBF5]">
            <p className="text-lg  font-onestBold text-[#0A5001] ">
              Make Payment
            </p>
            <span
              className="cursor-pointer"
              onClick={() => {
                setAmountToPay(invoiceData?.data?.outstandingAmount);
                setOpenPayModal(false);
              }}
            >
              <SlClose />
            </span>
          </div>
          <div className="mt-6">
            <TextInput
              type="number"
              label="Amount"
              value={amountToPay}
              onChange={(e: any) => {
                if (e.target.value !== "") {
                  setFormErrors((prev: any) => ({
                    ...prev,
                    amount: "",
                  }));
                }

                if (
                  !isNaN(e.target.value) &&
                  Number(e.target.value) <=
                    Number(invoiceData.data.outstandingAmount)
                ) {
                  setAmountToPay(e.target.value);
                }
              }}
              placeholder="e.g 100"
            />
            {formErrors.amount && (
              <span className="text-xs text-[#BA3838] font-onestMedium">
                {formErrors.amount}
              </span>
            )}
          </div>
          <div className="flex justify-between mt-14">
            <Button
              title="Pay with Card"
              loading={false}
              disabled={isInvoiceWalletLoading}
              onClick={() => {
                onPayCard();
              }}
              className="w-full  py-2.5 "
            />
            <Button
              title="Pay with Wallet"
              loading={isInvoiceWalletLoading}
              disabled={isInvoiceWalletLoading || invoiceData?.data?.outstandingAmount >=
                walletData?.balance?.business_wallet }
              onClick={() => {
                onPayWithWallet();
              }}
              className="w-full !bg-[#fff] border-[1px] border-[#18af04] !text-[#18af04]  py-2.5 mb-4 md:mb-0"
            />
          </div>
        </div>
      </ModalContainer>
    </div>
  );
};

export default InvoicePayment;
