import { Button } from "components/Button";
import { Loader } from "components/Loader";
import ModalContainer from "components/Modal";
import { TextInput } from "components/common/form/Input";
import { formatMoney } from "helpers/formatMoney";
import moment from "moment";
import DatePicker from "react-datepicker";
import React, { useEffect, useState } from "react";
import { FaCircle, FaMinus, FaPlus } from "react-icons/fa6";
import { usePaystackPayment } from "react-paystack";
import {
  useGetWalletBalanceQuery,
  useGetWalletTransactionQuery,
} from "services/slices/wallet";
import { truncate } from "lodash";
import PagePagination from "components/Pagination";
import { useGetUnReadNotificationsQuery } from "services/slices/notification";
import { filterTerms } from "constant/data";
import { getDateRange } from "helpers/getDateRange";
import { generateRandomDataWithDelay } from "helpers/generateRandomNumber";
import { getFromStorage, removeFromStorage, saveToStorage } from "helpers";

function Wallet() {
  const [filterDate, setFilterDate] = useState<boolean>(false);
  const [isModalOpen, setModalOpen] = useState<boolean>(false);
  const [isFundingModal, setFundingModal] = useState<boolean>(false);
  const [amountAdded, setAmountAdded] = useState("");
  const [startDate, setStartDate] = useState<Date | null>(null);
  const [endDate, setEndDate] = useState<Date | null>(null);
  const [showDateModal, setShowDateModal] = useState<boolean>(false);
  const [fetchNotifications, setFetchNotifications] = useState<boolean>(false);
  const [selectedPeriod, setSelectedPeriod] = useState("last7days");
  const [selectedPeriodName, setSelectedPeriodName] = useState("Last 7 days");
  const [moneyReference, setMoneyReference] = useState<string>("");
  const [formattedDate, setFormattedDate] = useState<string>("");
  const [formattedEndDate, setFormattedEndDate] = useState<string>("");
  const [walletEndDate, setWalletEndDate] = useState<string>(
    moment().add(1, "days").format("YYYY-MM-DD")
  );
  const [walletStartDate, setWalletStartDate] = useState<string>(
    moment().subtract(7, "days").format("YYYY-MM-DD")
  );
  const orgCode: any = getFromStorage("fez-org-code");
  const userData: any = getFromStorage("fez-user");
  const [formErrors, setFormErrors] = useState<any>({});
  const [page, setPage] = useState<number>(0);
  const orgEmail = getFromStorage("fez-org-email");
  const { data: walletData, isLoading: isWalletLoading } =
    useGetWalletBalanceQuery(undefined, {
      refetchOnMountOrArgChange: true,
      refetchOnFocus: true,
      refetchOnReconnect: true,
    });
  const id = userData?.userID;
  const { refetch: refetchNotification } = useGetUnReadNotificationsQuery(
    {
      id,
    },
    { skip: !fetchNotifications }
  );
  const handleDateChange = (date: Date | null) => {
    if (date) {
      const dateStart = moment(date).format("DD-MM-YYYY");
      setStartDate(date);
      setFormattedDate(dateStart);
    }
  };

  useEffect(() => {
    removeFromStorage("amountAdded");
  }, []);

  const handleEndDateChange = (date: Date | null) => {
    if (date) {
      const dateStart = moment(date).format("DD-MM-YYYY");
      setEndDate(date);
      setFormattedEndDate(dateStart);
    }
  };

  const onClearDate = () => {
    setSelectedPeriodName("Last 7 days");
    setSelectedPeriod("last7days");
    setShowDateModal(false);
  };
  const onApplyDate = () => {
    setSelectedPeriodName(`${formattedDate} - ${formattedEndDate}`);
    setWalletStartDate(formattedDate);
    setWalletEndDate(formattedEndDate);
    setShowDateModal(false);
  };

  const validateStepOne = (value: string) => {
    let errors: any = {};
    if (!value || value === "") {
      errors.amount = "Amount is required";
    } else if (value !== "") {
      errors.amount = "";
    }
    return errors;
  };

  useEffect(() => {
    if (Boolean(selectedPeriod.toLowerCase() === "custom")) {
      setShowDateModal(true);
    } else {
      setShowDateModal(false);
    }
  }, [selectedPeriod]);

  const handlePeriodChange = (value: string) => {
    setSelectedPeriod(value);
  };

  useEffect(() => {
    if (selectedPeriod && selectedPeriod.toLowerCase() !== "custom") {
      const { startDate, endDate } = getDateRange(selectedPeriod);
      setWalletStartDate(startDate);
      setWalletEndDate(endDate);
    }
  }, [selectedPeriod]);

  const { data, isLoading } = useGetWalletTransactionQuery(
    {
      startDate: walletStartDate,
      endDate: walletEndDate,
      page: page + 1,
    },
    {
      refetchOnMountOrArgChange: true,
      refetchOnFocus: true,
      refetchOnReconnect: true,
    }
  );

  useEffect(() => {
    if (fetchNotifications) {
      refetchNotification();
      setFetchNotifications(false);
    }
  }, [fetchNotifications, refetchNotification]);

  const onGenerate = () => {
    const response = generateRandomDataWithDelay();
    if (orgCode) {
      setMoneyReference(`c_${orgCode}_${response}${new Date().valueOf()}_BFW`);
    }
  };

  const addMoneyConfig = {
    reference: moneyReference,
    email:
      orgEmail === "null" || !orgEmail ? `${orgCode}@fezltd.com` : orgEmail,
    amount: Number(amountAdded) * 100,
    publicKey: `${process.env.REACT_APP_PAYSTACK_KEY}`,
  };
  const initializeAddMoney = usePaystackPayment(addMoneyConfig);

  const onAddMoney = () => {
    setFormErrors(validateStepOne(amountAdded));
    if (amountAdded !== "") {
      initializeAddMoney(onAddMoneySuccess, onAddMoneyClose);
    }
  };
  const onAddMoneySuccess = () => {
    saveToStorage("amountAdded", amountAdded);
    setModalOpen(false);
    setFundingModal(true);
    setFetchNotifications(true);
  };
  const onAddMoneyClose = () => {
    onGenerate();
  };

  return (
    <div>
      <div className="mt-[33px] sm:mt-[30px] rounded-lg max-w-[696px] sm:px-5 pb-6 mb-5 mx-4 sm:mx-auto">
        <h1 className="text-[#0A5001] font-[900] text-xl md:text-2xl mb-2 font-onestBold">
          Wallet
        </h1>
        <div className="bmd:mt-7 bg-[url('/public/images/wallet-bg.svg')]  bg-no-repeat bmd:bg-contain bg-right bmd:bg-center w-full sm:mt-[30px] rounded-lg max-w-[696px] px-3 bmd:px-8 bmd:py-6 sm:mx-auto">
          <div className="flex-col justify-between py-4 w-full items-center z-50 bmd:flex bmd:flex-row">
            <div className="mt-0 pt-4 bmd:pt-0 bmd:mt-0">
              <p className="text-sm font-onestMedium font-[500]">
                Your Wallet Balance
              </p>
              {isWalletLoading ? (
                <div>
                  <Loader />
                </div>
              ) : (
                <h1 className="text-[#0A5001] font-[900] bmd:mt-3 text-xl bmd:text-3xl mb-2 font-onestBold">
                  ₦
                  {formatMoney().format(
                    walletData?.balance?.business_wallet +
                      Number(getFromStorage("amountAdded"))
                  )}
                </h1>
              )}
            </div>
            <div className="mb-6 bmd:mb-0">
              <Button
                onClick={() => {
                  onGenerate();
                  setModalOpen(true);
                }}
                className={`!w-[120px] py-2.5 !bg-[#8C9D8A]"`}
                title="Add money"
              />
            </div>
          </div>
        </div>
        <div className="">
          <div className="flex flex-col md:flex-row justify-between items-center mt-0 md:mt-[50px]">
            <p className="text-[#4A564D] font-onestBold text-[13px] font-[700]">
              Funding history
            </p>
            <div className="flex flex-row items-center gap-4 mt-5 md:mt-0">
              <div
                onClick={() => setFilterDate(!filterDate)}
                className={`cursor-pointer min-w-[180px]  relative flex gap-2 items-center rounded-[9px] pl-4 pr-4 border-[1px] border-[#E4EBF5] `}
              >
                <img
                  className="w-[14px] h-[14px]"
                  src="/images/filter.svg"
                  alt="search-icon"
                />
                <p className="text-[13px] whitespace-nowrap text-[#929DAE]  py-[10px] outline-none">
                  Filter: {selectedPeriodName}
                </p>
                <img
                  className="w-[14px] rounded-xl cursor-pointer h-[14px]"
                  src="/images/circle-close.svg"
                  alt="close icon"
                  onClick={(e) => {
                    e.stopPropagation();
                    onClearDate();
                  }}
                />
                {filterDate && (
                  <div className="p-4 border-[1px] w-[190px] right-3 top-11 bg-white z-10 absolute rounded-lg border-[#E4EBF5]">
                    <h1 className="text-[#929DAE] pb-3 font-[900] text-xs font-onestBold">
                      PERIOD
                    </h1>
                    <form className="">
                      {filterTerms.map((terms) => (
                        <div
                          className="group flex my-3 gap-2.5 hover:bg-[#D4F3D5] text-[#289D17] hover:py-2 rounded-lg pl-2"
                          onClick={() => {
                            setSelectedPeriodName(terms.name);
                            handlePeriodChange(terms.value);
                          }}
                        >
                          <p className="flex items-center mb-0 cursor-pointer ">
                            <span
                              className={`${
                                selectedPeriod === terms.value && "bg-[#289D17]"
                              } w-4 h-4 border border-gray-300 rounded-full flex items-center justify-center group-hover:bg-[#289D17]`}
                            >
                              <span className="w-2 h-2 bg-white rounded-full"></span>
                            </span>
                            <span
                              className={`${
                                selectedPeriod === terms.value
                                  ? "text-[#289D17]"
                                  : "text-[#464748] group-hover:text-[#289D17]"
                              } ml-3 text-sm  `}
                            >
                              {terms.name}
                            </span>
                          </p>
                        </div>
                      ))}
                    </form>
                  </div>
                )}
              </div>
            </div>
          </div>
          <div className="mt-3 md:mt-6 p-2.5 bg-[#F9F9FB]">
            {isLoading ? (
              <div className="mt-5">
                <Loader />
              </div>
            ) : (
              <>
                {data?.transactions?.data?.length > 0 ? (
                  <>
                    {data?.transactions?.data?.map((item: any) => {
                      return (
                        <div
                          key={item?.id}
                          className="flex gap-[22px] items-center p-3 rounded-lg my-2 w-full bg-white"
                        >
                          <div className="ml-0 md:ml-3 bg-[#EEEEEE] rounded-full flex justify-center items-center h-8 w-8">
                            {item?.type.toLowerCase() !== "debit" ? (
                              <FaPlus color={"#18AF04"} />
                            ) : (
                              <FaMinus color="#F64108" />
                            )}{" "}
                          </div>
                          <div className="w-[95%] flex justify-between">
                            <div className="">
                              <h1 className="text-[#071227] font-onestMedium font-[600]">
                                {formatMoney().format(item?.amount)}
                              </h1>
                              <p className="flex items-center font-onestRegular text-[#686868] text-[13px]">
                                <div
                                  className={`${
                                    item?.type.toLowerCase() !== "debit"
                                      ? ""
                                      : "text-[#18AF04]"
                                  }`}
                                >
                                  <p className="md:block hidden">
                                    {" "}
                                    {item?.paymentReference}
                                  </p>
                                  <p className="md:hidden block">
                                    {" "}
                                    {truncate(item?.paymentReference, {
                                      length: 20,
                                    })}{" "}
                                  </p>
                                </div>
                                <FaCircle
                                  color="#d1d1d2"
                                  size={6}
                                  className="bg-[#d1d1d2] mx-2 rounded-lg"
                                />{" "}
                                {item?.type.charAt(0).toUpperCase() +
                                  item?.type?.slice(1).toLowerCase()}
                              </p>
                            </div>
                            <p className="text-[#686868] hidden md:block font-onestRegular text-[13px]">
                              {moment(item?.created_at).format(
                                "D MMM YYYY | h:mm A"
                              )}
                            </p>
                            <p className="text-[#686868] md:hidden font-onestRegular text-[13px]">
                              {moment(item?.created_at).format("DD/MM/YY")}
                            </p>
                          </div>
                        </div>
                      );
                    })}

                    {data?.transactions?.data?.length > 0 &&
                      data?.transactions?.total > data?.transactions?.per_page && (
                        <PagePagination
                          page={page}
                          handlePageClick={(event) => setPage(event.selected)}
                          totalPages={data?.transactions?.last_page}
                        />
                      )}
                  </>
                ) : (
                  <div className="py-5 text-center">
                    <p>No transaction yet!!</p>
                  </div>
                )}
              </>
            )}
          </div>
        </div>
      </div>
      <ModalContainer
        open={isModalOpen}
        showCloseIcon={false}
        tailwindClassName="w-[350px] sm:w-[402px]"
      >
        <div className="w-full p-8 bg-white flex flex-col  rounded-[12px]">
          <p className="text-lg pb-4 border-b border-[#E4EBF5] font-onestBold text-[#0A5001] ">
            Add Money
          </p>

          <div className="bg-[#F9F9FB] border-[1px] rounded-lg my-8 border-[#E4EBF5] px-[20px] py-[14px]">
            <p className="text-[13px] text-[#4A564D] bg-[#F9F9FB] font-onestRegular">
              Available wallet balance
            </p>
            <h1 className="text-lg font-onestBold text-[#18AF04] ">
              ₦
              {formatMoney().format(
                walletData?.balance?.business_wallet +
                  Number(getFromStorage("amountAdded"))
              )}
            </h1>
          </div>
          <div className="">
            <TextInput
              type="number"
              name="amount"
              value={amountAdded}
              onChange={(e: any) => {
                if (e.target.value !== "") {
                  setFormErrors((prev: any) => ({
                    ...prev,
                    amount: "",
                  }));
                }
                setAmountAdded(e.target.value);
              }}
              label="How much do you want to add?"
              placeholder="Enter amount"
            />
            {formErrors.amount && (
              <span className="text-xs text-[#BA3838] font-onestMedium">
                {formErrors.amount}
              </span>
            )}
          </div>

          <div className="flex justify-between items-center pt-[54px]">
            <Button
              title="Cancel"
              onClick={() => {
                setAmountAdded("");
                setModalOpen(false);
                
              }}
              className="!py-[10px] !border-[#BEC0BE] !px-6 !w-fit !rounded-[100px] !bg-transparent !border !text-[#289D17]"
            />
            <Button
              onClick={onAddMoney}
              title="Continue"
              className="!py-[10px] !w-fit !rounded-[100px] !px-6 !bg-[#18AF04] !text-[#fff]"
            />
          </div>
        </div>
      </ModalContainer>
      <ModalContainer
        open={isFundingModal}
        showCloseIcon={false}
        tailwindClassName="w-[350px] sm:w-[466px]"
        closeModal={() => setFundingModal(false)}
      >
        <div className="w-full p-8 bg-white flex flex-col  rounded-[12px]">
          <div className="flex justify-between pb-4 border-b border-[#E4EBF5]">
            <p className="text-lg  font-onestBold text-[#0A5001] ">
              Funding Successful
            </p>
            <div onClick={() => setFundingModal(false)}>
              <img src="/images/close.svg" alt="" />
            </div>
          </div>
          <div className="mt-8 flex justify-center">
            <img src="/images/funds.svg" alt="" />
          </div>
          <p className="text-center text-[#4A564D] font-onestMedium text-sm font-[500] mt-8">
            Your wallet has been funded with ₦
            {formatMoney().format(amountAdded)}
          </p>

          <div className="flex justify-center pt-[54px]">
            <Button
              onClick={() => {
                setFundingModal(false);
                setAmountAdded("");
              }}
              title="Close"
              className="!py-[10px] !w-fit !rounded-lg !px-10 !bg-[#18AF04] !text-[#fff]"
            />
          </div>
        </div>
      </ModalContainer>
      <ModalContainer
        open={showDateModal}
        showCloseIcon={false}
        tailwindClassName="max-w-[466px]"
      >
        <div className="w-full p-8 bg-white flex flex-col  rounded-[12px]">
          <p className="text-lg text-center pb-4 font-onestBold text-[#0A5001] ">
            Custom date range
          </p>
          <div className="flex gap-3 justify-between">
            <div className="">
              <DatePicker
                placeholderText={"Start date"}
                selected={startDate}
                onChange={handleDateChange}
                className="border-[1px] border-[#E4EBF5] w-[150px] p-2.5 rounded-lg outline-none"
              />
            </div>
            <div className="">
              <DatePicker
                placeholderText={"End date"}
                selected={endDate}
                onChange={handleEndDateChange}
                className="border-[1px] border-[#E4EBF5] w-[150px] p-2.5 rounded-lg outline-none"
              />
            </div>
          </div>
          <div className="flex justify-between gap-3 items-center pt-[30px]">
            <Button
              title="Clear"
              onClick={onClearDate}
              className="!py-[10px] !border-[#BEC0BE] !px-12 !w-fit !rounded-lg !bg-transparent !border !text-[#289D17]"
            />
            <Button
              title="Apply"
              className="!py-[10px] !w-fit !rounded-lg !px-12 !bg-[#127E04] !text-[#fff]"
              onClick={onApplyDate}
            />
          </div>
        </div>
      </ModalContainer>
    </div>
  );
}

export default Wallet;
