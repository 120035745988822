import { DropDownSelect } from "components/common/form/Dropdown";
import { TextInput } from "components/common/form/Input";
import Dropdown from "components/common/form/SelectDropdown";
import { TextArea } from "components/common/form/textArea";
import ToggleButton from "components/ToggleButton";
import { ExportFormProps } from "type";

const ExportDeliveryForm = ({
  setFormErrors,
  formErrors,
  deliveryDetails,
  setDeliveryDetails,
  handleDeliveryChange,
  searchCountryState,
  setSearchCountryState,
  exportCountryStateName,
  isExportCountryLoading,
  filteredExportCountrySearch,
  exportPickUpStateName,
  filteredStateSearch,
  setExportCountryStateName,
  setExportPickUpStateName,
  isWeightLoading,
  weightArray,
  setWeightIsOpen,
  weightIsOpen,
  setSelectedWeightOption,
  selectedWeightOption,
  isLoadingState,
  searchState,
  setSearchState,
}: ExportFormProps) => {
  return (
    <>
      <div className="mt-6">
        <div className="flex">
          <p className="text-[#4A564D] mb-2 text-sm leading-[20px] font-onestMedium">
            Select Drop-off country
          </p>
          <span className="text-red-500 text-sm">*</span>
        </div>
        <Dropdown
          selectPlaceholderName="country"
          dropdownStateName={exportCountryStateName}
          dropdownValue={deliveryDetails?.exportCountry}
          searchSubject={searchCountryState}
          setSearchSubject={setSearchCountryState}
          search
          loader={isExportCountryLoading}
          dropdownContainerClasses={`shadow-cardShadow-4 !w-full !right-0  border border-slate-100  rounded `}
          dropdownOptions={[
            {
              customChild: (
                <div>
                  <div>
                    {filteredExportCountrySearch?.map(
                      (item: any, idx: number) => {
                        return (
                          <div
                            key={idx}
                            onClick={() => {
                              setSearchCountryState("");
                              setDeliveryDetails((prev: any) => ({
                                ...prev,
                                exportCountry: item.id,
                              }));
                              setExportCountryStateName(item?.name);
                              setFormErrors((prev: any) => ({
                                ...prev,
                                exportCountry: "",
                              }));
                            }}
                          >
                            <div className="px-3 py-2  text-sm font-onestMedium text-[#4a564d] hover:bg-[#edecec] cursor-pointer">
                              <p>{item?.name}</p>
                            </div>
                          </div>
                        );
                      }
                    )}
                  </div>
                </div>
              ),
            },
          ]}
        />
        {formErrors.exportCountry && (
          <span className="text-xs text-[#BA3838] font-onestMedium">
            {formErrors.exportCountry}
          </span>
        )}
      </div>

      <div className="mt-6">
        <TextInput
          type="text"
          label="Drop-off address"
          isRequired
          placeholder="Enter address"
          name="exportDropOffAddress"
          onChange={handleDeliveryChange}
          value={deliveryDetails.exportDropOffAddress}
        />
        {formErrors.exportDropOffAddress && (
          <span className="text-xs text-[#BA3838] font-onestMedium">
            {formErrors.exportDropOffAddress}
          </span>
        )}
      </div>
      <div className="grid md:grid-cols-2 gap-x-3">
        <div className="mt-6">
          <TextInput
            type="text"
            label="Drop-off city"
            isRequired
            placeholder="Select a city"
            name="exportDropOffCity"
            onChange={handleDeliveryChange}
            value={deliveryDetails.exportDropOffCity}
          />
          {formErrors.exportDropOffCity && (
            <span className="text-xs text-[#BA3838] font-onestMedium">
              {formErrors.exportDropOffCity}
            </span>
          )}
        </div>
        <div className="mt-6">
          <TextInput
            type="text"
            label="Drop-off zip code"
            isRequired
            placeholder="Enter zip code"
            name="exportZipCode"
            onChange={handleDeliveryChange}
            value={deliveryDetails.exportZipCode}
          />
          {formErrors.exportZipCode && (
            <span className="text-xs text-[#BA3838] font-onestMedium">
              {formErrors.exportZipCode}
            </span>
          )}
        </div>
      </div>
      <div className="mt-6">
        <TextInput
          type="text"
          label="Pick-up address"
          isRequired
          placeholder="Enter pick-up address"
          name="exportPickUpAddress"
          onChange={handleDeliveryChange}
          value={deliveryDetails.exportPickUpAddress}
        />
        {formErrors.exportPickUpAddress && (
          <span className="text-xs text-[#BA3838] font-onestMedium">
            {formErrors.exportPickUpAddress}
          </span>
        )}
      </div>

    

      <div className="mt-6">
        <div className="flex">
          <p className="text-[#4A564D] mb-2 text-sm leading-[20px] font-onestMedium">
            Pick-up State
          </p>
          <span className="text-red-500 text-sm">*</span>
        </div>
        <Dropdown
          loader={isLoadingState}
          selectPlaceholderName="state"
          dropdownStateName={exportPickUpStateName}
          dropdownValue={deliveryDetails?.exportPickUpState}
          searchSubject={searchState}
          setSearchSubject={setSearchState}
          search
          dropdownContainerClasses={`shadow-cardShadow-4 !w-full !right-0  border border-slate-100  rounded `}
          dropdownOptions={[
            {
              customChild: (
                <div>
                  <div>
                    {filteredStateSearch?.map((item: any, idx: number) => {
                      return (
                        <div
                          key={idx}
                          onClick={() => {
                            setDeliveryDetails((prev: any) => ({
                              ...prev,
                              exportPickUpState: item.state,
                            }));
                            setSearchState("");
                            setExportPickUpStateName(item?.state);
                            setFormErrors((prev: any) => ({
                              ...prev,
                              exportPickUpState: "",
                            }));
                          }}
                        >
                          <div className="px-3 py-2 text-sm font-onestMedium text-[#4a564d] hover:bg-[#edecec] cursor-pointer">
                            <p>{item?.state}</p>
                          </div>
                        </div>
                      );
                    })}
                  </div>
                </div>
              ),
            },
          ]}
        />
        {formErrors.exportPickUpState && (
          <span className="text-xs text-[#BA3838] font-onestMedium">
            {formErrors.exportPickUpState}
          </span>
        )}
      </div>

      <div className="mt-6">
        <TextInput
          type="number"
          label="Pick-up phone number"
          isRequired
          placeholder="Enter phone number"
          name="exportPickUpPhoneNumber"
          onChange={handleDeliveryChange}
          value={deliveryDetails.exportPickUpPhoneNumber}
        />
        {formErrors.exportPickUpPhoneNumber && (
          <span className="text-xs text-[#BA3838] font-onestMedium">
            {formErrors.exportPickUpPhoneNumber}
          </span>
        )}
      </div>
      <div className="mt-6">
        <TextInput
          type="text"
          label="Recipient name"
          isRequired
          placeholder="Enter name"
          name="exportRecipientName"
          onChange={handleDeliveryChange}
          value={deliveryDetails.exportRecipientName}
        />
        {formErrors.exportRecipientName && (
          <span className="text-xs text-[#BA3838] font-onestMedium">
            {formErrors.exportRecipientName}
          </span>
        )}
      </div>
      <div className="grid md:grid-cols-2 gap-x-3">
        <div className="mt-6">
          <TextInput
            type="text"
            label="Recipient phone number"
            isRequired
            placeholder="Enter phone number"
            name="exportPhoneNumber"
            onChange={handleDeliveryChange}
            value={deliveryDetails.exportPhoneNumber}
          />
          {formErrors.exportPhoneNumber && (
            <span className="text-xs text-[#BA3838] font-onestMedium">
              {formErrors.exportPhoneNumber}
            </span>
          )}
        </div>
        <div className="mt-6">
          <TextInput
            type="email"
            label="Recipient email"
            placeholder="Enter email"
            name="exportRecipientEmail"
            onChange={handleDeliveryChange}
            value={deliveryDetails.exportRecipientEmail}
          />
          {formErrors.exportRecipientEmail && (
            <span className="text-xs text-[#BA3838] font-onestMedium">
              {formErrors.exportRecipientEmail}
            </span>
          )}
        </div>

        <div className="mt-6">
          <DropDownSelect
            isRequired
            title="Select weight"
            showcheckCountrySelected
            checkCountrySelected={deliveryDetails.exportCountry}
            lists={weightArray}
            loading={isWeightLoading}
            value={selectedWeightOption?.name}
            // setQuery={setQuery}
            isOpen={weightIsOpen}
            toggleDropdown={() => setWeightIsOpen((prev: boolean) => !prev)}
            handleOptionClick={(e: any) => {
              setSelectedWeightOption(e);
              setDeliveryDetails((prev: any) => ({
                ...prev,
                exportWeight: e.id,
              }));
              setWeightIsOpen(false);
              setFormErrors((prev: any) => ({
                ...prev,
                exportWeight: "",
              }));
            }}
            placeholder="Select weight"
          />
          {formErrors.exportWeight && (
            <span className="text-xs text-[#BA3838] font-onestMedium">
              {formErrors.exportWeight}
            </span>
          )}
        </div>
        <div className="mt-6">
          <TextInput
            type="number"
            label="Quantity"
            placeholder="Enter quantity"
            name="exportQuantity"
            onChange={handleDeliveryChange}
            value={deliveryDetails.exportQuantity}
          />
          {formErrors.exportQuantity && (
            <span className="text-xs text-[#BA3838] font-onestMedium">
              {formErrors.exportQuantity}
            </span>
          )}
        </div>
      </div>
      <div className="mt-6">
        <TextArea
          label="Item description"
          isRequired
          placeholder="Write a description"
          name="exportItemDescription"
          onChange={handleDeliveryChange}
          value={deliveryDetails.exportItemDescription}
        />
        {formErrors.exportItemDescription && (
          <span className="text-xs text-[#BA3838] font-onestMedium">
            {formErrors.exportItemDescription}
          </span>
        )}
      </div>

      <ToggleButton
        data={deliveryDetails.fragile}
        handleFn={handleDeliveryChange}
      />

      <div className="mt-6">
        <TextInput
          type="number"
          label={`Value of Item`}
          placeholder="Enter value"
          name="exportValueofItem"
          isRequired
          onChange={handleDeliveryChange}
          value={deliveryDetails.exportValueofItem}
        />
        {formErrors.exportValueofItem && (
          <span className="text-xs text-[#BA3838] font-onestMedium">
            {formErrors.exportValueofItem}
          </span>
        )}
      </div>
      <div className="mt-6">
        <TextInput
          type="text"
          label="Enter discount code if you have one"
          placeholder="Enter discount code"
          name="exportReferral"
          onChange={handleDeliveryChange}
          value={deliveryDetails.exportReferral}
        />
      </div>
    </>
  );
};

export default ExportDeliveryForm;
