import { TextInput } from "components/common/form/Input";
import Dropdown from "components/common/form/SelectDropdown";
import { TextArea } from "components/common/form/textArea";
import ToggleButton from "components/ToggleButton";
import { LocalFormProps } from "type";

const MobileLocalDeliveryForm = ({
  formErrors,
  pickUpStateName,
  isLoadingState,
  deliveryDetails,
  searchPickUpState,
  setSearchPickUpState,
  filteredPickUpStateSearch,
  setDeliveryDetails,
  setPickUpStateName,
  setFormErrors,
  handleDeliveryChange,
  stateName,
  searchState,
  setSearchState,
  filteredStateSearch,
  setStateName,
  isLockerLoading,
  useSmartLocker,
  lockerData,
  setUseSmartLocker,
  dropOffLockerName,
  searchLocker,
  setSearchLocker,
  setDropOffLockerName,
  filteredLockerSearch,
  lockerAvailabilityData,
  isLockerAvailabilityLoading,
}: LocalFormProps) => {
  return (
    <>
      <div className="mt-6">
        <div className="flex">
          <p className="text-[#4A564D] mb-2 text-sm leading-[20px] font-onestMedium">
            Pick-up State
          </p>
          <span className="text-red-500 text-sm">*</span>
        </div>
        <Dropdown
          loader={isLoadingState}
          selectPlaceholderName="Pick-up state"
          dropdownStateName={pickUpStateName}
          dropdownValue={deliveryDetails?.pickUpState}
          searchSubject={searchPickUpState}
          setSearchSubject={setSearchPickUpState}
          search
          dropdownContainerClasses={`shadow-cardShadow-4 !w-full !right-0  border border-slate-100  rounded `}
          dropdownOptions={[
            {
              customChild: (
                <div>
                  <div>
                    {filteredPickUpStateSearch?.map(
                      (item: any, idx: number) => {
                        return (
                          <div
                            key={idx}
                            onClick={() => {
                              setDeliveryDetails((prev: any) => ({
                                ...prev,
                                pickUpState: item.state,
                              }));
                              setPickUpStateName(item?.state);
                              setFormErrors((prev: any) => ({
                                ...prev,
                                pickUpState: "",
                              }));
                            }}
                          >
                            <div className="px-3 py-2 text-sm font-onestMedium text-[#4a564d] hover:bg-[#edecec] cursor-pointer">
                              <p>{item?.state}</p>
                            </div>
                          </div>
                        );
                      }
                    )}
                  </div>
                </div>
              ),
            },
          ]}
        />
        {formErrors.pickUpState && (
          <span className="text-xs text-[#BA3838] font-onestMedium">
            {formErrors.pickUpState}
          </span>
        )}
      </div>
      <div className="mt-6">
        <TextInput
          type="text"
          label="Pick-up address"
          isRequired
          name="pickUpAddress"
          value={deliveryDetails.pickUpAddress}
          onChange={handleDeliveryChange}
          placeholder="Enter pick-up address"
        />
        {formErrors.pickUpAddress && (
          <span className="text-xs text-[#BA3838] font-onestMedium">
            {formErrors.pickUpAddress}
          </span>
        )}
      </div>

      <div className="mt-6">
        <TextInput
          type="number"
          label="Pick-up phone number"
          name="pickUpPhoneNumber"
          value={deliveryDetails.pickUpPhoneNumber}
          onChange={handleDeliveryChange}
          placeholder="Enter pick-up phone number"
        />
        {formErrors.pickUpPhoneNumber && (
          <span className="text-xs text-[#BA3838] font-onestMedium">
            {formErrors.pickUpPhoneNumber}
          </span>
        )}
      </div>

      <div className="mt-6">
        <div className="flex">
          <p className="text-[#4A564D] mb-2 text-sm leading-[20px] font-onestMedium">
            Recipient State
          </p>
          <span className="text-red-500 text-sm">*</span>
        </div>
        <Dropdown
          selectPlaceholderName="state"
          dropdownStateName={stateName}
          dropdownValue={deliveryDetails?.state}
          searchSubject={searchState}
          setSearchSubject={setSearchState}
          search
          dropdownContainerClasses={`shadow-cardShadow-4 !w-full !right-0  border border-slate-100  rounded `}
          dropdownOptions={[
            {
              customChild: (
                <div>
                  <div>
                    {filteredStateSearch?.map((item: any, idx: number) => {
                      return (
                        <div
                          key={idx}
                          onClick={() => {
                            setDeliveryDetails((prev: any) => ({
                              ...prev,
                              state: item.state,
                            }));
                            setStateName(item?.state);
                            setFormErrors((prev: any) => ({
                              ...prev,
                              state: "",
                            }));
                          }}
                        >
                          <div className="px-3 py-2 text-sm font-onestMedium text-[#4a564d]  hover:bg-[#edecec] cursor-pointer">
                            <p>{item?.state}</p>
                          </div>
                        </div>
                      );
                    })}
                  </div>
                </div>
              ),
            },
          ]}
        />
        {formErrors.state && (
          <span className="text-xs text-[#BA3838] font-onestMedium">
            {formErrors.state}
          </span>
        )}
      </div>
      {deliveryDetails?.state !== "" &&
        deliveryDetails?.state !== undefined &&
        deliveryDetails?.pickUpState !== "" &&
        deliveryDetails?.pickUpState !== undefined &&
        deliveryDetails?.state === deliveryDetails?.pickUpState && (
          <>
            {isLockerLoading && <p>....searching for dropoff locker</p>}
            {deliveryDetails.deliveryType === "Local (within Nigeria)" &&
              lockerData?.Lockers?.length > 0 && (
                <div className="flex items-center mt-1.5 space-x-1">
                  <input
                    type="checkbox"
                    checked={useSmartLocker}
                    // required={true}
                    className="cursor-pointer"
                    onChange={(e: any) => {
                      setUseSmartLocker(e.target.checked);
                      setDeliveryDetails((prev: any) => ({
                        ...prev,
                        dropOffAddress: "",
                        dropOffLockerAddress: "",
                      }));
                    }}
                  />
                  <p className="text-[#4A564D]  text-sm leading-[20px] font-onestMedium">
                    Send to our smart Locker (Enjoy up to 50% discount)
                  </p>
                </div>
              )}
          </>
        )}
      {!useSmartLocker && (
        <div className="mt-6">
          <TextInput
            type="text"
            label="Drop-off address"
            isRequired
            name="dropOffAddress"
            value={deliveryDetails.dropOffAddress}
            onChange={handleDeliveryChange}
            placeholder="Enter drop-off address"
          />
          {formErrors.dropOffAddress && (
            <span className="text-xs text-[#BA3838] font-onestMedium">
              {formErrors.dropOffAddress}
            </span>
          )}
        </div>
      )}
      {useSmartLocker && (
        <div className="mt-6">
          <div className="flex">
            <p className="text-[#4A564D] mb-2 text-sm leading-[20px] font-onestMedium">
              Drop-off address
            </p>
            <span className="text-red-500 text-sm">*</span>
          </div>
          <Dropdown
            loader={isLockerLoading}
            selectPlaceholderName="drop-off address"
            dropdownStateName={dropOffLockerName}
            dropdownValue={deliveryDetails?.dropOffLockerAddress}
            searchSubject={searchLocker}
            setSearchSubject={setSearchLocker}
            search
            dropdownContainerClasses={`shadow-cardShadow-4 !w-full !right-0  border border-slate-100  rounded `}
            dropdownOptions={[
              {
                customChild: (
                  <div>
                    <div>
                      {filteredLockerSearch?.map((item: any, idx: number) => {
                        return (
                          <div
                            key={idx}
                            onClick={() => {
                              setDeliveryDetails((prev: any) => ({
                                ...prev,
                                dropOffLockerAddress: item.lockerID,
                              }));
                              setDropOffLockerName(item?.lockerAddress);
                              setFormErrors((prev: any) => ({
                                ...prev,
                                dropOffLockerAddress: "",
                              }));
                            }}
                          >
                            <div className="px-3 py-2  text-sm font-onestMedium text-[#4a564d] hover:bg-[#edecec] cursor-pointer">
                              <p>{item?.lockerAddress}</p>
                            </div>
                          </div>
                        );
                      })}
                    </div>
                  </div>
                ),
              },
            ]}
          />

          {formErrors.dropOffLockerAddress && (
            <p className="text-red-500 text-[12px]">
              {formErrors.dropOffLockerAddress}
            </p>
          )}
        </div>
      )}
      {!lockerAvailabilityData?.Available &&
        useSmartLocker &&
        !isLockerAvailabilityLoading &&
        deliveryDetails?.dropOffLockerAddress && (
          <div>
            <p className="text-[red] text-sm">
              {" "}
              It appears the locker selected is at capacity, please select
              another locker or{" "}
              <span
                className="underline cursor-pointer font-onestMedium"
                onClick={(e: any) => {
                  setUseSmartLocker(false);
                  setDropOffLockerName("");
                  setDeliveryDetails((prev: any) => ({
                    ...prev,
                    dropOffAddress: "",
                    dropOffLockerAddress: "",
                  }));
                }}
              >
                click here
              </span>{" "}
              to fill a drop off address instead
            </p>
          </div>
        )}
      {isLockerAvailabilityLoading && <p>....searching for available locker</p>}
      <div className="mt-6">
        <TextInput
          type="text"
          label="Recipient name"
          isRequired
          value={deliveryDetails.recipientName}
          name="recipientName"
          placeholder="Enter name"
          onChange={handleDeliveryChange}
        />
        {formErrors.recipientName && (
          <span className="text-xs text-[#BA3838] font-onestMedium">
            {formErrors.recipientName}
          </span>
        )}
      </div>
      <div className="grid md:grid-cols-2 gap-x-3">
        <div className="mt-6">
          <TextInput
            type="text"
            label="Recipient phone number"
            isRequired
            name="recipientPhoneNumber"
            value={deliveryDetails.recipientPhoneNumber || ""}
            placeholder="Enter phone number"
            onChange={handleDeliveryChange}
          />
          {formErrors.recipientPhoneNumber && (
            <span className="text-xs text-[#BA3838] font-onestMedium">
              {formErrors.recipientPhoneNumber}
            </span>
          )}
        </div>
        <div className="mt-6">
          <TextInput
            type="email"
            label="Recipient email"
            placeholder="Enter email"
            name="recipientEmail"
            onChange={handleDeliveryChange}
            value={deliveryDetails.recipientEmail}
          />
          {formErrors.recipientEmail && (
            <span className="text-xs text-[#BA3838] font-onestMedium">
              {formErrors.recipientEmail}
            </span>
          )}
        </div>
        <div className="mt-6">
          <TextInput
            type="number"
            label="Item weight"
            isRequired
            placeholder="Enter weight"
            name="weight"
            onChange={handleDeliveryChange}
            value={deliveryDetails.weight}
          />
          {formErrors.weight && (
            <span className="text-xs text-[#BA3838] font-onestMedium">
              {formErrors.weight}
            </span>
          )}
        </div>
        <div className="mt-6">
          <TextInput
            type="number"
            label="Quantity"
            placeholder="Enter quantity"
            name="quantity"
            onChange={handleDeliveryChange}
            value={deliveryDetails.quantity}
          />
          {formErrors.quantity && (
            <span className="text-xs text-[#BA3838] font-onestMedium">
              {formErrors.quantity}
            </span>
          )}
        </div>
      </div>
      <div className="mt-6">
        <TextInput
          type="number"
          label="Value of Item"
          placeholder="Enter value"
          name="valueOfItem"
          isRequired
          onChange={handleDeliveryChange}
          value={deliveryDetails.valueOfItem}
        />
        {formErrors.valueOfItem && (
          <span className="text-xs text-[#BA3838] font-onestMedium">
            {formErrors.valueOfItem}
          </span>
        )}
      </div>
      <div className="mt-6">
        <TextArea
          label="Item description"
          placeholder="Write a description"
          name="itemDescription"
          isRequired
          onChange={handleDeliveryChange}
          value={deliveryDetails.itemDescription}
        />
        {formErrors.itemDescription && (
          <span className="text-xs text-[#BA3838] font-onestMedium">
            {formErrors.itemDescription}
          </span>
        )}
      </div>

      <ToggleButton
        data={deliveryDetails.fragile}
        handleFn={handleDeliveryChange}
      />

      <div className="mt-6">
        <TextInput
          type="text"
          label="Enter discount code if you have one"
          placeholder="Enter discount code"
          name="discountCode"
          onChange={handleDeliveryChange}
          value={deliveryDetails.discountCode}
        />
      </div>
    </>
  );
};

export default MobileLocalDeliveryForm;
