import { Button } from "components/Button";
import Dropdown from "components/common/form/SelectDropdown";
import { TextArea } from "components/common/form/textArea";
import { Loader } from "components/Loader";
import ModalContainer from "components/Modal";
import { declineReasons } from "constant/data";
import { formatMoney } from "helpers/formatMoney";
import { getApprovalStatusStyles } from "helpers/getApprovalStatusStyles";
import { handleErrorToast } from "helpers/toastMessage";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { FaCheckCircle } from "react-icons/fa";
import { MdCancel } from "react-icons/md";
import { useNavigate, useParams } from "react-router-dom";
import {
  useDeclineOrderMutation,
  useLazyApproveOrderQuery,
  useRequestbyIdQuery,
} from "services/slices/inventory";
import { OrderDeclineSubmission, ReasonType } from "type/inventory";

const ApprovalStorageRequest = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const [isStorageApproved, setIsStorageApproved] = useState<boolean>(false);
  const [isStorageDeclined, setIsStorageDeclined] = useState<boolean>(false);
  const [declineStorage, setDeclineStorage] = useState<boolean>(false);
  const [totalQuantity, setTotalQuantity] = useState<number>(0);
  const [confirmQuantity, setConfirmQuantity] = useState<number>(0);
  const [declineError, setDeclineError] = useState<string>("");
  const [reasonsForDecline, setReasonsForDecline] = useState<ReasonType>({
    reason: "",
    additional_info: "",
  });
  const { data, isLoading, refetch } = useRequestbyIdQuery(Number(id), {
    skip: !id,
    refetchOnMountOrArgChange: true,
    refetchOnFocus: false,
  });

  const [approveOrder, { isLoading: isApprovalLoading }] =
    useLazyApproveOrderQuery();
  const [declineOrder, { isLoading: isDeclineLoading }] =
    useDeclineOrderMutation();

  useEffect(() => {
    const quantity =
      data?.data?.order?.items?.reduce(
        (sum, item) => sum + Number(item.quantity),
        0
      ) || 0;
    const secondQuantity =
      data?.data?.order?.items?.reduce(
        (sum, item) => sum + Number(item.confirmed_quantity),
        0
      ) || 0;

    setTotalQuantity(quantity);
    setConfirmQuantity(secondQuantity);
  }, [data]);

  const handleApprove = async () => {
    if (data?.data?.order.order_number) {
      await approveOrder(data?.data?.order.order_number)
        .unwrap()
        .then((data) => {
          setIsStorageApproved(true);
          refetch();
        })
        .catch((e) => handleErrorToast(e));
    }
  };
  const onCancelDecline = () => {
    setReasonsForDecline({
      reason: "",
      additional_info: "",
    });
    setDeclineStorage(false);
  };
  const onDecline = () => {
    const payload: OrderDeclineSubmission = {
      body: {
        reason: reasonsForDecline.reason,
        additional_info: reasonsForDecline.additional_info,
      },
      id: data?.data?.order.order_number || "",
    };
    if (reasonsForDecline.reason === "") {
      setDeclineError("Select a reason for the decline");
    } else {
      if (data?.data?.order.order_number) {
        setDeclineError("");
        declineOrder(payload)
          .unwrap()
          .then((data) => {
            refetch();
            setDeclineStorage(false);
            setIsStorageDeclined(true);
            setReasonsForDecline({
              additional_info: "",
              reason: "",
            });
          })
          .catch((e) => handleErrorToast(e));
      }
    }
  };
  return (
    <div className="mt-14 md:mt-0 overflow-x-hidden w-full ">
      <div className="mt-7 sm:mt-[72px]  lg:min-w-[800px]  rounded-lg max-w-[38rem] mx-4 md:mx-auto border-[1px] border-[#E1F0DF] flex flex-col bg-dashboard-gradient justify-center">
        <div
          onClick={() => navigate(-1)}
          className="flex mb-8 mt-5 px-4 sm:px-10 pt-6 sm:pt-8 font-onestMedium"
        >
          <div>
            {" "}
            <img className="w-3 h-3" src="/images/backArrow.svg" alt="" />
          </div>
          <p className=" text-[#4a564d] font-[500] text-[13px] -mt-1 font-onestMedium">
            Go back
          </p>
        </div>
        {isLoading ? (
          <div>
            <Loader />
          </div>
        ) : (
          <div className="w-full px-4 sm:px-10 font-onestMedium">
            <div>
              <div className="flex space-x-4 items-center">
                <p className="text-textBlackGreytext-xl md:text-lg font-onestBold">
                  Storage Request
                </p>
                <div
                  className={` ${getApprovalStatusStyles(
                    data?.data.order.status
                  )} text-[13px] font-onestBold rounded px-2 py-[2px]`}
                >
                  <p>
                    {data?.data?.order?.status
                      ? data.data.order.status.charAt(0).toUpperCase() +
                        data.data.order.status.slice(1)
                      : "N/A"}
                  </p>
                </div>
              </div>
              <div className="flex items-center space-x-1 mt-3">
                <p className="text-textLightGrey text-sm">Request ID:</p>
                <p className="text-[#464748] text-sm">
                  {data?.data.order.order_number}
                </p>
              </div>
              <div className="flex items-center space-x-1 mt-3">
                <p className="text-textLightGrey text-sm">Date requested:</p>
                <p className="text-[#464748] text-sm">
                  {moment(data?.data.order.created_at).format(
                    "DD MMM YYYY hh:mma"
                  )}
                </p>
              </div>
              {data?.data.order.status.toLowerCase() === "approved" && (
                <div className="flex items-center space-x-1 mt-3">
                  <p className="text-textLightGrey text-sm">Date approved:</p>
                  <p className="text-[#464748] text-sm">03 Nov 2024 10:25am</p>
                </div>
              )}
              {data?.data.order.status.toLowerCase() === "rejected" && (
                <div className="flex items-center space-x-1 mt-3">
                  <p className="text-textLightGrey text-sm">Date rejected:</p>
                  <p className="text-[#464748] text-sm">03 Nov 2024 10:25am</p>
                </div>
              )}
            </div>
            <div className="my-5">
              <table className="w-full border-collapse border border-borderGrey rounded-[6px]">
                <thead>
                  <tr className="text-[#929DAE] text-sm font-onestRegular border-b">
                    <th className="w-[200px] text-left p-3">Item name</th>
                    <th className="w-[110px] text-left p-3">Category</th>
                    <th className="w-[110px] text-left p-3">Sent by you</th>
                    <th className="w-[130px] text-left p-3 ">
                      Received by Fez
                    </th>
                    <th className="w-[53px] text-left p-3">Match</th>
                  </tr>
                </thead>
                <tbody>
                  {data?.data.order.items.map((item) => (
                    <tr
                      className="border-b text-darkBlack text-sm"
                      key={item.id}
                    >
                      <td className="w-[200px] p-3">{item.product.name}</td>
                      <td className="p-3">
                        {item.product.category?.name || "N/A"}
                      </td>
                      <td className="p-3">
                        {formatMoney().format(item.quantity)}
                      </td>
                      <td className="p-3">
                        {" "}
                        {formatMoney().format(item.confirmed_quantity)}
                      </td>
                      <td className="p-3">
                        {Number(item.quantity) ===
                        Number(item.confirmed_quantity) ? (
                          <FaCheckCircle className="text-[#7CC273] text-lg" />
                        ) : (
                          <MdCancel className="text-[#BA3838] text-xl" />
                        )}
                      </td>
                    </tr>
                  ))}
                  <tr className="border-b rounded-br-[4px] bg-[#E4EBF533] rounded-bl-[4px] text-darkBlack text-sm">
                    <td className="w-[200px] text-[#0E1728] font-onestBold p-3">
                      Total
                    </td>
                    <td className="p-3"></td>
                    <td className="p-3 text-[#0E1728] font-onestBold">
                      {formatMoney().format(totalQuantity)}{" "}
                    </td>
                    <td className="p-3 text-[#0E1728] font-onestBold">
                      {" "}
                      {formatMoney().format(confirmQuantity)}{" "}
                    </td>
                    <td className="p-3">
                      {totalQuantity === confirmQuantity ? (
                        <FaCheckCircle className="text-[#7CC273] text-lg" />
                      ) : (
                        <MdCancel className="text-[#BA3838] text-xl" />
                      )}
                    </td>
                  </tr>
                </tbody>
              </table>
              <div className="mt-8 mb-10">
                {data?.data.order.status.toLowerCase() !== "approved" &&
                  data?.data.order.status.toLowerCase() !== "rejected" && (
                    <>
                      <p className="text-textheaderColor text-base font-onestLight">
                        By clicking ‘Approve Storage’ you agree that the items
                        received by Fez are correct and will be stored in the
                        warehouse until retrieval.
                      </p>
                      <div className="mt-8 flex space-x-5">
                        <Button
                          title="Decline"
                          onClick={() => setDeclineStorage(true)}
                          className="!w-fit !px-10 py-3 bg-transparent !border !border-[#E1F0DF] rounded-lg !font-[500] !text-[#BA3838] !text-base"
                        />
                        <Button
                          title="Approve Storage"
                          onClick={handleApprove}
                          loading={isApprovalLoading}
                          className="!w-fit !px-5 py-3 bg-[#18AF04] rounded-lg !font-[500] !text-base"
                        />
                      </div>
                    </>
                  )}
              </div>
            </div>
            <ModalContainer
              open={isStorageApproved}
              showCloseIcon={false}
              tailwindClassName="w-[350px] sm:w-[466px]"
              closeModal={() => setIsStorageApproved(false)}
            >
              <div className="w-full px-8 pb-8 bg-white flex flex-col  rounded-[12px] relative">
                <p className="text-lg pb-3 mt-6 border-b border-[#E4EBF5] text-center font-onestBold text-[#0A5001] ">
                  Storage Approved!
                </p>
                <img
                  className="w-6 h-6 absolute right-6 top-6 cursor-pointer"
                  onClick={() => setIsStorageApproved(false)}
                  src="/images/close.svg"
                  alt=""
                />
                <div className="flex justify-center mt-8">
                  {" "}
                  <img
                    src="/images/storeCreated.svg"
                    alt=""
                    className="w-[123px] h-[98px]"
                  />
                </div>
                <p className="text-center text-[#4A564D] text-[16px] font-onestRegular mt-8">
                  All items received by Fez have
                  <br /> been added to your inventory
                </p>
                <div className="flex justify-center mt-14">
                  <Button
                    title="View Request"
                    onClick={() => {
                      navigate("/dashboard/inventory/requests");
                    }}
                    className="!w-[210px] py-4 bg-[#18AF04] rounded-lg !font-[500] !text-base"
                  />
                </div>
              </div>
            </ModalContainer>
            <ModalContainer
              open={isStorageDeclined}
              showCloseIcon={false}
              tailwindClassName="w-[350px] sm:w-[466px]"
              closeModal={() => setIsStorageDeclined(false)}
            >
              <div className="w-full px-8 pb-8 bg-white flex flex-col  rounded-[12px] relative">
                <p className="text-lg pb-3 mt-6 border-b border-[#E4EBF5] text-center font-onestBold text-[#0A5001] ">
                  Storage Declined
                </p>
                <img
                  className="w-6 h-6 absolute right-6 top-6 cursor-pointer"
                  onClick={() => setIsStorageDeclined(false)}
                  src="/images/close.svg"
                  alt=""
                />
                <div className="flex justify-center mt-8">
                  {" "}
                  <img
                    src="/images/cancelrequest.svg"
                    alt=""
                    className="w-[123px] h-[98px]"
                  />
                </div>
                <p className="text-center text-[#4A564D] text-sm font-onestRegular mt-8">
                  All items will be returned to your <br /> business
                  representative
                </p>
                <div className="flex justify-center mt-14">
                  <Button
                    title="Okay"
                    onClick={() => {
                      navigate("/dashboard/inventory/requests");
                    }}
                    className="!w-[210px] py-4 bg-[#18AF04] rounded-lg !font-[500] !text-base"
                  />
                </div>
              </div>
            </ModalContainer>
            <ModalContainer
              open={declineStorage}
              showCloseIcon={false}
              tailwindClassName="w-[310px] sm:w-[540px]"
              closeModal={onCancelDecline}
            >
              <div className="w-full px-6 pb-8 bg-white flex flex-col  rounded-[12px] relative">
                <p className="text-lg pb-3 mt-6 border-b border-[#E4EBF5] text-center font-onestBold text-[#0A5001] ">
                  Decline Storage?
                </p>

                <p className=" text-[#4A564D] text-sm font-onestRegular mt-8">
                  Are you sure you want to decline storage? All items will be
                  returned
                </p>
                <div className="mt-6">
                  <div className="flex">
                    <p className="text-[#4A564D] mb-2 text-sm leading-[20px] font-onestMedium">
                      Tell us why
                    </p>
                    <span className="text-red-500 text-sm">*</span>
                  </div>
                  <Dropdown
                    loader={isLoading}
                    selectPlaceholderName="reason"
                    dropdownStateName={reasonsForDecline.reason}
                    dropdownValue={reasonsForDecline.reason}
                    dropdownContainerClasses={`shadow-cardShadow-4 !w-full !right-0  border border-slate-100  rounded `}
                    dropdownOptions={[
                      {
                        customChild: (
                          <div>
                            <div>
                              {declineReasons?.map((reason) => {
                                return (
                                  <div
                                    key={reason.id}
                                    onClick={() => {
                                      setDeclineError("");
                                      setReasonsForDecline((prev) => ({
                                        ...prev,
                                        reason: reason.name,
                                      }));
                                    }}
                                  >
                                    <div className="px-3 py-2  text-sm font-onestMedium text-[#4a564d] hover:bg-[#edecec] cursor-pointer">
                                      <p>{reason?.name}</p>
                                    </div>
                                  </div>
                                );
                              })}
                            </div>
                          </div>
                        ),
                      },
                    ]}
                  />
                  {declineError && (
                    <span className="text-xs text-[#BA3838] font-onestMedium">
                      {declineError}
                    </span>
                  )}
                  <div className="mt-6">
                    <TextArea
                      label="Other"
                      placeholder="Enter description"
                      name="description"
                      onChange={(e: any) => {
                        setReasonsForDecline((prev) => ({
                          ...prev,
                          additional_info: e.target.value,
                        }));
                      }}
                      value={reasonsForDecline.additional_info}
                    />
                  </div>
                </div>
                <div className="flex md:flex-row flex-col justify-between md:space-y-0 space-y-3 md:space-x-4 mt-8">
                  <div className="w-full">
                    <Button
                      title="Cancel"
                      disabled={isDeclineLoading}
                      onClick={onCancelDecline}
                      className=" py-3 !bg-transparent !border-[#BEC0BE] !border rounded-lg !font-[500] !text-[#464748] !text-base"
                    />
                  </div>
                  <div className="w-full">
                    <Button
                      title="Decline"
                      loading={isDeclineLoading}
                      onClick={onDecline}
                      disabled={isDeclineLoading}
                      className=" py-3 bg-[#BA3838] rounded-lg !font-[500] !text-base"
                    />
                  </div>
                </div>
              </div>
            </ModalContainer>
          </div>
        )}
      </div>
    </div>
  );
};

export default ApprovalStorageRequest;
