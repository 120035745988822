import { Loader } from "components/Loader";
import { formatMoney } from "helpers/formatMoney";
import moment from "moment";
import React, { useState } from "react";
import { IoIosArrowDown, IoIosArrowUp } from "react-icons/io";
import { useNavigate, useParams } from "react-router-dom";
import { useViewInventoryIdQuery } from "services/slices/inventory";
import EmptyShipmentTable from "../shipment/EmptyShipmentTable";

const ViewInventoryById = () => {
  const navigate = useNavigate();
  const [openItems, setOpenItems] = useState<boolean>(true);
  const [openActivities, setOpenActivities] = useState<boolean>(true);
  const { id } = useParams();
  const { data, isLoading } = useViewInventoryIdQuery(Number(id), {
    skip: !id,
    refetchOnMountOrArgChange: true,
    refetchOnFocus: false,
  });

  return (
    <div>
      <div className="flex flex-col px-10 md:px-0  justify-center space-y-7 items-center w-full  md:mt-[72px] ">
        <div className="border-[1px] w-full md:w-[598px]  border-borderDeepGrey px-5 xl:px-8 bg-dashboard-gradient rounded-lg">
          <div className="hidden md:flex items-center justify-between pt-8  w-full">
            <div
              className="hidden md:flex gap-1 cursor-pointer w-[100px]"
              onClick={() => navigate(-1)}
            >
              <div>
                {" "}
                <img className="w-3 h-3" src="/images/backArrow.svg" alt="" />
              </div>
              <p className="text-[#4a564d] font-[500] text-[13px] -mt-1 font-onestMedium">
                Go back
              </p>
            </div>
          </div>
          <div className="flex md:hidden items-center justify-between mt-6 mx-4">
            <div className="w-full h-0.5 bg-[#D4F3D5]"></div>
            <div className="w-full h-0.5 bg-[#D4F3D5]"></div>
          </div>
          {isLoading ? (
            <div>
              <Loader />
            </div>
          ) : (
            <div className="mt-10 mb-7">
              <div className="flex items-start space-x-4">
                <div className="border-[0.26px] border-[#DBE4EF] rounded-[3px]">
                  <img
                    src={data?.data.stock.product.image}
                    width={148}
                    alt={data?.data.stock.product.name}
                  />
                </div>
                <div>
                  <h2 className="text-darkBlack whitespace-nowrap text-lg font-onestMedium">
                    {data?.data.stock.product.name}
                  </h2>
                  <div className="flex items-center space-x-1">
                    {" "}
                    <div
                      className={`h-[6px] w-[6px] shrink-0 ${
                        Number(data?.data.stock.quantity) < 0
                          ? "bg-[#BA3838]"
                          : "bg-[#18AF04]"
                      }  rounded`}
                    ></div>
                    <p className="text-textBlackGrey font-onestRegular text-sm">
                      {data?.data.stock.quantity
                        ? formatMoney().format(data?.data.stock.quantity)
                        : "N/A"}{" "}
                      {Number(data?.data.stock.quantity) > 0 ? "units" : "unit"}{" "}
                      in inventory
                    </p>
                  </div>
                </div>
              </div>
            </div>
          )}
        </div>
        <div className="border-[1px] w-full md:w-[598px]  border-borderDeepGrey px-5 xl:px-8 rounded-lg">
          <div className="mt-8 mb-7 w-full">
            <div
              className={`flex justify-between  ${
                openItems && "border-b pb-4"
              } border-borderGrey items-center`}
            >
              <p className="text-textheaderColor font-onestMedium text-[16px]">
                Item Details
              </p>
              <div
                className="cursor-pointer"
                onClick={() => setOpenItems(!openItems)}
              >
                {openItems ? (
                  <IoIosArrowUp className="text-textGrey" />
                ) : (
                  <IoIosArrowDown className="text-textGrey" />
                )}
              </div>
            </div>
            {isLoading ? (
              <div>
                <Loader />
              </div>
            ) : (
              <>
                {openItems && (
                  <div>
                    <div className="grid grid-cols-2 gap-4 mt-4">
                      <div className="text-sm space-y-1">
                        <h4 className="text-textLightGrey font-onestRegular ">
                          Item name
                        </h4>
                        <p className="text-textBlackGrey">
                          {data?.data.stock.product.name || "N/A"}
                        </p>
                      </div>
                      <div className="text-sm space-y-1">
                        <h4 className="text-textLightGrey font-onestRegular ">
                          SKU
                        </h4>
                        <p className="text-textBlackGrey">
                          {data?.data.stock.product.sku || "N/A"}
                        </p>
                      </div>
                      <div className="text-sm space-y-1">
                        <h4 className="text-textLightGrey font-onestRegular ">
                          Category
                        </h4>
                        <p className="text-textBlackGrey">
                          {" "}
                          {data?.data.stock.product.category.name || "N/A"}
                        </p>
                      </div>
                      <div className="text-sm space-y-1">
                        <h4 className="text-textLightGrey font-onestRegular ">
                          Weight
                        </h4>
                        <p className="text-textBlackGrey">
                          {data?.data.stock.product.weight
                            ? formatMoney().format(
                                data?.data.stock.product.weight
                              )
                            : "N/A"}{" "}
                          kg
                        </p>
                      </div>
                      <div className="text-sm space-y-1">
                        <h4 className="text-textLightGrey font-onestRegular ">
                          Size
                        </h4>
                        <p className="text-textBlackGrey">
                          {" "}
                          {data?.data.stock.product.size
                            ? formatMoney().format(
                                data?.data.stock.product.size
                              )
                            : "N/A"}
                        </p>
                      </div>
                      <div className="text-sm space-y-1">
                        <h4 className="text-textLightGrey font-onestRegular ">
                          Cost per unit
                        </h4>
                        <p className="text-textBlackGrey">
                          {data?.data.stock.price_per_unit
                            ? `₦ ${formatMoney().format(
                                data?.data.stock.price_per_unit
                              )}`
                            : "N/A"}
                        </p>
                      </div>
                      <div className="text-sm space-y-1">
                        <h4 className="text-textLightGrey font-onestRegular ">
                          Quantity in storage
                        </h4>
                        <div className="text-textBlackGrey flex items-center space-x-1">
                          <div
                            className={`h-[6px] w-[6px] shrink-0 ${
                              Number(data?.data.stock.quantity) < 0
                                ? "bg-[#BA3838]"
                                : "bg-[#18AF04]"
                            }  rounded`}
                          ></div>
                          <p>
                            {" "}
                            {data?.data.stock.quantity
                              ? formatMoney().format(data?.data.stock.quantity)
                              : "N/A"}
                          </p>
                        </div>
                      </div>
                    </div>
                    <div className="text-sm space-y-1 mt-4">
                      <h4 className="text-textLightGrey font-onestRegular ">
                        Description
                      </h4>
                      <p className="text-textBlackGrey">
                        {data?.data.stock.product.description || "N/A"}
                      </p>
                    </div>
                  </div>
                )}
              </>
            )}
          </div>
        </div>
        <div className="border-[1px] w-full md:w-[598px]   border-borderDeepGrey px-5 xl:px-8 rounded-lg">
          <div className="mt-8 mb-7 w-full">
            <div
              className={`flex justify-between  ${
                openActivities && "border-b pb-4"
              } border-borderGrey items-center`}
            >
              <p className="text-textheaderColor font-onestMedium text-[16px]">
                Recent Activity
              </p>
              <div
                className="cursor-pointer"
                onClick={() => setOpenActivities(!openActivities)}
              >
                {openActivities ? (
                  <IoIosArrowUp className="text-textGrey" />
                ) : (
                  <IoIosArrowDown className="text-textGrey" />
                )}
              </div>
            </div>
            {isLoading ? (
              <div>
                <Loader />
              </div>
            ) : (
              <>
                {openActivities && (
                  <>
                    {data?.data.activities &&
                    data?.data.activities.length > 0 ? (
                      <div className="mt-4 space-y-6">
                        {data?.data.activities.map((activity, index) => (
                          <div className="flex space-x-4" key={index}>
                            <div className="flex flex-col space-y-1 items-center mt-1">
                              <div className="bg-[#9CA6A2] rounded-full w-2 h-2 flex-shrink-0"></div>
                              <div
                                className={`bg-[#D1D1D1] w-[2px] flex items-center ${
                                  index !== data.data.activities.length - 1
                                    ? "h-full"
                                    : ""
                                }  flex-shrink-0`}
                              ></div>
                            </div>
                            <div className=" space-y-1">
                              <div>
                                {" "}
                                <h4 className="text-textBlackGrey text-sm font-onestMedium">
                                  {activity.order.type === "addition"
                                    ? " Received at warehouse"
                                    : "Retrieved from warehouse"}
                                  <span
                                    className={` ${
                                      Number(activity.quantity) < 0
                                        ? "bg-[#F1D7D7]  text-[#BA3838]"
                                        : "bg-[#D4F3D5] text-[#17730A]"
                                    } ml-2 py-1 px-2 rounded-[100px]  font-onestMedium`}
                                  >
                                    {activity.quantity}
                                  </span>
                                </h4>
                              </div>
                              <p className="font-onestRegular text-xs text-[#464748]">
                                {moment(activity.order.created_at).format(
                                  "DD MMM, YYYY h:mm A"
                                )}
                              </p>
                            </div>
                          </div>
                        ))}
                      </div>
                    ) : (
                      <EmptyShipmentTable
                        image="/images/invoice.svg"
                        title="You do not have any recent activity"
                        content="When you have an activity, it will show here"
                      />
                    )}
                  </>
                )}
              </>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default ViewInventoryById;
