import { Button } from "components/Button";
import { initialRetrievalItemDetails } from "constant/data";
import { useState } from "react";
import { GoPencil } from "react-icons/go";
import { IoMdAdd } from "react-icons/io";
import { RiDeleteBin6Line } from "react-icons/ri";
import { CreateRetrieveItem } from "./CreateRetrieveItem";
import { validateItem } from "helpers/validation";
import { useRetrieveOrderMutation } from "services/slices/inventory";
import { handleErrorToast } from "helpers/toastMessage";
import ModalContainer from "components/Modal";
import { useNavigate } from "react-router-dom";

interface Item {
  id: number;
  name: string;
  sub_category: string;
  categoryName: string;
  quantity: number;
  retrieval_date: string;
}

const CreateInventoryRetrieveItem = () => {
  const navigate = useNavigate();
  const [formErrors, setFormErrors] = useState<any>({});
  const [itemName, setItemName] = useState<string>("");
  const [warehouseDeliveryMode, setWarehouseDeliveryMode] =
    useState<string>("");

  const [isRetrievalItem, setIsRetrievalItem] = useState<boolean>(false);
  const [isRetrieveSuccess, setIsRetrieveSuccess] = useState<boolean>(false);
  const [itemDetails, setItemDetails] = useState(initialRetrievalItemDetails);
  const [items, setItems] = useState<Item[]>([]);
  const [retrieveOrder, { isLoading }] = useRetrieveOrderMutation();


  const onSubmit = async () => {
    setFormErrors(validateItem(itemDetails));
    if (
      warehouseDeliveryMode !== "drop-off" &&
      itemDetails.deliveryAddress !== ""
    ) {
      setFormErrors((prev: any) => ({
        ...prev,
        deliveryAddress: "",
      }));
    } else if (
      warehouseDeliveryMode !== "drop-off" &&
      itemDetails.deliveryAddress === ""
    ) {
      setFormErrors((prev: any) => ({
        ...prev,
        deliveryAddress: "",
      }));
    } else if (
      warehouseDeliveryMode === "drop-off" &&
      itemDetails.deliveryAddress === ""
    ) {
      setFormErrors((prev: any) => ({
        ...prev,
        deliveryAddress: "Delivery address is required",
      }));
    }

    if (warehouseDeliveryMode === "") {
      setFormErrors((prev: any) => ({
        ...prev,
        deliveryMode: "Delivery mode is required",
      }));
    }
    if (
      itemDetails.category !== "" &&
      !formErrors.deliveryAddress &&
      !formErrors.quantity &&
      itemDetails.item !== "" &&
      itemDetails.retrievalDate !== "" &&
      warehouseDeliveryMode !== "" &&
      itemDetails.quantity !== ""
    ) {
      setItems((prevItems) => {
        const updatedItems = prevItems.some(
          (item) => item.name === itemDetails.item
        )
          ? prevItems.map((item) =>
              item.name === itemDetails.item
                ? {
                    ...item,
                    quantity: Number(itemDetails.quantity),
                    retrieval_date: itemDetails.retrievalDate,
                    sub_category: itemDetails.subCategoryId,
                    categoryName: itemDetails.categoryName,
                  }
                : item
            )
          : [
              ...prevItems,
              {
                id: Date.now(),
                name: itemDetails.item,
                sub_category: itemDetails.subCategoryId,
                quantity: Number(itemDetails.quantity),
                retrieval_date: itemDetails.retrievalDate,
                categoryName: itemDetails.categoryName,
              },
            ];

        return updatedItems;
      });
      setIsRetrievalItem(true);
    }
  };

  const onRetrieve = () => {
    retrieveOrder({
      items: items.map((item) => ({
        name: item.name,
        sub_category: Number(item.sub_category),
        quantity: Number(item.quantity),
      })),
      retrieval_date: itemDetails.retrievalDate,
      delivery_mode:
        warehouseDeliveryMode === "drop-off" ? "drop-off" : "pickup",
      address: itemDetails.deliveryAddress,
    })
      .unwrap()
      .then((data) => {
        setIsRetrieveSuccess(true);
      })
      .catch((e) => handleErrorToast(e));
  };

  const handleDelete = (id: number) => {
    if (items.length === 1) {
      setItems((prevItems) => prevItems.filter((item) => item.id !== id));
      setIsRetrievalItem(false);
    } else {
      setItems((prevItems) => prevItems.filter((item) => item.id !== id));
    }
  };
  return (
    <>
      <div className="mt-14 md:mt-0 overflow-x-hidden ">
        {!isRetrievalItem ? (
          <CreateRetrieveItem
            itemDetails={itemDetails}
            setItemDetails={setItemDetails}
            warehouseDeliveryMode={warehouseDeliveryMode}
            setWarehouseDeliveryMode={setWarehouseDeliveryMode}
            onSubmit={onSubmit}
            itemName={itemName}
            setItemName={setItemName}
            setFormErrors={setFormErrors}
            formErrors={formErrors}
            isEdit={false}
          />
        ) : (
          <div className="mt-7 min-w-[657px] pt-10 sm:mt-[72px] rounded-lg max-w-[38rem] mx-4 md:mx-auto border-[1px] border-[#E1F0DF] flex flex-col bg-dashboard-gradient justify-center">
            <div className="w-full px-4 sm:px-10 font-onestMedium">
              <div
                onClick={() => setIsRetrievalItem(false)}
                className=" flex mb-5  cursor-pointer"
              >
                <div>
                  {" "}
                  <img className="w-3 h-3" src="/images/backArrow.svg" alt="" />
                </div>
                <p className=" text-[#4a564d] font-[500] text-[13px] -mt-1 font-onestMedium">
                  Go back
                </p>
              </div>
              <h1 className="text-2xl font-onestBold font-[800] text-[#0a5001] tracking-wide">
                Retrieve Item
              </h1>
              <p className="text-[#a0aba3] font-[500] font-onestRegular tracking-normal mb-12">
                Fill in the details retrieve items from the warehouse
              </p>
              <div className="overflow-auto max-h-[500] h-fit  relative">
                <div className="bg-[#F9F9FB] overflow-auto max-h-[500] h-fit rounded-2xl w-full border border-borderGrey  pb-4 mt-10">
                  <table className="whitespace-nowrap text-left border-spacing-2 rounded-2xl w-full shadow-[0px 4px 8px -2px rgba(16, 24, 40, 0.1), 0px 2px 4px -2px rgba(16, 24, 40, 0.06)]">
                    <thead className="bg-white sticky top-0 z-20 border-b-[1px] border-[#E4EBF5]">
                      <tr className="">
                        <th className="py-3 text-xs pl-4  text-[#929DAE] font-onestRegular">
                          Item name
                        </th>

                        <th className="py-3 text-xs pl-4 pr-4 text-[#929DAE] border-l-[1px] border-[#E4EBF5] font-onestRegular">
                          Sub-cateogry
                        </th>
                        <th className="py-3 text-xs pl-4 pr-4 text-[#929DAE] border-l-[1px] border-[#E4EBF5] font-onestRegular">
                          Quantity
                        </th>

                        <th className="py-3 sticky right-0 text-xs bg-white pl-4 pr-10 text-[#929DAE] border-l-[1px] border-[#E4EBF5] font-onestRegular">
                          Actions
                        </th>
                      </tr>
                    </thead>

                    <tbody className=" bg-white">
                      {items.map((item: Item, idx: number) => {
                        return (
                          <tr
                            key={idx}
                            className="text-sm text-[#161718]  w-full font-[400] border-b-[1px] border-[#E4EBF5]"
                          >
                            <td
                              className={` py-4 whitespace-normal pl-4 flex gap-x-1.5 pr-4 border-r-[1px] border-[#E4EBF5]`}
                            >
                              {item.name}
                            </td>
                            <td
                              className={` py-4 pl-4 text-sx border-l-[#fff] border-[1px] border-[#E4EBF5]  px-4`}
                            >
                              {item.categoryName}
                            </td>
                            <td
                              className={` py-4 pl-4 text-xs border-l-[#fff] border-[1px] border-[#E4EBF5] `}
                            >
                              {item.quantity} Qty
                            </td>

                            <td className="pl-5 bg-white text-xs  border-r-[#fff] border-l-[#fff] border-[1px] border-[#E4EBF5]">
                              <div className="flex items-center">
                                <Button
                                  className=" !w-fit !px-0 !bg-transparent !text-[#1D4CC4]"
                                  title="Edit"
                                  onClick={() => {
                                    setIsRetrievalItem(false);
                                    setItemDetails((prev: any) => ({
                                      ...prev,
                                      quantity: item.quantity,
                                      item: item.name,
                                      category: item.categoryName,
                                      categoryName: item.categoryName,
                                      subCategoryId: item.id,
                                      
                                    }));
                                    setItemName(item.name);
                                  }}
                                  iconLeft
                                  icon={<GoPencil className="text-[#1D4CC4]" />}
                                />
                                <Button
                                  className=" !w-fit  !bg-transparent !text-[#BA3838]"
                                  title="Delete"
                                  onClick={() => handleDelete(item.id)}
                                  iconLeft
                                  icon={
                                    <RiDeleteBin6Line className="text-[#BA3838]" />
                                  }
                                />
                              </div>
                            </td>
                          </tr>
                        );
                      })}
                    </tbody>
                  </table>
                </div>
              </div>
              <div className="mt-8 mb-6 flex space-x-3">
                <Button
                  className=" !w-fit  !bg-transparent py-2.5 !border !text-[#18AF04]"
                  title="Click to add more"
                  onClick={() => {
                    setIsRetrievalItem(false);
                    setItemDetails((prev: any) => ({
                      ...prev,
                      quantity: "",
                      item: "",
                      category: "",
                      categoryName: "",
                    }));
                    setItemName("");
                  }}
                  disabled={isLoading}
                  iconLeft
                  icon={<IoMdAdd className="text-[#18AF04]" />}
                />
                <Button
                  className="py-2.5 !w-fit "
                  title="Request Retrieval"
                  onClick={onRetrieve}
                  loading={isLoading}
                />
              </div>
            </div>
          </div>
        )}

        <ModalContainer
          open={isRetrieveSuccess}
          showCloseIcon={false}
          tailwindClassName="w-[350px] sm:w-[466px]"
          closeModal={() => setIsRetrieveSuccess(false)}
        >
          <div className="w-full px-8 pb-8 bg-white flex flex-col  rounded-[12px] relative">
            <p className="text-lg pb-3 mt-6 border-b border-[#E4EBF5] text-center font-onestBold text-[#0A5001] ">
              Retrieval Request Created
            </p>
            <img
              className="w-6 h-6 absolute right-6 top-6 cursor-pointer"
              onClick={() => navigate("/dashboard/inventory/requests")}
              src="/images/close.svg"
              alt="close-icon"
            />
            <div className="flex justify-center mt-8">
              {" "}
              <img
                src="/images/storeCreated.svg"
                alt=""
                className="w-[123px] h-[98px]"
              />
            </div>
            <p className="text-center text-[#4A564D] text-sm font-onestRegular mt-8">
              You will be notified once your request <br /> is approved for
              retrieval
            </p>
            <div className="flex justify-center mt-14">
              <Button
                title="View Request"
                onClick={() => navigate("/dashboard/inventory/requests")}
                className="!w-[210px] py-4 bg-[#18AF04] rounded-lg !font-[500] !text-base"
              />
            </div>
          </div>
        </ModalContainer>
      </div>
    </>
  );
};

export default CreateInventoryRetrieveItem;
