/* eslint-disable no-unused-vars */
import  { useEffect, useState } from "react";
import { getFromStorage, removeFromStorage } from "helpers";
import { useLocation, useNavigate } from "react-router-dom";

type PublicRoutesProps = {
  children: JSX.Element;
};

const PublicRoutes = ({ children }: PublicRoutesProps) => {
  const navigate = useNavigate();
  const location = useLocation();
  const [token, setToken] = useState(() => getFromStorage("token"));
  const [secretKey, setSecretKey] = useState(() =>
    getFromStorage("secret-key")
  );

  const handleStorageChange = () => {
    setToken(getFromStorage("token"));
    setSecretKey(getFromStorage("secret-key"));
  };

  useEffect(() => {
    if (location.pathname === "/dashboard/logout") {
      removeFromStorage("webhook");
      removeFromStorage("integration-key");
      removeFromStorage("token");
      removeFromStorage("secret-key");
      removeFromStorage("fez-org-code");
      removeFromStorage("fez-org-id");
      removeFromStorage("fez-org-email");
      removeFromStorage("expire-token-date");
      removeFromStorage("fez-user");
      removeFromStorage("fez-ref");
      removeFromStorage("fez-org-details");
      removeFromStorage("invoice");
      removeFromStorage("path");
      navigate("/")
    }
  }, [location.pathname, navigate]);
  
  useEffect(() => {
    if (!token || !secretKey) {
      return;
    }
    
    if (token && !location.pathname.startsWith("/dashboard")) {
      navigate("/dashboard");
    }
// eslint-disable-next-line
  }, []);

  useEffect(() => {
    window.addEventListener("storage", handleStorageChange);
    return () => {
      window.removeEventListener("storage", handleStorageChange);
    };
  }, []);

  return children;
};

export default PublicRoutes;