import { Button } from "components/Button";
import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import {
  useLazyDeleteRequestQuery,
  useRequestbyIdQuery,
} from "services/slices/inventory";
import { formatMoney } from "helpers/formatMoney";
import moment from "moment";
import { TableLoader } from "components/TableLoader";
import { Loader } from "components/Loader";
import MenuField from "components/dashboard/MenuField";
import ModalContainer from "components/Modal";
import { handleErrorToast } from "helpers/toastMessage";

const StorageRequest = () => {
  const navigate = useNavigate();
  const { id } = useParams();
  const { data, isLoading } = useRequestbyIdQuery(Number(id), {
    skip: !id,
    refetchOnMountOrArgChange: true,
    refetchOnReconnect: true,
    refetchOnFocus: false,
  });
  const [isCancelOrder, setIsCancelOrder] = useState<boolean>(false);
  const [isCancelSuccess, setIsCancelSuccess] = useState<boolean>(false);
  const [requestStatus, setRequestStatus] = useState<string>("");
  const [inventoryId, setInventoryId] = useState<number>(0);
  const [deleteSuccess, setDeleteSuccess] = useState<boolean>(false);

  const [triggerDelete, { isLoading: deleteLoading, isSuccess }] =
    useLazyDeleteRequestQuery();
  const getOptions = () => {
    return [
      {
        label: "Edit request",
        value: "edit",
        icon: "/images/edit.svg",
      },
      {
        label: "Cancel request",
        value: "cancel",
        icon: "/images/Delete.svg",
      },
    ];
  };
  const handleDelete = async () => {
    try {
      await triggerDelete(inventoryId).unwrap();
      setIsCancelOrder(false);
      setIsCancelSuccess(true);
    } catch (error) {
      handleErrorToast(error);
    }
  };
  useEffect(() => {
    if (isSuccess) {
      setIsCancelOrder(false);
      setIsCancelSuccess(true);
      setDeleteSuccess(true);
    }
  }, [isSuccess, deleteSuccess]);

  return (
    <div className="overflow-x-hidden mx-9 mt-16">
      <div onClick={() => navigate(-1)} className=" flex mb-2  cursor-pointer">
        <div>
          {" "}
          <img className="w-3 h-3" src="/images/backArrow.svg" alt="" />
        </div>
        <p className=" text-[#4a564d] font-[500] text-[13px] -mt-1 font-onestMedium">
          Go back
        </p>
      </div>
      <div className="mt-6">
        <div>
          <div className="flex space-x-4 items-center">
            <p className="text-textBlackGreytext-xl md:text-lg font-onestBold">
              Storage Request
            </p>
            <div className="">
              <MenuField
                onClick={(x: string) => {
                  if (x === "edit") {
                    navigate(`/dashboard/inventory/edit-storage-request/${id}`);
                    return;
                  }
                  if (x === "cancel") {
                    const status =
                      data?.data.order.type.toLowerCase() === "addition"
                        ? "Storage"
                        : "Retrieval";
                    setRequestStatus(status);
                    setInventoryId(Number(id));
                    setIsCancelOrder(true);
                    return;
                  }
                }}
                options={getOptions()}
              >
                <div className="align-bottom mt-1 ">
                  <svg
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M12 18L12 18.01M12 12L12 12.01M12 6L12 6.01M12 13C11.4477 13 11 12.5523 11 12C11 11.4477 11.4477 11 12 11C12.5523 11 13 11.4477 13 12C13 12.5523 12.5523 13 12 13ZM12 19C11.4477 19 11 18.5523 11 18C11 17.4477 11.4477 17 12 17C12.5523 17 13 17.4477 13 18C13 18.5523 12.5523 19 12 19ZM12 7C11.4477 7 11 6.55228 11 6C11 5.44772 11.4477 5 12 5C12.5523 5 13 5.44772 13 6C13 6.55228 12.5523 7 12 7Z"
                      stroke="#5C6881"
                      strokeWidth="2"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                  </svg>
                </div>
              </MenuField>
            </div>
          </div>
          <div className="flex items-center space-x-1 mt-3">
            <p className="text-textLightGrey text-sm">Request ID:</p>
            {isLoading ? (
              <Loader />
            ) : (
              <p className="text-[#464748] text-sm">
                {data?.data.order.order_number}
              </p>
            )}
          </div>
          <div className="flex items-center space-x-1 mt-3">
            <p className="text-textLightGrey text-sm">Date requested:</p>
            {isLoading ? (
              <Loader />
            ) : (
              <p className="text-[#464748] text-sm">
                {moment(data?.data.order.created_at).format(
                  "DD MMM YYYY hh:mma"
                )}
              </p>
            )}
          </div>
        </div>
        {isLoading ? (
          <div className="">
            <TableLoader rowLength={10} />
          </div>
        ) : (
          <div className="overflow-auto max-h-[600px] h-fit  relative">
            <div className="bg-[#F9F9FB] overflow-auto max-h-[600px] h-fit rounded-2xl w-full border border-borderGrey  pb-4 mt-10">
              <table className="whitespace-nowrap bg-white pt-4 text-left border-spacing-2 rounded-2xl w-full shadow-[0px 4px 8px -2px rgba(16, 24, 40, 0.1), 0px 2px 4px -2px rgba(16, 24, 40, 0.06)]">
                <thead className="bg-white sticky top-0 z-20 border-b-[1px] border-[#E4EBF5]">
                  <tr>
                    <th className="py-3 pl-4 pr-4 text-xs text-[#929DAE] font-onestRegular">
                      Item ID/SKU
                    </th>
                    <th className="py-3 text-xs pl-4 text-[#929DAE] font-onestRegular">
                      Item name
                    </th>
                    <th className="py-3 text-xs pl-4 pr-4 text-[#929DAE] font-onestRegular">
                      Category
                    </th>

                    <th className="py-3 text-xs pl-4 pr-4 text-[#929DAE] font-onestRegular">
                      Quantity to store
                    </th>
                    <th className="py-3 text-xs pl-4 pr-20 text-[#929DAE] font-onestRegular">
                      Item weight
                    </th>
                    <th className="py-3 text-xs pl-4 text-[#929DAE] font-onestRegular">
                      Cost value per item
                    </th>
                    <th className="py-3 text-xs pl-4 pr-4 text-[#929DAE] font-onestRegular">
                      Expiry date
                    </th>
                  </tr>
                </thead>
                <tbody className="bg-white">
                  {data?.data?.order?.items?.map((item) => (
                    <tr
                      key={item.id}
                      className="text-sm text-[#161718] font-[400] border-b-[1px] border-[#E4EBF5]"
                    >
                      <td className="py-4 pl-3 pr-6">{item.id}</td>
                      <td className="!w-[200px] pl-4 flex pr-4">
                        {item.product.name}
                      </td>
                      <td className="py-4 pl-4 w-[300px] px-4">
                        {item.product.category.name}
                      </td>
                      <td className="py-4 pl-4 w-[300px]">
                        {" "}
                        {formatMoney().format(item.quantity)} Qty
                      </td>
                      <td className="py-4 pl-4 pr-6 !w-[150px]">
                        {formatMoney().format(item.product.weight)} kg
                      </td>
                      <td className="!w-[200px] whitespace-normal py-4 pl-4 flex pr-4">
                        ₦{formatMoney().format(item.product.price_per_unit)}
                      </td>
                      <td className="py-4 pl-4 w-[300px] px-4">
                        {moment(item.expiry_date).format("DD MMM, YYYY")}
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </div>
        )}

        {/* {!isLoading && (
          <div className="mt-4 flex space-x-3">
            <Button
              className=" !w-fit  !bg-transparent py-2.5 !border !text-[#18AF04]"
              title="Click to add more"
              onClick={() => {}}
              iconLeft
              icon={<IoMdAdd className="text-[#18AF04]" />}
            />
            <Button
              className="py-2.5 !w-fit "
              title="Request Storage"
              onClick={() => {}}
            />
          </div>
        )} */}
      </div>
      <ModalContainer
        open={isCancelOrder}
        showCloseIcon={false}
        tailwindClassName="w-[350px] sm:w-[466px]"
        closeModal={() => setIsCancelOrder(false)}
      >
        <div className="w-full px-8 pb-8 bg-white flex flex-col  rounded-[12px] relative">
          <p className="text-lg pb-3 mt-6 border-b border-[#E4EBF5] text-center font-onestBold text-[#0A5001] ">
            Cancel {requestStatus} Request?
          </p>
          <img
            className="w-6 h-6 absolute right-6 top-6 cursor-pointer"
            onClick={() => setIsCancelOrder(false)}
            src="/images/close.svg"
            alt=""
          />
          <div className="flex justify-center mt-8">
            {" "}
            <img
              src="/images/cancelrequest.svg"
              alt=""
              className="w-[123px] h-[98px]"
            />
          </div>
          <p className="text-center text-[#4A564D] text-sm font-onestRegular mt-8">
            Are you sure you want to cancel this {requestStatus} request?
          </p>
          <div className="flex justify-between space-x-5 w-full mt-14">
            <div className="w-full">
              <Button
                title="No, don’t cancel"
                disabled={deleteLoading}
                onClick={() => setIsCancelOrder(false)}
                className="px-5  !text-[#464748] py-2 bg-transparent border rounded-lg !font-[500] !text-base"
              />
            </div>
            <div className="w-full">
              <Button
                title="Yes, cancel"
                loading={deleteLoading}
                disabled={deleteLoading}
                onClick={handleDelete}
                className="!w-full  py-2 bg-[#BA3838] border rounded-lg !font-[500] !text-base"
              />
            </div>
          </div>
        </div>
      </ModalContainer>
      <ModalContainer
        open={isCancelSuccess}
        showCloseIcon={false}
        tailwindClassName="w-[350px] sm:w-[466px]"
        closeModal={() => setIsCancelSuccess(false)}
      >
        <div className="w-full px-8 pb-8 bg-white flex flex-col  rounded-[12px] relative">
          <p className="text-lg pb-3 mt-6 border-b border-[#E4EBF5] text-center font-onestBold text-[#0A5001] ">
            {requestStatus} Request Cancelled
          </p>
          <img
            className="w-6 h-6 absolute right-6 top-6 cursor-pointer"
            onClick={() => setIsCancelSuccess(false)}
            src="/images/close.svg"
            alt=""
          />
          <div className="flex justify-center mt-8">
            {" "}
            <img
              src="/images/storeCreated.svg"
              alt=""
              className="w-[123px] h-[98px]"
            />
          </div>
          <p className="text-center text-[#4A564D] text-sm font-onestRegular mt-8">
            Your {requestStatus} request has been cancelled and will no longer
            be processed
          </p>
          <div className="flex justify-center mt-14">
            <Button
              title="Okay"
              onClick={() => {
                setIsCancelSuccess(false);
                navigate("/dashboard/inventory/requests");
              }}
              className="!w-fit !px-10 py-3 bg-[#18AF04] rounded-lg !font-[500] !text-base"
            />
          </div>
        </div>
      </ModalContainer>
    </div>
  );
};

export default StorageRequest;
