import { Button } from "components/Button";
import { Loader } from "components/Loader";
import getSymbolFromCurrency from "currency-symbol-map";
import { getFromStorage, saveToStorage } from "helpers";
import { formatMoney } from "helpers/formatMoney";
import React from "react";
import { FaCircle } from "react-icons/fa6";
import { IoWarningOutline } from "react-icons/io5";
import { ExportState, Locker, State } from "type";

interface PaymentPageProps {
  setIsPayment: React.Dispatch<React.SetStateAction<boolean>>;
  setAddMoney: React.Dispatch<React.SetStateAction<boolean>>;
  isWalletLoading: boolean;
  orderLoading: boolean;
  totalCost: number;
  walletData: any;
  items: any;
  selectedOption: any;
  onGenerate: () => void;
  onHandleImportPayment: () => void;
  onHandlePayment: (e: string) => void;
  onHandleExportPayment: (e: string) => void;
  payType: string;
  setPayType: React.Dispatch<React.SetStateAction<string>>;
  isWalletCheckoutLoading: boolean;
  fezOrgDetails: any;
  filteredCountrySearch: any;
  setAddOrder: React.Dispatch<React.SetStateAction<boolean>>;
  currencyValue: string;
  setEditOrder: React.Dispatch<React.SetStateAction<boolean>>;
  setItems: React.Dispatch<React.SetStateAction<any>>;
  weightData: any;
  filteredExportCountrySearch: any;
  filteredLockerSearch: any;
  setEditedDetails: React.Dispatch<React.SetStateAction<any>>;
  setUseSmartLocker: React.Dispatch<React.SetStateAction<boolean>>;
  setDropOffLockerName: React.Dispatch<React.SetStateAction<string>>;
  setExportCountryStateName: React.Dispatch<React.SetStateAction<string>>;
  setExportPickUpStateName: React.Dispatch<React.SetStateAction<string>>;
  setSelectedWeightOption: React.Dispatch<React.SetStateAction<any>>;
  setShowAddresses: React.Dispatch<React.SetStateAction<any>>;
  isRemovingOrder:boolean;
}

const PaymentPage = ({
  setIsPayment,
  isWalletLoading,
  totalCost,
  walletData,
  items,
  selectedOption,
  onGenerate,
  setAddMoney,
  orderLoading,
  onHandleImportPayment,
  payType,
  setPayType,
  onHandleExportPayment,
  onHandlePayment,
  isWalletCheckoutLoading,
  fezOrgDetails,
  filteredCountrySearch,
  setEditOrder,
  setAddOrder,
  currencyValue,
  setItems,
  setEditedDetails,
  weightData,
  filteredExportCountrySearch,
  filteredLockerSearch,
  setUseSmartLocker,
  setDropOffLockerName,
  setExportCountryStateName,
  setExportPickUpStateName,
  setSelectedWeightOption,
  setShowAddresses,
  isRemovingOrder
}: PaymentPageProps) => {
  const handleEdit = (uniqueID: string) => {
    setEditOrder(true);
    const updatedItem = items.find(
      (item: { uniqueID: string }) => item.uniqueID === uniqueID
    );
    setEditedDetails(updatedItem);
    const pickUpCountry = filteredCountrySearch?.find(
      (item: State) => item.id === updatedItem.importCountry
    );
    const weightSelected = weightData?.weight?.find(
      (item: ExportState) => item.id === updatedItem.exportWeight
    );
    const exportCountry = filteredExportCountrySearch?.find(
      (item: ExportState) => item.id === updatedItem.exportCountry
    );
    const lockerData = filteredLockerSearch?.find(
      (data: Locker) => data.lockerID === updatedItem.dropOffLockerAddress
    );
    if (updatedItem.dropOffLockerAddress !== "") {
      setUseSmartLocker(true);
    } else {
      setUseSmartLocker(false);
    }
    if (lockerData) {
      setDropOffLockerName(lockerData?.lockerAddress);
    }

    setSelectedWeightOption(weightSelected);
    setShowAddresses(pickUpCountry);
    setExportCountryStateName(exportCountry?.name);
    setExportPickUpStateName(updatedItem.exportPickUpState);
  };

  const handleDelete = (uniqueID: string) => {
    const updatedItem = items.filter((item: any) => item.uniqueID !== uniqueID);
    setItems(updatedItem);
    saveToStorage("items", JSON.stringify(updatedItem));
  };
  return (
    <div className="overflow-x-hidden mt-14 md:mt-0">
      <div
        onClick={() => setIsPayment(false)}
        className="mx-5 flex mb-2 md:hidden cursor-pointer"
      >
        <div>
          {" "}
          <img className="w-3 h-3" src="/images/backArrow.svg" alt="" />
        </div>
        <p className=" text-[#4a564d] font-[500] text-[13px] -mt-1 font-onestMedium">
          Go back
        </p>
      </div>
      <div className="grid lg:grid-cols-[65%_35%] gap-[22px] md:mt-[72px] mx-5 md:mx-[100px] pb-[150px]">
        <div className="border-[1px] border-[#E1F0DF] md:pt-8 px-5 md:px-8 bg-dashboard-gradient rounded-lg">
          <div className="hidden md:flex items-center justify-between">
            <div
              onClick={() => setIsPayment(false)}
              className="flex gap-1 cursor-pointer w-[100px]"
            >
              <div>
                {" "}
                <img className="w-3 h-3" src="/images/backArrow.svg" alt="" />
              </div>
              <p className="text-[#4a564d] font-[500] text-[13px] -mt-1 font-onestMedium">
                Go back
              </p>
            </div>
            <div className="flex gap-2.5">
              <div className="">
                <div
                  className={`w-[82px] h-[6px] bg-add-gradient rounded-xl`}
                ></div>
                <p className="text-[11px] flex justify-end font-[500] text-[#68726B] font-onestMedium">
                  Pick-up
                </p>
              </div>
              <div>
                <div
                  className={`w-[82px] h-[6px] bg-add-gradient rounded-xl`}
                ></div>
                <p className="text-[11px] flex justify-end font-[500] text-[#68726B] font-onestMedium">
                  Delivery
                </p>
              </div>
              <div>
                <div
                  className={`w-[82px] h-[6px] bg-add-gradient rounded-xl`}
                ></div>
                <p className="text-[11px] flex justify-end font-[500] text-[#68726B] font-onestMedium">
                  Payment
                </p>
              </div>
            </div>
          </div>
          <div className="flex md:hidden items-center justify-between mt-6 mx-4">
            <div className="relative">
              <FaCircle color="#18AF04" />
              <p className="absolute mt-1 text-[#68726B] font-[500] -left-2 text-[12px] font-onestMedium w-[100px]">
                Pick-up
              </p>
            </div>
            <div className="w-full h-0.5 bg-[#D4F3D5]"></div>
            <div className="relative">
              <FaCircle color="#18AF04" />
              <p className="absolute mt-1 text-[#68726B] font-[500] -left-3 text-[12px] font-onestMedium w-[100px]">
                Delivery
              </p>
            </div>
            <div className="w-full h-0.5 bg-[#D4F3D5]"></div>
            <div className="relative">
              <FaCircle color="#18AF04" />
              <p className="absolute mt-1 text-[#68726B] font-[500] -left-4 text-[12px] font-onestMedium w-[100px]">
                Payment
              </p>
            </div>
          </div>
          <h1 className="text-2xl mt-10 font-onestBold font-[800] text-[#0a5001] tracking-wide">
            Make Payment
          </h1>
          <p className=" text-[#4a564d] text-[13px] font-[500]">
            Local (within Nigeria)
          </p>
          <div className="bg-[#f3fbf2] mt-12 rounded-lg px-4 py-6 border-[1px] border-[#e1f0df]">
            <div className="flex justify-between items-center">
              <h4 className="text-[#0e1728] font-[500] w-full md:w-[20%] font-onestMedium text-sm">
                Wallet Balance
              </h4>
              <div className="hidden md:block w-[45%] border-b-[1px] border-dashed border-[#b8c2cf]"></div>
              <div>
                {isWalletLoading ? (
                  <Loader />
                ) : (
                  <h1
                    className={`font-[800] text-lg font-onestBold ${
                      totalCost > walletData?.balance?.business_wallet
                        ? "text-[#F64108]"
                        : "text-[#0a5001]"
                    } `}
                  >
                    ₦
                    {formatMoney().format(
                      walletData?.balance?.business_wallet +
                        Number(getFromStorage("amountAdded"))
                    )}
                  </h1>
                )}
              </div>
            </div>
            <div className="flex justify-between items-center mt-5">
              <h4 className="text-[#0e1728] font-[500] w-full md:w-[20%] font-onestMedium text-sm">
                No of orders
              </h4>
              <div className="hidden md:block w-[45%] border-b-[1px] -ml-[20%] border-dashed border-[#b8c2cf]"></div>
              <h1 className="font-[800] text-lg font-onestBold text-[#0a5001]">
                {formatMoney().format(items?.length)}
              </h1>
            </div>
          </div>
          {totalCost > walletData?.balance?.business_wallet && (
            <div className="pb-3">
              <div className="flex gap-1 border-b-[1px] mt-3 p-2 border-[#F59A49] bg-[#FAEFE5] rounded-[10px]">
                <div className="">
                  {" "}
                  <IoWarningOutline color="#F59A49" />
                </div>
                <h1 className="text-xs font-onestRegular font-[400] text-[#0E1728]">
                  Your wallet balance is insufficient, please add money
                </h1>
              </div>
              <Button
                onClick={() => {
                  onGenerate();
                  setAddMoney(true);
                }}
                title="Add Money"
                className="!w-[150px] py-4 mt-5 !bg-[#F3FBF2] border-[1px] border-[#18af04] !text-[#18af04]"
              />
            </div>
          )}

          {selectedOption?.id !== 2 && (
            <h1 className="block md:hidden font-[700] my-8 font-onestBold">
              Total: ₦{formatMoney().format(totalCost)}
            </h1>
          )}
          {selectedOption?.id === 2 ? (
            <div className="flex-col space-y-3 md:space-y-0 md:flex md:flex-row justify-between mt-10 md:mt-[120px] mb-7">
              <Button
                title="Continue with your shipment"
                loading={orderLoading}
                disabled={orderLoading}
                onClick={() => {
                  onHandleImportPayment();
                }}
                className="w-fit  py-2.5 "
              />
            </div>
          ) : (
            <div className="flex-col space-y-3 md:space-y-0 md:flex md:flex-row justify-between md:mt-[120px] mb-7">
              <Button
                title="Pay with Card"
                loading={orderLoading && payType.toLowerCase() === "paystack"}
                disabled={orderLoading || isRemovingOrder||isWalletCheckoutLoading }
                onClick={() => {
                  setPayType("paystack");
                  selectedOption?.id === 1
                    ? onHandlePayment("paystack")
                    : onHandleExportPayment("paystack");
                }}
                className="w-full  py-2.5 "
              />
              <Button
                title="Pay with Wallet"
                loading={
                  (orderLoading && payType.toLowerCase() === "wallet") ||
                  isWalletCheckoutLoading
                }
                disabled={orderLoading || isWalletCheckoutLoading || totalCost > walletData?.balance?.business_wallet || isRemovingOrder}
                onClick={() => {
                  setPayType("wallet");
                  selectedOption?.id === 1
                    ? onHandlePayment("wallet")
                    : onHandleExportPayment("wallet");
                }}
                className="w-full !bg-[#fff] border-[1px] border-[#18af04] !text-[#18af04]  py-2.5 mb-4 md:mb-0"
              />
              {fezOrgDetails?.canPayOffline === 1 && (
                <Button
                  onClick={() => {
                    setPayType("pay-later");
                    selectedOption?.id === 1
                      ? onHandlePayment("pay-later")
                      : onHandleExportPayment("pay-later");
                  }}
                  loading={
                    orderLoading && payType.toLowerCase() === "pay-later"
                  }
                  disabled={orderLoading || isWalletCheckoutLoading || isRemovingOrder}
                  title="Pay Later"
                  className="w-full md:!w-[150px] py-2.5 !bg-[#d3d2d2]  !text-[#141514]"
                />
              )}
            </div>
          )}
        </div>
        <div>
          {items.length > 0 && (
            <div className="hidden bmd:block border-[1px] border-[#E1F0DF] h-full xl:h-[526px]  rounded-lg">
              <div className="flex gap-[14px] items-center px-8 pt-8 pb-6 bg-white">
                <h1 className="text-[#0a5001] text-sm font-[700] font-onestBold">
                  MY ORDERS ({formatMoney().format(items.length)})
                </h1>
              </div>
              <div className="px-8 bg-white h-[70%]">
                {items.map((item: any, index: number) => {
                  const pickUpCountry = filteredCountrySearch.filter(
                    (state: any) => {
                      return state.id === item.importCountry;
                    }
                  );
                  return (
                    <div
                      key={index}
                      className="flex gap-3 border-b-[1px] border-[#e4ebf5] py-4"
                    >
                      <div className="bg-[#F4F5F6] w-[28px] h-[28px] flex items-center justify-center rounded-[30px]">
                        <img
                          className="w-[14px] h-[14px]"
                          src="/images/location.svg"
                          alt=""
                        />
                      </div>
                      <div className="flex justify-between w-full">
                        <div>
                          <h1 className="text-[#071227] text-sm font-onestMedium font-[500] mb-1.5">
                            {item.recipientName ||
                              item.importRecipientName ||
                              item.exportRecipientName}
                          </h1>
                          <p className="text-[#686868] flex items-center font-onestRegular text-[13px] mb-0.5">
                            <span>{item.deliveryType} </span>
                            <FaCircle
                              color="#d1d1d2"
                              size={6}
                              className="bg-[#d1d1d2] mx-2 rounded-lg"
                            />{" "}
                            <span>
                              {item.pickUpState ||
                                pickUpCountry?.[0]?.country?.name ||
                                item.exportPickUpState}
                            </span>
                          </p>
                          <div className="flex space-x-3 items-center">
                            <p
                              className="text-[#18AF04] cursor-pointer text-xs font-onestRegular"
                              onClick={() => {
                                handleEdit(item.uniqueID);
                                setIsPayment(false);
                              }}
                            >
                              Edit order
                            </p>
                            <p
                              onClick={() => {
                                handleDelete(item.uniqueID);
                                if (items.length === 1) {
                                  setAddOrder(false);
                                  setIsPayment(false);
                                }
                              }}
                              className="ml-3 text-[#F64108] cursor-pointer text-xs font-onestRegular"
                            >
                              Delete
                            </p>
                          </div>
                        </div>
                        {selectedOption?.id === 2 ? (
                          <div className="flex space-x-1 ">
                            {item.discountedCost &&
                            item.discountedCost !== item.cost ? (
                              <div className="flex space-x-1 ">
                                <p className="text-red-400 font-onestMedium line-through text-[14px] font-[500]">
                                  {getSymbolFromCurrency(currencyValue)}
                                  {formatMoney().format(
                                    item?.dropOffDiscount?.discount || item.cost
                                  )}
                                </p>
                                <p className="text-[#071527] font-onestMedium text-[14px] font-[500]">
                                  {getSymbolFromCurrency(currencyValue)}
                                  {formatMoney().format(item.discountedCost)}
                                </p>
                              </div>
                            ) : (
                              <p className="text-[#071527] font-onestMedium text-[14px] font-[500]">
                                {getSymbolFromCurrency(currencyValue)}
                                {formatMoney().format(
                                  item?.dropOffDiscount?.discount || item.cost
                                )}
                              </p>
                            )}
                          </div>
                        ) : (
                          <div className="flex space-x-1 ">
                            {item.discountedCost &&
                            item.discountedCost !== item.cost ? (
                              <div className="flex space-x-1">
                                <p className="text-red-400 font-onestMedium line-through text-[14px] font-[500]">
                                  ₦
                                  {formatMoney().format(
                                    item?.dropOffDiscount?.discount || item.cost
                                  )}
                                </p>
                                <p className="text-[#071527] font-onestMedium text-[14px] font-[500]">
                                  ₦{formatMoney().format(item.discountedCost)}
                                </p>
                              </div>
                            ) : (
                              <p className="text-[#071527] font-onestMedium text-[14px] font-[500]">
                                ₦
                                {formatMoney().format(
                                  item?.dropOffDiscount?.discount || item.cost
                                )}
                              </p>
                            )}
                          </div>
                        )}
                      </div>
                    </div>
                  );
                })}
              </div>
              <div className="px-4 xl:px-8 flex-col xl:flex xl:flex-row items-center justify-between mt-6">
                {selectedOption?.id !== 2 && (
                  <h1 className="font-[700] font-onestBold">
                    ₦{formatMoney().format(totalCost)}
                  </h1>
                )}
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default PaymentPage;
