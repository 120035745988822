export const filterTerms = [
  {
    id: 1,
    name: "Last 7 days",
    value: "last7days",
  },
  {
    id: 2,
    name: "Last month",
    value: "lastmonth",
  },
  {
    id: 3,
    name: "This year",
    value: "thisyear",
  },
  {
    id: 4,
    name: "Last year",
    value: "lastyear",
  },
  {
    id: 5,
    name: "Custom",
    value: "custom",
  },
];
export const dateRangeType: any = [
  { id: 1, name: "Last 7 days", value: "last7days" },
  { id: 2, name: "Last month", value: "lastmonth" },
  { id: 3, name: "This year", value: "thisyear" },
  { id: 4, name: "Last year", value: "lastyear" },
  { id: 5, name: "Custom date range", value: "custom" },
];
export const shipmentNavTabTitle = [
  {
    id: 1,
    name: "Add",
  },
  {
    id: 2,
    name: "Upload",
  },
  {
    id: 3,
    name: "Payment",
  },
];
export const deliveryType: any = [
  { id: 1, name: "Local (within Nigeria)" },
  { id: 2, name: "International - Sending to Nigeria (Import)" },
  { id: 3, name: "International - Sending from Nigeria (Export)" },
];
export const bulkDeliveryType: any = [
  { id: 1, name: "Local" },
  { id: 2, name: "International - Sending to Nigeria (Import)" },
  { id: 3, name: "International - Sending from Nigeria (Export)" },
];
export const deliveryModes = [
  { id: 1, name: "Bike" },
  { id: 2, name: "Van" },
];

export const sortType = [
  { id: 1, name: "Name" },
  { id: 2, name: "Email" },
  { id: 3, name: "Role" },
  { id: 4, name: "User ID" },
  { id: 5, name: "Status" },
];
export const excelSample = [
  {
    id: 1,
    name: "Local",
    data: "https://res.cloudinary.com/dxhgszxvl/raw/upload/v1729067677/FEZ_Business_Portal_Template_V5_2_nxfith.xlsx",
  },
  {
    id: 2,
    name: "International - Sending to Nigeria (Import)",
    data: "https://res.cloudinary.com/dxhgszxvl/raw/upload/v1730276235/FEZ_Business_Portal_Template_Import_V1_rewyak.xlsx",
  },
  {
    id: 3,
    name: "International - Sending from Nigeria (Export)",
    data: "https://res.cloudinary.com/dxhgszxvl/raw/upload/v1730538954/FEZ_Business_Portal_Template_Export_V1_1_ynhx4v.xlsx",
  },
];

export const filterType: any = [
  { id: 1, name: "Order status" },
  { id: 2, name: "Recipient name" },
  { id: 3, name: "Recipient phone" },
  { id: 4, name: "Recipient email" },
];

export const orderStatusList: any = [
  { id: 1, name: "Prepared For Delivery" },
  { id: 2, name: "Delivery In Progress" },
  { id: 3, name: "Delivered" },
  { id: 4, name: "Pending Pick-Up" },
  { id: 5, name: "Picked-Up" },
  { id: 6, name: "In Transit Back to you" },
  { id: 7, name: "Returned" },
];

export const initialValues = {
  deliveryType: "",
  // dropOffId: null,
  state: "",
  pickUpState: "",
  weight: "",
  pickUpAddress: "",
  recipientName: "",
  recipientPhoneNumber: "",
  recipientEmail: "",
  quantity: "",
  itemDescription: "",
  importCountry: "",
  discountCode: "",
  dropOffAddress: "",
  valueOfItem: "",
  pickUpPhoneNumber: "",
  importItem: "",
  importReferral: "",
  importDescription: "",
  importQuantity: "",
  importWeight: "",
  importRecipientEmail: "",
  importRecipientPhone: "",
  importRecipientName: "",
  importDropOffAdd: "",
  importVendorName: "",
  importOrderNumber: "",
  importShippingAdd: "",
  importDropOffState: "",
  importValueofItem: "",
  file: "",
  fileNames: "",
  exportCountry: "",
  exportRecipientName: "",
  exportWeight: "",
  exportReferral: "",
  exportItemDescription: "",
  exportQuantity: "",
  exportRecipientEmail: "",
  exportPhoneNumber: "",
  exportZipCode: "",
  exportDropOffAddress: "",
  exportDropOffCity: "",
  exportPickUpState: "",
  exportValueofItem: "",
  exportPickUpPhoneNumber: "",
  exportPickUpAddress: "",
  safeLockerDropOffState: "",
  dropOffLockerAddress: "",
  cost: "",
  fragile: false,
};

export const initialItemDetails = {
  category: "",
  categoryName: "",
  quantity: "",
  weight: "",
  subCategoryId: "",
  subCategory: "",
  size: "",
  price: "",
  description: "",
  expiryDate: "",
  image: "",
  pickUpAddress: "",
};
export const initialRetrievalItemDetails = {
  category: "",
  subCategoryId:"",
  categoryName: "",
  retrievalDate: "",
  item: "",
  quantity: "",
  deliveryAddress: "",
};
export const updateRetrievalItemDetails = {
  category: "",
  categoryName: "",
  retrievalDate: "",
  item: "",
  quantity: "",
  deliveryAddress: "",
  sub_category: "",
  weight: "",
  size: "",
  price_per_unit: "",
  description: "",
  expiry_date: "",
  image: "",
  subCategoryId: "",
};
export const deliveryMode = [
  { id: 1, name: "Fez pickup", value: "pickup" },
  { id: 2, name: "Self drop-off", value: "drop-off" },
];
export const retievalMode = [
  { id: 1, name: "Fez delivery", value: "drop-off" },
  { id: 2, name: "Self pickup", value: "pickup" },
];

export const requestTypes = [
  {
    title: `Pending Requests`,
    miniTitle: `Pending`,
    name: `pending`,
    id: 1,
  },
  {
    title: `Storage Requests`,
    miniTitle: `Storage`,
    name: `addition`,
    id: 2,
  },
  {
    title: `Retrieval Requests`,
    miniTitle: `Retrieval`,
    name: `retrieval`,
    id: 3,
  },
];

export const inventoryInitials = {
  status: "",
  description: "",
  data: {
    warehouseOrder: {
      current_page: 1,
      data: [],
      first_page_url: "",
      from: 1,
      last_page: 10,
      last_page_url: "",
      next_page_url: 1,
      path: "",
      per_page: 10,
      prev_page_url: 1,
      to: 1,
      total: 10,
    },
  },
};
export const stockInitials = {
  status: "",
  description: "",
  data: {
    stocks: {
      current_page: 1,
      data: [],
      first_page_url: "",
      from: 1,
      last_page: 10,
      last_page_url: "",
      next_page_url: 1,
      path: "",
      per_page: 10,
      prev_page_url: 1,
      to: 1,
      total: 10,
    },
  },
};

export const declineReasons = [
  { id: 1, name: "Discrepancy in quantity items sent and received" },
  { id: 2, name: "Incomplete item list" },
  { id: 3, name: "Other" },
];

export const getOptions = (status: string) => {
  switch (status) {
    case "pending inbound":
      return [
        {
          label: "View details",
          value: "VIEW_DETAILS",
          icon: "/images/eye.svg",
        },
        {
          label: "Edit details",
          value: "EDIT_DETAILS",
          icon: "/images/eye.svg",
        },
        {
          label: "Cancel",
          value: "CANCEL",
          icon: "/images/Download.svg",
        },
      ];
    case "pending outbound":
      return [
        {
          label: "View details",
          value: "VIEW_DETAILS",
          icon: "/images/eye.svg",
        },
        {
          label: "Edit details",
          value: "EDIT_DETAILS",
          icon: "/images/edit.svg",
        },
        {
          label: "Cancel",
          value: "CANCEL",
          icon: "/images/Delete.svg",
        },
      ];
    default:
      return [
        {
          label: "View details",
          value: "VIEW_DETAILS",
          icon: "/images/eye.svg",
        },
      ];
  }
};
