import { Button } from "components/Button";
import PeriodFilter from "components/common/PeriodFilter";
import MenuField from "components/dashboard/MenuField";
import PagePagination from "components/Pagination";
import { TableLoader } from "components/TableLoader";
import { getOptions } from "constant/data";
import { getApprovalStatusStyles } from "helpers/getApprovalStatusStyles";
import {
  useNavigateToEditInventory,
  useNavigateToInventory,
} from "helpers/useInventoryNavigation";
import moment from "moment";
import EmptyShipmentTable from "pages/dashboard/shipment/EmptyShipmentTable";
import { GoPencil } from "react-icons/go";
import { IoEyeOutline } from "react-icons/io5";
import { RiDeleteBin6Line } from "react-icons/ri";
import { Props } from "type/inventory";

const RetrievalInventoryRequests = ({
  data,
  page,
  setPage,
  isLoading,
  setSelectedPeriodName,
  selectedPeriod,
  handlePeriodChange,
  setFilterDate,
  filterDate,
  selectedPeriodName,
  handleInputChange,
  debounceSearch,
  handleCancel,
  setRequestStatus,
}: Props) => {
  const navigateToInventory = useNavigateToInventory();
  const navigateToEditInventory = useNavigateToEditInventory();
  return (
    <div className="">
      <div className=" md:overflow-x-auto bg-[#F9F9FB] rounded-[8px] border-[#E4EBF5] border pb-[50px] h-fit p-4 mt-10">
      <div className="flex sm:flex-row flex-col sm:gap-3">
          <div className="w-full mb-4 md:w-[279px] bg-white flex gap-2 items-center rounded-[9px] pl-4 pr-4 border-[1px] border-[#E4EBF5] ">
            <img
              className="w-[14px] h-[14px]"
              src="/images/search-icon.svg"
              alt="search-icon"
            />
            <input
              className="text-[13px] py-[10px] w-[250px] outline-none"
              type="text"
              placeholder="Search by Request ID"
              onChange={(e) => {
                handleInputChange(e.target.value);
              }}
              value={debounceSearch}
            />
          </div>
          <PeriodFilter
            filterDate={filterDate}
            setFilterDate={setFilterDate}
            setSelectedPeriodName={setSelectedPeriodName}
            selectedPeriod={selectedPeriod}
            handlePeriodChange={handlePeriodChange}
            selectedPeriodName={selectedPeriodName}
          />
        </div>

        <>
          <div className="overflow-x-auto">
            {isLoading ? (
              <TableLoader rowLength={17} />
            ) : (
              <>
                {data.data.warehouseOrder.data.length > 0 ? (
                  <>
                    <table className="hidden md:table w-full whitespace-nowrap text-left p-4 border-spacing-2 rounded-2xl md:w-full shadow-[0px 4px 8px -2px rgba(16, 24, 40, 0.1), 0px 2px 4px -2px rgba(16, 24, 40, 0.06)]">
                      <thead className="bg-white !mx-12">
                        <tr className="border-b-[1px]   border-[#E4EBF5]">
                          <th className="py-4 text-left pl-4 pr-6 text-xs text-[#929DAE] font-onestRegular">
                            Request ID
                          </th>
                          <th className="py-4 text-left pl-4 pr-6  text-xs text-[#929DAE] font-onestRegular">
                            Request Status
                          </th>
                          <th className="py-4 text-left pl-4 pr-6 text-xs text-[#929DAE] font-onestRegular">
                            Total Quantity
                          </th>
                          <th className="py-4 text-left  text-xs  pl-4 pr-6 text-[#929DAE] font-onestRegular">
                            Date requested
                          </th>

                          <th className=" text-xs text-[#929DAE] pl-4  py-5 bg-white font-onestRegular">
                            Actions
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        {data.data.warehouseOrder.data.map((inventory) => (
                          <tr
                            key={inventory.id}
                            className="bg-[#fff] py-8 border-b-[1px] font-onestRegular border-[#E4EBF5]"
                          >
                            <td className="py-4 pl-4  pr-6 text-sm text-[#0E1728] ">
                              {inventory.order_number}
                            </td>
                            <td className="text-xs pl-4 pr-6">
                              <div
                                className={`
                               ${getApprovalStatusStyles(inventory.status)}
                              w-fit  items-center rounded text-[13px] text-[#65603F] font-onestBold px-2 py-[2px]`}
                              >
                                {inventory.status.charAt(0).toUpperCase() +
                                  inventory.status.slice(1)}
                              </div>
                            </td>
                            <td className="py-3 pl-4  pr-6   text-sm text-[#0E1728] ">
                              {inventory.items.length}{" "}
                              {inventory.items.length > 1 ? "units" : "unit"}
                            </td>
                            <td className="py-3 pl-4 pr-6 text-sm text-[#0E1728] font-onestRegular">
                              {moment(inventory.created_at).format(
                                "DD MMM YYYY HH:mm:ss"
                              )}
                            </td>

                            <td className=" text-center  sticky z-[10] pl-4  right-0 py-5  gap-0.5   cursor-pointer bg-white">
                              <div className="flex space-x-4 items-center">
                                <Button
                                  className=" !w-fit !py-0 !px-0 !bg-transparent !text-[#45C734]"
                                  title="View"
                                  onClick={() => {
                                    navigateToInventory(
                                      inventory.status,
                                      inventory.type,
                                      inventory.id
                                    );
                                  }}
                                  iconLeft
                                  icon={
                                    <IoEyeOutline className="text-[#45C734]" />
                                  }
                                />
                                {(inventory.status?.trim().toLowerCase() ===
                                  "pending inbound" ||
                                  inventory.status?.trim().toLowerCase() ===
                                    "pending outbound") && (
                                  <>
                                    <Button
                                      className=" !w-fit !py-0  !px-0 !bg-transparent !text-[#1D4CC4]"
                                      title="Edit"
                                      onClick={() =>
                                        navigateToEditInventory(
                                          inventory.status,
                                          inventory.type,
                                          inventory.id
                                        )
                                      }
                                      iconLeft
                                      icon={
                                        <GoPencil className="text-[#1D4CC4]" />
                                      }
                                    />
                                    <Button
                                      className=" !w-fit !py-0 !px-0  !bg-transparent !text-[#BA3838]"
                                      title="Cancel"
                                      onClick={() => {
                                        setRequestStatus("Storage");
                                        handleCancel(inventory.id);
                                      }}
                                      iconLeft
                                      icon={
                                        <RiDeleteBin6Line className="text-[#BA3838]" />
                                      }
                                    />
                                  </>
                                )}
                              </div>
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                    <div className="block md:hidden">
                      <>
                        {data.data.warehouseOrder.data.map((inventory) => (
                          <div
                            className="bg-[#fff] md:hidden block my-3"
                            key={inventory?.id}
                          >
                            <div className=" border-[1px] flex rounded-lg p-3 justify-between pb-5 border-[#E4EBF5]">
                              <div>
                                <p className="text-sm  text-[#071227] mt-2 font-onestMedium">
                                  ID: {inventory.order_number}
                                </p>
                                <p className="text-sm  text-[#071227] mt-1 font-onestRegular">
                                  Request Type:{" "}
                                  {inventory.type.toLowerCase() === "addition"
                                    ? "Storage"
                                    : inventory.type.charAt(0).toUpperCase() +
                                      inventory.type.slice(1)}{" "}
                                </p>
                                <p className="text-sm  text-[#071227] mt-1 font-onestRegular">
                                  Date Requested:{" "}
                                  {inventory.type.toLowerCase() === "addition"
                                    ? "Storage"
                                    : inventory.type.charAt(0).toUpperCase() +
                                      inventory.type.slice(1)}{" "}
                                </p>
                              </div>

                              <MenuField
                                retrieve
                                onClick={(x: string) => {
                                  if (x === "VIEW_DETAILS") {
                                    navigateToInventory(
                                      inventory.status,
                                      inventory.type,
                                      inventory.id
                                    );
                                    return;
                                  }
                                  if (x === "EDIT") {
                                    navigateToEditInventory(
                                      inventory.status,
                                      inventory.type,
                                      inventory.id
                                    );
                                    return;
                                  }
                                  if (x === "CANCEL") {
                                    const status =
                                      inventory.type.toLowerCase() ===
                                      "addition"
                                        ? "Storage"
                                        : "Retrieval";
                                    setRequestStatus(status);
                                    handleCancel(inventory.id);
                                    return;
                                  }
                                }}
                                options={getOptions(
                                  inventory.status.toLowerCase()
                                )}
                              >
                                <div className="align-bottom mt-1 ">
                                  <svg
                                    width="24"
                                    height="24"
                                    viewBox="0 0 24 24"
                                    fill="none"
                                    xmlns="http://www.w3.org/2000/svg"
                                  >
                                    <path
                                      d="M12 18L12 18.01M12 12L12 12.01M12 6L12 6.01M12 13C11.4477 13 11 12.5523 11 12C11 11.4477 11.4477 11 12 11C12.5523 11 13 11.4477 13 12C13 12.5523 12.5523 13 12 13ZM12 19C11.4477 19 11 18.5523 11 18C11 17.4477 11.4477 17 12 17C12.5523 17 13 17.4477 13 18C13 18.5523 12.5523 19 12 19ZM12 7C11.4477 7 11 6.55228 11 6C11 5.44772 11.4477 5 12 5C12.5523 5 13 5.44772 13 6C13 6.55228 12.5523 7 12 7Z"
                                      stroke="#5C6881"
                                      strokeWidth="2"
                                      strokeLinecap="round"
                                      strokeLinejoin="round"
                                    />
                                  </svg>
                                </div>
                              </MenuField>
                            </div>
                          </div>
                        ))}
                      </>
                    </div>
                  </>
                ) : (
                  <EmptyShipmentTable
                    image="/images/invoice.svg"
                    title="You do not have any retrieval request yet"
                    content="When you have request, it will show here"
                  />
                )}
              </>
            )}
          </div>
        </>
      </div>

      {data.data.warehouseOrder.data.length > 0 &&
        data.data.warehouseOrder.total > data.data.warehouseOrder.per_page && (
          <div className="mt-3">
            <PagePagination
              totalPages={data.data.warehouseOrder.last_page}
              page={page}
              handlePageClick={(event) => {
                setPage(event.selected);
              }}
            />
          </div>
        )}
    </div>
  );
};

export default RetrievalInventoryRequests;
