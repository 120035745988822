import DatePicker from "react-datepicker";
import moment from "moment";
import { useCallback, useEffect, useState } from "react";
import ModalContainer from "components/Modal";
import { Button } from "components/Button";
import { IoEyeOutline } from "react-icons/io5";
import PagePagination from "components/Pagination";
import { TableLoader } from "components/TableLoader";
import { formatMoney } from "helpers/formatMoney";
import { stockInitials } from "constant/data";
import { getDateRange } from "helpers/getDateRange";
import { debouncedFunction } from "helpers/debouncedSearch";
import { useViewInventoryQuery } from "services/slices/inventory";
import { StockResponse } from "type/inventory";
import PeriodFilter from "components/common/PeriodFilter";
import { useNavigate } from "react-router-dom";
import { FiEye } from "react-icons/fi";
import { Loader } from "components/Loader";

function ViewInventory() {
  const navigate = useNavigate();
  const [filterDate, setFilterDate] = useState(false);
  const [showDateModal, setShowDateModal] = useState<boolean>(false);
  const [startDate, setStartDate] = useState<Date | null>(null);
  const [endDate, setEndDate] = useState<Date | null>(null);
  const [formattedDate, setFormattedDate] = useState<string>("");
  const [formattedEndDate, setFormattedEndDate] = useState<string>("");
  const [page, setPage] = useState(0);
  const [search, setSearch] = useState<string>("");
  const [debounceSearch, setDebounceSearch] = useState<string>("");
  const [stockEndDate, setStockEndDate] = useState<string>(
    moment().add(1, "days").format("YYYY-MM-DD")
  );
  const [stockStartDate, setStockStartDate] = useState<string>(
    moment().subtract(7, "days").format("YYYY-MM-DD")
  );
  const [stocks, setStocks] = useState<StockResponse>(stockInitials);
  const [selectedPeriodName, setSelectedPeriodName] = useState("Last 7 days");
  const [selectedPeriod, setSelectedPeriod] = useState("last7days");
  const {
    data,
    isLoading: isInventoryLoading,
    isFetching,
  } = useViewInventoryQuery(
    {
      filter: search,
      start_date: stockStartDate,
      end_date: stockEndDate,
      page: page + 1,
    },
    {
      refetchOnMountOrArgChange: true,
      refetchOnFocus: false,
    }
  );

  const debouncedSetSearch = useCallback((value: string) => {
    debouncedFunction(value, setSearch);
  }, []);
  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    debouncedSetSearch(e.target.value);
    setDebounceSearch(e.target.value);
    setPage(0);
  };

  const onClearDate = () => {
    setSelectedPeriodName("Last 7 days");
    setSelectedPeriod("last7days");
    setShowDateModal(false);
    setStockStartDate(moment().subtract(7, "days").format("YYYY-MM-DD"));
    setStockEndDate(moment().add(1, "days").format("YYYY-MM-DD"));
  };

  const handlePeriodChange = (value: string) => {
    setSelectedPeriod(value);
  };

  const handleDateChange = (date: Date | null) => {
    if (date) {
      const dateStart = moment(date).format("DD-MM-YYYY");
      setStartDate(date);
      setFormattedDate(dateStart);
    }
  };

  const handleEndDateChange = (date: Date | null) => {
    if (date) {
      const dateStart = moment(date).format("DD-MM-YYYY");
      setEndDate(date);
      setFormattedEndDate(dateStart);
    }
  };

  useEffect(() => {
    if (Boolean(selectedPeriod.toLowerCase() === "custom")) {
      setShowDateModal(true);
    } else {
      setShowDateModal(false);
    }
  }, [selectedPeriod]);

  useEffect(() => {
    if (selectedPeriod && selectedPeriod.toLowerCase() !== "custom") {
      const { startDate, endDate } = getDateRange(selectedPeriod);
      setStockStartDate(startDate);
      setStockEndDate(endDate);
    }
  }, [selectedPeriod]);

  useEffect(() => {
    if (data) setStocks(data);
  }, [data]);

  const onApplyDate = () => {
    setStockStartDate(formattedDate);
    setStockEndDate(formattedEndDate);
    setPage(0);
    setShowDateModal(false);
  };

  return (
    <div>
      <div className="md:px-[72px] px-4">
        <div className="flex justify-between items-center mt-10">
          <h1 className="text-2xl font-onestBold font-[800] text-[#0A5001] tracking-wide">
            Inventory
          </h1>
        </div>
        <div className="bg-[#F9F9FB] p-4 mt-10">
          <div className="flex gap-3 items-center justify-between ">
            <div className="flex sm:flex-row flex-col gap-3 w-full">
              <div className="w-full sm:w-[279px] bg-white flex gap-2 items-center rounded-[9px] pl-4 pr-4 border-[1px] border-[#E4EBF5] ">
                <img
                  className="w-[14px] h-[14px]"
                  src="/images/search-icon.svg"
                  alt="search-icon"
                />
                <input
                  className="text-[13px] py-[10px] w-full sm:w-[250px] outline-none"
                  type="text"
                  placeholder="Search by SKU or item name"
                  onChange={(e) => {
                    handleInputChange(e);
                  }}
                  value={debounceSearch}
                />
              </div>
              <PeriodFilter
                filterDate={filterDate}
                setFilterDate={setFilterDate}
                setSelectedPeriodName={setSelectedPeriodName}
                selectedPeriod={selectedPeriod}
                handlePeriodChange={handlePeriodChange}
                selectedPeriodName={selectedPeriodName}
              />
            </div>
          </div>
          {stocks?.data.stocks.data.length > 0 &&
            !isInventoryLoading &&
            !isFetching && (
              <>
                <div className="overflow-x-auto hidden md:block">
                  <table className="whitespace-nowrap mt-3 w-full">
                    <>
                      <thead className="bg-white !mx-12">
                        <tr className="border-b-[1px] py-5 pl-4 pr-6 border-[#E4EBF5]">
                          <th className="py-5 text-left pl-4 pr-6 text-xs text-[#929DAE] font-onestRegular">
                            Item ID/SKU
                          </th>
                          <th className="py-5 text-left pl-5 pr-6 text-xs text-[#929DAE] font-onestRegular">
                            Item name
                          </th>
                          <th className="py-3 text-left  text-xs  pl-6 pr-4 text-[#929DAE] font-onestRegular">
                            Category
                          </th>
                          <th className="py-3 text-left  text-xs  pl-6 pr-4 text-[#929DAE] font-onestRegular">
                            Sub-cateogry
                          </th>
                          <th className="py-3 text-left  text-xs  pl-5 pr-4 text-[#929DAE] font-onestRegular">
                            Quantity in stock
                          </th>
                          <th className="py-5 sticky right-0 bg-white text-xs text-[#929DAE] font-onestRegular">
                            Action
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        {stocks?.data.stocks.data?.map((item) => (
                          <tr
                            key={item.id}
                            className="bg-[#fff] py-8 border-b-[1px] border-[#E4EBF5]"
                          >
                            <td className="py-6 pl-4  pr-6 text-sm text-[#161718] font-onestRegular">
                              {item.product.sku}
                            </td>
                            <td className="py-3 pl-4 pr-6  text-sm font-onestRegular">
                              {item.product.name}
                            </td>

                            <td className="text-xs pl-4 pr-10">
                              <span className="text-sm text-[#0E1728] px-2 py-1 rounded-md font-[400] font-onesRegular">
                                {item.product.category.name}
                              </span>
                            </td>
                            <td className="py-3 pl-6 pr-6 text-sm  font-onestRegular">
                              <span className="">
                                {formatMoney().format(item.price_per_unit) ||
                                  "N/A"}
                              </span>
                            </td>
                            <td className="text-xs   pl-4 pr-10">
                              <div className="px-2 py-1 space-x-1 flex items-center">
                                <div
                                  className={` ${
                                    Number(item.quantity) < 0
                                      ? "bg-[#BA3838]"
                                      : "bg-[#18AF04]"
                                  } w-2 h-2 rounded-full`}
                                ></div>
                                <p
                                  className={`text-sm ${
                                    Number(item.quantity) < 0
                                      ? "text-[#BA3838]"
                                      : "text-[#0E1728] "
                                  }  rounded-md font-[400] font-onestRegular`}
                                >
                                  {formatMoney().format(item.quantity)}
                                </p>
                              </div>
                            </td>

                            <td className="pr-1.5 text-center pl-4 z-0  py-5 flex gap-0.5  justify-center cursor-pointer bg-white">
                              <Button
                                className=" !w-fit !py-0 !px-0 !bg-transparent !text-[#45C734]"
                                title="View"
                                onClick={() =>
                                  navigate(
                                    `/dashboard/inventory/view-inventory/${item.product_id}`
                                  )
                                }
                                iconLeft
                                icon={
                                  <IoEyeOutline className="text-[#45C734]" />
                                }
                              />
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </>
                  </table>
                </div>
                {!isInventoryLoading &&
                  stocks.data.stocks.data.length > 0 &&
                  stocks.data.stocks.total > stocks.data.stocks.per_page && (
                    <div className="mt-3">
                      <PagePagination
                        totalPages={stocks.data.stocks.last_page}
                        page={page}
                        handlePageClick={(event) => setPage(event.selected)}
                      />
                    </div>
                  )}
              </>
            )}
          {stocks.data.stocks.data.length === 0 &&
            !isInventoryLoading &&
            !isFetching && (
              <div className="flex justify-center min-h-[400px] items-center py-[32px] w-full">
                <div className="">
                  <div className="flex justify-center pb-5 w-full">
                    {" "}
                    <img src="/images/new-request.svg" alt="" />
                  </div>
                  <p className="font-onestRegular mb-2 bmd:w-full text-sm text-[#4A564D]">
                    You do not have any inventory for this period
                  </p>
                  <p className="font-onestRegular w-[100%] bmd:w-full text-xs text-center text-[#A0ABA3]">
                    When you make a request, it will show here
                  </p>
                </div>
              </div>
            )}
          {(isInventoryLoading || isFetching) && (
            <>
              <div className="md:block hidden">
                <TableLoader rowLength={17} />
              </div>
              <div className="md:hidden block my-5">
                <Loader />
              </div>
            </>
          )}
          <div className="block md:hidden">
            {stocks?.data.stocks.data.length > 0 &&
              !isInventoryLoading &&
              !isFetching && (
                <>
                  {stocks?.data.stocks.data?.map((item) => (
                    <div
                      className="bg-[#fff] md:hidden block my-3"
                      key={item?.id}
                    >
                      <div className=" border-[1px] flex rounded-lg p-3 justify-between pb-5 border-[#E4EBF5]">
                        <div>
                          <p className="text-sm  text-[#071227] mt-2 font-onestMedium">
                            SKU: {item.product.sku}
                          </p>
                          <p className="text-sm  text-[#071227] mt-1 font-onestRegular">
                            Item Name: {item.product.name}
                          </p>
                          <p className="pt-1 text-sm text-[#071227] mb-1">
                            Price: ₦
                            {formatMoney().format(item.price_per_unit) || "N/A"}
                          </p>
                          <div className=" py-1 space-x-1 flex items-center">
                            <div
                              className={` ${
                                Number(item.quantity) < 0
                                  ? "bg-[#BA3838]"
                                  : "bg-[#18AF04]"
                              } w-2 h-2 rounded-full`}
                            ></div>
                            <p
                              className={`text-sm ${
                                Number(item.quantity) < 0
                                  ? "text-[#BA3838]"
                                  : "text-[#0E1728] "
                              }  rounded-md font-[400] font-onestRegular`}
                            >
                              {formatMoney().format(item.quantity)}
                            </p>
                          </div>
                        </div>

                        <Button
                          icon={<FiEye className="text-[#45C734]" />}
                          title={"View"}
                          iconLeft={true}
                          onClick={() =>
                            navigate(
                              `/dashboard/inventory/view-inventory/${item.product_id}`
                            )
                          }
                          className="!pl-2 !bg-transparent !text-[#45C734] !pr-2 !pt-1 !pb-1 !rounded-[4px]"
                        />
                      </div>
                    </div>
                  ))}
                </>
              )}
          </div>
        </div>
      </div>
      <ModalContainer
        open={showDateModal}
        showCloseIcon={false}
        tailwindClassName="max-w-[466px]"
      >
        <div className="w-full p-8 bg-white flex flex-col  rounded-[12px]">
          <p className="text-lg text-center pb-4 font-onestBold text-[#0A5001] ">
            Custom date range
          </p>
          <div className="flex gap-3 justify-between">
            <div className="">
              <DatePicker
                placeholderText={"Start date"}
                selected={startDate}
                onChange={handleDateChange}
                className="border-[1px] border-[#E4EBF5] w-[150px] p-2.5 rounded-lg outline-none"
              />
            </div>
            <div className="">
              <DatePicker
                placeholderText={"End date"}
                selected={endDate}
                onChange={handleEndDateChange}
                className="border-[1px] border-[#E4EBF5] w-[150px] p-2.5 rounded-lg outline-none"
              />
            </div>
          </div>
          <div className="flex justify-between gap-3 items-center pt-[30px]">
            <Button
              title="Clear"
              onClick={onClearDate}
              className="!py-[10px] !border-[#BEC0BE] !px-12 !w-fit !rounded-lg !bg-transparent !border !text-[#289D17]"
            />
            <Button
              title="Apply"
              className="!py-[10px] !w-fit !rounded-lg !px-12 !bg-[#127E04] !text-[#fff]"
              onClick={onApplyDate}
            />
          </div>
        </div>
      </ModalContainer>
    </div>
  );
}

export default ViewInventory;
