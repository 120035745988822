import { Button } from "components/Button";
import { TextInput } from "components/common/form/Input";
import Dropdown from "components/common/form/SelectDropdown";
import { deliveryMode } from "constant/data";
import React, { useEffect, useState } from "react";
import { AiFillPicture } from "react-icons/ai";
import { useGetProductsQuery } from "services/slices/inventory";

interface Props {
  setIsStoreItem?: React.Dispatch<React.SetStateAction<boolean>>;
  warehouseDeliveryMode: string;
  setWarehouseDeliveryMode: React.Dispatch<React.SetStateAction<string>>;
  formErrors: any;
  categoryName: string;
  itemDetails: any;
  searchCategory: string;
  setSearchCategory: React.Dispatch<React.SetStateAction<string>>;
  setCategoryName: React.Dispatch<React.SetStateAction<string>>;
  setItemDetails: React.Dispatch<React.SetStateAction<any>>;
  setFormErrors: React.Dispatch<React.SetStateAction<any>>;
  onSubmit: () => void;
  isEdit: boolean;
  isAddNew: boolean;
  setIsAddNew: React.Dispatch<React.SetStateAction<boolean>>;
}
interface Item {
  id: number;
  name: string;
  product_category_id: number;
  weight: string;
  size: string;
  price_per_unit: string;
  description: string;
  expiry_date: string;
  image: string;
  subCategoryId: string;
}

const CreateStorageItem = ({
  setIsStoreItem,
  warehouseDeliveryMode,
  setWarehouseDeliveryMode,
  formErrors,
  setIsAddNew,
  itemDetails,
  setItemDetails,
  setFormErrors,
  onSubmit,
  isEdit,
  isAddNew,
}: Props) => {
  const [searchCategory, setSearchCategory] = useState<string>("");
  const [searchItem, setSearchItem] = useState<string>("");
  const [items, setItems] = useState<Item[]>([]);
  const { data, isLoading } = useGetProductsQuery("addition");
  const isValid =
    warehouseDeliveryMode !== "" &&
    itemDetails.category !== "" &&
    itemDetails.subCategory !== "" &&
    itemDetails.quantity &&
    ((warehouseDeliveryMode === "pickup" && itemDetails.pickUpAddress !== "") ||
      (warehouseDeliveryMode !== "pickup" && itemDetails.pickUpAddress === ""));

  useEffect(() => {
    const selectedCategory = data?.data.categories?.find(
      (category) => category.name === itemDetails.categoryName
    );

    setItems(selectedCategory?.products || []);
  }, [data?.data.categories, itemDetails.categoryName]);

  const filteredCategories = data?.data.categories
    ?.filter(
      (category) =>
        category.name.toLowerCase().includes(searchCategory.toLowerCase()) ||
        category.products.some((product) =>
          product.name.toLowerCase().includes(searchCategory.toLowerCase())
        )
    )
    ?.sort((a, b) => a.name.localeCompare(b.name));

  const filteredProducts = items
    ?.filter((product) =>
      product.name.toLowerCase().includes(searchItem.toLowerCase())
    )
    ?.sort((a, b) => a.name.localeCompare(b.name));

  return (
    <div className="mt-14 md:mt-0 overflow-x-hidden ">
      <div className="mt-7 sm:mt-[72px] rounded-lg max-w-[38rem] mx-4 md:mx-auto border-[1px] border-[#E1F0DF] flex flex-col bg-dashboard-gradient justify-center">
        <div
          onClick={() => {
            isAddNew
              ? setIsAddNew(false)
              : setIsStoreItem && setIsStoreItem(false);
          }}
          className="flex mb-8 mt-5 px-4 sm:px-10 pt-6 sm:pt-8 font-onestMedium"
        >
          <div>
            {" "}
            <img className="w-3 h-3" src="/images/backArrow.svg" alt="" />
          </div>
          <p className=" text-[#4a564d] font-[500] text-[13px] -mt-1 font-onestMedium">
            Go back
          </p>
        </div>
        <div className="w-full px-4 sm:px-10 font-onestMedium">
          <h1 className="md:text-2xl text-xl font-onestBold font-[800] text-[#0a5001] tracking-wide">
            Store Item in Warehouse
          </h1>
          <p className="text-[#a0aba3] font-[500] font-onestRegular tracking-normal mb-12">
            Add items to storage
          </p>

          <form
            className={` `}
            onSubmit={(e) => {
              e.preventDefault();
              onSubmit();
            }}
          >
            {!isAddNew && (
              <>
                <div className="flex">
                  <p className="text-[#4A564D] mb-2 text-sm leading-[20px] font-onestMedium">
                    Warehouse delivery mode
                  </p>
                  <span className="text-red-500 text-sm">*</span>
                </div>
                <div className="flex sm:flex-row flex-col w-full sm:space-y-0 space-y-3 sm:space-x-2 items-center">
                  {deliveryMode.map((mode) => (
                    <div
                      key={mode.id}
                      className={`flex border ${
                        warehouseDeliveryMode === mode.value
                          ? "border-[#289D17]"
                          : "border-borderGrey"
                      } w-full py-2 px-3 rounded-[8px] items-center space-x-3`}
                    >
                      <div
                        onClick={() => {
                          if (!isEdit) setWarehouseDeliveryMode(mode.value);
                          setFormErrors((prev: any) => ({
                            ...prev,
                            deliveryMode: "",
                          }));
                        }}
                        className={`w-[18px] flex justify-center items-center cursor-pointer h-[18px] border ${
                          warehouseDeliveryMode === mode.value
                            ? "border-[#289D17]"
                            : "border-borderGrey"
                        }  rounded-full`}
                      >
                        {warehouseDeliveryMode === mode.value && (
                          <div className="w-[10px] h-[10px] bg-[#289D17] rounded-full"></div>
                        )}
                      </div>
                      <p className="text-[#9899A1] text-sm">{mode.name}</p>
                    </div>
                  ))}
                  {formErrors.deliveryMode && (
                    <span className="text-xs text-[#BA3838] font-onestMexdium">
                      {formErrors.deliveryMode}
                    </span>
                  )}
                </div>
                {warehouseDeliveryMode === "pickup" && (
                  <div className="mt-6">
                    <TextInput
                      type="text"
                      label="Pick-up address"
                      isRequired
                      placeholder="Enter pick-up address"
                      name="pickUpAddress"
                      value={itemDetails.pickUpAddress}
                      onChange={(e) => {
                        setItemDetails((prev: any) => ({
                          ...prev,
                          pickUpAddress: e.target.value,
                        }));
                        setFormErrors((prev: any) => ({
                          ...prev,
                          deliveryAddress: "",
                        }));
                      }}
                    />
                    {formErrors.pickUpAddress && (
                      <span className="text-xs text-[#BA3838] font-onestMedium">
                        {formErrors.pickUpAddress}
                      </span>
                    )}
                  </div>
                )}
                <hr className="my-5 border border-borderGrey" />
              </>
            )}

            <div className="mt-6">
              <div className="flex">
                <p className="text-[#4A564D] mb-2 text-sm leading-[20px] font-onestMedium">
                  Category
                </p>
                <span className="text-red-500 text-sm">*</span>
              </div>
              <Dropdown
                loader={isLoading}
                selectPlaceholderName="category"
                dropdownStateName={itemDetails.categoryName}
                dropdownValue={itemDetails?.category}
                searchSubject={searchCategory}
                search
                setSearchSubject={setSearchCategory}
                dropdownContainerClasses={`shadow-cardShadow-4 !w-full !right-0  border border-slate-100  rounded `}
                dropdownOptions={[
                  {
                    customChild: (
                      <div>
                        {filteredCategories && filteredCategories.length > 0 ? (
                          <div>
                            {filteredCategories?.map((item) => {
                              return (
                                <div
                                  key={item.id}
                                  onClick={() => {
                                    setItemDetails((prev: any) => ({
                                      ...prev,
                                      category: item.id,
                                      categoryName: item.name,
                                      subCategoryId: "",
                                      subCategory: "",
                                      weight: "",
                                      size: "",
                                      price: "",
                                      description: "",
                                      image: "",
                                    }));
                                    setItems(item.products);
                                    setSearchCategory("");
                                    setFormErrors((prev: any) => ({
                                      ...prev,
                                      category: "",
                                    }));
                                  }}
                                >
                                  <div className="px-3 py-2  text-sm font-onestMedium text-[#4a564d] hover:bg-[#edecec] cursor-pointer">
                                    <p>{item?.name}</p>
                                  </div>
                                </div>
                              );
                            })}
                          </div>
                        ) : (
                          <p className="text-sm">No data</p>
                        )}
                      </div>
                    ),
                  },
                ]}
              />
              {formErrors.category && (
                <span className="text-xs text-[#BA3838] font-onestMedium">
                  {formErrors.category}
                </span>
              )}
            </div>
            <div className="mt-6">
              <div className="flex">
                <p className="text-[#4A564D] mb-2 text-sm leading-[20px] font-onestMedium">
                  Sub-category
                </p>
                <span className="text-red-500 text-sm">*</span>
              </div>
              <Dropdown
                loader={isLoading}
                selectPlaceholderName="sub-category"
                dropdownStateName={itemDetails.subCategory}
                dropdownValue={itemDetails.subCategoryId}
                searchSubject={searchItem}
                setSearchSubject={setSearchItem}
                search
                dropdownContainerClasses={`shadow-cardShadow-4 !w-full !right-0  border border-slate-100  rounded `}
                dropdownOptions={[
                  {
                    customChild: (
                      <div>
                        {filteredProducts && filteredProducts.length > 0 ? (
                          <div>
                            {filteredProducts?.map((item) => {
                              return (
                                <div
                                  key={item.id}
                                  onClick={() => {
                                    setItemDetails((prev: any) => ({
                                      ...prev,
                                      subCategoryId: item.id,
                                      subCategory: item.name,
                                      weight: item.weight,
                                      size: item.size,
                                      price: item.price_per_unit,
                                      description: item.description,
                                      expiryDate: item.expiry_date,
                                      image: item.image,
                                    }));
                                    setSearchItem("");
                                    setFormErrors((prev: any) => ({
                                      ...prev,
                                      subCategory: "",
                                    }));
                                  }}
                                >
                                  <div className="px-3 py-2  text-sm font-onestMedium text-[#4a564d] hover:bg-[#edecec] cursor-pointer">
                                    <p>{item?.name}</p>
                                  </div>
                                </div>
                              );
                            })}
                          </div>
                        ) : (
                          <>
                            {items.length === 0 ? (
                              <p className="text-xs text-red-500 ml-2">
                                Select categotory
                              </p>
                            ) : (
                              <p className="text-sm">No data</p>
                            )}
                          </>
                        )}
                      </div>
                    ),
                  },
                ]}
              />
              {formErrors.subCategory && (
                <span className="text-xs text-[#BA3838] font-onestMedium">
                  {formErrors.subCategory}
                </span>
              )}
            </div>
            <div className="mt-6 flex sm:flex-row flex-col w-full sm:space-y-0 space-y-6 sm:space-x-3">
              <div className="w-full">
                <TextInput
                  type="number"
                  label="Quantity to store"
                  placeholder="Enter quantity"
                  isRequired
                  value={itemDetails.quantity}
                  name="quantity"
                  onChange={(e) => {
                    const value = Math.max(0, Number(e.target.value));
                    setItemDetails((prev: any) => ({
                      ...prev,
                      quantity: value,
                    }));
                    setFormErrors((prev: any) => ({
                      ...prev,
                      quantity: "",
                    }));
                  }}
                />

                {formErrors.quantity && (
                  <span className="text-xs text-[#BA3838] font-onestMedium">
                    {formErrors.quantity}
                  </span>
                )}
              </div>
              <div className="w-full">
                <p className="text-[#4A564D] text-sm leading-[20px] font-onestMedium">
                  Cost value per item
                </p>
                <div
                  className={`h-[42px] flex ${
                    itemDetails.price === "" && "text-[#4A564D]"
                  }  text-sm px-3 pt-[8px] mt-[8px]   
       border  rounded-[8px]  `}
                >
                  <p>₦ {itemDetails.price === "" ? "-" : itemDetails.price}</p>
                </div>
              </div>
            </div>
            <div className="mt-6 flex sm:flex-row flex-col w-full sm:space-y-0 space-y-6 sm:space-x-3">
              <div className="w-full">
                <p className="text-[#4A564D] text-sm leading-[20px] font-onestMedium">
                  Item weight
                </p>
                <div
                  className={`flex justify-between ${
                    itemDetails.weight === "" && "text-[#4A564D]"
                  } py-2 px-3 mt-[8px]   
       border  rounded-[8px] items-center `}
                >
                  <p> {itemDetails.weight === "" ? "-" : itemDetails.weight}</p>
                  <p>kg</p>
                </div>
              </div>
              <div className="w-full">
                <p
                  className={`${
                    itemDetails.weight === "" && "text-[#4A564D]"
                  } text-sm leading-[20px] font-onestMedium`}
                >
                  Item size
                </p>
                <div
                  className={` py-2 px-3 mt-[8px]   
       border  rounded-[8px] items-center `}
                >
                  <p> {itemDetails.size === "" ? "-" : itemDetails.size}</p>
                </div>
              </div>
            </div>
            <div className="mt-6">
              <div className="w-full">
                <p
                  className={`${
                    itemDetails.description === "" && "text-[#4A564D]"
                  } text-sm leading-[20px] font-onestMedium`}
                >
                  Description
                </p>
                <div
                  className={`py-2 h-20 px-3 mt-[8px]   
       border  rounded-[8px]  `}
                >
                  <p>
                    {" "}
                    {itemDetails.description === ""
                      ? "-"
                      : itemDetails.description}
                  </p>
                </div>
              </div>
            </div>
            <div className="mt-6">
              <TextInput
                type="date"
                label="Expiry date"
                placeholder="Enter date"
                name="expiryDate"
                min={new Date().toISOString().split("T")[0]}
                onChange={(e) => {
                  setItemDetails((prev: any) => ({
                    ...prev,
                    expiryDate: e.target.value,
                  }));
                  setFormErrors((prev: any) => ({
                    ...prev,
                    expiryDate: "",
                  }));
                }}
                value={itemDetails.expiryDate?.split(" ")[0] || ""}
              />

              {formErrors.expiryDate && (
                <span className="text-xs text-[#BA3838] font-onestMedium">
                  {formErrors.expiryDate}
                </span>
              )}
            </div>
            <div className="mt-6">
              <p className="text-[#4A564D] text-sm leading-[20px] font-onestMedium">
                Item photo
              </p>
              {itemDetails.image === "" ? (
                <div
                  className={`flex flex-col justify-center items-center py-2 px-3 h-[104px] bg-[#F4F5F6] mt-[8px]   
             border  rounded-[8px] `}
                >
                  <AiFillPicture className="text-[#68766B] text-xl" />
                  <p className="text-[#68766B] text-sm">
                    Item photo will be displayed here
                  </p>
                </div>
              ) : (
                <div
                  className={`flex flex-col justify-center items-center py-2 px-3  mt-[8px]   
             border  rounded-[8px] `}
                >
                  <img src={itemDetails.image} alt={itemDetails.sub_category} />
                </div>
              )}
            </div>
            <div className="mt-6 mb-8">
              <Button
                title="Add Item"
                className={`!py-[10px] !rounded-lg !px-12 ${
                  !isValid ? "!bg-[#8C9D8A]" : "!bg-[#18AF04]"
                }  !text-[#fff]`}
                type="submit"
                disabled={!isValid}
              />
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

export default CreateStorageItem;
