import { useNavigate } from "react-router-dom";
import { warehouseData, warehouseStats } from "../../../variables/data";
import { useEffect, useState } from "react";
import "react-toastify/dist/ReactToastify.css";
import { formatMoney } from "helpers/formatMoney";
import { Loader } from "components/Loader";
import { useWarehouseDashboardQuery } from "services/slices/inventory";
import { Stats } from "type/inventory";



function Inventory() {
  const navigate = useNavigate();
  const [statistics, setStatistics] = useState<Stats>({
    items_in_storage: 0,
    items_retrieved: 0,
    pending_request: 0,
    value_of_stock: 0,
  });

  const { data, isLoading } = useWarehouseDashboardQuery();

  useEffect(() => {
    setStatistics({
      items_in_storage: data?.data.items_in_storage || 0,
      items_retrieved: data?.data.items_retrieved || 0,
      pending_request: data?.data.pending_request || 0,
      value_of_stock: data?.data.value_of_stock || 0,
    });
  }, [data]);

  return (
    <>
      <div className="pl-5  pr-5 md:pl-[40px] md:pr-[55px] overflow-auto ">
        <div className="flex flex-col md:flex-row justify-between pt-[60px] font-onestRegular">
          <div className="">
            <h1 className="text-[#0A5001] font-[900] text-xl md:text-2xl font-onestBold">
              Warehouse Management System
            </h1>
            <p className="text-[#A0ABA3] pt-10 text-sm font-onestRegular">
              What would you like to do?
            </p>
          </div>
        </div>
        <div className="grid grid-cols-1 sm:grid-cols-2 bmd:grid-cols-4 gap-3 md:gap-6 mt-4">
          {warehouseStats.map((item) => (
            <div
              onClick={() => navigate(`/dashboard/inventory/${item.link}`)}
              key={item.id}
              className={` flex justify-between cursor-pointer items-center border-[1px] border-[#E4EBF5] py-2 sm:py-1 rounded-lg pl-[18px] pr-2.5`}
            >
              <h1
                className={`text-[13px] font-[600] font-onestMedium w-[110px]`}
              >
                {item.title}
              </h1>
              <img src={item.image} alt={item.title} />
            </div>
          ))}
        </div>

        <div className="flex relative flex-col md:flex-row md:justify-between gap-[14px] md:gap-0 md:items-center mt-14">
          <h1 className="text-[#A0ABA3] text-sm font-[500]">
            Warehouse Overview
          </h1>
        </div>
        {isLoading ? (
          <Loader />
        ) : (
          <div className="grid md:grid-cols-2  gap-x-[31px] gap-y-5 mt-4">
            {warehouseData.map((item: any, idx) => (
              <div
                key={idx}
                className="flex gap-3 rounded-lg items-center border-[1px] border-[#E4EBF5]"
              >
                <div
                  style={{
                    background: `linear-gradient(to bottom, #ffffff, ${item.color})`,
                  }}
                  className={`rounded-bl-lg rounded-tl-lg px-4   w-[60px] py-6 flex justify-center items-center`}
                >
                  <img src={item.image} alt={item.title} />
                </div>
                <div>
                  <h1 className="text-[#4A564D] text-sm">{item.title}</h1>
                  {item.title.toLowerCase() === "items in storage" && (
                    <p className="text-[#071227] font-[700] font-onestMedium text-xl">
                      {(statistics.items_in_storage &&
                        formatMoney().format(statistics.items_in_storage)) ||
                        0}
                    </p>
                  )}
                  {item.title.toLowerCase() === "pending requests" && (
                    <p className="text-[#071227] font-[700] font-onestMedium text-xl">
                      {(statistics.pending_request &&
                        formatMoney().format(statistics.pending_request)) ||
                        0}
                    </p>
                  )}
                  {item.title.toLowerCase() === "value of items in storage" && (
                    <p className="text-[#071227] font-[700] font-onestMedium text-xl">
                      {(statistics.value_of_stock &&
                        formatMoney().format(statistics.value_of_stock)) ||
                        0}
                    </p>
                  )}
                  {item.title.toLowerCase() === "items retrieved" && (
                    <p className="text-[#071227] font-[700] font-onestMedium text-xl">
                      {(statistics.items_retrieved &&
                        formatMoney().format(statistics.items_retrieved)) ||
                        0}
                    </p>
                  )}
                </div>
              </div>
            ))}
          </div>
        )}
      </div>
    </>
  );
}

export default Inventory;
