import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { getFromStorage } from "helpers";

interface WalletTransactionParams {
  startDate: string;
  endDate: string;
  page:number;
}

export const wallet = createApi({
  reducerPath: "wallet",
  baseQuery: fetchBaseQuery({
    baseUrl: `${process.env.REACT_APP_URL}`,
    prepareHeaders: (headers) => {
      headers.set("Content-Type", "application/json");
      const token = getFromStorage("token");
      const secretKey = getFromStorage("secret-key");
      if (token) {
        headers.set("Authorization", `Bearer ${token}`);
        headers.set("secret-key", `${secretKey}`);
      }
      return headers;
    },
  }),
  endpoints: (builder) => ({
    getWalletBalance: builder.query<any, void>({
      query: () => ({
        url: `wallets/balance`,
        method: "GET",
      }),
    }),
    getWalletTransaction: builder.query<any, WalletTransactionParams>({
      query: ({startDate, endDate, page}) => ({
        url: `wallets/transactions?startDate=${startDate}&endDate=${endDate}&page=${page}`,
        method: "GET",
      }),
    }),
    walletCheckout: builder.mutation<any, any>({
      query: (body) => ({
        url: `wallets/checkout`,
        method: "POST",
        body,
      }),
    }),
  }),
});

export const { useGetWalletBalanceQuery, useWalletCheckoutMutation, useGetWalletTransactionQuery } = wallet;
