import { TextInput } from "components/common/form/Input";
import Dropdown from "components/common/form/SelectDropdown";
import { TextArea } from "components/common/form/textArea";
import ToggleButton from "components/ToggleButton";
import { handleFileChange } from "helpers/validation";
import React from "react";
import { PiCopySimpleLight } from "react-icons/pi";
import { ImportFormProps } from "type";

const MobileImportDeliveryForm = ({
  isCountryLoading,
  importCountryStateName,
  searchCountryState,
  setSearchCountryState,
  filteredCountrySearch,
  setProof,
  setImportCountryStateName,
  setShowAddresses,
  showAddresses,
  setCurrencyValue,
  showClipboard,
  copiedAdd,
  handleCopy,
  isItemLoading,
  searchItem,
  itemName,
  setSearchItem,
  setItemName,
  filteredItemSearch,
  importDropOffStateName,
  setImportDropOffStateName,
  currencyValue,
  proof,
  formErrors,
  setFormErrors,
  deliveryDetails,
  setDeliveryDetails,
  handleDeliveryChange,
  searchPickUpState,
  setSearchPickUpState,
  filteredPickUpStateSearch,
}: ImportFormProps) => {
  return (
    <>
      <div className="mt-6">
        <div className="flex">
          <p className="text-[#4A564D] mb-2 text-sm leading-[20px] font-onestMedium">
            Select Pick-up country
          </p>
          <span className="text-red-500 text-sm">*</span>
        </div>
        <Dropdown
          loader={isCountryLoading}
          selectPlaceholderName="country"
          dropdownStateName={importCountryStateName}
          dropdownValue={deliveryDetails?.importCountry}
          searchSubject={searchCountryState}
          setSearchSubject={setSearchCountryState}
          search
          dropdownContainerClasses={`shadow-cardShadow-4 !w-full !right-0  border border-slate-100  rounded `}
          dropdownOptions={[
            {
              customChild: (
                <div>
                  <div>
                    {filteredCountrySearch?.map((item: any, idx: number) => {
                      return (
                        <div
                          key={idx}
                          onClick={() => {
                            setProof(item?.proof);
                            setSearchCountryState("");
                            setDeliveryDetails((prev: any) => ({
                              ...prev,
                              importCountry: item.id,
                            }));
                            setImportCountryStateName(item?.state);
                            setShowAddresses(item);
                            setCurrencyValue(item?.currency?.code);
                            setFormErrors((prev: any) => ({
                              ...prev,
                              importCountry: "",
                            }));
                          }}
                        >
                          <div className="px-3 py-2 text-sm font-onestMedium text-[#4a564d]  hover:bg-[#edecec] cursor-pointer">
                            <p>{item?.country?.name}</p>
                          </div>
                        </div>
                      );
                    })}
                  </div>
                </div>
              ),
            },
          ]}
        />
        {formErrors.importCountry && (
          <span className="text-xs text-[#BA3838] font-onestMedium">
            {formErrors.importCountry}
          </span>
        )}
      </div>
      {showAddresses && showAddresses?.addresses?.length > 0 && (
        <div className="mt-6">
          <h6 className="items-start text-[#4A564D] flex flex-col text-[14px]  font-onestMedium">
            Shipping Address
            {showAddresses?.addresses?.map((data: any, idx: number) => (
              <div className="w-full mt-2" key={idx}>
                <label
                  htmlFor={"state"}
                  className="text-[#4A564D] mb-1  items-center flex text-[14px]  font-onestMedium"
                >
                  Address
                </label>
                <div className="flex space-x-3 mb-3 !border-[#E4EBF5] text-[#161718] rounded-[8px]  p-2 text-[14px] outline-0 focus:outline-none focus:ring-0 cursor-text border  w-full items-center">
                  <div
                    className="text-[#161718]  pl-1 flex flex-col text-[15px]"
                    key={idx}
                  >
                    {data?.address}
                  </div>
                  <div
                    onClick={() => handleCopy(data?.address)}
                    className="cursor-pointer flex space-x-1 items-center"
                  >
                    <PiCopySimpleLight />

                    <p className="text-[#BEC0BE] text-sm">
                      {showClipboard && copiedAdd === data?.address
                        ? "Copied!"
                        : "Copy"}
                    </p>
                  </div>
                </div>
              </div>
            ))}
            {showAddresses && (
              <div className="w-full">
                {showAddresses?.state && (
                  <>
                    <label
                      htmlFor={"state"}
                      className="text-[#4A564D] mb-1  items-center flex text-[14px]  font-onestMedium"
                    >
                      State
                    </label>
                    <div className="flex space-x-3 mb-3   p-2 text-[14px] outline-0 focus:outline-none focus:ring-0 cursor-text border  !border-[#E4EBF5] rounded-[8px]  w-full items-center">
                      <div className=" text-[#161718]  pl-1 flex flex-col text-[15px]">
                        {showAddresses?.state}
                      </div>
                      <div
                        onClick={() => handleCopy(showAddresses?.state)}
                        className="cursor-pointer flex space-x-1 items-center"
                      >
                        <PiCopySimpleLight />

                        <p className="text-[#BEC0BE] text-sm">
                          {showClipboard && copiedAdd === showAddresses?.state
                            ? "Copied!"
                            : "Copy"}
                        </p>
                      </div>
                    </div>
                  </>
                )}
                <div className="flex w-full space-x-3 mb-1 justify-between">
                  {showAddresses?.city && (
                    <label
                      htmlFor={"state"}
                      className="text-[#4A564D] mb-1  items-center flex text-[14px]  font-onestMedium"
                    >
                      City
                    </label>
                  )}

                  {showAddresses?.postCode && (
                    <label
                      htmlFor={"state"}
                      className="text-[#4A564D] mb-1  items-center flex text-[14px]  font-onestMedium"
                    >
                      Post Code
                    </label>
                  )}
                </div>
                <div className="flex  space-x-3 w-full">
                  {showAddresses?.city && (
                    <div className="flex  mb-3 space-x-3 text-[#161718] p-2 text-[14px] rounded-[8px] cursor-text border !border-[#E4EBF5]  w-full items-center">
                      <div className="text-[#161718]  pl-1 flex flex-col text-[15px]">
                        {showAddresses?.city}
                      </div>
                      <div
                        onClick={() => handleCopy(showAddresses?.city)}
                        className="cursor-pointer flex space-x-1 items-center"
                      >
                        <PiCopySimpleLight />

                        <p className="text-[#BEC0BE] text-sm">
                          {showClipboard && copiedAdd === showAddresses?.city
                            ? "Copied!"
                            : "Copy"}
                        </p>
                      </div>
                    </div>
                  )}
                  {showAddresses?.postCode && (
                    <div className="flex space-x-3 mb-3 text-[#161718] p-2 text-[14px]  !border-[#E4EBF5] border-2  w-full items-center">
                      <div className="text-green-800  pl-1 flex flex-col text-[15px]">
                        {showAddresses?.postCode}
                      </div>
                      <div
                        onClick={() => handleCopy(showAddresses?.postCode)}
                        className="cursor-pointer flex space-x-1 items-center"
                      >
                        <PiCopySimpleLight />

                        <p className="text-[#BEC0BE] text-sm">
                          {showClipboard &&
                          copiedAdd === showAddresses?.postCode
                            ? "Copied!"
                            : "Copy"}
                        </p>
                      </div>
                    </div>
                  )}
                </div>
              </div>
            )}
          </h6>
        </div>
      )}

      <div className="grid gap-4 md:grid-cols-2">
        <div className="mt-6">
          <TextInput
            type="text"
            label="Order number/Tracking ID"
            isRequired
            placeholder="Enter order number/tracking id"
            name="importOrderNumber"
            onChange={handleDeliveryChange}
            value={deliveryDetails.importOrderNumber}
          />

          {formErrors.importOrderNumber && (
            <span className="text-xs text-[#BA3838] font-onestMedium">
              {formErrors.importOrderNumber}
            </span>
          )}
        </div>
        <div className="mt-6">
          <div className="flex">
            <p className="text-[#4A564D] mb-2 text-sm leading-[20px] font-onestMedium">
              Select Item category
            </p>
            <span className="text-red-500 text-sm">*</span>
          </div>
          <Dropdown
            loader={isItemLoading}
            selectPlaceholderName="item category"
            dropdownStateName={itemName}
            dropdownValue={deliveryDetails?.importItem}
            searchSubject={searchItem}
            setSearchSubject={setSearchItem}
            search
            dropdownContainerClasses={`shadow-cardShadow-4  !w-full  !right-0  border border-slate-100  rounded `}
            dropdownOptions={[
              {
                customChild: (
                  <div>
                    {filteredItemSearch?.length > 0 ? (
                      <div>
                        {filteredItemSearch?.map((item: any, idx: number) => {
                          return (
                            <div
                              key={idx}
                              onClick={() => {
                                setDeliveryDetails((prev: any) => ({
                                  ...prev,
                                  importItem: item.id,
                                }));
                                setSearchItem("");
                                setItemName(item?.name);
                                setFormErrors((prev: any) => ({
                                  ...prev,
                                  importItem: "",
                                }));
                              }}
                            >
                              <div className="px-3 py-2 text-sm font-onestMedium text-[#4a564d]  hover:bg-[#edecec] cursor-pointer">
                                <p>{item?.name}</p>
                              </div>
                            </div>
                          );
                        })}
                      </div>
                    ) : (
                      <p>No data !!</p>
                    )}
                  </div>
                ),
              },
            ]}
          />
          {formErrors.importItem && (
            <span className="text-xs text-[#BA3838] font-onestMedium">
              {formErrors.importItem}
            </span>
          )}
        </div>
      </div>
      <div className="mt-6">
        <TextInput
          type="text"
          label="Vendor/Business name"
          isRequired
          placeholder="eg:Zara, ASOS, TESCO, next, MATALAN"
          name="importVendorName"
          onChange={handleDeliveryChange}
          value={deliveryDetails.importVendorName}
        />

        {formErrors.importVendorName && (
          <span className="text-xs text-[#BA3838] font-onestMedium">
            {formErrors.importVendorName}
          </span>
        )}
      </div>
      <div className="mt-6">
        <div className="flex">
          <p className="text-[#4A564D] mb-2 text-sm leading-[20px] font-onestMedium">
            Drop-off zone
          </p>
          <span className="text-red-500 text-sm">*</span>
        </div>
        <Dropdown
          selectPlaceholderName="pick-up state"
          dropdownStateName={importDropOffStateName}
          dropdownValue={deliveryDetails?.importDropOffState}
          searchSubject={searchPickUpState}
          setSearchSubject={setSearchPickUpState}
          search
          dropdownContainerClasses={`shadow-cardShadow-4 !w-full !right-0  border border-slate-100  rounded `}
          dropdownOptions={[
            {
              customChild: (
                <div>
                  <div>
                    {filteredPickUpStateSearch?.map(
                      (item: any, idx: number) => {
                        return (
                          <div
                            key={idx}
                            onClick={() => {
                              setDeliveryDetails((prev: any) => ({
                                ...prev,
                                importDropOffState: item.state,
                              }));
                              setImportDropOffStateName(item?.state);
                              setSearchPickUpState("");
                              setFormErrors((prev: any) => ({
                                ...prev,
                                importDropOffState: "",
                              }));
                            }}
                          >
                            <div className="px-3 py-2  text-sm font-onestMedium text-[#4a564d] hover:bg-[#edecec] cursor-pointer">
                              <p>{item?.state}</p>
                            </div>
                          </div>
                        );
                      }
                    )}
                  </div>
                </div>
              ),
            },
          ]}
        />
        {formErrors.importDropOffState && (
          <span className="text-xs text-[#BA3838] font-onestMedium">
            {formErrors.importDropOffState}
          </span>
        )}
      </div>
      <div className="mt-6">
        <TextInput
          type="text"
          label="Drop off address"
          isRequired
          placeholder="Enter address"
          name="importDropOffAdd"
          onChange={handleDeliveryChange}
          value={deliveryDetails.importDropOffAdd}
        />

        {formErrors.importDropOffAdd && (
          <span className="text-xs text-[#BA3838] font-onestMedium">
            {formErrors.importDropOffAdd}
          </span>
        )}
      </div>
      <div className="mt-6">
        <TextInput
          type="text"
          label="Recipient name"
          isRequired
          placeholder="Enter name"
          name="importRecipientName"
          onChange={handleDeliveryChange}
          value={deliveryDetails.importRecipientName}
        />

        {formErrors.importRecipientName && (
          <span className="text-xs text-[#BA3838] font-onestMedium">
            {formErrors.importRecipientName}
          </span>
        )}
      </div>
      <div className="grid md:grid-cols-2 gap-x-3">
        <div className="mt-6">
          <TextInput
            type="number"
            label="Recipient phone number"
            isRequired
            placeholder="Enter phone number"
            name="importRecipientPhone"
            onChange={handleDeliveryChange}
            value={deliveryDetails.importRecipientPhone}
          />

          {formErrors.importRecipientPhone && (
            <span className="text-xs text-[#BA3838] font-onestMedium">
              {formErrors.importRecipientPhone}
            </span>
          )}
        </div>
        <div className="mt-6">
          <TextInput
            type="email"
            label="Recipient email"
            placeholder="Enter email"
            name="importRecipientEmail"
            onChange={handleDeliveryChange}
            value={deliveryDetails.importRecipientEmail}
          />

          {formErrors.importRecipientEmail && (
            <span className="text-xs text-[#BA3838] font-onestMedium">
              {formErrors.importRecipientEmail}
            </span>
          )}
        </div>
        <div className="mt-6">
          <TextInput
            type="number"
            label="Item weight"
            isRequired
            isWeight
            placeholder="Enter weight"
            name="importWeight"
            onChange={handleDeliveryChange}
            value={deliveryDetails.importWeight}
          />

          {formErrors.importWeight && (
            <span className="text-xs text-[#BA3838] font-onestMedium">
              {formErrors.importWeight}
            </span>
          )}
        </div>
        <div className="mt-6">
          <TextInput
            type="text"
            label="Quantity"
            placeholder="Enter quantity"
            name="importQuantity"
            onChange={handleDeliveryChange}
            value={deliveryDetails.importQuantity}
          />

          {formErrors.importQuantity && (
            <span className="text-xs text-[#BA3838] font-onestMedium">
              {formErrors.importQuantity}
            </span>
          )}
        </div>
      </div>
      <div className="mt-6">
        <TextArea
          label="Item description"
          isRequired
          placeholder="Write a description"
          name="importDescription"
          onChange={handleDeliveryChange}
          value={deliveryDetails.importDescription}
        />
        {formErrors.importDescription && (
          <span className="text-xs text-[#BA3838] font-onestMedium">
            {formErrors.importDescription}
          </span>
        )}
      </div>

      <ToggleButton
        data={deliveryDetails.fragile}
        handleFn={handleDeliveryChange}
      />

      <div className="mt-6">
        <TextInput
          type="number"
          label={`Value of Item in ${currencyValue}`}
          placeholder="Enter value"
          name="importValueofItem"
          isRequired
          onChange={handleDeliveryChange}
          value={deliveryDetails.importValueofItem}
        />
        {formErrors.importValueofItem && (
          <span className="text-xs text-[#BA3838] font-onestMedium">
            {formErrors.importValueofItem}
          </span>
        )}
      </div>
      {deliveryDetails.importValueofItem !== "" &&
        Number(deliveryDetails.importValueofItem) >= Number(proof) && (
          <div className="mt-6">
            <TextInput
              type="file"
              name="invoice"
              label="Invoice"
              isRequired
              accept=".jpg, .jpeg, .png, .pdf"
              placeholder={""}
              onChange={(e: any) =>
                handleFileChange(e, setFormErrors, setDeliveryDetails)
              }
              file={deliveryDetails.fileNames}
            />
            {formErrors.file && (
              <span className="text-xs text-[#BA3838] font-onestMedium">
                {formErrors.file}
              </span>
            )}
          </div>
        )}
      <div className="mt-6">
        <TextInput
          type="text"
          label="Enter discount code if you have one"
          placeholder="Enter discount code"
          name="importReferral"
          onChange={handleDeliveryChange}
          value={deliveryDetails.importReferral}
        />
      </div>
    </>
  );
};

export default MobileImportDeliveryForm;
