import React, { useEffect, useState } from "react";
import PublicRoutes from "components/common/Routes/PublicRoute";
import Login from "pages/auth/Login";
import Register from "pages/auth/Register";
import NotFound from "pages/notFound";
import ForgotPassword from "pages/auth/ForgotPassword";
import DashboardHome from "pages/dashboard/Home";
import BulkShipment from "pages/dashboard/ship-now/BulkShipment";
import ChangePassword from "pages/auth/ChangePassword";
import CreateUsers from "pages/dashboard/users/create";
import EditUsers from "pages/dashboard/users/EditUser";
import Dashboard from "components/layout/DashboardFrame";
import BusinessRegister from "pages/auth/BusinessRegister";
import Pricing from "pages/dashboard/pricing/Pricing";
import Users from "pages/dashboard/users";
import SingleShipment from "pages/dashboard/ship-now/SingleShipment";
import AllTickets from "pages/dashboard/tickets/AllTickets";
import CreateTicket from "pages/dashboard/tickets/CreateTicket";
import Ticketing from "pages/dashboard/tickets/TicketMessage";
import Developer from "pages/dashboard/Developer";
import Reports from "pages/dashboard/Reports";
import ImportOrder from "pages/dashboard/bumpa/ImportOrder";
import ManageKey from "pages/dashboard/bumpa/ManageKey";
import Shipments from "pages/dashboard/shipment/Shipments";
import Wallet from "pages/dashboard/Wallet";
import PrintManifest from "pages/dashboard/print-manifest/PrintManifest";
import ViewShipment from "pages/dashboard/shipment/ViewShipment";
import Setting from "pages/dashboard/settings/Setting";
import Notification from "pages/dashboard/Notification";
import Help from "pages/dashboard/Help";
import PricingTable from "pages/dashboard/pricing/PricingTable";
import Invoice from "pages/dashboard/invoice";
import InvoiceDetails from "pages/dashboard/invoice/InvoiceDetails";
import InvoicePayment from "pages/dashboard/invoice/invoicePay";
import { getFromStorage } from "helpers";
import Inventory from "pages/dashboard/inventory";
import ViewInventory from "pages/dashboard/inventory/ViewInventory";
import ViewInventoryById from "pages/dashboard/inventory/ViewInventoryById";
import SingleStoreItem from "pages/dashboard/inventory/storage/SingleStoreItem";
import BulkStoreItem from "pages/dashboard/inventory/storage/BulkStoreItem";
import StorageRequest from "pages/dashboard/inventory/storage/StorageRequest";
import EditStorageRequest from "pages/dashboard/inventory/storage/EditStorageRequest";
import ApprovalStorageRequest from "pages/dashboard/inventory/storage/ApprovalStorageRequest";
import CreateInventoryRetrieveItem from "pages/dashboard/inventory/retrieval/CreateInventoryRetrieveItem";
import RetrieveInventoryItem from "pages/dashboard/inventory/retrieval/RetrieveItem";
import EditRetrieveItem from "pages/dashboard/inventory/retrieval/EditRetrieveItem";
import ViewInventoryRequests from "pages/dashboard/inventory/ViewRequest";

const Routes = () => {
  const [canViewInvoice, setCanViewInvoice] = useState<string>("");
  const invoicePermission = getFromStorage("invoice") || "";

  useEffect(() => {
    setCanViewInvoice(invoicePermission);
  }, [invoicePermission]);

  const routes = [
    {
      path: "/",
      element: (
        <PublicRoutes>
          <Login />
        </PublicRoutes>
      ),
    },
    {
      path: "/register",
      element: (
        <PublicRoutes>
          <Register />
        </PublicRoutes>
      ),
    },
    {
      path: "/business-register",
      element: (
        <PublicRoutes>
          <BusinessRegister />
        </PublicRoutes>
      ),
    },
    {
      path: "/forgot-password",
      element: (
        <PublicRoutes>
          <ForgotPassword />
        </PublicRoutes>
      ),
    },
    {
      path: "/change-password",
      element: (
        <PublicRoutes>
          <ChangePassword />
        </PublicRoutes>
      ),
    },
    {
      path: "/dashboard",
      element: <Dashboard />,
      children: [
        {
          index: true,
          element: <DashboardHome />,
        },
        {
          path: "ship-now",
          element: <SingleShipment />,
        },
        {
          path: "ship-now/bulk-upload",
          element: <BulkShipment />,
        },
        {
          path: "shipments",
          element: <Shipments />,
        },
        {
          path: "shipments/:id",
          element: <ViewShipment />,
        },
        {
          path: "wallet",
          element: <Wallet />,
        },
        {
          path: "users/create-user",
          element: <CreateUsers />,
        },
        {
          path: "users/edit-user/:id",
          element: <EditUsers />,
        },
        {
          path: "users",
          element: <Users />,
        },
        {
          path: "pricing",
          element: <Pricing />,
        },
        {
          path: "pricing/list",
          element: <PricingTable />,
        },
        {
          path: "tickets/create-ticket",
          element: <CreateTicket />,
        },
        {
          path: "ticket",
          element: <AllTickets />,
        },
        {
          path: `ticket/:id`,
          element: <Ticketing />,
        },
        {
          path: "developer",
          element: <Developer />,
        },
        {
          path: "reports",
          element: <Reports />,
        },
        {
          path: "import-orders",
          element: <ImportOrder />,
        },
        {
          path: "print-manifest",
          element: <PrintManifest />,
        },
        {
          path: "inventory",
          element: <Inventory />,
        },
        {
          path: "inventory/view-inventory",
          element: <ViewInventory />,
        },
        {
          path: "inventory/view-inventory/:id",
          element: <ViewInventoryById />,
        },
        {
          path: "inventory/store-item",
          element: <SingleStoreItem />,
        },
        {
          path: "inventory/store-item/bulk-upload",
          element: <BulkStoreItem />,
        },
        {
          path: "inventory/storage-request/:id",
          element: <StorageRequest />,
        },
        {
          path: "inventory/edit-storage-request/:id",
          element: <EditStorageRequest />,
        },
        {
          path: "inventory/storage-request/:id/approval",
          element: <ApprovalStorageRequest />,
        },
        {
          path: "inventory/retrieve-item",
          element: <CreateInventoryRetrieveItem />,
        },
        {
          path: "inventory/retrieve-item/:id",
          element: <RetrieveInventoryItem />,
        },
        {
          path: "inventory/edit-retrieve-item/:id",
          element: <EditRetrieveItem />,
        },
        {
          path: "inventory/requests",
          element: <ViewInventoryRequests />,
        },
        ...(canViewInvoice === "true"
          ? [
              {
                path: "invoice",
                element: <Invoice />,
              },
              {
                path: "invoice/:id",
                element: <InvoiceDetails />,
              },
              {
                path: "invoice/:id/pay",
                element: <InvoicePayment />,
              },
            ]
          : []),
        {
          path: "manage-keys",
          element: <ManageKey />,
        },
        {
          path: "help",
          element: <Help />,
        },
        {
          path: "setting",
          element: <Setting />,
        },
        {
          path: "notification",
          element: <Notification />,
        },
      ],
    },
    {
      path: "*",
      element: <NotFound />,
    },
  ];

  return routes;
};

export default Routes;
