import DatePicker from "react-datepicker";
import moment from "moment";
import { useCallback, useEffect, useRef, useState } from "react";
import { useReactToPrint } from "react-to-print";
import ModalContainer from "components/Modal";
import { Button } from "components/Button";
import { TextInput } from "components/common/form/Input";
import { IoEyeOutline } from "react-icons/io5";
import PagePagination from "components/Pagination";
import { useCreateCustomizeLogoMutation } from "services/slices/printManifest";
import { BarCode } from "components/BarcodeGen";
import { ComponentToPrintSVG } from "components/ComponentToPrint";
import { useGetAllOrdersMutation } from "services/slices/shipNow";
import { TableLoader } from "components/TableLoader";
import { BiPrinter } from "react-icons/bi";
import { DashboardModalAction } from "components/ModalCopy";
import { formatMoney } from "helpers/formatMoney";
import { filterTerms } from "constant/data";
import { getDateRange } from "helpers/getDateRange";
import { handleErrorToast, handleSuccessToast } from "helpers/toastMessage";
import BrandLogoUpload from "components/dashboard/BrandLogoUpload";
import { debouncedFunction } from "helpers/debouncedSearch";
import { getFromStorage } from "helpers";
import PeriodFilter from "components/common/PeriodFilter";
import { onDateChange, resetDateSelection } from "helpers/dateChange";

function PrintManifest() {
  const org: any = getFromStorage("fez-org-details");
  const userData: any = getFromStorage("fez-org-code");
  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
  });
  const handleMultiplePrint = useReactToPrint({
    content: () => componentMultipleRef.current,
  });
  const [logo, setLogo] = useState<string>("");
  const [filterDate, setFilterDate] = useState(false);
  const [barcodeData, setBarcodeData] = useState<any>([]);
  const [viewData, setViewData] = useState<any>([]);
  const [isPrinting, setIsPrinting] = useState(false);
  const [openPrint, setOpenPrint] = useState(false);
  const [openModal, setOpenModal] = useState(false);
  const [PrintManifestInfo, setPrintManifest] = useState(false);
  const [showDateModal, setShowDateModal] = useState<boolean>(false);
  const [startDate, setStartDate] = useState<Date | null>(null);
  const [endDate, setEndDate] = useState<Date | null>(null);
  const [formattedDate, setFormattedDate] = useState<string>("");
  const [formattedEndDate, setFormattedEndDate] = useState<string>("");
  const [page, setPage] = useState(0);
  const [selectedOrders, setSelectedOrders] = useState<
    { [key: string]: any }[]
  >([]);
  const [search, setSearch] = useState<string>("");
  const [debounceSearch, setDebounceSearch] = useState<string>("");
  const [orderEndDate, setOrderEndDate] = useState<string>(
    moment().add(1, "days").format("YYYY-MM-DD")
  );
  const [orderStartDate, setOrderStartDate] = useState<string>(
    moment().subtract(7, "days").format("YYYY-MM-DD")
  );
  const [selectedPeriodName, setSelectedPeriodName] = useState("Last 7 days");
  const [selectedPeriod, setSelectedPeriod] = useState("last7days");
  const componentRef = useRef<any>();
  const componentMultipleRef = useRef<any>();
  const [getAllOrders, { data, isLoading: isOrderLoading }] =
    useGetAllOrdersMutation();
  const [createCustomizedLogo, { isLoading }] =
    useCreateCustomizeLogoMutation();
  const orgData = JSON.parse(org);

  const debouncedSetSearch = useCallback((value: string) => {
    debouncedFunction(value, setSearch);
  }, []);
  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    debouncedSetSearch(e.target.value);
    setDebounceSearch(e.target.value);
    setPage(0);
  };

  const onCloseCustomLogo = () => {
    setLogo("");
    setOpenModal(false);
  };
  useEffect(() => {
    const getOrders = async () => {
      await getAllOrders({
        startDate: orderStartDate,
        endDate: orderEndDate,
        orderNo: search,
        page: page + 1,
      })
        .unwrap()
        .then((data) => {})
        .catch((e: { data: { description: string } }) => {
          handleErrorToast(e);
        });
    };
    if (selectedPeriod.toLowerCase() !== "custom") {
      getOrders();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [orderStartDate, orderEndDate, search, selectedPeriod, page]);

  const onClearDate = () => {
    resetDateSelection(
      setSelectedPeriodName,
      setSelectedPeriod,
      setShowDateModal,
      setOrderStartDate,
      setOrderEndDate
    );
  };

  const onApplyDate = () => {
    const getOrders = async () => {
      await getAllOrders({
        startDate: formattedDate,
        endDate: formattedEndDate,
        orderNo: search,
        page: page + 1,
      })
        .unwrap()
        .then((data) => {
          setFormattedDate("");
          setFormattedEndDate("");
          setEndDate(null);
          setStartDate(null);
        })
        .catch((e: { data: { description: string } }) => {
          handleErrorToast(e);
        });
    };
    if (formattedDate !== "" && formattedEndDate !== "") {
      setSelectedPeriodName(`${formattedDate} - ${formattedEndDate}`);
      getOrders();
      setOrderStartDate(formattedDate);
      setOrderEndDate(formattedEndDate);
    }

    setShowDateModal(false);
  };
  const handlePeriodChange = (value: string) => {
    setSelectedPeriod(value);
  };

  const handleDateChange = (date: Date | null) => {
    onDateChange(date, setStartDate, setFormattedDate);
  };
  const handleEndDateChange = (date: Date | null) => {
    onDateChange(date, setEndDate, setFormattedEndDate);
  };

  useEffect(() => {
    if (Boolean(selectedPeriod.toLowerCase() === "custom")) {
      setShowDateModal(true);
    } else {
      setShowDateModal(false);
    }
  }, [selectedPeriod]);

  useEffect(() => {
    if (selectedPeriod && selectedPeriod.toLowerCase() !== "custom") {
      const { startDate, endDate } = getDateRange(selectedPeriod);
      setOrderStartDate(startDate);
      setOrderEndDate(endDate);
    }
  }, [selectedPeriod]);

  function isValidLogoUrl(url: string): boolean {
    try {
      const parsedUrl = new URL(url);

      const validExtensions = [".png", ".jpg", ".jpeg", ".gif", ".svg"];
      return validExtensions.some((ext) => parsedUrl.pathname.endsWith(ext));
    } catch (e) {
      return false;
    }
  }

  const onSaveLogo = async () => {
    await createCustomizedLogo({
      body: {
        logo,
      },
      id: userData,
    })
      .unwrap()
      .then((data) => {
        onCloseCustomLogo();
        handleSuccessToast("Logo updated successfully!");
      })
      .catch((error: { data: { description: string } }) => {
        onCloseCustomLogo();
        handleErrorToast(error);
      });
  };

  const onPrint = async () => {
    try {
      await setOpenPrint(false);
      setIsPrinting(true);
      handlePrint();
      setIsPrinting(false);
    } catch (error) {
      setIsPrinting(false);
    }
  };

  const onPrintAll = async () => {
    try {
      await setOpenPrint(false);
      setIsPrinting(true);
      handleMultiplePrint();
      setIsPrinting(false);
    } catch (error) {
      setIsPrinting(false);
    }
  };

  const clickPrint = (e: any) => {
    setBarcodeData(e);
    setOpenPrint(true);
  };

  const isAllSelected =
    data?.orders?.data.length > 0 &&
    data?.orders?.data.every((pageItem: any) =>
      selectedOrders.some(
        (selectedItem: any) => selectedItem.id === pageItem.id
      )
    );

  const handleSelectAll = () => {
    if (isAllSelected) {
      // Deselect all items on the current page
      setSelectedOrders(
        selectedOrders.filter(
          (selectedItem) =>
            !data?.orders?.data.some(
              (pageItem: any) => pageItem.id === selectedItem.id
            )
        )
      );
    } else {
      // Add all items on the current page to the selected list
      const newSelectedOrders = data?.orders?.data;
      setSelectedOrders([
        ...selectedOrders,
        ...newSelectedOrders.filter(
          (item: any) =>
            !selectedOrders.some(
              (selectedItem: any) => selectedItem.id === item.id
            )
        ),
      ]);
    }
  };

  const handleSelectOne = (order: any) => {
    const isAlreadySelected = selectedOrders.some(
      (selectedOrder) => selectedOrder.id === order.id
    );

    if (isAlreadySelected) {
      setSelectedOrders(
        selectedOrders.filter((selectedOrder) => selectedOrder.id !== order.id)
      );
    } else {
      setSelectedOrders([...selectedOrders, order]);
    }
  };

  return (
    <div>
      <ModalContainer
        open={openModal}
        showCloseIcon={false}
        tailwindClassName="w-[350px] sm:w-[466px]"
        closeModal={onCloseCustomLogo}
      >
        <div className="w-full p-8 bg-white flex flex-col  rounded-[12px]">
          <div className="flex justify-between pb-4 border-b border-[#E4EBF5]">
            <p className="text-lg  font-onestBold text-[#0A5001] ">
              Customised Print Shipping Label
            </p>
            <div className="cursor-pointer" onClick={onCloseCustomLogo}>
              <img src="/images/close.svg" alt="" />
            </div>
          </div>

          <p className="text-[#4A564D] font-onestMedium text-sm font-[500] mt-6">
            Display your brand logo on the Print Shipping Label.
          </p>
          <div className=" py-[0px] mt-4 mb-2">
            {isValidLogoUrl(logo) ? (
              <div className="flex justify-center">
                <img
                  src={logo}
                  className="w-[200px] h-[200px] object-cover"
                  alt="company-logo"
                />
              </div>
            ) : (
              <>
                <BrandLogoUpload
                  loading={false}
                  url={logo}
                  setUrl={setLogo}
                  text={"Logo image will appear here"}
                  icon={"/images/file.svg"}
                  bgColor={"bg-[#fff]"}
                />
              </>
            )}
          </div>
          <div className="mt-2">
            {" "}
            <TextInput
              label="Logo URL"
              type="text"
              value={logo}
              placeholder="Enter logo URL"
              onChange={(e) => setLogo(e.target.value)}
            />
          </div>
          <div className="flex justify-center pt-[54px]">
            <Button
              title="Save"
              loading={isLoading}
              disabled={isLoading || !isValidLogoUrl(logo)}
              onClick={onSaveLogo}
              className={`!py-[10px] !w-fit !rounded-lg !px-10 ${
                !isValidLogoUrl(logo) ? "bg-[#BEC0BE]" : "!bg-[#18AF04]"
              } !text-[#fff]`}
            />
          </div>
        </div>
      </ModalContainer>

      <div className="px-[72px] hidden md:block">
        <div className="flex justify-between items-center mt-10">
          <h1 className="text-2xl font-onestBold font-[800] text-[#0A5001] tracking-wide">
            Print Shipping Label
          </h1>
          <div className="flex items-center space-x-2">
            <div className="">
              <Button
                iconLeft
                icon={<BiPrinter />}
                title="Print all"
                onClick={onPrintAll}
                loading={isPrinting}
                disabled={selectedOrders.length < 1}
                className={` ${
                  selectedOrders?.length > 0 ? "bg-[#289D17]" : "bg-[#BEC0BE]"
                } cursor-pointer py-3 flex justify-center bmd:mt-0 text-[#E6E8E6] !w-fit gap-1 items-center px-4 bmd:px-8 rounded-lg`}
              />
            </div>
            {orgData.orgBusinessType?.toLowerCase() === "logistics" && (
              <div
                onClick={() => setOpenModal(true)}
                className="bg-[#D4F3D5] cursor-pointer py-3 flex text-[#17730A] gap-2 font-onestMedium items-center font-[500] px-8 rounded-lg"
              >
                <button>Custom Settings</button>
              </div>
            )}
          </div>
        </div>

        <div className="bg-[#F9F9FB] p-4 mt-10">
          <div className="flex gap-3 items-center justify-between ">
            <div className="flex gap-3">
              <div className="w-full md:w-[279px] bg-white flex gap-2 items-center rounded-[9px] pl-4 pr-4 border-[1px] border-[#E4EBF5] ">
                <img
                  className="w-[14px] h-[14px]"
                  src="/images/search-icon.svg"
                  alt="search-icon"
                />
                <input
                  className="text-[13px] py-[10px] w-[250px] outline-none"
                  type="text"
                  placeholder="Keyword Search by Order ID"
                  onChange={(e) => {
                    handleInputChange(e);
                  }}
                  value={debounceSearch}
                />
              </div>
              <PeriodFilter
                filterDate={filterDate}
                setFilterDate={setFilterDate}
                setSelectedPeriodName={setSelectedPeriodName}
                selectedPeriod={selectedPeriod}
                handlePeriodChange={handlePeriodChange}
                selectedPeriodName={selectedPeriodName}
              />
            </div>
          </div>
          {data?.orders?.data.length > 0 && !isOrderLoading && (
            <>
              <div className="overflow-x-auto">
                <table className="whitespace-nowrap mt-3 w-full">
                  <>
                    <thead className="bg-white !mx-12">
                      <tr className="border-b-[1px] py-5 pl-4 pr-6 border-[#E4EBF5]">
                        <th className="px-2 pl-4 pr-6">
                          <input
                            type="checkbox"
                            className="accent-[#289D17]"
                            checked={isAllSelected}
                            onChange={handleSelectAll}
                          />
                        </th>
                        <th className="py-5 text-left pl-4 pr-6 text-xs text-[#929DAE] font-onestRegular">
                          Order date
                        </th>
                        <th className="py-5 text-left pl-5 pr-6 text-xs text-[#929DAE] font-onestRegular">
                          Order ID
                        </th>
                        <th className="py-5 text-left pl-6 pr-6  text-xs text-[#929DAE] font-onestRegular">
                          Status
                        </th>
                        <th className="py-3 text-left  text-xs  pl-6 pr-4 text-[#929DAE] font-onestRegular">
                          Recipient Name
                        </th>
                        <th className="py-3 text-left  text-xs  pl-6 pr-4 text-[#929DAE] font-onestRegular">
                          Recipient Address
                        </th>
                        <th className="py-3 text-left  text-xs  pl-5 pr-4 text-[#929DAE] font-onestRegular">
                          Recipient Phone
                        </th>
                        <th className="py-3 text-left  text-xs  pl-5 pr-4 text-[#929DAE] font-onestRegular">
                          Recipient Email
                        </th>
                        <th className="py-3 text-left  text-xs  pl-6 pr-4 text-[#929DAE] font-onestRegular">
                          Cost
                        </th>
                        <th className="py-3 text-left  text-xs  pl-4 pr-4 text-[#929DAE] font-onestRegular">
                          Return Reason
                        </th>
                        <th className="py-3 text-left  text-xs  pl-6 pr-4 text-[#929DAE] font-onestRegular">
                          Created By
                        </th>
                        <th className="py-3 text-left  text-xs  pl-5 pr-4 text-[#929DAE] font-onestRegular">
                          Pick Up Date
                        </th>
                        <th className="py-3 text-left  text-xs  pl-5 pr-4 text-[#929DAE] font-onestRegular">
                          Dispatch Date
                        </th>
                        <th className="py-3 text-left  text-xs  pl-5 pr-4 text-[#929DAE] font-onestRegular">
                          Delivery Date
                        </th>
                        <th className="py-3 text-left  text-xs  pl-4 pr-8 text-[#929DAE] font-onestRegular">
                          Additional Note
                        </th>
                        <th className="py-5 sticky right-0 bg-white text-xs text-[#929DAE] font-onestRegular">
                          Action
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      {data?.orders?.data?.map((item: any) => (
                        <tr
                          key={item.id}
                          className="bg-[#fff] py-8 border-b-[1px] border-[#E4EBF5]"
                        >
                          <td className="pl-6">
                            <input
                              type="checkbox"
                              className="accent-[#289D17]"
                              checked={selectedOrders.some(
                                (order) => order.id === item.id
                              )}
                              onChange={() => handleSelectOne(item)}
                            />
                          </td>
                          <td className="py-6 pl-4  pr-6 text-sm text-[#161718] font-onestRegular">
                            {item.orderDate
                              ? moment(item.orderDate).format(
                                  "DD MMM, YYYY: h:mm a"
                                )
                              : "N/A"}
                          </td>
                          <td className="py-3 pl-4 pr-6  text-sm font-onestRegular">
                            {item.orderNo}
                          </td>
                          <td className="pl-4 pr-6">
                            <span
                              className={`${
                                item.orderStatus === "pending"
                                  ? "bg-[#D4F3D5] text-[#289D17]"
                                  : item.orderStatus === "Pending Pickup"
                                  ? "bg-[#F8E4D1] text-[#65513F]"
                                  : "bg-[#FAEFE5] text-[#F59A49]"
                              } py-1 bg-[#FAEFE5] font-[600] rounded-[4px] px-2 text-[#65513F] text-xs`}
                            >
                              {item.orderStatus}
                            </span>
                          </td>
                          <td className="text-xs pl-4 pr-10">
                            <span className="text-sm text-[#0E1728] px-2 py-1 rounded-md font-[400] font-onesRegular">
                              {item.recipientName}
                            </span>
                          </td>
                          <td className="py-3 pl-6 pr-6 text-sm  font-onestRegular">
                            <span className="">
                              {item.recipientAddress || "N/A"}
                            </span>
                          </td>
                          <td className="text-xs pl-4 pr-10">
                            <span className="text-sm w-[50px] text-[#0E1728] px-2 py-1 rounded-md font-[400] font-onestRegular">
                              {item.recipientPhone}
                            </span>
                          </td>
                          <td className="text-xs pl-4 pr-10">
                            <span className="text-sm w-[50px] text-[#0E1728] px-2 py-1 rounded-md font-[400] font-onestRegular">
                              {item.recipientEmail || "N/A"}
                            </span>
                          </td>
                          <td className="text-xs pl-4 pr-10">
                            <span className="text-sm w-[50px] text-[#0E1728] px-2 py-1 rounded-md font-[400] font-onestRegular">
                              {item.cost}
                            </span>
                          </td>
                          <td className="text-xs pl-4 pr-10">
                            <span className="text-sm w-[50px] text-[#0E1728] px-2 py-1 rounded-md font-[400] font-onestRegular">
                              {item.returnReason}
                            </span>
                          </td>
                          <td className="text-xs pl-4 pr-10">
                            <span className="text-sm w-[50px] text-[#0E1728] px-2 py-1 rounded-md font-[400] font-onestRegular">
                              {item.createdBy}
                            </span>
                          </td>
                          <td className="text-xs pl-4 pr-10">
                            <span className="text-sm w-[50px] text-[#0E1728] px-2 py-1 rounded-md font-[400] font-onestRegular">
                              {item.pickUpDate
                                ? moment(item.pickUpDate).format("DD MMM, YYYY")
                                : "N/A"}
                            </span>
                          </td>
                          <td className="text-xs pl-4 pr-10">
                            <span className="text-sm w-[50px] text-[#0E1728] px-2 py-1 rounded-md font-[400] font-onestRegular">
                              {item.dispatchDate
                                ? moment(item.dispatchDate).format(
                                    "DD MMM, YYYY"
                                  )
                                : "N/A"}
                            </span>
                          </td>
                          <td className="text-xs pl-4 pr-10">
                            <span className="text-sm w-[50px] text-[#0E1728] px-2 py-1 rounded-md font-[400] font-onestRegular">
                              {item.deliveryDate
                                ? moment(item.deliveryDate).format(
                                    "DD MMM, YYYY"
                                  )
                                : "N/A"}
                            </span>
                          </td>
                          <td className="text-xs pl-4 pr-12">
                            <span className="text-sm w-[50px] text-[#0E1728] px-2 py-1 rounded-md font-[400] font-onestRegular">
                              {item.additionalNote || "N/A"}
                            </span>
                          </td>
                          <td className="pr-1.5 text-center pl-4 z-0 sticky right-0 py-5 flex gap-0.5  justify-center cursor-pointer bg-white">
                            <Button
                              title={"Print"}
                              icon={<BiPrinter />}
                              iconLeft={true}
                              onClick={() => clickPrint(item)}
                              className="!bg-transparent  !text-[#289D17] !pl-0 !font-[400]"
                            />
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </>
                </table>
              </div>
              {!isOrderLoading &&
                data?.orders?.data.length > 0 &&
                data?.orders?.meta?.total > data?.orders?.meta?.per_page && (
                  <div className="mt-3">
                    <PagePagination
                      totalPages={data?.orders?.last_page}
                      page={page}
                      handlePageClick={(event) => setPage(event.selected)}
                    />
                  </div>
                )}
            </>
          )}
          {data?.orders?.data.length === 0 && !isOrderLoading && (
            <div className="flex justify-center min-h-[400px] items-center py-[32px] w-full">
              <div className="">
                <div className="flex justify-center pb-5 w-full">
                  {" "}
                  <img src="/images/new-request.svg" alt="" />
                </div>
                <p className="font-onestRegular mb-2 bmd:w-full text-sm text-[#4A564D]">
                  You do not have any shipments for this period
                </p>
                <p className="font-onestRegular w-[100%] bmd:w-full text-xs text-center text-[#A0ABA3]">
                  When you make a shipment, it will show here
                </p>
              </div>
            </div>
          )}
          {isOrderLoading && (
            <div className="">
              <TableLoader rowLength={17} />
            </div>
          )}
        </div>
      </div>

      {!PrintManifestInfo ? (
        <div className="">
          <div className="flex justify-between items-center mx-4 mt-6  md:hidden">
            <h1 className="text-[#0A5001] font-[900] text-xl md:text-2xl font-onestBold">
              Print Shipping Label
            </h1>
            {orgData.orgBusinessType?.toLowerCase() === "logistics" && (
              <div
                onClick={() => setOpenModal(true)}
                className="bg-[#D4F3D5] py-3 flex text-[#17730A] gap-2 font-onestMedium items-center font-[500] px-8 rounded-lg"
              >
                <button>Custom Settings</button>
              </div>
            )}
          </div>
          <div className="mt-7 sm:mt-[72px] rounded-lg max-w-[38rem] block px-5 py-6 mb-[150px] md:hidden mx-4 sm:mx-auto border-[1px] border-[#E1F0DF] bg-dashboard-gradient">
            <div className="flex gap-3 mt-3 mb-3">
              <div className="w-[80%] bg-[#fff] flex gap-2 items-center rounded-[9px] pl-4 pr-4 border-[1px] border-[#E4EBF5]">
                <img
                  className="w-[14px] h-[14px]"
                  src="/images/search-icon.svg"
                  alt="search-icon"
                />
                <input
                  className="text-[13px] py-[10px] w-[279px] outline-none"
                  type="text"
                  placeholder="Keyword Search by Order ID"
                  onChange={(e) => {
                    handleInputChange(e);
                  }}
                  value={debounceSearch}
                />
              </div>
              <div
                className="relative cursor-pointer"
                onClick={() => setFilterDate(!filterDate)}
              >
                <div className="flex items-center gap-3 bg-white border-[1px] rounded-[9px] px-4 py-3  border-[#E4EBF5]">
                  <img
                    className="w-full"
                    src="/images/filter-mobile.svg"
                    alt=""
                  />
                </div>
                {filterDate && (
                  <div className="p-4 border-[1px] w-[190px] right-0 top-11 bg-white z-10 absolute rounded-lg border-[#E4EBF5]">
                    <h1 className="text-[#929DAE] pb-3 font-[900] text-xs font-onestBold">
                      PERIOD
                    </h1>
                    <form className="">
                      {filterTerms.map((terms) => (
                        <div
                          className="group flex my-3 gap-2.5 hover:bg-[#D4F3D5] text-[#289D17] hover:py-2 rounded-lg pl-2"
                          onClick={() => {
                            setSelectedPeriodName(terms.name);
                            handlePeriodChange(terms.value);
                          }}
                        >
                          <p className="flex items-center mb-0 cursor-pointer ">
                            <span
                              className={`${
                                selectedPeriod === terms.value && "bg-[#289D17]"
                              } w-4 h-4 border border-gray-300 rounded-full flex items-center justify-center group-hover:bg-[#289D17]`}
                            >
                              <span className="w-2 h-2 bg-white rounded-full"></span>
                            </span>
                            <span
                              className={`${
                                selectedPeriod === terms.value
                                  ? "text-[#289D17]"
                                  : "text-[#464748] group-hover:text-[#289D17]"
                              } ml-3 text-sm  `}
                            >
                              {terms.name}
                            </span>
                          </p>
                        </div>
                      ))}
                    </form>
                  </div>
                )}
              </div>
            </div>
            {isLoading ? (
              <TableLoader rowLength={8} />
            ) : (
              <div>
                {data?.orders?.data?.map((item: any) => {
                  return (
                    <div className="bg-[#fff] my-3" key={item.id}>
                      <div className=" border-[1px] flex rounded-lg p-3 justify-between pb-5 border-[#E4EBF5]">
                        <div>
                          <p className="text-sm text-[#071227] mt-2 font-[700]">
                            Order {item.orderNo}
                          </p>
                          <p className="pt-2 text-xs text-[#9899A1] mb-2">
                            {item.orderDate
                              ? moment(item.orderDate).format(
                                  "DD MMM, YYYY: h:mm a"
                                )
                              : "N/A"}
                          </p>
                          <div
                            className={`inline px-2.5 py-1.5 text-xs mb-3 rounded-md font-onestMedium font-[600] ${
                              item.orderStatus === "pending"
                                ? "bg-[#D4F3D5] text-[#289D17]"
                                : item.orderStatus === "Pending Pickup"
                                ? "bg-[#F8E4D1] text-[#65513F]"
                                : "bg-[#FAEFE5] text-[#F59A49]"
                            }`}
                          >
                            {item.orderStatus}
                          </div>
                        </div>
                        <div className="">
                          <div className="pr-1.5 text-center py-5 flex justify-center items-center">
                            <div
                              onClick={() => {
                                setPrintManifest(true);
                                setViewData(item);
                              }}
                              className="text-sm text-[#45C734] flex items-center gap-1 px-2 py-1 rounded-md font-[400] font-onestRegular"
                            >
                              <IoEyeOutline size={16} />
                              View
                            </div>
                            <div className="py-1  justify-center text-[#289D17] font-[600] rounded-[4px] px-2 text-xs">
                              <Button
                                title={"Print"}
                                icon={<BiPrinter />}
                                iconLeft={true}
                                onClick={() => clickPrint(item)}
                                className="!bg-transparent  !text-[#289D17] !pl-0 !font-[400]"
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  );
                })}
                {!isOrderLoading &&
                  data?.orders?.data.length > 0 &&
                  data?.orders?.meta?.total > data?.orders?.meta?.per_page && (
                    <PagePagination
                      totalPages={data?.orders?.last_page}
                      page={page}
                      handlePageClick={(event) => setPage(event.selected)}
                    />
                  )}
              </div>
            )}
          </div>
        </div>
      ) : (
        <div className="md:hidden">
          <div
            onClick={() => setPrintManifest(!PrintManifestInfo)}
            className="mx-5 mt-9 flex mb-2 cursor-pointer "
          >
            <div>
              {" "}
              <img className="w-3 h-3" src="/images/backArrow.svg" alt="" />
            </div>
            <p className=" text-[#4a564d] font-[500] text-[13px] -mt-1 font-onestMedium">
              Go back
            </p>
          </div>
          <div className="mt-3 sm:mt-[72px] rounded-lg max-w-[38rem] block px-5 py-6 mb-[150px] md:hidden mx-4 sm:mx-auto border-[1px] border-[#E1F0DF] bg-dashboard-gradient">
            <h1 className="text-[#0A5001] font-[900] text-xl md:text-2xl mb-2 font-onestBold">
              Order - {viewData?.orderNo}
            </h1>
            <span
              className={`text-xs text-[#65513F] ${
                viewData.orderStatus === "pending"
                  ? "bg-[#D4F3D5] text-[#289D17]"
                  : viewData.orderStatus === "Pending Pickup"
                  ? "bg-[#F8E4D1] text-[#65513F]"
                  : "bg-[#FAEFE5] text-[#F59A49]"
              } px-2 py-1 rounded-md font-[500] font-onestMedium`}
            >
              {viewData?.orderStatus}
            </span>
            <div className="border-[1px] rounded-lg mt-8 bg-white border-[#E4EBF5]">
              <div className="font-onestRegular mt-[30px] py-4 px-3">
                <h1 className="font-onestRegular ">Pick up address</h1>
                <p className="font-onestMedium ">{viewData?.clientAddress}</p>
              </div>
              <div className="px-3 py-4 ">
                <h1 className="font-onestRegular">Recipient Name</h1>
                <p className="font-onestMedium ">{viewData?.recipientName}</p>
              </div>
              <div className=" py-4 px-3">
                <h1 className="font-onestRegular">Recipient Address</h1>
                <p className="font-onestMedium">{viewData?.recipientAddress}</p>
              </div>
              <div className="py-4  px-3">
                <h1 className="font-onestRegular ">Recipient Phone</h1>
                <p className="font-onestMedium ">
                  {viewData?.recipientPhone || "N/A"}
                </p>
              </div>
              <div className="py-4 px-3">
                <h1 className="font-onestRegular">Recipient Email</h1>
                <p className="font-onestMedium ">
                  {viewData?.recipientEmail || "N/A"}
                </p>
              </div>
              <div className="py-4 px-3">
                <h1 className="font-onestRegular">Status</h1>
                <span className="text-xs text-[#65513F] bg-[#F8E4D1] px-2 py-1 rounded-md font-[500] font-onestMedium">
                  {viewData?.orderStatus}
                </span>
              </div>
              <div className=" py-4  px-3">
                <h1 className="font-onestRegular ">Status Description</h1>
                <p className="font-onestMedium ">
                  {viewData?.statusDescription || "N/A"}
                </p>
              </div>
              <div className="py-4 px-3">
                <h1 className="font-onestRegular">Cost</h1>
                <p className="font-onestMedium ">
                  N{formatMoney().format(viewData?.cost)}
                </p>
              </div>
              <div className="py-4 px-3">
                <h1 className="font-onestRegular ">Created By</h1>
                <p className="font-onestMedium ">{viewData?.createdBy}</p>
              </div>
              <div className="py-4 px-3">
                <h1 className="font-onestRegular ">Pick Up Date</h1>
                <p className="font-onestMedium ">
                  {viewData?.pickUpDate
                    ? moment(viewData?.pickUpDate).format("DD MMM, YYYY")
                    : "N/A"}
                </p>
              </div>
              <div className="py-4 px-3">
                <h1 className="font-onestRegular ">Dispatch Date</h1>
                <p className="font-onestMedium ">
                  {viewData?.dispatchDate
                    ? moment(viewData?.dispatchDate).format("DD MMM, YYYY")
                    : "N/A"}
                </p>
              </div>
              <div className="py-4 px-3">
                <h1 className="font-onestRegular ">Delivery Date</h1>
                <p className="font-onestMedium ">
                  {viewData?.deliveryDate
                    ? moment(viewData?.deliveryDate).format("DD MMM, YYYY")
                    : "N/A"}
                </p>
              </div>
              <div className="py-4 px-3">
                <h1 className="font-onestRegular ">Additional Note</h1>
                <p className="font-onestMedium ">
                  {viewData?.additionalNote || "N/A"}
                </p>
              </div>
            </div>
          </div>
        </div>
      )}

      <div className="hidden">
        <ComponentToPrintSVG
          details={selectedOrders}
          ref={componentMultipleRef}
        />
      </div>

      <div className="hidden">
        <BarCode ref={componentRef} barcodeData={barcodeData} />
      </div>
      <ModalContainer
        open={showDateModal}
        showCloseIcon={false}
        tailwindClassName="max-w-[466px]"
      >
        <div className="w-full p-8 bg-white flex flex-col  rounded-[12px]">
          <p className="text-lg text-center pb-4 font-onestBold text-[#0A5001] ">
            Custom date range
          </p>
          <div className="flex gap-3 justify-between">
            <div className="">
              <DatePicker
                placeholderText={"Start date"}
                selected={startDate}
                onChange={handleDateChange}
                className="border-[1px] border-[#E4EBF5] w-[150px] p-2.5 rounded-lg outline-none"
              />
            </div>
            <div className="">
              <DatePicker
                placeholderText={"End date"}
                selected={endDate}
                onChange={handleEndDateChange}
                className="border-[1px] border-[#E4EBF5] w-[150px] p-2.5 rounded-lg outline-none"
              />
            </div>
          </div>
          <div className="flex justify-between gap-3 items-center pt-[30px]">
            <Button
              title="Clear"
              onClick={onClearDate}
              className="!py-[10px] !border-[#BEC0BE] !px-12 !w-fit !rounded-lg !bg-transparent !border !text-[#289D17]"
            />
            <Button
              title="Apply"
              className="!py-[10px] !w-fit !rounded-lg !px-12 !bg-[#127E04] !text-[#fff]"
              onClick={onApplyDate}
            />
          </div>
        </div>
      </ModalContainer>
      {openPrint && (
        <DashboardModalAction
          contentTitle="Print order"
          btnContent="Print"
          btnContentTwo="Cancel"
          imageStr="/images/print.png"
          verify
          content="Do you want to print the order?"
          onCloseTwo={() => {
            setOpenPrint(false);
          }}
          onClose={() => {
            setOpenPrint(false);
            onPrint();
          }}
        />
      )}
    </div>
  );
}

export default PrintManifest;
