import React from "react";
import Skeleton from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";

interface TableLoaderProps {
  rowLength: number;
  columnLength?: number;
}

export const TableLoader: React.FC<TableLoaderProps> = ({
  rowLength,
  columnLength = 7,
}) => {
  return (
    <table>
      <tbody>
        {Array.from({ length: columnLength }, (_, index) => (
          <tr key={index}>
            {Array.from({ length: rowLength }, (_, index) => (
              <td
                key={index}
                className="border-r h-[34px] border-slate-100 py-4 px-4"
              >
                <Skeleton
                  height={10}
                  width={40}
                  highlightColor="#E2E8F0"
                  borderRadius={16}
                  baseColor="#F1F5F9"
                  className="custom-skeleton"
                />
              </td>
            ))}
          </tr>
        ))}
      </tbody>
    </table>
  );
};
