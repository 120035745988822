

export const productLinks = [
  {
    title: "Home",
    icons: "/images/home.svg",
    link: "/dashboard",
  },
  {
    title: "Ship Now",
    icons: "/images/request.svg",
    link: [
      "/dashboard/ship-now",
      "/dashboard/ship-now/bulk-upload",
      "/dashboard/ship-now/single-shipment",
    ],
    children: [
      {
        title: "Create",
        link: "/dashboard/ship-now",
      },
      {
        title: "Bulk Upload",
        link: "/dashboard/ship-now/bulk-upload",
      },
    ],
  },
    {
    title: "Shipments",
    icons: "/images/orders.svg",
    link: "/dashboard/shipments",
  },
  {
    title: "Wallet",
    icons: "/images/wallet.svg",
    link: "/dashboard/wallet",
  },
  {
    title: "Print Shipping Label",
    icons: "/images/print.svg",
    link: "/dashboard/print-manifest",
  },
  {
    title: "Inventory Management",
    icons: "/images/inv-mgt.svg",
    link: [
      "/dashboard/inventory",
      "/dashboard/inventory/view-inventory",
      "/dashboard/inventory/view-inventory/:id",
      "/dashboard/inventory/store-item",
      "/dashboard/inventory/store-item/bulk-upload",
      "/dashboard/inventory/storage-request",
     "/dashboard/inventory/storage-request/:id/approval",
      "/dashboard/inventory/retrieve-item",
      "/dashboard/inventory/retrieve-item/:id",
      "/dashboard/inventory/requests",
      "/dashboard/inventory/create-retrieve-item",
      "/dashboard/inventory/edit-retrieve-item/:id"

    ],
    children: [
      {
        title: "Overview",
        link: "/dashboard/inventory",
      },
      {
        title: "Store Item",
        link: "/dashboard/inventory/store-item",
      },
      {
        title: "Retrieve Item",
        link: "/dashboard/inventory/retrieve-item",
      },
      {
        title: "View Requests",
        link: "/dashboard/inventory/requests",
      },
      {
        title: "View Inventory",
        link: "/dashboard/inventory/view-inventory",
      },
    ],
  },
];

export const toolsLinks = [
  {
    title: "Import from Bumpa",
    icons: "/images/Bumpa1.svg",
    link: ["/dashboard/import-orders", "/dashboard/manage-keys"],
    children: [
      {
        title: "Import Orders",
        link: "/dashboard/import-orders",
      },
      {
        title: "Manage Keys",
        link: "/dashboard/manage-keys",
      },
    ],
  },
  {
    title: "Tickets",
    icons: "/images/ticket.svg",
    link: [
      "/dashboard/tickets/create-ticket",
      "/dashboard/ticket",
      "/dashboard/ticket/",
      "/dashboard/ticket/:id",
    ],
    children: [
      {
        title: "Create New",
        link: "/dashboard/tickets/create-ticket",
      },
      {
        title: "View All",
        link: "/dashboard/ticket",
      },
    ],
  },
  {
    title: "Reports",
    icon: "/images/reports.svg",
    link: "/dashboard/reports",
  },
  {
    title: "Users",
    icon: "/images/users.svg",
    link: ["/dashboard/users/create-user", "/dashboard/users"],
    children: [
      {
        title: "Create new",
        link: "/dashboard/users/create-user",
      },
      {
        title: "View All",
        link: "/dashboard/users",
      },
    ],
  },
];
export const serviceLinks = [
  {
    title: "Pricing",
    icon: "/images/pricing.svg",
    link: ["/dashboard/pricing","/dashboard/pricing/list"],
    children: [
      {
        title: "Get a quote",
        link: "/dashboard/pricing",
      },
      {
        title: "Pricing",
        link: "/dashboard/pricing/list",
      },
    ],
  },
  {
    title: "Developers",
    icon: "/images/dev.svg",
    link: "/dashboard/developer",
  },
  // {
  //   title: "Help",
  //   icon: "/images/help.svg",
  //   link: "/dashboard/help",
  // },
];
export const navTitle = [
  {
    title: "Home",
    link: "dashboard",
  },
  {
    title: "Ship Now",
    link: "ship-now",
  },
  {
    title: "Ship Now",
    link: "bulk-upload",
  },
  {
    title: "Users",
    link: "users",
  },
  {
    title: "Users",
    link: "create-user",
  },
  {
    title: "Pricing",
    link: "pricing",
  },
  {
    title: "Pricing",
    link: "list",
  },
  {
    title: "Tickets",
    link: "ticket",
  },
  {
    title: "Inventory Management",
    link: "inventory",
  },
  {
    title: "Tickets",
    link: "tickets",
  },
  {
    title: "Tickets",
    link: "create-ticket",
  },
  {
    title: "Tickets",
    link: `:id`,
  },
  {
    title: "Developers",
    link: "developer",
  },
  {
    title: "Import from Bumpa",
    link: "import-orders",
  },
  {
    title: "Import from Bumpa",
    link: "manage-keys",
  },
  {
    title: "Report",
    link: "reports",
  },
  {
    title: "Shipments",
    link: "shipments",
  },
  {
    title: "Print Shipping Label",
    link: "print-manifest",
  },
  {
    title: "Invoice",
    link: "invoices",
  },
  {
    title: "Invoice",
    link: `invoice:id`,
  },
  {
    title: "Wallet",
    link: "wallet",
  },
  {
    title: "Notifications",
    link: "notification",
  },
];
