

const regex = /^[^\s@]+@[^\s@]+\.[^\s@]{2,}$/i;

  export  const validateStepOne = (values: any) => {
    let errors: any = {};

    if (!values.pickUpState || values.pickUpState === "") {
      errors.pickUpState = "Pick up state is required";
    }

    if (!values.dropOffAddress || values.dropOffAddress === "") {
      errors.dropOffAddress = "Drop-off address is required";
    }

    if (!values.pickUpAddress || values.pickUpAddress === "") {
      errors.pickUpAddress = "Pick-up address is required";
    }

    if (!values.state || values.state === "") {
      errors.state = "State is required";
    }

    if (!values.deliveryType || values.deliveryType === "") {
      errors.deliveryType = "DeliveryType is required";
    }
    if (!values.recipientPhoneNumber || values.recipientPhoneNumber === "") {
      errors.recipientPhoneNumber = "Recipient phone number is required";
    } else if (values.recipientPhoneNumber?.length !== 11) {
      errors.recipientPhoneNumber = "Recipient phone number must be 11 digits";
    }
    if (values.pickUpPhoneNumber === "" || !values.pickUpPhoneNumber) {
      errors.pickUpPhoneNumber = "";
    } else if (
      values.pickUpPhoneNumber &&
      values.pickUpPhoneNumber?.length !== 11
    ) {
      errors.pickUpPhoneNumber = "Pick-up phone number must be 11 digits";
    }
    if (!values.recipientName || values.recipientName === "") {
      errors.recipientName = "Recipient name is required";
    }
    if (!values.itemDescription || values.itemDescription === "") {
      errors.itemDescription = "Item description is required";
    }
    if (!values.weight || values.weight === "") {
      errors.weight = "Weight is required";
    }
    if (!values.valueOfItem || values.valueOfItem === "") {
      errors.valueOfItem = "Value of item is required";
    }
    if (values.recipientEmail && !regex.test(values.recipientEmail)) {
      errors.recipientEmail = "Invalid email format";
    }
    return errors;
  };
  export const validateStepTwo = (values: any) => {
    let errors: any = {};

    if (!values.importDropOffState || values.importDropOffState === "") {
      errors.importDropOffState = "Drop-off country is required";
    }

    if (!values.importCountry || values.importCountry === "") {
      errors.importCountry = "Pick-up country is required";
    }

    if (!values.importOrderNumber || values.importOrderNumber === "") {
      errors.importOrderNumber = "Order number is required";
    }

    if (!values.importVendorName || values.importVendorName === "") {
      errors.importVendorName = "Vendor/Business name is required";
    }
    if (!values.importDropOffAdd || values.importDropOffAdd === "") {
      errors.importDropOffAdd = "Drop-off address is required";
    }
    if (!values.importItem || values.importItem === "") {
      errors.importItem = "Item category is required";
    }
    if (!values.importRecipientPhone || values.importRecipientPhone === "") {
      errors.importRecipientPhone = "Recipient phone number is required";
    } else if (values.importRecipientPhone?.length !== 11) {
      errors.importRecipientPhone = "Recipient phone number must be 11 digits";
    }

    if (!values.importRecipientName || values.importRecipientName === "") {
      errors.importRecipientName = "Recipient name is required";
    }
    if (!values.importWeight || values.importWeight === "") {
      errors.importWeight = "Weight is required";
    }
    if (!values.importValueofItem || values.importValueofItem === "") {
      errors.importValueofItem = "Value of item is required";
    }
    if (!values.importDescription || values.importDescription === "") {
      errors.importDescription = "Item description is required";
    }
    if (
      values.importRecipientEmail &&
      !regex.test(values.importRecipientEmail)
    ) {
      errors.recipientEmail = "Invalid email format";
    }
    return errors;
  };
  export const validateStepThree = (values: any) => {
    let errors: any = {};


    if (!values.exportCountry || values.exportCountry === "") {
      errors.exportCountry = "Drop-off country is required";
    }
    if (!values.exportPickUpState || values.exportPickUpState === "") {
      errors.exportPickUpState = "Pick-up state is required";
    }
    if (!values.exportDropOffCity || values.exportDropOffCity === "") {
      errors.exportDropOffCity = "Drop-off city is required";
    }

    if (!values.exportZipCode || values.exportZipCode === "") {
      errors.exportZipCode = "Zip code is required";
    }
    if (!values.exportDropOffAddress || values.exportDropOffAddress === "") {
      errors.exportDropOffAddress = "Drop-off address is required";
    }
    if (!values.exportPickUpAddress || values.exportPickUpAddress === "") {
      errors.exportPickUpAddress = "Pick-up address is required";
    }

    if (!values.exportPhoneNumber || values.exportPhoneNumber === "") {
      errors.exportPhoneNumber = "Recipient phone number is required";
    } else if (
      values.exportPickUpPhoneNumber?.length < 9 ||
      values.exportPickUpPhoneNumber?.length > 13
    ) {
      errors.exportPickUpPhoneNumber =
        "phone number must be between 9 - 13 digits.";
    }
    if (
      !values.exportPickUpPhoneNumber ||
      values.exportPickUpPhoneNumber === ""
    ) {
      errors.exportPickUpPhoneNumber = "Pick-up phone number is required";
    } else if (values.exportPickUpPhoneNumber?.length !== 11) {
      errors.exportPickUpPhoneNumber = "Pick-up phone number must be 11 digits";
    }

    if (!values.exportRecipientName || values.exportRecipientName === "") {
      errors.exportRecipientName = "Recipient name is required";
    }
    if (!values.exportWeight || values.exportWeight === "") {
      errors.exportWeight = "Weight is required";
    }
    if (!values.exportValueofItem || values.exportValueofItem === "") {
      errors.exportValueofItem = "Value of item is required";
    }
    if (!values.exportItemDescription || values.exportItemDescription === "") {
      errors.exportItemDescription = "Item description is required";
    }
    if (
      values.exportRecipientEmail &&
      !regex.test(values.exportRecipientEmail)
    ) {
      errors.recipientEmail = "Invalid email format";
    }
    return errors;
  };
  export const validateAmount = (value: string) => {
    let errors: any = {};
    if (!value || value === "") {
      errors.amount = "Amount is required";
    } else if (value !== "") {
      errors.amount = "";
    }
    return errors;
  };
 export  const handleFileChange = (event: any, setFormErrors:any,  setDeliveryDetails:any ) => {
    if (event.target === null) {
      console.error("No file selected");
      return;
    }

    const file = event.target.files[0];
    if (!file) {
      console.error("Failed to retrieve file");
      return;
    }
    if (file) {
      setFormErrors((prev: any) => ({
        ...prev,
        file: "",
      }));
      setDeliveryDetails((prev:any) => ({
        ...prev,
        fileName: file?.type.split("/")[1],
      }));
      setDeliveryDetails((prev:any) => ({
        ...prev,
        fileNames: file?.name,
      }));
    }
    const reader = new FileReader();
    reader.readAsDataURL(file);

    reader.onload = (event: any) => {
      const base64EncodedData = event.target.result;
      const base64Data = base64EncodedData.substring(
        base64EncodedData.indexOf(",") + 1
      );
      setDeliveryDetails((prev:any) => ({
        ...prev,
        file: base64Data,
      }));
    };
  };

  export  const validateItem = (values: any) => {
    let errors: any = {};

    if (!values.deliveryAddress || values.deliveryAddress === "") {
      errors.deliveryAddress = "Delivery address is required";
    }

    if (!values.retrievalDate || values.retrievalDate === "") {
      errors.retrievalDate = "Retrieval date is required";
    }

    if (!values.category || values.category === "") {
      errors.category = "Category is required";
    }

    if (!values.item || values.item === "") {
      errors.item = "Item name is required";
    }

    if (!values.quantity || values.quantity === "") {
      errors.quantity = "Quantity is required";
    }


    return errors;
  };

  export  const validateStoreItem = (values: any) => {
    let errors: any = {};

    if (!values.pickUpAddress || values.pickUpAddress === "") {
      errors.pickUpAddress = "Pick up address is required";
    }

    if (!values.expiryDate || values.expiryDate === "") {
      errors.expiryDate = "Retrieval date is required";
    }

    if (!values.category || values.category === "") {
      errors.category = "Category is required";
    }

    if (!values.subCategory || values.subCategory === "") {
      errors.subCategory = "Sub-category is required";
    }

    if (!values.quantity || values.quantity === "") {
      errors.quantity = "Quantity is required";
    }
    if (!values.expiryDate || values.expiryDate === "") {
      errors.expiryDate = "Expiry date is required";
    }
    return errors;
  };