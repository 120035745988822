import { Button } from "components/Button";
import { TextInput } from "components/common/form/Input";
import Dropdown from "components/common/form/SelectDropdown";
import FileDragNDrop from "components/dashboard/FileDragNDrop";
import BulkStorageTable from "components/dashboard/Inventory/bulk/BulkStorageTable";
import ModalContainer from "components/Modal";
import { deliveryMode } from "constant/data";
import { formatMoney } from "helpers/formatMoney";
import { handleErrorToast } from "helpers/toastMessage";
import React, { useEffect, useRef, useState } from "react";
import { AiFillPicture } from "react-icons/ai";
import { IoIosArrowBack, IoIosClose } from "react-icons/io";
import { Link, useNavigate } from "react-router-dom";
import {
  useCreateOrderMutation,
  useExtractOrderMutation,
  useGetProductsQuery,
} from "services/slices/inventory";
import { BulkItem, EditableState } from "type/inventory";

interface Item {
  id: number;
  name: string;
  product_category_id: number;
  weight: string;
  size: string;
  price_per_unit: string;
  description: string;
  expiry_date: string;
  image: string;
  subCategoryId: string;
}

const BulkStoreItem = () => {
  const navigate = useNavigate();
  const tableContainerRef = useRef<HTMLDivElement | null>(null);
  const errorRefs = useRef<{ [key: string]: HTMLTableCellElement | null }>({});
  const [isEditOrder, setIsEditOrder] = useState<boolean>(false);
  const [isBulkStorage, setIsBulkStorage] = useState<boolean>(false);
  const [isStorageCreated, setIsStorageCreated] = useState<boolean>(false);
  const [acceptedFiles, setAcceptedFiles] = useState<any>([]);
  const [uiFileType, setUiFileType] = useState<string>("");
  const [itemsBulk, setItemsBulk] = useState<BulkItem[]>([]);
  const [pickUpAddress, setPickUpAddress] = useState<string>("");
  const [searchCategory, setSearchCategory] = useState<string>("");
  const [searchSubCategory, setSearchSubCategory] = useState<string>("");
  const [items, setItems] = useState<Item[]>([]);
  const [editDetails, setEditDetails] = useState<BulkItem | any>({});

  const [warehouseDeliveryMode, setWarehouseDeliveryMode] =
    useState<string>("");
  const [loading, setLoading] = useState<boolean>(false);

  const [formErrors, setFormErrors] = useState<any>({});
  const [result, setResult] = useState<any>({});
  const [editable, setEditable] = useState<EditableState>({
    id: null,
    field: null,
  });
  const [
    extractOrder,

    {
      isLoading: isExtractOrderLoading,
      data: extractData,
      error: extractOrderError,
      isError: isExtractOrderError,
    },
  ] = useExtractOrderMutation();
  const { data, isLoading: isLoadingProducts } = useGetProductsQuery("addition");
  const [createOrder, { isLoading }] = useCreateOrderMutation();

  const isValid =
    warehouseDeliveryMode !== "" &&
    acceptedFiles.length !== 0 &&
    ((warehouseDeliveryMode === "pickup" && pickUpAddress !== "") ||
      (warehouseDeliveryMode !== "pickup" && pickUpAddress === ""));

  const filteredCategories = data?.data.categories
    ?.filter(
      (category) =>
        category.name.toLowerCase().includes(searchCategory.toLowerCase()) ||
        category.products.some((product) =>
          product.name.toLowerCase().includes(searchCategory.toLowerCase())
        )
    )
    ?.sort((a, b) => a.name.localeCompare(b.name));

  const filteredSubCategories = items
    ?.filter((product) =>
      product.name.toLowerCase().includes(searchSubCategory.toLowerCase())
    )
    ?.sort((a, b) => a.name.localeCompare(b.name));

  const selectedCategory = data?.data.categories.find(
    (cat: any) => cat.name === editDetails.category
  );

  const subCategoryOptions = selectedCategory?.products || [];
  const filteredEditedSubCategories = subCategoryOptions
    ?.filter((product: any) =>
      product.name.toLowerCase().includes(searchSubCategory.toLowerCase())
    )
    ?.sort((a: Item, b: Item) => a.name.localeCompare(b.name));

  const onDrop = async (acceptedFiles: File[]) => {
    setLoading(true);
    const file = acceptedFiles[0];
    const reader = new FileReader();

    reader.onload = (event: ProgressEvent<FileReader>) => {
      const base64String = event.target?.result as string;
      const base64Result = base64String.split(",")[1];
      setUiFileType(base64Result);
      setLoading(false);
    };

    reader.onerror = (error: ProgressEvent<FileReader>) => {
      console.error("Error reading file:", error);
      setLoading(false);
    };

    reader.readAsDataURL(file);
  };

  const validateField = (field: string, value: string) => {
    if (field === "category" && value.trim() === "") {
      return { valid: false, errorMessage: "Category is required" };
    }
    if (field === "subCategory" && value.trim() === "") {
      return { valid: false, errorMessage: "Sub-category is required" };
    }
    if (field === "quantity" && value.trim() === "") {
      return { valid: false, errorMessage: "Quantity is required" };
    }
    if (field === "expiryDate" && value.trim() === "") {
      return { valid: false, errorMessage: "Expiry date is required" };
    }
    return { valid: true, errorMessage: null };
  };
  function countValidEntries(data: any) {
    let trueCount = 0;
    let falseCount = 0;
    let rowValidCount = 0;
    let rowInvalidCount = 0;

    if (Array.isArray(data)) {
      data.forEach((manifest) => {
        const fields = [
          "categoryValid",
          "subCategoryValid",
          "quantityValid",
          "expiryDateValid",
        ];

        let isRowValid = true;

        fields.forEach((field) => {
          if (manifest[field]) {
            trueCount++;
          } else {
            falseCount++;
            isRowValid = false;
          }
        });

        if (isRowValid) {
          rowValidCount++;
        } else {
          rowInvalidCount++;
        }
      });
    } else {
      console.error("Data is not an array");
    }
    return { trueCount, falseCount, rowValidCount, rowInvalidCount };
  }
  useEffect(() => {
    const result = countValidEntries(itemsBulk);
    setResult(result);
  }, [itemsBulk]);

  const handleSubmitOrder = async (e: React.FormEvent<HTMLFormElement>) => {
    try {
      e.preventDefault();
      if (uiFileType !== "") {
        const body: any = {
          fileName: acceptedFiles[0]?.name,
          type: "addition",
          delivery_mode: warehouseDeliveryMode,
          address: pickUpAddress,
          file: uiFileType,
        };

        await extractOrder(body).unwrap();
      }
    } catch (e) {}
  };

  useEffect(() => {
    if (extractData?.data?.items) {
      setIsBulkStorage(true);

      const itemsInfo: BulkItem[] = extractData.data.items.map(
        (manifestItem: any, index: number) => {
          const row = Array.isArray(manifestItem) ? manifestItem : [];
          const findField = (fieldName: string) => {
            const field = row.find((item: any) => item.name === fieldName);
            return field || { value: "", valid: false, errorMessage: null };
          };

          const isRowValid =
            row.length > 0 && row.every((item: any) => item.valid);

          return {
            id: index,
            category: findField("category").value || "",
            categoryValid: findField("category").valid || false,
            categoryError: findField("category").errorMessage || null,
            subCategory: findField("sub_category").value || "",
            subCategoryValid: findField("sub_category").valid || false,
            subCategoryError: findField("sub_category").errorMessage || null,
            quantity: findField("quantity").value || "",
            quantityValid: findField("quantity").valid || false,
            quantityError: findField("quantity").errorMessage || null,
            expiryDate: findField("expiry_date").value || "",
            expiryDateValid: findField("expiry_date").valid || false,
            expiryDateError: findField("expiry_date").errorMessage || null,
            action: "Delete",
            rowValid: isRowValid,
            rowError: isRowValid
              ? null
              : row
                  .filter((item: any) => !item.valid)
                  .map((item: any) => item.errorMessage)
                  .join("; "),
          };
        }
      );

      const sortedItemsInfo = itemsInfo.sort((a, b) =>
        a.rowValid === b.rowValid ? 0 : a.rowValid ? 1 : -1
      );

      setItemsBulk(sortedItemsInfo);
    }
  }, [extractData]);

  useEffect(() => {
    if (extractOrderError && isExtractOrderError) {
      handleErrorToast(extractOrderError);
    }
  }, [isExtractOrderError, extractOrderError]);

  const handleBlur = () => {
    setEditable({ id: null, field: null });
  };
  const handleEditClick = (id: number, field: any) => {
    setEditable({ id, field });
  };
  const handleChange = (id: number, field: keyof BulkItem, value: string) => {
  
    setItemsBulk((prevItems) => {
      const updatedItems = prevItems.map((item) => {
        if (item.id === id) {
          const validation = validateField(field, value);
          return {
            ...item,
            [field]: value,
            [`${field}Valid`]: validation.valid,
            rowValid:
              item.categoryValid &&
              item.subCategoryValid &&
              item.quantityValid &&
              item.expiryDateValid,
          };
        }

        return item;
      });

      const updatedItem = updatedItems.find((item) => item.id === id);
      if (updatedItem && (field === "category" || field === "subCategory")) {
        setSearchCategory("");
        setSearchSubCategory("");
      }

      return updatedItems;
    });
  };
  const handleDelete = (id: number) => {
    const updatedItems = itemsBulk.filter((item) => item.id !== id);
    setItemsBulk(updatedItems);
  };

  const onRequestStorage = () => {
    const updatedItems = itemsBulk.map((item) => {
      const matchedCategory = filteredCategories?.find(
        (category) =>
          category.name.toLowerCase() === item.category.toLowerCase()
      );

      const matchedProduct = matchedCategory?.products.find(
        (product) =>
          product.name.toLowerCase() === item.subCategory.toLowerCase()
      );

      return {
        category: matchedCategory ? Number(matchedCategory.id) : null,
        sub_category: matchedProduct ? Number(matchedProduct.id) : null,
        quantity: Number(item.quantity),
        expiry_date: item.expiryDate,
      };
    });
    createOrder({
      items: updatedItems,
      address: pickUpAddress,
      delivery_mode: warehouseDeliveryMode,
    })
      .unwrap()
      .then(() => {
        setIsStorageCreated(true);
      })
      .catch((e) => handleErrorToast(e));
  };
  const onEditOrder = (e: any) => {
    setIsEditOrder(true);
    const updatedItem: any = itemsBulk.find(
      (item: BulkItem) => item.id === e?.id
    );
    setEditDetails(updatedItem);
  };

  const handleErrorChange = (name: string, value: any) => {
    if (name === "category" && value !== "") {
      setFormErrors((prev: any) => ({
        ...prev,
        category: "",
      }));
    } else if (name === "sub-category" && value !== "") {
      setFormErrors((prev: any) => ({
        ...prev,
        subCategory: "",
      }));
    } else if (name === "expiryDate" && value !== "") {
      setFormErrors((prev: any) => ({
        ...prev,
        expiryDate: "",
      }));
    }
  };
  const handleDeliveryChange = (e: any, field: string) => {
    const { name, value: inputValue } = e?.target; 
    handleErrorChange(name, inputValue);

    let value = inputValue;

    if (field === "quantity") {
      value = inputValue.replace(/[^0-9]/g, ""); 
      value = Math.max(0, Number(e.target.value)); 
    }
    setItemsBulk((prevItems) => {
      const updatedItems = prevItems.map((item) => {
        if (item.id === editDetails?.id) {
          const validation = validateField(field, value);
          let updatedItem = {
            ...item,
            [field]: value,
            [`${field}Valid`]: validation.valid,
            rowValid:
              item.categoryValid &&
              item.subCategoryValid &&
              item.quantityValid &&
              item.expiryDateValid,
          };

          if (field === "subCategory") {
            const selectedSubCategory = filteredEditedSubCategories.find(
              (sub) => sub.name === value
            );

            if (selectedSubCategory) {
              updatedItem = {
                ...updatedItem,
                image: selectedSubCategory.image,
                weight: selectedSubCategory.weight,
                size: selectedSubCategory.size,
                price: selectedSubCategory.price_per_unit,
                description: selectedSubCategory.description,
              };
            }
          }

          return updatedItem;
        }
        return item;
      });

      const updatedItem = updatedItems.find(
        (item) => item.id === editDetails?.id
      );
      if (updatedItem && (field === "category" || field === "subCategory")) {
        setSearchCategory("");
        setSearchSubCategory("");
      }
      setEditDetails(updatedItem);

      return updatedItems;
    });
  };

  return (
    <>
      {!isBulkStorage ? (
        <>
          <div
            onClick={() => navigate(-1)}
            className="flex gap-1 mt-14 md:mt-0 ml-4 cursor-pointer w-[100px] md:hidden"
          >
            <div>
              {" "}
              <img className="w-3 h-3" src="/images/backArrow.svg" alt="" />
            </div>
            <p className="text-[#4a564d] font-[500] text-[13px] -mt-1 font-onestMedium">
              Go back
            </p>
          </div>
          <div className="mt-2 sm:mt-[72px] mx-4 rounded-lg px-4 md:px-10 pb-8 md:py-8 max-w-[38rem] md:mx-auto border-[1px] border-[#E1F0DF] flex bg-dashboard-gradient justify-center">
            <div className="w-full md:px-0 onestBold">
              <div className="hidden md:flex items-center justify-between">
                <div
                  onClick={() => navigate(-1)}
                  className="flex gap-1 cursor-pointer w-[100px]"
                >
                  <div>
                    {" "}
                    <img
                      className="w-3 h-3"
                      src="/images/backArrow.svg"
                      alt=""
                    />
                  </div>
                  <p className="text-[#4a564d] font-[500] text-[13px] -mt-1 font-onestMedium">
                    Go back
                  </p>
                </div>
              </div>
              <div className="w-full mt-8 font-onestMedium">
                <h1 className="md:text-2xl text-xl font-onestBold font-[800] text-[#0a5001] tracking-wide">
                  Store Item in Warehouse
                </h1>
                <p className="text-[#a0aba3] font-[500] font-onestRegular tracking-normal mb-7">
                  You are bulk uploading multiple items. You can download the
                  file template{" "}
                  <Link to={""} className="text-textGreen">
                    here
                  </Link>
                </p>
              </div>
              <div>
                <div className="flex  mb-2">
                  <p className="text-[#4A564D] text-sm leading-[20px] font-onestMedium">
                    Warehouse delivery mode
                  </p>
                  <span className="text-red-500 text-sm">*</span>
                </div>
                <div className="flex sm:flex-row flex-col sm:space-y-0 space-y-3 items-center sm:space-x-2 w-full">
                  {deliveryMode.map((mode) => (
                    <div
                      key={mode.id}
                      className={`flex border ${
                        warehouseDeliveryMode === mode.value
                          ? "border-[#289D17]"
                          : "border-borderGrey"
                      } w-full py-2 px-3 rounded-[8px] items-center space-x-3`}
                    >
                      <div
                        onClick={() => setWarehouseDeliveryMode(mode.value)}
                        className={`w-[18px] flex justify-center items-center cursor-pointer h-[18px] border ${
                          warehouseDeliveryMode === mode.value
                            ? "border-[#289D17]"
                            : "border-borderGrey"
                        }  rounded-full`}
                      >
                        {warehouseDeliveryMode === mode.value && (
                          <div className="w-[10px] h-[10px] bg-[#289D17] rounded-full"></div>
                        )}
                      </div>
                      <p className="text-[#9899A1] text-sm">{mode.name}</p>
                    </div>
                  ))}
                </div>
                {warehouseDeliveryMode === "pickup" && (
                  <div className="mt-6">
                    <TextInput
                      type="text"
                      label="Pick-up address"
                      isRequired
                      name="pickUpAddress"
                      value={pickUpAddress}
                      onChange={(e) => setPickUpAddress(e.target.value)}
                      placeholder="Enter pick-up address"
                    />
                    {formErrors.pickUpAddress && (
                      <span className="text-xs text-[#BA3838] font-onestMedium">
                        {formErrors.pickUpAddress}
                      </span>
                    )}
                  </div>
                )}
              </div>
              <p className="mt-8 text-[#4a564d] text-[13px] font-[500]">
                Upload file
              </p>

              <FileDragNDrop
                setUiFileType={setUiFileType}
                acceptedFiles={acceptedFiles}
                setAcceptedFiles={setAcceptedFiles}
                onDrop={onDrop}
                loading={loading}
                asideClassName="!mb-0"
                text={" Drop your file here to upload or"}
                secondText={"Click to upload your Excel"}
                icon={"/images/upload.svg"}
                bgColor={"bg-[#F4F5F6]"}
              />
              <div className="flex md:flex-row flex-col text-[#9899A1] text-xs md:text-sm justify-between">
                <p>Supported formats: CSV and XLS</p>
                <p>Max size: 10MB</p>
              </div>

              <div className="mt-10 w-full">
                <Button
                  onClick={handleSubmitOrder}
                  className={`w-full !py-2.5 !px-8 font-[600] ${
                    !isValid && "!text-white !bg-[#8C9D8A]"
                  }`}
                  title="Continue"
                  disabled={isExtractOrderLoading || !isValid}
                  loading={isExtractOrderLoading}
                />
              </div>
            </div>
          </div>
        </>
      ) : (
        <div>
          <BulkStorageTable
            result={result}
            tableContainerRef={tableContainerRef}
            errorRefs={errorRefs}
            editable={editable}
            formErrors={formErrors}
            handleChange={handleChange}
            handleEditClick={handleEditClick}
            handleBlur={handleBlur}
            itemsBulk={itemsBulk}
            setFormErrors={setFormErrors}
            searchCategory={searchCategory}
            setSearchCategory={setSearchCategory}
            filteredCategorySearch={filteredCategories}
            items={items}
            searchSubCategory={searchSubCategory}
            setSearchSubCategory={setSearchSubCategory}
            setItems={setItems}
            filteredSubCategorySearch={filteredSubCategories}
            setIsBulkStorage={setIsBulkStorage}
            handleDelete={handleDelete}
            isLoadingStorage={isLoading}
            onAddStorageItem={onRequestStorage}
          />

          {!isEditOrder ? (
            <div className="block overflow-x-hidden md:hidden">
              <>
                <div
                  onClick={() => {}}
                  className="flex gap-1 mt-14 md:mt-0 ml-4 cursor-pointer w-fit md:hidden"
                >
                  <div>
                    {" "}
                    <img
                      className="w-3 h-3"
                      src="/images/backArrow.svg"
                      alt=""
                    />
                  </div>
                  <p className="text-[#4a564d] font-[500] text-[13px] -mt-1 font-onestMedium">
                    Go back
                  </p>
                </div>

                <div className="mt-2 mb-[140px] sm:mt-[72px] rounded-lg px-4 md:px-10 pt-4 pb-8 md:py-8 max-w-[38rem] mx-4 sm:mx-auto border-[1px] border-[#E1F0DF] flex bg-dashboard-gradient justify-center">
                  <div className="w-full">
                    <div className="hidden md:flex items-center justify-between">
                      <div
                        onClick={() => {}}
                        className="flex gap-1 cursor-pointer w-[100px]"
                      >
                        <div>
                          <img
                            className="w-3 h-3"
                            src="/images/backArrow.svg"
                            alt=""
                          />
                        </div>
                        <p className="text-[#4a564d] font-[500] text-[13px] -mt-1 font-onestMedium">
                          Go back
                        </p>
                      </div>
                    </div>

                    <h1 className="text-2xl mt-10 font-onestBold font-[800] text-[#0a5001] tracking-wide">
                      Bulk Storage
                    </h1>
                    <p className="mt-3 text-[#4a564d] text-[14px] font-onestMedium font-[500]">
                      Preview and edit items to be stored. If there are no
                      errors, submit storage request.
                    </p>
                    <div className="flex justify-between mt-8">
                      <div className="flex text-xs text-[#4A564D] font-[600] gap-3 mb-3 ml-2">
                        <p className="">
                          {itemsBulk.length} shipments uploaded
                        </p>
                        <div className="flex items-center gap-1">
                          <img src="/images/check-mark.svg" alt="" />
                          <p className="text-[#18AF04]">
                            {result.rowValidCount}
                          </p>
                        </div>
                        <div className="flex items-center gap-0">
                          <IoIosClose color={"#F64108"} size={18} />
                          <p className="text-[#F64108]">
                            {result.rowInvalidCount}
                          </p>
                        </div>
                      </div>
                    </div>
                    <div>
                      {itemsBulk.map((item: any, idx: number) => {
                        const valid =
                          item.categoryValid &&
                          item.subCategoryValid &&
                          item.quantityValid &&
                          item.expiryDateValid;
                        return (
                          <div
                            key={idx}
                            className="text-sm  text-[#161718] w-full font-[400]"
                          >
                            <div
                              className={`flex justify-between w-full border-[1px] ${
                                Boolean(valid)
                                  ? "border-[#E4EBF5]"
                                  : "border-[#F64108]"
                              } py-3 px-4 mb-3 rounded-lg`}
                            >
                              <div>
                                <h1 className="text-[#071227] text-sm font-onestMedium font-[500] mb-1.5">
                                  {item.subCategory}
                                </h1>
                                <h1 className="text-[#68766B] text-sm font-onestRegular font-[500] mb-1.5">
                                  Category: {item.category}
                                </h1>
                                <div className="flex items-center">
                                  <div
                                    className="text-[#18AF04] text-xs font-onestRegular"
                                    onClick={() => onEditOrder(item)}
                                  >
                                    Edit order
                                  </div>

                                  <p
                                    onClick={() => {
                                      handleDelete(item.id);
                                      handleDelete(item.id);
                                      if (itemsBulk.length === 1 && idx === 0) {
                                        setIsBulkStorage(false);
                                      }
                                    }}
                                    className="ml-1 text-[#F64108] text-xs font-onestRegular"
                                  >
                                    Delete
                                  </p>
                                </div>
                              </div>
                              <p className="text-[#071527] font-onestMedium text-[14px] font-[500]">
                                {formatMoney().format(item.quantity)}
                              </p>
                            </div>
                          </div>
                        );
                      })}
                    </div>
                    <div className="flex-col space-y-3 items-center left-0 right-0 px-5 shadow-faintShadow border-t justify-between  py-5 border-slate-100 bottom-[0px] w-full fixed z-[50]   bg-white">
                      <Button
                        className={`w-full !py-2.5 !px-8 font-[600] ${
                          result.rowInvalidCount !== 0 &&
                          "!text-white !bg-[#8C9D8A]"
                        }`}
                        title="Store Items"
                        loading={isLoading}
                        disabled={isLoading || result.rowInvalidCount !== 0}
                        onClick={onRequestStorage}
                      />
                    </div>
                  </div>
                </div>
              </>
            </div>
          ) : (
            <div className="block overflow-x-hidden md:hidden">
              <>
                <div
                  onClick={() => setIsEditOrder(false)}
                  className="flex items-center gap-1 mt-14 md:mt-0 ml-4 cursor-pointer w-[100px] md:hidden"
                >
                  <IoIosArrowBack />
                  <p className="text-[#4a564d] font-[500] text-[13px]  font-onestMedium">
                    Cancel
                  </p>
                </div>
                <div className="mt-2 mb-[140px] sm:mt-[72px] rounded-lg px-4 md:px-10 pt-4 pb-8 md:py-8 max-w-[38rem] mx-4 sm:mx-auto border-[1px] border-[#E1F0DF] flex bg-dashboard-gradient justify-center">
                  <div className="w-full">
                    <h1 className="text-2xl mt-6 font-onestBold font-[800] text-[#0a5001] tracking-wide">
                      Edit Item
                    </h1>
                    <p className="mt-2 text-[#A0ABA3] text-[16px] font-onestMedium font-[500]">
                      Make edits and save changes to update
                    </p>

                    <>
                      <div className="mt-6">
                        <div className="flex">
                          <p className="text-[#4A564D] mb-2 text-sm leading-[20px] font-onestMedium">
                            Category
                          </p>
                          <span className="text-red-500 text-sm">*</span>
                        </div>
                        <Dropdown
                        loader={isLoadingProducts}
                          selectPlaceholderName="category"
                          dropdownStateName={editDetails?.category}
                          dropdownValue={editDetails?.category}
                          searchSubject={searchCategory}
                          setSearchSubject={setSearchCategory}
                          dropdownValueValid={editDetails.categoryValid}
                          dropdownValueValidShow
                          dropdownContainerClasses={`shadow-cardShadow-4 !w-full !right-0  border border-slate-100  rounded `}
                          dropdownOptions={[
                            {
                              customChild: (
                                <div>
                                  <div>
                                    {filteredCategories?.map(
                                      (item: any, idx: number) => {
                                        return (
                                          <div
                                            key={idx}
                                            onClick={() => {
                                              handleDeliveryChange(
                                                {
                                                  target: {
                                                    name: "category",
                                                    value: item.name,
                                                  },
                                                },
                                                "category"
                                              );

                                              setEditDetails((prev: any) => ({
                                                ...prev,
                                                category: item.name,
                                              }));
                                              setFormErrors((prev: any) => ({
                                                ...prev,
                                                category: "",
                                              }));
                                            }}
                                          >
                                            <div className="px-3 py-2  hover:bg-[#edecec] cursor-pointer">
                                              <p>{item?.name}</p>
                                            </div>
                                          </div>
                                        );
                                      }
                                    )}
                                  </div>
                                </div>
                              ),
                            },
                          ]}
                        />
                      </div>
                      <div className="mt-6">
                        <div className="flex">
                          <p className="text-[#4A564D] mb-2 text-sm leading-[20px] font-onestMedium">
                            Sub-category
                          </p>
                          <span className="text-red-500 text-sm">*</span>
                        </div>
                        <Dropdown
                          selectPlaceholderName="sub-category"
                          dropdownStateName={editDetails?.subCategory}
                          dropdownValue={editDetails?.subCategory}
                          searchSubject={searchSubCategory}
                          setSearchSubject={setSearchSubCategory}
                          dropdownValueValid={editDetails.subCategoryValid}
                          dropdownValueValidShow
                          dropdownContainerClasses={`shadow-cardShadow-4 !w-full !right-0  border border-slate-100  rounded `}
                          dropdownOptions={[
                            {
                              customChild: (
                                <div>
                                  <div>
                                    {filteredEditedSubCategories?.map(
                                      (item: any, idx: number) => {
                                        return (
                                          <div
                                            key={idx}
                                            onClick={() => {
                                              handleDeliveryChange(
                                                {
                                                  target: {
                                                    name: "subCategory",
                                                    value: item.name,
                                                  },
                                                },
                                                "subCategory"
                                              );

                                              setEditDetails((prev: any) => ({
                                                ...prev,
                                                subCategory: item.name,
                                              }));
                                              setFormErrors((prev: any) => ({
                                                ...prev,
                                                subCategory: "",
                                              }));
                                            }}
                                          >
                                            <div className="px-3 py-2  hover:bg-[#edecec] cursor-pointer">
                                              <p>{item?.name}</p>
                                            </div>
                                          </div>
                                        );
                                      }
                                    )}
                                  </div>
                                </div>
                              ),
                            },
                          ]}
                        />
                      </div>
                      <div className="mt-6">
                        <TextInput
                          type="text"
                          label="Quantity to store"
                          isRequired
                          name="quantity"
                          isError={editDetails.quantityValid}
                          value={editDetails.quantity}
                          onChange={(e: any) =>
                            handleDeliveryChange(e, "quantity")
                          }
                          placeholder="Enter quantity"
                        />
                      </div>
                      <div className="mt-6 flex sm:flex-row flex-col w-full sm:space-y-0 space-y-6 sm:space-x-3">
                        <div className="w-full">
                          <p className="text-[#4A564D] text-sm leading-[20px] font-onestMedium">
                            Item weight
                          </p>
                          <div
                            className={`flex justify-between ${
                              editDetails.weight === "" && "text-[#4A564D]"
                            } py-2 px-3 mt-[8px]   
       border  rounded-[8px] items-center `}
                          >
                            <p>
                              {" "}
                              {editDetails.weight === ""
                                ? "-"
                                : editDetails.weight}
                            </p>
                            <p>kg</p>
                          </div>
                        </div>
                        <div className="w-full">
                          <p
                            className={`${
                              editDetails.weight === "" && "text-[#4A564D]"
                            } text-sm leading-[20px] font-onestMedium`}
                          >
                            Item size
                          </p>
                          <div
                            className={` py-2 px-3 mt-[8px]   
       border  rounded-[8px] items-center `}
                          >
                            <p>
                              {" "}
                              {editDetails.size === "" ? "-" : editDetails.size}
                            </p>
                          </div>
                        </div>
                      </div>
                      <div className="mt-6">
                        <div className="w-full">
                          <p
                            className={`${
                              editDetails.description === "" && "text-[#4A564D]"
                            } text-sm leading-[20px] font-onestMedium`}
                          >
                            Description
                          </p>
                          <div
                            className={`py-2 h-20 px-3 mt-[8px]   
       border  rounded-[8px]  `}
                          >
                            <p>
                              {" "}
                              {editDetails.description === ""
                                ? "-"
                                : editDetails.description}
                            </p>
                          </div>
                        </div>
                      </div>
                      <div className="mt-6">
                        <TextInput
                          type="date"
                          label="Expiry Date"
                          isRequired
                          name="expiryDate"
                          isError={editDetails.expiryDateValid}
                          value={editDetails.expiryDate}
                          onChange={(e: any) =>
                            handleDeliveryChange(e, "expiryDate")
                          }
                          placeholder="Enter date"
                        />
                      </div>
                      <div className="mt-6">
                        <p className="text-[#4A564D] text-sm leading-[20px] font-onestMedium">
                          Item photo
                        </p>
                        {editDetails.image === "" ? (
                          <div
                            className={`flex flex-col justify-center items-center py-2 px-3 h-[104px] bg-[#F4F5F6] mt-[8px]   
             border  rounded-[8px] `}
                          >
                            <AiFillPicture className="text-[#68766B] text-xl" />
                            <p className="text-[#68766B] text-sm">
                              Item photo will be displayed here
                            </p>
                          </div>
                        ) : (
                          <div
                            className={`flex flex-col justify-center items-center py-2 px-3  mt-[8px]   
             border  rounded-[8px] `}
                          >
                            <img
                              src={editDetails.image}
                              alt={editDetails.sub_category}
                            />
                          </div>
                        )}
                      </div>
                    </>
                    <div className="mt-[32px] w-full">
                      <Button
                        className="!py-4 "
                        title="Update and save"
                        loading={loading}
                        disabled={loading}
                        onClick={() => {
                          const valid =
                            editDetails.categoryValid &&
                            editDetails.subCategoryValid &&
                            editDetails.quantityValid &&
                            editDetails.expiryDateValid;
                          if (Boolean(valid) && !loading) {
                            setIsEditOrder(false);
                          }
                        }}
                      />
                    </div>
                  </div>
                </div>
              </>
            </div>
          )}
        </div>
      )}
      <ModalContainer
        open={isStorageCreated}
        showCloseIcon={false}
        tailwindClassName="w-[350px] sm:w-[466px]"
        closeModal={() => setIsStorageCreated(false)}
      >
        <div className="w-full px-8 pb-8 bg-white flex flex-col  rounded-[12px] relative">
          <p className="text-lg pb-3 mt-6 border-b border-[#E4EBF5] text-center font-onestBold text-[#0A5001] ">
            New Storage Request Created
          </p>
          <img
            className="w-6 h-6 absolute right-6 top-6 cursor-pointer"
            onClick={() => {
              navigate("/dashboard/inventory/requests");
            }}
            src="/images/close.svg"
            alt=""
          />
          <div className="flex justify-center mt-8">
            {" "}
            <img
              src="/images/storeCreated.svg"
              alt=""
              className="w-[123px] h-[98px]"
            />
          </div>
          <p className="text-center text-[#4A564D] text-sm font-onestRegular mt-8">
            Your storage request has been submitted
          </p>
          <div className="flex justify-center mt-14">
            <Button
              title="View Request"
              onClick={() => {
                navigate("/dashboard/inventory/requests");
              }}
              className="!w-[210px] py-4 bg-[#18AF04] rounded-lg !font-[500] !text-base"
            />
          </div>
        </div>
      </ModalContainer>
    </>
  );
};

export default BulkStoreItem;
