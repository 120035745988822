import PendingInventoryRequests from "components/dashboard/Inventory/Requests/Pending";
import React, { useCallback, useEffect, useState } from "react";
import StorageInventoryRequests from "components/dashboard/Inventory/Requests/Storage";
import RetrievalInventoryRequests from "components/dashboard/Inventory/Requests/Retrieval";
import { inventoryInitials, requestTypes } from "constant/data";
import {
  useLazyDeleteRequestQuery,
  useRequestsQuery,
} from "services/slices/inventory";
import { InventoryOrderResponse } from "type/inventory";
import moment from "moment";
import DatePicker from "react-datepicker";
import { getDateRange } from "helpers/getDateRange";
import ModalContainer from "components/Modal";
import { Button } from "components/Button";
import { onDateChange, resetDateSelection } from "helpers/dateChange";
import { debouncedFunction } from "helpers/debouncedSearch";

const ViewInventoryRequests = () => {
  const [requestType, setRequestType] = useState<number>(1);
  const [filter, setFilter] = useState<string>("pending");
  const [formattedDate, setFormattedDate] = useState<string>("");
  const [formattedEndDate, setFormattedEndDate] = useState<string>("");
  const [selectedPeriod, setSelectedPeriod] = useState("last7days");
  const [selectedPeriodName, setSelectedPeriodName] = useState("Last 7 days");
  const [filterDate, setFilterDate] = useState(false);
  const [showDateModal, setShowDateModal] = useState<boolean>(false);
  const [isCancelOrder, setIsCancelOrder] = useState<boolean>(false);
  const [isCancelSuccess, setIsCancelSuccess] = useState<boolean>(false);
  const [deleteSuccess, setDeleteSuccess] = useState<boolean>(false);
  const [inventoryId, setInventoryId] = useState<number>(0);
  const [startDate, setStartDate] = useState<Date | null>(null);
  const [endDate, setEndDate] = useState<Date | null>(null);
  const [debounceSearch, setDebounceSearch] = useState<string>("");
  const [requestStatus, setRequestStatus] = useState<string>("");
  const [search, setSearch] = useState<string>("");
  const [inventoryEndDate, setInventoryEndDate] = useState<string>(
    moment().add(1, "days").format("YYYY-MM-DD")
  );
  const [inventoryStartDate, setInventoryStartDate] = useState<string>(
    moment().subtract(7, "days").format("YYYY-MM-DD")
  );
  const [page, setPage] = useState<number>(0);
  const [inventoryRequests, setInventoryRequests] =
    useState<InventoryOrderResponse>(inventoryInitials);
  const { data, isLoading, isFetching, refetch } = useRequestsQuery(
    {
      orderNumber: search,
      filter,
      start_date: inventoryStartDate,
      end_date: inventoryEndDate,
      page: page + 1,
    },
    {
      refetchOnMountOrArgChange: true,
      refetchOnFocus: false,
    }
  );

  const [triggerDelete, { isLoading: deleteLoading, isSuccess }] =
    useLazyDeleteRequestQuery();
  useEffect(() => {
    if (data) setInventoryRequests(data);
  }, [data]);

  useEffect(() => {
    if (selectedPeriod && selectedPeriod.toLowerCase() !== "custom") {
      const { startDate, endDate } = getDateRange(selectedPeriod);
      setInventoryStartDate(startDate);
      setInventoryEndDate(endDate);
    }
  }, [selectedPeriod]);

  useEffect(() => {
    if (Boolean(selectedPeriod.toLowerCase() === "custom")) {
      setShowDateModal(true);
    } else {
      setShowDateModal(false);
    }
  }, [selectedPeriod]);
  const handlePeriodChange = (value: string) => {
    setSelectedPeriod(value);
  };

  const handleDateChange = (date: Date | null) => {
    onDateChange(date, setStartDate, setFormattedDate);
  };
  const handleEndDateChange = (date: Date | null) => {
    onDateChange(date, setEndDate, setFormattedEndDate);
  };

  const onClearDate = () => {
    resetDateSelection(
      setSelectedPeriodName,
      setSelectedPeriod,
      setShowDateModal,
      setInventoryStartDate,
      setInventoryEndDate
    );
  };
  const onApplyDate = () => {
    setInventoryStartDate(formattedDate);
    setInventoryEndDate(formattedEndDate);
    setPage(0);
    refetch();
    setShowDateModal(false);
  };

  const debouncedSetSearch = useCallback((value: string) => {
    debouncedFunction(value, setSearch);
  }, []);
  const handleInputChange = (e: string) => {
    debouncedSetSearch(e);
    setDebounceSearch(e);
    setPage(0);
  };
  const handleCancel = (id: number) => {
    setInventoryId(id);
    setIsCancelOrder(true);
  };

  const handleDelete = async () => {
    try {
      await triggerDelete(inventoryId).unwrap();
      setIsCancelOrder(false);
      setIsCancelSuccess(true);
    } catch (error) {
      console.error("Delete failed:");
    }
  };
  useEffect(() => {
    if (isSuccess) {
      setIsCancelOrder(false);
      setIsCancelSuccess(true);
      setDeleteSuccess(true);
    }
  }, [isSuccess, deleteSuccess]);

  return (
    <div className="p-10">
      <h2 className="text-[#0A5001] text-2xl font-onestBold">
        Order Requests
      </h2>
      <div className="flex  items-center mt-10 space-x-4 xs:space-x-8">
        {requestTypes.map((data) => (
          <div
          key={data.id}
          >
          <p
            onClick={() => {
              setRequestType(data.id);
              setFilter(data.name);
            }}
            className={`text-[#4A564D] xs:block hidden cursor-pointer text-sm font-onestMedium  border-[#45C734] ${
              data.id === requestType && "border-b-[2px]"
            } pb-1.5 `}
          >
            {data.title}
          </p>
          <p
            key={data.id}
            onClick={() => {
              setRequestType(data.id);
              setFilter(data.name);
            }}
            className={`text-[#4A564D] xs:hidden block cursor-pointer text-sm font-onestMedium  border-[#45C734] ${
              data.id === requestType && "border-b-[2px]"
            } pb-1.5 `}
          >
            {data.miniTitle}
          </p>
          </div>
        ))}
      </div>
      {requestType === 1 && (
        <PendingInventoryRequests
          data={inventoryRequests}
          setPage={setPage}
          page={page}
          isLoading={isLoading || isFetching}
          handlePeriodChange={handlePeriodChange}
          setFilterDate={setFilterDate}
          filterDate={filterDate}
          selectedPeriodName={selectedPeriodName}
          selectedPeriod={selectedPeriod}
          setSelectedPeriodName={setSelectedPeriodName}
          handleInputChange={handleInputChange}
          debounceSearch={debounceSearch}
          handleCancel={handleCancel}
          setRequestStatus={setRequestStatus}
        />
      )}
      {requestType === 2 && (
        <StorageInventoryRequests
          data={inventoryRequests}
          setPage={setPage}
          page={page}
          isLoading={isLoading || isFetching}
          handlePeriodChange={handlePeriodChange}
          setFilterDate={setFilterDate}
          filterDate={filterDate}
          selectedPeriodName={selectedPeriodName}
          selectedPeriod={selectedPeriod}
          setSelectedPeriodName={setSelectedPeriodName}
          handleInputChange={handleInputChange}
          debounceSearch={debounceSearch}
          handleCancel={handleCancel}
          setRequestStatus={setRequestStatus}
        />
      )}
      {requestType === 3 && (
        <RetrievalInventoryRequests
          data={inventoryRequests}
          setPage={setPage}
          page={page}
          isLoading={isLoading || isFetching}
          handlePeriodChange={handlePeriodChange}
          setFilterDate={setFilterDate}
          filterDate={filterDate}
          selectedPeriodName={selectedPeriodName}
          selectedPeriod={selectedPeriod}
          setSelectedPeriodName={setSelectedPeriodName}
          handleInputChange={handleInputChange}
          debounceSearch={debounceSearch}
          handleCancel={handleCancel}
          setRequestStatus={setRequestStatus}
        />
      )}
      <ModalContainer
        open={showDateModal}
        showCloseIcon={false}
        tailwindClassName="max-w-[466px]"
      >
        <div className="w-full p-8 bg-white flex flex-col  rounded-[12px]">
          <p className="text-lg text-center pb-4 font-onestBold text-[#0A5001] ">
            Custom date range
          </p>
          <div className="flex gap-3 justify-between">
            <div className="">
              <DatePicker
                placeholderText={"Start date"}
                selected={startDate}
                onChange={handleDateChange}
                className="border-[1px] border-[#E4EBF5] w-[150px] p-2.5 rounded-lg outline-none"
              />
            </div>
            <div className="">
              <DatePicker
                placeholderText={"End date"}
                selected={endDate}
                onChange={handleEndDateChange}
                className="border-[1px] border-[#E4EBF5] w-[150px] p-2.5 rounded-lg outline-none"
              />
            </div>
          </div>
          <div className="flex justify-between gap-3 items-center pt-[30px]">
            <Button
              title="Clear"
              onClick={onClearDate}
              className="!py-[10px] !border-[#BEC0BE] !px-12 !w-fit !rounded-lg !bg-transparent !border !text-[#289D17]"
            />
            <Button
              title="Apply"
              className="!py-[10px] !w-fit !rounded-lg !px-12 !bg-[#127E04] !text-[#fff]"
              onClick={onApplyDate}
            />
          </div>
        </div>
      </ModalContainer>
      <ModalContainer
        open={isCancelOrder}
        showCloseIcon={false}
        tailwindClassName="w-[350px] sm:w-[466px]"
        closeModal={() => setIsCancelOrder(false)}
      >
        <div className="w-full px-8 pb-8 bg-white flex flex-col  rounded-[12px] relative">
          <p className="text-lg pb-3 mt-6 border-b border-[#E4EBF5] text-center font-onestBold text-[#0A5001] ">
            Cancel {requestStatus} Request?
          </p>
          <img
            className="w-6 h-6 absolute right-6 top-6 cursor-pointer"
            onClick={() => setIsCancelOrder(false)}
            src="/images/close.svg"
            alt=""
          />
          <div className="flex justify-center mt-8">
            {" "}
            <img
              src="/images/cancelrequest.svg"
              alt=""
              className="w-[123px] h-[98px]"
            />
          </div>
          <p className="text-center text-[#4A564D] text-sm font-onestRegular mt-8">
            Are you sure you want to cancel this {requestStatus} request?
          </p>
          <div className="flex justify-between space-x-5 w-full mt-14">
            <div className="w-full">
              <Button
                title="No, don’t cancel"
                disabled={deleteLoading}
                onClick={() => setIsCancelOrder(false)}
                className="px-5  !text-[#464748] py-2 bg-transparent border rounded-lg !font-[500] !text-base"
              />
            </div>
            <div className="w-full">
              <Button
                title="Yes, cancel"
                loading={deleteLoading}
                disabled={deleteLoading}
                onClick={handleDelete}
                className="!w-full  py-2 bg-[#BA3838] border rounded-lg !font-[500] !text-base"
              />
            </div>
          </div>
        </div>
      </ModalContainer>
      <ModalContainer
        open={isCancelSuccess}
        showCloseIcon={false}
        tailwindClassName="w-[350px] sm:w-[466px]"
        closeModal={() => setIsCancelSuccess(false)}
      >
        <div className="w-full px-8 pb-8 bg-white flex flex-col  rounded-[12px] relative">
          <p className="text-lg pb-3 mt-6 border-b border-[#E4EBF5] text-center font-onestBold text-[#0A5001] ">
            {requestStatus} Request Cancelled
          </p>
          <img
            className="w-6 h-6 absolute right-6 top-6 cursor-pointer"
            onClick={() => setIsCancelSuccess(false)}
            src="/images/close.svg"
            alt=""
          />
          <div className="flex justify-center mt-8">
            {" "}
            <img
              src="/images/storeCreated.svg"
              alt=""
              className="w-[123px] h-[98px]"
            />
          </div>
          <p className="text-center text-[#4A564D] text-sm font-onestRegular mt-8">
            Your {requestStatus} request has been cancelled and will no longer
            be processed
          </p>
          <div className="flex justify-center mt-14">
            <Button
              title="Okay"
              onClick={() => {
                refetch();
                setIsCancelSuccess(false);
              }}
              className="!w-fit !px-10 py-3 bg-[#18AF04] rounded-lg !font-[500] !text-base"
            />
          </div>
        </div>
      </ModalContainer>
    </div>
  );
};

export default ViewInventoryRequests;
