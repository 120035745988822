import { filterTerms } from "constant/data";
import React from "react";

interface PeriodFilterProps {
  setSelectedPeriodName: React.Dispatch<React.SetStateAction<string>>;
  setFilterDate: React.Dispatch<React.SetStateAction<boolean>>;
  selectedPeriod: string;
  filterDate: boolean;
  selectedPeriodName: string;
  handlePeriodChange: (e: string) => void;
}
const PeriodFilter = ({
  setSelectedPeriodName,
  selectedPeriod,
  handlePeriodChange,
  setFilterDate,
  filterDate,
  selectedPeriodName,
}: PeriodFilterProps) => {
  return (
    <div
      className="min-w-[180px] relative"
      onClick={() => setFilterDate(!filterDate)}
    >
      <div className="flex gap-2 cursor-pointer items-center bg-white  rounded-[9px] pl-4 pr-4 border-[1px] border-[#E4EBF5] ">
        <img
          className="w-[14px] h-[14px]"
          src="/images/filter.svg"
          alt="search-icon"
        />
        <p className="text-[13px] text-[#929DAE] py-[10px] outline-none">
          Filter: {selectedPeriodName}
        </p>
      </div>
      {filterDate && (
        <div className="p-4 border-[1px] w-[190px] left-0 top-11 bg-white z-10 absolute rounded-lg border-[#E4EBF5]">
          <h1 className="text-[#929DAE] pb-3 font-[900] text-xs font-onestBold">
            PERIOD
          </h1>
          <div className="">
            <div className="">
              {filterTerms.map((terms) => (
                <div
                  key={terms.value}
                  className="group flex my-3 gap-2.5 hover:bg-[#D4F3D5] text-[#289D17] hover:py-2 rounded-lg pl-2"
                  onClick={() => {
                    setSelectedPeriodName(terms.name);
                    handlePeriodChange(terms.value);
                  }}
                >
                  <p className="flex items-center mb-0 cursor-pointer ">
                    <span
                      className={`${
                        selectedPeriod === terms.value && "bg-[#289D17]"
                      } w-4 h-4 border border-gray-300 rounded-full flex items-center justify-center group-hover:bg-[#289D17]`}
                    >
                      <span className="w-2 h-2 bg-white rounded-full"></span>
                    </span>
                    <span
                      className={`${
                        selectedPeriod === terms.value
                          ? "text-[#289D17]"
                          : "text-[#464748] group-hover:text-[#289D17]"
                      } ml-3 text-sm  `}
                    >
                      {terms.name}
                    </span>
                  </p>
                </div>
              ))}
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default PeriodFilter;
