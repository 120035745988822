import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { getFromStorage } from "helpers";
import { ExtractOrder, InventoryDashboardResponse, InventoryOrderResponse, InventoryParam, OrderDeclineSubmission, OrderRequest, OrderUpdateSubmission, ProductResponse, RetrieveSubmission, SingleInventoryOrderResponse, Stock, StockParam, StockResponse, SubmissionResponse, ValidationResponse } from "type/inventory";


export const inventory = createApi({
  reducerPath: "inventory",
  baseQuery: fetchBaseQuery({
    baseUrl: `${process.env.REACT_APP_URL}`,
    prepareHeaders: (headers) => {
      headers.set("Content-Type", "application/json");
      const token = getFromStorage("token");
      const secretKey = getFromStorage("secret-key");
      if (token) {
        headers.set("Authorization", `Bearer ${token}`);
        headers.set("secret-key", `${secretKey}`);
      }
      return headers;
    },
  }),
  endpoints: (builder) => ({
    warehouseDashboard: builder.query<InventoryDashboardResponse, void>({
      query: () => ({
        url: `wms/dashboard`,
        method: "GET",
      }),
    }),
    requests: builder.query<InventoryOrderResponse, InventoryParam>({
      query: ({ filter, start_date, end_date, page, orderNumber }) => {
        const params = new URLSearchParams({
          filter,
          start_date,
          end_date,
          page: page.toString(),
        });
      
        if (orderNumber) {
          params.append("orderNumber", orderNumber);
        }
      
        return {
          url: `wms/orders?${params.toString()}`,
          method: "GET",
        };
      }
    }),
    requestbyId: builder.query<SingleInventoryOrderResponse, number>({
      query: ( id) => ({
        url: `wms/orders/${id}`,
        method: "GET",
      }),
    }),
    deleteRequest: builder.query<InventoryOrderResponse, number>({
      query: ( id) => ({
        url: `wms/orders/${id}`,
        method: "DELETE",
      }),
      
    }),
    ViewInventory: builder.query<StockResponse, StockParam>({
      query: ({filter,start_date, end_date, page}) => {
        const params = new URLSearchParams({
          filter,
          start_date,
          end_date,
          page: page.toString(),
        });
      
        if (filter) {
          params.append("filter", filter);
        }
      
        return {
          url: `wms/stocks?${params.toString()}`,
          method: "GET",
        };
      }
      
    }),
    viewInventoryId: builder.query<Stock, number>({
      query: ( id) => ({
        url: `wms/stocks/${id}`,
        method: "GET",
      }),
      
    }),
    getProducts: builder.query<ProductResponse, string>({
      query: (type) => ({
        url: `wms/product-category?type=${type}`,
        method: "GET",
      }),
    }),
    retrieveOrder: builder.mutation<SubmissionResponse, RetrieveSubmission>({
      query: (body) => ({
        url: `wms/orders/retrieve`,
        method: "POST",
        body,
      }),
    }),
    declineOrder: builder.mutation<SubmissionResponse, OrderDeclineSubmission>({
      query: ({body, id}) => ({
        url: `wms/orders/${id}/decline`,
        method: "POST",
        body,
      }),
    }),
    approveOrder: builder.query<SubmissionResponse, string>({
      query: (id) => ({
        url: `wms/orders/${id}/approve`,
        method: "GET",
      }),
    }),
    updateOrder: builder.mutation<SubmissionResponse, OrderUpdateSubmission>({
      query: ({body, id}) => ({
        url: `wms/orders/${id}`,
        method: "PUT",
        body,
      }),
    }),
    createOrder: builder.mutation<SubmissionResponse, OrderRequest>({
      query: (body) => ({
        url: `wms/orders`,
        method: "POST",
        body,
      }),
    }),
    extractOrder: builder.mutation<ValidationResponse, ExtractOrder>({
      query: (body) => ({
        url: `wms/orders/extract`,
        method: "POST",
        body,
      }),
    }),
  }),
});

export const {
  useWarehouseDashboardQuery,
  useRequestsQuery,
  useLazyDeleteRequestQuery,
  useViewInventoryQuery,
  useViewInventoryIdQuery,
  useRequestbyIdQuery,
  useGetProductsQuery,
  useRetrieveOrderMutation,
  useLazyApproveOrderQuery,
  useDeclineOrderMutation,
  useUpdateOrderMutation,
  useCreateOrderMutation,
  useExtractOrderMutation

} = inventory;
