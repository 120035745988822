import { Button } from "components/Button";
import React, { useEffect, useState } from "react";
import { GoPencil } from "react-icons/go";
import { RiDeleteBin6Line } from "react-icons/ri";
import ModalContainer from "components/Modal";
import { useNavigate, useParams } from "react-router-dom";
import {
  useRequestbyIdQuery,
  useUpdateOrderMutation,
} from "services/slices/inventory";
import { Loader } from "components/Loader";
import moment from "moment";
import { TableLoader } from "components/TableLoader";
import { IoMdAdd } from "react-icons/io";
import CreateStorageItem from "./CreateStorageItem";
import { initialItemDetails } from "constant/data";
import { formatMoney } from "helpers/formatMoney";
import { validateStoreItem } from "helpers/validation";
import { OrderUpdateSubmission } from "type/inventory";
import { handleErrorToast } from "helpers/toastMessage";

interface Item {
  id: any;
  subCategory: string;
  category: string;
  categoryName: string;
  quantity: number;
  weight: string;
  size: string;
  price: string;
  description: string;
  image: string;
  subCategoryId: string;
  expiryDate: string;
}

const EditStorageRequest = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const [isCancelStorage, setIsCancelStorage] = useState<boolean>(false);
  const [isChangesSaved, setIsChangesSaved] = useState<boolean>(false);
  const [addItem, setAddItem] = useState<boolean>(false);
  const [deleteId, setDeleteId] = useState<number[]>([]);
  const [storageId, setStorageId] = useState<string>("");
  const [items, setItems] = useState<Item[]>([]);
  const [isAddNew, setIsAddNew] = useState<boolean>(false);
  const [formErrors, setFormErrors] = useState<any>({});
  const [itemDetails, setItemDetails] = useState(initialItemDetails);
  const [categoryName, setCategoryName] = useState<string>("");
  const [searchCategory, setSearchCategory] = useState<string>("");
  const [warehouseDeliveryMode, setWarehouseDeliveryMode] =
    useState<string>("");
  const { data, isLoading } = useRequestbyIdQuery(Number(id), {
    skip: !id,
    refetchOnMountOrArgChange: true,
    refetchOnReconnect: true,
    refetchOnFocus: false,
  });
  const [updateOrder, { isLoading: isUpdating }] = useUpdateOrderMutation();
  console.log("items", items);
  useEffect(() => {
    if (data?.data?.order) {
      setItems(
        data.data.order.items.map((item: any) => ({
          id: item.id || `${Date.now() + "st"}`,
          name: item.product.name,
          subCategory: item.product.name,
          categoryName: item.product?.category?.name || "N/A",
          subCategoryId: item.product.id,
          category: item.product?.category?.id,
          quantity: item.quantity,
          weight: item.product.weight,
          size: item.product?.size,
          price: item.product.price_per_unit,
          description: item.product.description,
          expiryDate: item.expiry_date,
          image: item.product.image,
        }))
      );
      setItemDetails({
        category: "",
        categoryName: "",
        expiryDate: data.data.order.date.split(" ")[0],
        quantity: "",
        pickUpAddress: data.data.order.delivery_address || "",
        subCategoryId: "",
        weight: "",
        size: "",
        price: "",
        description: "",
        image: "",
        subCategory: "",
      });
      setWarehouseDeliveryMode(data.data.order.delivery_mode);
    }
  }, [data]);

  const handleDelete = (id: any) => {
    console.log("id", id);
    if (!id.toString().includes("st")) {
      setDeleteId((prev) => [...prev, Number(id)]);
    }
    setItems((prevItems) =>
      prevItems.filter((item) => String(item.id) !== String(id))
    );
    setIsCancelStorage(false);
  };
  const onSubmit = () => {
    setFormErrors(validateStoreItem(itemDetails));
    if (
      warehouseDeliveryMode !== "pickup" &&
      itemDetails.pickUpAddress === ""
    ) {
      setFormErrors((prev: any) => ({
        ...prev,
        pickUpAddress: "",
      }));
    } else if (
      warehouseDeliveryMode === "pickup" &&
      itemDetails.pickUpAddress === ""
    ) {
      setFormErrors((prev: any) => ({
        ...prev,
        pickUpAddress: "Delivery address is required",
      }));
    }
    if (warehouseDeliveryMode === "") {
      setFormErrors((prev: any) => ({
        ...prev,
        deliveryMode: "Delivery mode is required",
      }));
    }
    if (
      itemDetails.category !== "" &&
      !formErrors.pickUpAddress &&
      itemDetails.subCategory !== "" &&
      itemDetails.expiryDate !== "" &&
      warehouseDeliveryMode !== "" &&
      itemDetails.quantity !== ""
    ) {
      setItems((prevItems) => {
        const updatedItems = prevItems.some(
          (item) => item.subCategory === itemDetails.subCategory
        )
          ? prevItems.map((item) =>
              item.subCategory === itemDetails.subCategory
                ? {
                    ...item,
                    quantity: Number(itemDetails.quantity),
                    expiryDate: itemDetails.expiryDate,
                    subCategory: itemDetails.subCategory,
                    category: itemDetails.category,
                    categoryName: itemDetails.categoryName,
                    weight: itemDetails.weight,
                    subCategoryId: itemDetails.subCategoryId,
                    size: itemDetails.size,
                    price: itemDetails.price,
                    description: itemDetails.description,
                    image: itemDetails.image,
                    pickUpAddress: itemDetails.pickUpAddress,
                  }
                : item
            )
          : [
              ...prevItems,
              {
                id: `${Date.now() + "st"}`,
                subCategory: itemDetails.subCategory,
                category: itemDetails.category,
                categoryName: itemDetails.categoryName,
                quantity: Number(itemDetails.quantity),
                expiryDate: itemDetails.expiryDate,
                weight: itemDetails.weight,
                subCategoryId: itemDetails.subCategoryId,
                size: itemDetails.size,
                price: itemDetails.price,
                description: itemDetails.description,
                image: itemDetails.image,
                pickUpAddress: itemDetails.pickUpAddress,
              },
            ];

        return updatedItems;
      });

      setAddItem(false);
    }
  };
  const onUpdate = () => {
    const payload: OrderUpdateSubmission = {
      body: {
        deleted: deleteId,
        items: items
          .filter((item) => item.id.toString().includes("st"))
          .map((item) => ({
            sub_category: Number(item.subCategoryId),
            quantity: Number(item.quantity),
            category: Number(item.category),
            weight: Number(item.weight),
            size: Number(item.size),
            price_per_unit: Number(item.price),
            description: item.description,
            image: item.image,
            expiry_date: item.expiryDate,
          })),
        edited: items
          .filter((item) => !item.id.toString().includes("st"))
          .map((item) => ({
            id: item.id,
            sub_category: Number(item.subCategoryId),
            quantity: Number(item.quantity),
            category: Number(item.category),
            weight: Number(item.weight),
            size: Number(item.size),
            price_per_unit: Number(item.price),
            description: item.description,
            image: item.image,
            expiry_date: item.expiryDate,
          })),
      },
      id: id || "",
    };
    updateOrder(payload)
      .unwrap()
      .then((data) => {
        setIsChangesSaved(true);
      })
      .catch((e) => handleErrorToast(e));
  };
  return (
    <>
      {addItem ? (
        <CreateStorageItem
          warehouseDeliveryMode={warehouseDeliveryMode}
          setWarehouseDeliveryMode={setWarehouseDeliveryMode}
          formErrors={formErrors}
          categoryName={categoryName}
          itemDetails={itemDetails}
          searchCategory={searchCategory}
          setSearchCategory={setSearchCategory}
          setCategoryName={setCategoryName}
          setItemDetails={setItemDetails}
          setFormErrors={setFormErrors}
          onSubmit={onSubmit}
          isEdit={false}
          isAddNew={isAddNew}
          setIsAddNew={setIsAddNew}
        />
      ) : (
        <div className="overflow-x-hidden mx-9 mt-16">
          <div
            onClick={() => navigate(-1)}
            className=" flex mb-2  cursor-pointer"
          >
            <div>
              {" "}
              <img className="w-3 h-3" src="/images/backArrow.svg" alt="" />
            </div>
            <p className=" text-[#4a564d] font-[500] text-[13px] -mt-1 font-onestMedium">
              Go back
            </p>
          </div>
          <div className="mt-6">
            <div>
              <div className="flex space-x-4 items-center">
                <p className="text-textBlackGreytext-xl md:text-lg font-onestBold">
                  Edit Storage Request
                </p>
              </div>
              <div className="flex items-center space-x-1 mt-3">
                <p className="text-textLightGrey text-sm">Request ID:</p>
                {isLoading ? (
                  <Loader />
                ) : (
                  <p className="text-[#464748] text-sm">
                    {data?.data.order.order_number}
                  </p>
                )}
              </div>
              <div className="flex items-center space-x-1 mt-3">
                <p className="text-textLightGrey text-sm">Date requested:</p>
                {isLoading ? (
                  <Loader />
                ) : (
                  <p className="text-[#464748] text-sm">
                    {moment(data?.data.order.created_at).format(
                      "DD MMM YYYY hh:mma"
                    )}
                  </p>
                )}
              </div>
              <div>
                <p className="text-textheaderColor mt-6 text-sm">
                  Edit the details and save changes to update storage request
                </p>
              </div>
            </div>
            {isLoading ? (
              <div className="">
                <TableLoader rowLength={17} />
              </div>
            ) : (
              <div className="overflow-auto max-h-[600px] h-fit  relative">
                <div className="bg-[#F9F9FB] overflow-auto max-h-[600px] h-fit rounded-2xl w-full border border-borderGrey  pb-4 mt-10">
                  <table className="whitespace-nowrap bg-white pt-4 text-left border-spacing-2 rounded-2xl w-full shadow-[0px 4px 8px -2px rgba(16, 24, 40, 0.1), 0px 2px 4px -2px rgba(16, 24, 40, 0.06)]">
                    <thead className="bg-white sticky top-0 z-20 border-b-[1px] border-[#E4EBF5]">
                      <tr className="">
                        <th className="py-3 pl-4 pr-4  text-xs text-[#929DAE] font-onestRegular">
                          Item ID/SKU
                        </th>
                        <th className="py-3 text-xs pl-4  text-[#929DAE]  font-onestRegular">
                          Item name
                        </th>
                        <th className="py-3 text-xs pl-4 pr-4  text-[#929DAE]  font-onestRegular">
                          Category
                        </th>
                        <th className="py-3 text-xs pl-4 pr-4 text-[#929DAE]  font-onestRegular">
                          Quantity to store
                        </th>
                        <th className="py-3 text-xs pl-4 pr-20 text-[#929DAE]  font-onestRegular">
                          Item weight
                        </th>
                        <th className="py-3 text-xs pl-4  text-[#929DAE]  font-onestRegular">
                          Cost value per item
                        </th>
                        <th className="py-3 text-xs pl-4 pr-4  text-[#929DAE]  font-onestRegular">
                          Expiry date
                        </th>
                        <th className="py-3 sticky right-0 text-xs bg-white pl-4 pr-10 text-[#929DAE] border-l-[1px] border-[#E4EBF5] font-onestRegular">
                          Actions
                        </th>
                      </tr>
                    </thead>

                    <tbody className=" bg-white">
                      {items?.map((item) => (
                        <tr
                          key={item.id}
                          className="text-sm text-[#161718]  w-full font-[400] border-b-[1px] border-[#E4EBF5]"
                        >
                          <td className={` py-4 text-sm pl-3 pr-6 `}>
                            {item.id}
                          </td>
                          <td className={` !w-[300px]   pl-4 pr-4  `}>
                            {item.subCategory}
                          </td>
                          <td className={` py-4 pl-4 text-sm  w-[300px] px-4`}>
                            {item.categoryName}
                          </td>
                          <td className={`py-4 pl-4 text-sm  w-[300px]`}>
                            {formatMoney().format(item.quantity)}Qty
                          </td>
                          <td className={`py-4 pl-4 pr-6 !w-[150px]  text-sm `}>
                            {formatMoney().format(item.weight)}kg
                          </td>
                          <td
                            className={` !w-[400px] whitespace-normal  py-4 pl-4 `}
                          >
                            ₦{formatMoney().format(item.price)}
                          </td>
                          <td
                            className={` py-4 pl-4 text-sm border-l-[#fff] border-[1px] border-[#E4EBF5] w-[300px] px-4`}
                          >
                            {moment(item.expiryDate).format("DD MMM YYYY")}
                          </td>

                          <td className=" sticky right-0 pl-5 bg-white w-[300px] text-sm text-red-500 border-r-[#fff] border-l-[#fff] border-[1px] border-[#E4EBF5]">
                            <div className="flex items-center">
                              <Button
                                className=" !w-fit !px-0 !bg-transparent !text-[#1D4CC4]"
                                title="Edit"
                                disabled={isUpdating}
                                onClick={() => {
                                  setAddItem(true);
                                  setIsAddNew(true);
                                  setItemDetails((prev: any) => ({
                                    ...prev,
                                    category: item.category,
                                    categoryName: item.categoryName,
                                    quantity: item.quantity,
                                    weight: item.weight,
                                    subCategoryId: item.subCategoryId,
                                    subCategory: item.subCategory,
                                    size: item.size,
                                    price: item.price,
                                    description: item.description,
                                    expiryDate: item.expiryDate,
                                    image: item.image,
                                    pickUpAddress: "",
                                  }));
                                }}
                                iconLeft
                                icon={<GoPencil className="text-[#1D4CC4]" />}
                              />
                              <Button
                                className=" !w-fit  !bg-transparent !text-[#BA3838]"
                                title="Delete"
                                disabled={isUpdating}
                                onClick={() => {
                                  setStorageId(`${item.id}`);
                                  setIsCancelStorage(true);
                                }}
                                iconLeft
                                icon={
                                  <RiDeleteBin6Line className="text-[#BA3838]" />
                                }
                              />
                            </div>
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
              </div>
            )}
            {!isLoading && (
              <div className="mt-5 flex justify-between  space-x-3">
                <div>
                  <Button
                    className=" !w-fit !py-3 !px-5 !border !bg-transparent !text-[#18AF04]"
                    title="Cancel"
                    disabled={isUpdating}
                    onClick={() => navigate(-1)}
                  />
                </div>
                <div className="flex space-x-3">
                  <Button
                    className=" !w-fit !py-3 !px-5 !border !bg-transparent !text-[#18AF04]"
                    title="Add New Item"
                    disabled={isUpdating}
                    onClick={() => {
                      setIsAddNew(true);
                      setAddItem(true);
                      setItemDetails((prev: any) => ({
                        ...prev,
                        category: "",
                        categoryName: "",
                        quantity: "",
                        weight: "",
                        subCategoryId: "",
                        subCategory: "",
                        size: "",
                        price: "",
                        description: "",
                        expiryDate: "",
                        image: "",
                      }));
                    }}
                    iconLeft
                    icon={<IoMdAdd className="text-[#18AF04]" />}
                  />
                  <Button
                    className="!py-3 !w-fit "
                    title="Save Changes"
                    onClick={onUpdate}
                    loading={isUpdating}
                  />
                </div>
              </div>
            )}
          </div>
          <ModalContainer
            open={isCancelStorage}
            showCloseIcon={false}
            tailwindClassName="w-[350px] sm:w-[466px]"
            closeModal={() => setIsCancelStorage(false)}
          >
            <div className="w-full px-8 pb-8 bg-white flex flex-col  rounded-[12px] relative">
              <p className="text-lg pb-3 mt-6 border-b border-[#E4EBF5] text-center font-onestBold text-[#0A5001] ">
                Cancel Storage Request?
              </p>
              <img
                className="w-6 h-6 absolute right-6 top-6 cursor-pointer"
                onClick={() => setIsCancelStorage(false)}
                src="/images/close.svg"
                alt=""
              />
              <div className="flex justify-center mt-8">
                {" "}
                <img
                  src="/images/cancelrequest.svg"
                  alt=""
                  className="w-[123px] h-[98px]"
                />
              </div>
              <p className="text-center text-[#4A564D] text-sm font-onestRegular mt-8">
                Are you sure you want to cancel this storage request?
              </p>
              <div className="flex justify-between space-x-5 w-full mt-14">
                <div className="w-full">
                  <Button
                    title="No, don’t cancel"
                    onClick={() => setIsCancelStorage(false)}
                    className="px-5  !text-[#464748] py-2 bg-transparent border rounded-lg !font-[500] !text-base"
                  />
                </div>
                <div className="w-full">
                  <Button
                    title="Yes, cancel"
                    onClick={() => {
                      handleDelete(storageId);
                    }}
                    className="!w-full  py-2 bg-[#BA3838] border rounded-lg !font-[500] !text-base"
                  />
                </div>
              </div>
            </div>
          </ModalContainer>
          <ModalContainer
            open={isChangesSaved}
            showCloseIcon={false}
            tailwindClassName="w-[350px] sm:w-[466px]"
            closeModal={() => setIsChangesSaved(false)}
          >
            <div className="w-full px-8 pb-8 bg-white flex flex-col  rounded-[12px] relative">
              <p className="text-lg pb-3 mt-6 border-b border-[#E4EBF5] text-center font-onestBold text-[#0A5001] ">
                Changes Saved!
              </p>
              <img
                className="w-6 h-6 absolute right-6 top-6 cursor-pointer"
                onClick={() => setIsChangesSaved(false)}
                src="/images/close.svg"
                alt=""
              />
              <div className="flex justify-center mt-8">
                {" "}
                <img
                  src="/images/storeCreated.svg"
                  alt=""
                  className="w-[123px] h-[98px]"
                />
              </div>
              <p className="text-center text-[#4A564D] text-sm font-onestRegular mt-8">
                Your storage request has been updated successfully
              </p>
              <div className="flex justify-center mt-14">
                <Button
                  title="Okay"
                  onClick={() => navigate(`/dashboard/inventory/requests`)}
                  className="!w-fit !px-10 py-3 bg-[#18AF04] rounded-lg !font-[500] !text-base"
                />
              </div>
            </div>
          </ModalContainer>
        </div>
      )}
    </>
  );
};

export default EditStorageRequest;
