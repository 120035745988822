import { Button } from "components/Button";
import { DropDownSelect } from "components/common/form/Dropdown";
import MenuField from "components/dashboard/MenuField";
import ModalContainer from "components/Modal";
import DatePicker from "react-datepicker";
import React, { useEffect, useState } from "react";
import { MdOutlineCancel } from "react-icons/md";
import { useNavigate } from "react-router-dom";
import { filterTerms } from "constant/data";
import moment from "moment";
import { formatMoney } from "helpers/formatMoney";
import { TableLoader } from "components/TableLoader";
import PagePagination from "components/Pagination";
import EmptyShipmentTable from "pages/dashboard/shipment/EmptyShipmentTable";
import { useGetInvoicePDFQuery } from "services/slices/invoice";

interface Props {
  filterType: any;
  selectedPeriod: string;
  onApplyDate: () => void;
  onClearFilterDate: () => void;
  handleEndDateChange: (date: Date | null) => void;
  formattedEndDate: Date | null;
  handleDateChange: (date: Date | null) => void;
  handleDateRangeType: (option: any) => void;
  formattedDate: Date | null;
  toggleDateRangeType: any;
  isDateRangeType: boolean;
  setSelectedPeriod: React.Dispatch<React.SetStateAction<string>>;
  showFilterModal: boolean;
  filterQuery: any;
  isFilterType: boolean;
  toggleFilterType: any;
  debounceSearch: string;
  handleFilterType: (option: any) => void;
  handleInputChange: (e: any) => void;
  setPage: React.Dispatch<React.SetStateAction<number>>;
  page: number;
  invoiceData: any;
  isInvoiceLoading: boolean;
  setShowFilterModal: React.Dispatch<React.SetStateAction<boolean>>;
  setFilterQuery: React.Dispatch<React.SetStateAction<any>>;
}
const InvoiceHistory = ({
  handleDateChange,
  toggleDateRangeType,
  handleDateRangeType,
  formattedDate,
  onApplyDate,
  selectedPeriod,
  onClearFilterDate,
  formattedEndDate,
  filterType,
  handleEndDateChange,
  isDateRangeType,
  setSelectedPeriod,
  showFilterModal,
  filterQuery,
  isFilterType,
  toggleFilterType,
  handleFilterType,
  setPage,
  page,
  setFilterQuery,
  setShowFilterModal,
  handleInputChange,
  debounceSearch,
  invoiceData,
  isInvoiceLoading,
}: Props) => {
  const navigate = useNavigate();
  const [isDownload, setIsDownload] = useState<boolean>(false);
  const [id, setId] = useState<string>("");

  const getOptions = () => {
    return [
      {
        label: "View details",
        value: "VIEW_DETAILS",
        icon: "/images/resend-invite.svg",
      },
      {
        label: "Download",
        value: "DOWNLOAD",
        icon: "/images/download.svg",
      },
    ];
  };
  const { data: invoiceDownloadData, isLoading: isDownloadingInvoice } =
    useGetInvoicePDFQuery(
      {
        id: id || "",
      },
      {
        skip: !isDownload,
        refetchOnMountOrArgChange: true,
        refetchOnFocus: true,
        refetchOnReconnect: true,
      }
    );
  const triggerDownload = () => {
    if (!isDownloadingInvoice) {
      setIsDownload(true);
      setTimeout(() => {
        setIsDownload(false);
      }, 2000);
    }
  };

  useEffect(() => {
    if (invoiceDownloadData?.data?.pdf) {
      const blob = new Blob(
        [
          Uint8Array.from(atob(invoiceDownloadData.data.pdf), (c) =>
            c.charCodeAt(0)
          ),
        ],
        { type: "application/pdf" }
      );
      const link = document.createElement("a");
      link.href = URL.createObjectURL(blob);
      link.download = "invoice.pdf";
      link.click();
      URL.revokeObjectURL(link.href);
    }
  }, [invoiceDownloadData]);

  return (
    <div className="md:block hidden">
      <div className=" md:overflow-x-auto bg-[#F9F9FB] rounded-[8px] border-[#E4EBF5] border pb-[50px] h-fit p-4 mt-10">
        <div className="flex gap-3">
          <div className="w-full mb-4 md:w-[279px] bg-white flex gap-2 items-center rounded-[9px] pl-4 pr-4 border-[1px] border-[#E4EBF5] ">
            <img
              className="w-[14px] h-[14px]"
              src="/images/search-icon.svg"
              alt="search-icon"
            />
            <input
              className="text-[13px] py-[10px] w-[250px] outline-none"
              type="text"
              placeholder="Search by Invoice ID"
              onChange={(e) => {
                handleInputChange(e);
              }}
              value={debounceSearch}
            />
          </div>
          <div className="flex items-center gap-1 bg-white border-[1px] rounded-[9px] h-fit px-4 py-2.5  border-[#E4EBF5]">
            <div
              className="relative "
              onClick={() => {
                setShowFilterModal(true);
              }}
            >
              <div className="items-center flex gap-1  rounded-[9px]  cursor-pointer">
                <img className="" src="/images/filter.svg" alt="" />
                <p className="text-[13px] text-[#929DAE] w-fit px-2 outline-none">
                  Filter by:{" "}
                  {[
                    selectedPeriod !== "custom" ? `${selectedPeriod}` : "",
                    filterQuery?.name !== "" ? filterQuery?.name : "",
                  ]
                    .filter(Boolean)
                    .join(", ")}
                </p>
              </div>
            </div>
            <span onClick={onClearFilterDate} className="cursor-pointer">
              <MdOutlineCancel className="text-[#7e7f80]" />
            </span>
          </div>
        </div>
        {isInvoiceLoading ? (
          <TableLoader rowLength={17} />
        ) : (
          <>
            {invoiceData?.data?.data?.length > 0 ? (
              <>
                <div className="overflow-x-auto">
                  <table className="hidden md:table w-full whitespace-nowrap text-left p-4 border-spacing-2 rounded-2xl md:w-full shadow-[0px 4px 8px -2px rgba(16, 24, 40, 0.1), 0px 2px 4px -2px rgba(16, 24, 40, 0.06)]">
                    <thead className="bg-white !mx-12">
                      <tr className="border-b-[1px]   border-[#E4EBF5]">
                        <th className="py-4 text-left pl-4 pr-6 text-xs text-[#929DAE] font-onestRegular">
                          Client ID
                        </th>
                        <th className="py-4 text-left pl-5 pr-6 text-xs text-[#929DAE] font-onestRegular">
                          Invoice ID
                        </th>
                        <th className="py-4 text-left pl-6 pr-6  text-xs text-[#929DAE] font-onestRegular">
                          Start date
                        </th>
                        <th className="py-4 text-left  text-xs  pl-6 pr-4 text-[#929DAE] font-onestRegular">
                          End date
                        </th>
                        <th className="py-4 text-left  text-xs  pl-6 pr-4 text-[#929DAE] font-onestRegular">
                          No of orders
                        </th>
                        <th className="py-4 text-left  text-xs  pl-5 pr-4 text-[#929DAE] font-onestRegular">
                          Total cost of order
                        </th>
                        <th className="py-4 text-left  text-xs  pl-5 pr-4 text-[#929DAE] font-onestRegular">
                          VAT
                        </th>
                        <th className="py-4 text-left  text-xs  pl-6 pr-4 text-[#929DAE] font-onestRegular">
                          Total amount
                        </th>
                        <th className="py-4 text-left  text-xs  pl-4 pr-4 text-[#929DAE] font-onestRegular">
                          Amount paid
                        </th>
                        <th className="py-4 text-left  text-xs z-[0]  pl-6 pr-4 text-[#929DAE] font-onestRegular">
                          Payment status
                        </th>

                        <th className="sticky  z-[10]  right-0  text-xs text-[#929DAE] font-onestRegular">
                          <div className="pl-4 border-l py-5 bg-white">
                            Actions
                          </div>
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      {invoiceData?.data?.data?.map((invoice: any) => (
                        <tr
                          key={invoice.id}
                          className="bg-[#fff] py-8 border-b-[1px] font-onestRegular border-[#E4EBF5]"
                        >
                          <td className="py-4 pl-4  pr-6 text-sm text-[#0E1728] ">
                            {invoice.clientId}
                          </td>
                          <td className="pl-4 pr-6 text-sm text-[#0E1728]">
                            {invoice.invoiceNumber}
                          </td>
                          <td className="text-xs pl-4 pr-10">
                            <span className="text-sm text-[#0E1728] px-2 py-1 rounded-md font-[400] font-onesRegular">
                              {moment(invoice.startDate).format("DD MMM, YYYY")}
                            </span>
                          </td>
                          <td className="py-3 pl-6 pr-6 text-sm text-[#0E1728] font-onestRegular">
                            {moment(invoice.endDate).format("DD MMM, YYYY")}
                          </td>
                          <td className="py-3 pl-6 w-[50px] pr-6 text-sm text-[#0E1728] font-onestRegular">
                            {formatMoney().format(invoice.orderCount)}
                          </td>
                          <td className="text-sm pl-4 pr-10">
                            <span className="text-sm w-[50px] text-[#0E1728] px-2 py-1 rounded-md font-[400] font-onestRegular">
                              ₦{formatMoney().format(invoice.amount)}
                            </span>
                          </td>
                          <td className="text-sm pl-4 pr-10">
                            <span className="text-sm w-[50px] text-[#0E1728] px-2 py-1 rounded-md font-[400] font-onestRegular">
                              ₦{formatMoney().format(invoice.vat)}
                            </span>
                          </td>
                          <td className="text-sm pl-4 pr-10">
                            <span className="text-sm w-[50px] text-[#0E1728] px-2 py-1 rounded-md font-[400] font-onestRegular">
                              ₦{formatMoney().format(invoice.totalAmount)}
                            </span>
                          </td>
                          <td className="text-sm pl-4 pr-10">
                            <span className="text-sm w-[50px] text-[#0E1728] px-2 py-1 rounded-md font-[400] font-onestRegular">
                              ₦{formatMoney().format(invoice.paidAmount)}
                            </span>
                          </td>
                          <td className="text-sm pl-4 pr-10">
                            <span
                              className={`text-sm text-[#65513F] ${
                                invoice.status === "paid"
                                  ? "bg-[#D4F3D5] text-[#289D17]"
                                  : invoice.status === "partly_paid"
                                  ? "bg-[#F8E4D1] text-[#65513F]"
                                  : "bg-[#F8D1D1] text-[#E64500]"
                              } px-2 py-1 rounded-md font-[500] font-onestMedium`}
                            >
                              {invoice.status === "partly_paid"
                                ? "Partially Paid"
                                : invoice.status === "paid"
                                ? "Paid"
                                : "Unpaid"}
                            </span>
                          </td>

                          <td className="pr-1.5 text-center pl-4 sticky z-[10] border-l  right-0 py-5 flex gap-0.5  justify-center cursor-pointer bg-white">
                            {invoice.status !== "paid" && (
                              <Button
                                title={"Pay Now"}
                                onClick={() =>
                                  navigate(
                                    `/dashboard/invoice/${invoice?.invoiceNumber}/pay`
                                  )
                                }
                                iconLeft={true}
                                className="!pl-2 !pr-2 !pt-1 !pb-1 !rounded-[4px]"
                              />
                            )}

                            <MenuField
                              showInvoice
                              onClick={(x: string) => {
                                if (x === "VIEW_DETAILS") {
                                  navigate(
                                    `/dashboard/invoice/${invoice?.invoiceNumber}`
                                  );
                                  // return;
                                }
                                if (x === "DOWNLOAD") {
                                  setId(invoice?.invoiceNumber);
                                  triggerDownload();
                                  return;
                                }
                              }}
                              options={getOptions()}
                            >
                              <div className="align-bottom mt-1 ">
                                <svg
                                  width="24"
                                  height="24"
                                  viewBox="0 0 24 24"
                                  fill="none"
                                  xmlns="http://www.w3.org/2000/svg"
                                >
                                  <path
                                    d="M12 18L12 18.01M12 12L12 12.01M12 6L12 6.01M12 13C11.4477 13 11 12.5523 11 12C11 11.4477 11.4477 11 12 11C12.5523 11 13 11.4477 13 12C13 12.5523 12.5523 13 12 13ZM12 19C11.4477 19 11 18.5523 11 18C11 17.4477 11.4477 17 12 17C12.5523 17 13 17.4477 13 18C13 18.5523 12.5523 19 12 19ZM12 7C11.4477 7 11 6.55228 11 6C11 5.44772 11.4477 5 12 5C12.5523 5 13 5.44772 13 6C13 6.55228 12.5523 7 12 7Z"
                                    stroke="#5C6881"
                                    strokeWidth="2"
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                  />
                                </svg>
                              </div>
                            </MenuField>
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
              </>
            ) : (
              <EmptyShipmentTable
                image="/images/invoice.svg"
                title="You do not have any invoice yet"
                content="When you have invoice, it will show here"
              />
            )}
          </>
        )}
      </div>
      {invoiceData?.data?.data?.length > 0 &&
        invoiceData?.data?.meta?.total > invoiceData?.data?.meta?.per_page && (
          <div className="mt-3">
            <PagePagination
              totalPages={invoiceData?.data?.meta?.last_page}
              page={page}
              handlePageClick={(event) => {
                setPage(event.selected);
              }}
            />
          </div>
        )}
      <ModalContainer
        open={showFilterModal}
        showCloseIcon={false}
        tailwindClassName={`h-[450px] w-[300px] sm:w-[350px] rounded-md`}
      >
        <div className="p-6 w-full pb-4 h-full">
          <DropDownSelect
            title="Payment status"
            lists={filterType}
            value={filterQuery?.name}
            setQuery={setFilterQuery}
            isOpen={isFilterType}
            toggleDropdown={toggleFilterType}
            handleOptionClick={handleFilterType}
            placeholder="Select payment status"
            name="paymentStatus"
          />

          <div className="mt-10">
            <DropDownSelect
              title="Date range"
              lists={filterTerms}
              value={selectedPeriod}
              setQuery={setSelectedPeriod}
              isOpen={isDateRangeType}
              toggleDropdown={toggleDateRangeType}
              handleOptionClick={handleDateRangeType}
              placeholder="-Select a date range-"
              name="deliveryType"
            />
          </div>

          {selectedPeriod === "Custom" && (
            <div className="mt-10">
              <p className="text-sm pb-4 font-onestBold text-[#929DAE] ">
                Custom date range
              </p>
              <div className="grid grid-cols-2 gap-6">
                <div className="">
                  <DatePicker
                    placeholderText={"Start date"}
                    selected={formattedDate}
                    onChange={handleDateChange}
                    className="border-[1px] border-[#E4EBF5] w-[150px] p-2.5 rounded-lg outline-none"
                  />
                </div>
                <div className="">
                  <DatePicker
                    placeholderText={"End date"}
                    selected={formattedEndDate}
                    onChange={handleEndDateChange}
                    className="border-[1px] border-[#E4EBF5] w-[150px] p-2.5 rounded-lg outline-none"
                  />
                </div>
              </div>
            </div>
          )}

          <div
            className={` pt-[110px] flex justify-between gap-3 items-center pb-8 `}
          >
            <Button
              title="Clear"
              onClick={onClearFilterDate}
              className="!py-[10px] !border-[#BEC0BE] !px-12 !w-fit !rounded-lg !bg-transparent !border !text-[#464748]"
            />
            <Button
              title="Apply"
              className="!py-[10px] !w-fit !rounded-lg !px-12  !text-[#fff]"
              onClick={() => {
                onApplyDate();
              }}
            />
          </div>
        </div>
      </ModalContainer>
    </div>
  );
};

export default InvoiceHistory;
