import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { getFromStorage } from "helpers";

interface UpdateUsersProps {
    fullname: string,
    user_id: string,
    email: string,
    role: string
}

interface UpdateClientProps {
    businessAddress: string,
    phone: string,
    businessEmail: string,
    businessType: number,
    businessState: number
}

interface ChangePasswordProps {
    user_id: string,
    oldPassword: string,
    newPassword: string
}

interface UserNotificationProps {
    app_notification: boolean,
    email_notification: boolean,
    sms_notification: boolean
}


export const settings = createApi({
  reducerPath: "settings",
  baseQuery: fetchBaseQuery({
    baseUrl: `${process.env.REACT_APP_URL}`,
    prepareHeaders: (headers) => {
      headers.set("Content-Type", "application/json");
      const token = getFromStorage("token");
      const secretKey = getFromStorage("secret-key");
      if (token) {
        headers.set("Authorization", `Bearer ${token}`);
        headers.set("secret-key", `${secretKey}`);
      }
      return headers;
    },
  }),
  endpoints: (builder) => ({
    
      updateUsers: builder.mutation<any, UpdateUsersProps>({
        query: (body) => ({
          url: `update-users`,
          method: "PUT",
          body,
        }),
      }),
      validateBusiness: builder.mutation<any, any>({
        query: (body) => ({
          url: `onboarding/validateData`,
          method: "POST",
          body,
        }),
      }),
      updateClient: builder.mutation<any, UpdateClientProps>({
        query: (body) => ({
          url: `clients`,
          method: "PUT",
          body,
        }),
     }),
      changePassword: builder.mutation<any, ChangePasswordProps>({
        query: (body) => ({
          url: `user/changePassword`,
          method: "POST",
          body,
        }),
    }),
      userNotification: builder.mutation<any, UserNotificationProps>({
        query: (body) => ({
          url: `user-notification`,
          method: "PUT",
          body,
        }),
    })
  }),
});

export const {
  useUpdateUsersMutation,
  useUpdateClientMutation,
  useValidateBusinessMutation,
  useChangePasswordMutation,
  useUserNotificationMutation,
} = settings;
