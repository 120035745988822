import React, { useEffect, useState } from "react";
import { getFromStorage, removeFromStorage, saveToStorage } from "helpers";
import { useLocation, useNavigate } from "react-router-dom";

interface PrivateRoutesProps {
  children: React.ReactNode;
}

const PrivateRoutes: React.FC<PrivateRoutesProps> = ({ children }) => {
  const navigate = useNavigate();
  const location = useLocation();
  const [token, setToken] = useState(() => getFromStorage("token"));
  const [secretKey, setSecretKey] = useState(() =>
    getFromStorage("secret-key")
  );
  const [expireDate, setExpireDate] = useState(() =>
    getFromStorage("expire-token-date")
  );
  const invoicePermission = getFromStorage("invoice");

  const formatDate = (date: Date): string => {
    const padZero = (num: number): string => num.toString().padStart(2, "0");

    const year = date.getFullYear();
    const month = padZero(date.getMonth() + 1);
    const day = padZero(date.getDate());
    const hours = padZero(date.getHours());
    const minutes = padZero(date.getMinutes());
    const seconds = padZero(date.getSeconds());

    return `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`;
  };
  const urlPath = getFromStorage("path");

  const handleStorageChange = () => {
    setToken(getFromStorage("token"));
    setSecretKey(getFromStorage("secret-key"));
    setExpireDate(getFromStorage("expire-token-date"));
  };
  useEffect(() => {
    if (location.pathname === "/dashboard/invoice") {
      saveToStorage("path", "/dashboard/invoice");
    }
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (location.pathname === "/dashboard/logout") {
      removeFromStorage("webhook");
      removeFromStorage("integration-key");
      removeFromStorage("token");
      removeFromStorage("secret-key");
      removeFromStorage("fez-org-code");
      removeFromStorage("fez-org-id");
      removeFromStorage("fez-org-email");
      removeFromStorage("expire-token-date");
      removeFromStorage("fez-user");
      removeFromStorage("fez-ref");
      removeFromStorage("fez-org-details");
      removeFromStorage("invoice");
      removeFromStorage("path");
      navigate("/");
    }
  }, [location.pathname, navigate]);

  useEffect(() => {
    const date = new Date();

    if (expireDate && expireDate <= formatDate(date)) {
      removeFromStorage("webhook");
      removeFromStorage("integration-key");
      removeFromStorage("token");
      removeFromStorage("secret-key");
      removeFromStorage("fez-org-code");
      removeFromStorage("fez-org-id");
      removeFromStorage("fez-org-email");
      removeFromStorage("expire-token-date");
      removeFromStorage("fez-user");
      removeFromStorage("fez-ref");
      removeFromStorage("fez-org-details");
      removeFromStorage("invoice");
      removeFromStorage("path");
      navigate("/");
    }
  }, [expireDate, navigate]);

  useEffect(() => {
    if (!token || !secretKey) {
      navigate("/");
      return;
    }

    if (token && !location.pathname.startsWith("/dashboard")) {
      navigate("/dashboard");
    }

    if (
      token &&
      invoicePermission === "true" &&
      urlPath === "/dashboard/invoice"
    ) {
      removeFromStorage("path");
      navigate("/dashboard/invoice");
    }
    window.addEventListener("storage", handleStorageChange);

    return () => {
      window.removeEventListener("storage", handleStorageChange);
    };
  }, [
    navigate,
    invoicePermission,
    token,
    urlPath,
    secretKey,
    location.pathname,
  ]);

  if (!token || !secretKey) {
    return null;
  }

  return <>{children}</>;
};

export default PrivateRoutes;
