import { Button } from "components/Button";
import { Loader } from "components/Loader";
import { TextInput } from "components/common/form/Input";
import { getFromStorage, saveToStorage } from "helpers";
import { copyToClipboard } from "helpers/handleCopy";
import { handleErrorToast, handleSuccessToast } from "helpers/toastMessage";
import React, { useEffect, useState } from "react";
import { MdOutlineModeEditOutline } from "react-icons/md";
import {
  useClientApiKeyMutation,
  useGetClientKeyQuery,
  useUpdateWebhookMutation,
} from "services/slices/developer";

function Developer() {
  const hook = getFromStorage("webhook");
  const [key, setKey] = useState<string>("");
  const [webhook, setWebhook] = useState<any>(hook === "undefined" ? "" : hook);
  const [isKeyVisible, setIsKeyVisible] = useState<boolean>(false);
  const [copySuccess, setCopySuccess] = useState<boolean>(false);
  const [editWebhook, setEditWebhook] = useState<boolean>(false);
  const [copyKeySuccess, setCopyKeySuccess] = useState<boolean>(false);
  const { data, isLoading, refetch } = useGetClientKeyQuery();
  const [clientApiKey, { isLoading: isUpdateKeyLoading }] =
    useClientApiKeyMutation();
  const [updateWebhook, { isLoading: isUpdateWebhookLoading }] =
    useUpdateWebhookMutation();
  const userData: any = getFromStorage("fez-user");
  const [urlError, setUrlError] = useState<{ url: string | {} }>({ url: {} });

  const regex =
    /(https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|www\.[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9]+\.[^\s]{2,}|www\.[a-zA-Z0-9]+\.[^\s]{2,})/gi;

  const validateStepOne = (value: string) => {
    let errors: any = {};
    if (!value || value === "") {
      errors.url = "Url is required";
    } else if (value !== "" && !regex.test(value)) {
      errors.url = "Invalid url format";
    } else {
      errors.url = {};
    }
    return errors;
  };

  useEffect(() => {
    setKey(data?.["Secret Key"]);
  }, [data]);

  const handleCopyWebhook = () => {
    copyToClipboard(webhook, setCopySuccess);

  }
  const handleCopyKey = () => {
    copyToClipboard(key, setCopyKeySuccess);
  };
  const onUpdateKey = async () => {
    const userId = JSON.parse(userData);
    const body = {
      client_id: userId?.userID,
    };
    if (userId?.userID && key !== "") {
      await clientApiKey(body)
        .unwrap()
        .then((data) => {
          if (data) {
            saveToStorage("secret-key", data?.["Secret Key"]);
            refetch();
            handleSuccessToast("updated key successfully");
          }
        })
        .catch((e: any) => {
          handleErrorToast(e);
        });
    }
  };

  const onUpdateWebhook = async () => {
    const userId = JSON.parse(userData);
    const body = {
      client_id: userId?.userID,
      webhook,
    };
    if (
      userId?.userID &&
      webhook !== "" &&
      urlError.url &&
      typeof urlError.url === "object" &&
      Object.keys(urlError.url).length === 0 &&
      !urlError.url.hasOwnProperty("Invalid url format")
    ) {
      await updateWebhook(body)
        .unwrap()
        .then((data) => {
          if (data) {
            handleSuccessToast("updated key successfully");
            setEditWebhook(false);
          }
        })
        .catch((e: any) => {
          handleErrorToast(e);
        });
    }
  };

  return (
    <div className="px-3 md:px-[32px] lg:px-[72px] mt-[60px] mb-5">
      <h1 className="text-lg font-onestBold font-[800] text-[#0E1728] tracking-wide">
        Developers
      </h1>
      <p className="font-onestRegular text-sm text-[#4A564D]">
        Connect your site to Fez Delivery and arrange deliveries easily.
      </p>
      <div className="rounded-lg md:mx-4 sm:mx-auto border-[1px] p-6 mt-12 border-[#E1F0DF] bg-dashboard-gradient">
        <h1 className="text-lg font-onestBold font-[800] text-[#0A5001] tracking-wide">
          API Documentation
        </h1>
        <div className="xl:flex xl:flex-row justify-between">
          <p className="font-onestRegular xl:w-[60%] text-base mt-[15px] text-[#4A564D]">
            This API collection has been developed to allow businesses integrate
            into our logistic platform. Once our team completes KYC and all
            internal review of your business, they will share your API
            credentials with you for you to commence integration.
          </p>
          <div className="flex items-end mt-6 xl:mt-0">
            <a
              href="https://fez-delivery-co.gitbook.io/fezcorporate-api-docs/"
              target="__blank"
              className="text-[#289D17] -pb-3 mr-2 underline py-0"
            >
              Learn more about this documentation
            </a>
            <a
              href="https://fez-delivery-co.gitbook.io/fezcorporate-api-docs/"
              target="__blank"
            >
              {" "}
              <img src="/images/link.svg" alt="" />
            </a>
          </div>
        </div>
      </div>
      <div className="rounded-lg md:mx-4 sm:mx-auto border-[1px] p-6 mt-12 border-[#E1F0DF] bg-dashboard-gradient">
        <h1 className="text-lg font-onestBold font-[800] text-[#0A5001] tracking-wide">
          Manage API Key
        </h1>
        {isLoading ? (
          <Loader />
        ) : (
          <div className="mt-10 flex justify-between gap-3">
            <div className="border-[1px]  py-2.5 w-[100%] xl:w-[90%] overflow-hidden text-ellipsis whitespace-nowrap rounded-lg border-[#D4F3D5] flex justify-between">
              <h1 className="text-sm break-words pl-4 w-[90%]">
                {isKeyVisible ? key : "*".repeat(key?.length)}
              </h1>
              <div className="w-[10%] flex justify-end bg-white pr-4">
                <img
                  src={
                    isKeyVisible
                      ? "/images/show-password.svg"
                      : "/images/hide-password.png"
                  }
                  alt={
                    isKeyVisible ? "show-password-icon" : "hide-password-icon"
                  }
                  className="h-5 w-5 cursor-pointer"
                  onClick={() => setIsKeyVisible(!isKeyVisible)}
                />
              </div>
            </div>
            <div
              className="flex items-center gap-2 border-[1px] px-4 py-2.5 rounded-lg border-[#D4F3D5] cursor-pointer"
              onClick={handleCopyKey}
            >
              <img src="/images/copy.svg" alt="copy-icon" />
              <p className="text-[#BEC0BE] text-sm">
                {copyKeySuccess ? "Copied!" : "Copy"}
              </p>
            </div>
          </div>
        )}

        <Button
          onClick={onUpdateKey}
          className={`!w-[200px] mt-6 py-4 ${"!bg-[#8C9D8A]"}`}
          loading={isUpdateKeyLoading}
          disabled={isUpdateKeyLoading}
          title="Update Secret Key"
        />
      </div>
      <div className="rounded-lg md:mx-4 sm:mx-auto border-[1px] p-6 mt-12 border-[#E1F0DF] bg-dashboard-gradient">
        <h1 className="text-lg font-onestBold font-[800] text-[#0A5001] tracking-wide">
          Manage Webhook
        </h1>
        <div className="mt-10 flex justify-between gap-3">
          <div
            className={`border-[1px] ${
              !editWebhook && "py-2.5"
            } w-[100%] rounded-lg overflow-hidden text-ellipsis whitespace-nowrap border-[#D4F3D5] flex items-center justify-between`}
          >
            {editWebhook && (
              <TextInput
                value={webhook||""}
                type="string"
                placeholder="webhook"
                className="!mt-0 !border-0"
                onChange={(e) => {
                  setUrlError(validateStepOne(e.target.value));
                  setWebhook(e.target.value);
                }}
              />
            )}
            {!editWebhook && (
              <h1 className="text-sm break-words w-[90%] pl-4">
             {webhook ? webhook : ""}
              </h1>
            )}
            <div className="w-[10%] flex justify-end bg-white pr-4">
              <span
                className="text-sm cursor-pointer flex items-center gap-1 text-[#0A5001] px-2 rounded-md font-[400] font-onestRegular"
                onClick={() => setEditWebhook(true)}
              >
                <MdOutlineModeEditOutline />
                Edit
              </span>
            </div>
          </div>
          <div
            className="flex items-center gap-2 border-[1px] px-4 py-2.5 rounded-lg border-[#D4F3D5] cursor-pointer"
            onClick={handleCopyWebhook}
          >
            <img src="/images/copy.svg" alt="copy-icon" />
            <p className="text-[#BEC0BE] text-sm">
              {copySuccess ? "Copied!" : "Copy"}
            </p>
          </div>
        </div>
        <p className="text-xs ml-2 text-[#0A5001]">
          Format: https://www.url.com
        </p>
        {typeof urlError.url === "string" && (
          <span className="text-xs text-[#BA3838] font-onestMedium">
            {urlError.url}
          </span>
        )}

        <Button
          onClick={onUpdateWebhook}
          className={`!w-[200px] mt-6 py-4 ${"!bg-[#8C9D8A]"}`}
          loading={isUpdateWebhookLoading}
          disabled={isUpdateWebhookLoading}
          title="Update Webhook"
        />
      </div>
    </div>
  );
}

export default Developer;
