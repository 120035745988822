import { Button } from "components/Button";
import MenuField from "components/dashboard/MenuField";
import { Loader } from "components/Loader";
import ModalContainer from "components/Modal";
import { TableLoader } from "components/TableLoader";
import { formatMoney } from "helpers/formatMoney";
import { getApprovalStatusStyles } from "helpers/getApprovalStatusStyles";
import { handleErrorToast } from "helpers/toastMessage";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import {
  useLazyDeleteRequestQuery,
  useRequestbyIdQuery,
} from "services/slices/inventory";

const RetrieveInventoryItem = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const [isCancelOrder, setIsCancelOrder] = useState<boolean>(false);
  const [isCancelSuccess, setIsCancelSuccess] = useState<boolean>(false);
  const [requestStatus, setRequestStatus] = useState<string>("");
  const [inventoryId, setInventoryId] = useState<number>(0);
  const [deleteSuccess, setDeleteSuccess] = useState<boolean>(false);
  const { data, isLoading } = useRequestbyIdQuery(Number(id), {
    skip: !id,
  });
  const [triggerDelete, { isLoading: deleteLoading, isSuccess }] =
    useLazyDeleteRequestQuery();

  const getOptions = () => {
    return [
      {
        label: "Edit request",
        value: "edit",
        icon: "/images/edit.svg",
      },
      {
        label: "Cancel request",
        value: "cancel",
        icon: "/images/Delete.svg",
      },
    ];
  };
  const handleDelete = async () => {
    try {
      await triggerDelete(inventoryId).unwrap();
      setIsCancelOrder(false);
      setIsCancelSuccess(true);
    } catch (error) {
      handleErrorToast(error);
    }
  };
  useEffect(() => {
    if (isSuccess) {
      setIsCancelOrder(false);
      setIsCancelSuccess(true);
      setDeleteSuccess(true);
    }
  }, [isSuccess, deleteSuccess]);

  return (
    <div className="mt-7 min-w-[300px] md:min-w-[657px] pt-10 sm:mt-[72px] rounded-lg max-w-[38rem] mx-4 md:mx-auto border-[1px] border-[#E1F0DF] flex flex-col bg-dashboard-gradient justify-center">
      <div className="w-full px-4 sm:px-10 font-onestMedium">
        <div
          onClick={() => navigate(-1)}
          className=" flex mb-5  cursor-pointer"
        >
          <div>
            {" "}
            <img className="w-3 h-3" src="/images/backArrow.svg" alt="" />
          </div>
          <p className=" text-[#4a564d] font-[500] text-[13px] -mt-1 font-onestMedium">
            Go back
          </p>
        </div>

        <div className="mb-5">
          <div className="flex items-center justify-between">
            <div className="flex space-x-4 items-center">
              <p className="text-textBlackGreytext-xl md:text-lg font-onestBold">
                Retrieval Request
              </p>
              <div
                className={`
                 ${getApprovalStatusStyles(data?.data.order.status)}
                rounded text-[13px] font-onestBold px-2 py-[2px]`}
              >
                <p>
                  {data?.data?.order?.status
                    ? data.data.order.status.charAt(0).toUpperCase() +
                      data.data.order.status.slice(1)
                    : "N/A"}
                </p>
              </div>
            </div>
            <div className="">
              <MenuField
                retrieve
                onClick={(x: string) => {
                  if (x === "edit") {
                    navigate(`/dashboard/inventory/edit-storage-request/${id}`);
                    return;
                  }
                  if (x === "cancel") {
                    const status =
                      data?.data.order.type.toLowerCase() === "addition"
                        ? "Storage"
                        : "Retrieval";
                    setRequestStatus(status);
                    setInventoryId(Number(id));
                    setIsCancelOrder(true);
                    return;
                  }
                }}
                options={getOptions()}
              >
                <div className="align-bottom mt-1 ">
                  <svg
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M12 18L12 18.01M12 12L12 12.01M12 6L12 6.01M12 13C11.4477 13 11 12.5523 11 12C11 11.4477 11.4477 11 12 11C12.5523 11 13 11.4477 13 12C13 12.5523 12.5523 13 12 13ZM12 19C11.4477 19 11 18.5523 11 18C11 17.4477 11.4477 17 12 17C12.5523 17 13 17.4477 13 18C13 18.5523 12.5523 19 12 19ZM12 7C11.4477 7 11 6.55228 11 6C11 5.44772 11.4477 5 12 5C12.5523 5 13 5.44772 13 6C13 6.55228 12.5523 7 12 7Z"
                      stroke="#5C6881"
                      strokeWidth="2"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                  </svg>
                </div>
              </MenuField>
            </div>
          </div>
          <div className="flex items-center space-x-1 mt-3">
            <p className="text-textLightGrey text-sm">Request ID:</p>
            {isLoading ? (
              <Loader />
            ) : (
              <p className="text-[#464748] text-sm">
                {data?.data.order.order_number}
              </p>
            )}
          </div>
          <div className="flex items-center space-x-1 mt-3">
            <p className="text-textLightGrey text-sm">Date requested:</p>
            {isLoading ? (
              <Loader />
            ) : (
              <p className="text-[#464748] text-sm">
                {moment(data?.data.order.created_at).format(
                  "DD MMM YYYY hh:mma"
                )}
              </p>
            )}
          </div>
          <p className="mt-8 font-onestRegular text-[#4A564D]">
            You will be notified when your request has been confirmed
          </p>
        </div>
        {isLoading ? (
          <div className="">
            <TableLoader rowLength={7} />
          </div>
        ) : (
          <div className="overflow-auto mt-8 relative mb-10">
            <div className=" overflow-auto rounded-lg w-full border border-borderGrey">
              <table className="whitespace-nowrap text-left border-spacing-2 rounded-2xl w-full shadow-[0px 4px 8px -2px rgba(16, 24, 40, 0.1), 0px 2px 4px -2px rgba(16, 24, 40, 0.06)]">
                <thead className="bg-white sticky top-0 z-20 border-b-[1px] border-[#E4EBF5]">
                  <tr className="">
                    <th className="py-4 text-xs pl-4  text-[#929DAE] font-onestRegular">
                      Item name
                    </th>

                    <th className="py-4 text-xs pl-4 pr-4 text-[#929DAE] font-onestRegular">
                      Sub-cateogry
                    </th>
                    <th className="py-4 text-xs pl-4 pr-4 text-[#929DAE] font-onestRegular">
                      Quantity to retrieve
                    </th>
                  </tr>
                </thead>

                <tbody className="overflow-y-auto bg-white max-h-[500px]">
                  {data?.data.order.items.map((item) => (
                    <tr
                      key={item.id}
                      className="text-sm  text-[#161718]  w-full font-[400] border-b-[1px] border-[#E4EBF5]"
                    >
                      <td
                        className={` whitespace-normal py-4 pl-4 flex gap-x-1.5 pr-4 `}
                      >
                        {item.product.name}
                      </td>
                      <td className={`  pl-4 text-sx  px-4`}>Drinks</td>
                      <td className={` pl-4 text-xs `}>
                        {" "}
                        {formatMoney().format(item.quantity)} Qty
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </div>
        )}
      </div>
      <ModalContainer
        open={isCancelOrder}
        showCloseIcon={false}
        tailwindClassName="w-[350px] sm:w-[466px]"
        closeModal={() => setIsCancelOrder(false)}
      >
        <div className="w-full px-8 pb-8 bg-white flex flex-col  rounded-[12px] relative">
          <p className="text-lg pb-3 mt-6 border-b border-[#E4EBF5] text-center font-onestBold text-[#0A5001] ">
            Cancel {requestStatus} Request?
          </p>
          <img
            className="w-6 h-6 absolute right-6 top-6 cursor-pointer"
            onClick={() => setIsCancelOrder(false)}
            src="/images/close.svg"
            alt=""
          />
          <div className="flex justify-center mt-8">
            {" "}
            <img
              src="/images/cancelrequest.svg"
              alt=""
              className="w-[123px] h-[98px]"
            />
          </div>
          <p className="text-center text-[#4A564D] text-sm font-onestRegular mt-8">
            Are you sure you want to cancel this {requestStatus} request?
          </p>
          <div className="flex justify-between space-x-5 w-full mt-14">
            <div className="w-full">
              <Button
                title="No, don’t cancel"
                disabled={deleteLoading}
                onClick={() => setIsCancelOrder(false)}
                className="px-5  !text-[#464748] py-2 bg-transparent border rounded-lg !font-[500] !text-base"
              />
            </div>
            <div className="w-full">
              <Button
                title="Yes, cancel"
                loading={deleteLoading}
                disabled={deleteLoading}
                onClick={handleDelete}
                className="!w-full  py-2 bg-[#BA3838] border rounded-lg !font-[500] !text-base"
              />
            </div>
          </div>
        </div>
      </ModalContainer>
      <ModalContainer
        open={isCancelSuccess}
        showCloseIcon={false}
        tailwindClassName="w-[350px] sm:w-[466px]"
        closeModal={() => setIsCancelSuccess(false)}
      >
        <div className="w-full px-8 pb-8 bg-white flex flex-col  rounded-[12px] relative">
          <p className="text-lg pb-3 mt-6 border-b border-[#E4EBF5] text-center font-onestBold text-[#0A5001] ">
            {requestStatus} Request Cancelled
          </p>
          <img
            className="w-6 h-6 absolute right-6 top-6 cursor-pointer"
            onClick={() => setIsCancelSuccess(false)}
            src="/images/close.svg"
            alt=""
          />
          <div className="flex justify-center mt-8">
            {" "}
            <img
              src="/images/storeCreated.svg"
              alt=""
              className="w-[123px] h-[98px]"
            />
          </div>
          <p className="text-center text-[#4A564D] text-sm font-onestRegular mt-8">
            Your {requestStatus} request has been cancelled and will no longer
            be processed
          </p>
          <div className="flex justify-center mt-14">
            <Button
              title="Okay"
              onClick={() => {
                setIsCancelSuccess(false);
                navigate("/dashboard/inventory/requests");
              }}
              className="!w-fit !px-10 py-3 bg-[#18AF04] rounded-lg !font-[500] !text-base"
            />
          </div>
        </div>
      </ModalContainer>
    </div>
  );
};

export default RetrieveInventoryItem;
