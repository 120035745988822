import React, { useEffect, useState } from "react";
import { useGetPricingQuery } from "services/slices/pricing";
import {
  MdOutlineKeyboardArrowDown,
  MdOutlineKeyboardArrowUp,
} from "react-icons/md";
import { Loader } from "components/Loader";
import { handleErrorToast } from "helpers/toastMessage";

interface CostItem {
  "Destination State": string;
  "Cost Of Delivery": number;
}

const PricingTable = () => {
  const [sortedCost, setSortedCost] = useState<CostItem[]>([]);
  const { data, error, isLoading, isError } = useGetPricingQuery();

  useEffect(() => {
    if (data?.Cost && Array.isArray(data.Cost)) {
      const sortedCost: CostItem[] = [...data.Cost].sort((a, b) => {
        if (a["Destination State"] < b["Destination State"]) {
          return -1;
        }
        if (a["Destination State"] > b["Destination State"]) {
          return 1;
        }
        return 0;
      });
      setSortedCost(sortedCost);
    }
  }, [data]);

  useEffect(() => {
    if (isError && error) {
      handleErrorToast(error);
    }
  }, [error, isError]);

  return (
    <div>
      <div className="overflow-x-auto mb-5 bg-[#F9F9FB] p-4 mt-[60px] mx-5 lg:mx-[72px]">
        {isLoading ? (
          <Loader />
        ) : (
          <div className="whitespace-nowrap flex flex-col text-left lg:p-4 border-spacing-2 rounded-2xl w-full shadow-[0px 4px 8px -2px rgba(16, 24, 40, 0.1), 0px 2px 4px -2px rgba(16, 24, 40, 0.06)]">
            <div className="lg:flex hidden w-full space-x-6">
              <div className="w-full">
                <div className="flex text-[#929DAE] text-sm font-onestMedium mb-2 justify-between">
                  <div className="flex space-x-3 items-center">
                    <p>Destination State</p>
                    <div>
                      <span
                        className="cursor-pointer w-fit"
                        // onClick={() => setSortStateAscending(true)}
                      >
                        <MdOutlineKeyboardArrowUp />
                      </span>
                      <span
                        className="cursor-pointer w-fit"
                        // onClick={() => setSortStateDescending(true)}
                      >
                        <MdOutlineKeyboardArrowDown />
                      </span>
                    </div>
                  </div>
                  <div className="flex space-x-3 items-center">
                    <p>Cost of Delivery</p>
                    <div>
                      <span
                        className="cursor-pointer w-fit"
                        // onClick={() => setSortCostAscending(true)}
                      >
                        <MdOutlineKeyboardArrowUp />
                      </span>
                      <span
                        className="cursor-pointer w-fit"
                        // onClick={() => setSortCostDescending(true)}
                      >
                        <MdOutlineKeyboardArrowDown />
                      </span>
                    </div>
                  </div>
                </div>
                <div className="w-full bg-[#fff] p-3">
                  {sortedCost
                    ?.slice(0, 19)
                    .map((item: CostItem, idx: number) => (
                      <div
                        key={idx}
                        className="text-sm flex justify-between py-3 text-[#161718] font-[400] w-full border-b-[1px] border-[#E4EBF5]"
                      >
                        <div className="w-full">
                          {" "}
                          {item["Destination State"]}
                        </div>
                        <div className="">{item["Cost Of Delivery"]}</div>
                      </div>
                    ))}
                </div>
              </div>
              <div className="w-full mt-8">
                <div className="w-full bg-[#fff] px-3">
                  {sortedCost
                    .slice(19, sortedCost.length)
                    .map((item: CostItem, idx: number) => (
                      <div
                        key={idx}
                        className="text-sm flex justify-between py-3 text-[#161718] bg-[#fff] font-[400] w-full border-b-[1px] border-[#E4EBF5]"
                      >
                        <div className="w-full">
                          {" "}
                          {item["Destination State"]}
                        </div>
                        <div className="">{item["Cost Of Delivery"]}</div>
                      </div>
                    ))}
                </div>
              </div>
            </div>
            <div className="lg:hidden block w-full space-x-6">
              <div className="w-full">
                <div className="flex text-[#929DAE] text-sm font-onestMedium mb-2 justify-between">
                  <div className="flex space-x-3 items-center">
                    <p>Destination State</p>
                    <div>
                      <span className="cursor-pointer">
                        <MdOutlineKeyboardArrowUp />
                      </span>
                      <span className="cursor-pointer">
                        <MdOutlineKeyboardArrowDown />
                      </span>
                    </div>
                  </div>
                  <div className="flex space-x-3 items-center">
                    <p>Cost of Delivery</p>
                    <div>
                      <span className="cursor-pointer">
                        <MdOutlineKeyboardArrowUp />
                      </span>
                      <span className="cursor-pointer">
                        <MdOutlineKeyboardArrowDown />
                      </span>
                    </div>
                  </div>
                </div>
                <div className="w-full bg-[#fff] p-3">
                  {sortedCost.map((item: CostItem, idx: number) => (
                    <div
                      key={idx}
                      className="text-sm flex justify-between py-2.5 text-[#161718] font-[400] mt-3 w-full border-b-[1px] border-[#E4EBF5]"
                    >
                      <div className="w-full"> {item["Destination State"]}</div>
                      <div className="">{item["Cost Of Delivery"]}</div>
                    </div>
                  ))}
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default PricingTable;
